import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import {
  fetchDictionaryEntries,
  submitDictionaryEntry,
  deleteDictionaryEntry
} from "../../store/dictionaries/actions";
import { makeSelectDictionaryEntries } from "../../store/dictionaries/selectors";
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";
import { dictionaryEntriesType } from "../../types";
import DictionaryEditor from "../../components/Dictionary";

class DictionaryPage extends Component {
  componentDidMount() {
    const {
      fetchDictionaryEntries: fetchEntries,
      match: {
        params: { code }
      }
    } = this.props;

    fetchEntries(code);
  }

  render() {
    const {
      dictionaryEntries,
      submitDictionaryEntry: onSubmitDictionaryEntry,
      deleteDictionaryEntry: onDeleteDictionaryEntry,

      match: {
        params: { code }
      },
      loaders
    } = this.props;

    return (
      <DictionaryEditor
        dictionaryEntries={dictionaryEntries}
        dictionaryCode={code}
        onSubmitDictionaryEntry={onSubmitDictionaryEntry}
        onDeleteDictionaryEntry={onDeleteDictionaryEntry}
        loaders={loaders}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  dictionaryEntries: makeSelectDictionaryEntries(
    state,
    props.match.params.code
  ),
  loaders: makeSelectLocalLoaders(state)
});

const mapDispatchToProps = dispatch => ({
  fetchDictionaryEntries: code => dispatch(fetchDictionaryEntries(code)),
  submitDictionaryEntry: (dictionaryCode, entryCode) =>
    dispatch(submitDictionaryEntry(dictionaryCode, entryCode)),
  deleteDictionaryEntry: (dictionaryCode, entryCode) =>
    dispatch(deleteDictionaryEntry(dictionaryCode, entryCode))
});

DictionaryPage.propTypes = {
  dictionaryEntries: dictionaryEntriesType.isRequired,

  fetchDictionaryEntries: PropTypes.func.isRequired,
  submitDictionaryEntry: PropTypes.func.isRequired,
  deleteDictionaryEntry: PropTypes.func.isRequired,

  match: PropTypes.shape().isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionaryPage);
