import {
  FETCH_DICTIONARIES,
  SET_DICTIONARIES,
  FETCH_DICTIONARIES_LIST,
  SET_DICTIONARIES_LIST,
  FETCH_DICTIONARY_ENTRIES,
  SET_DICTIONARY_ENTRIES,
  SET_DICTIONARY_IN_PROJECT,
  DELETE_DICTIONARY_ENTRY, SUBMIT_DICTIONARY, SUBMIT_DICTIONARY_ENTRY
} from "./constants";


export const setChangeDictionaryEntry = dictionaryEntry => ({
  type: SET_DICTIONARY_IN_PROJECT,
  payload:  dictionaryEntry
})

export const fetchDictionaries = dictionaries => ({
  type: FETCH_DICTIONARIES,
  payload: {
    dictionaries
  }
});

export const setDictionaries = dictionaries => ({
  type: SET_DICTIONARIES,
  payload: {
    dictionaries
  }
});

export const fetchDictionariesList = () => ({
  type: FETCH_DICTIONARIES_LIST
});

export const setDictionariesList = dictionaries => ({
  type: SET_DICTIONARIES_LIST,
  payload: {
    dictionaries
  }
});

export const fetchDictionaryEntries = code => ({
  type: FETCH_DICTIONARY_ENTRIES,
  payload: {
    code
  }
});

export const setDictionaryEntries = (code, dictionaryEntries) => ({
  type: SET_DICTIONARY_ENTRIES,
  payload: {
    code,
    dictionaryEntries
  }
});

export const submitDictionaryEntry = (dictionaryCode, dictionaryEntry) => ({
  type: SUBMIT_DICTIONARY_ENTRY,
  payload: {
    dictionaryCode,
    dictionaryEntry
  }
});

export const submitDictionary = (dictionary) => ({
  type: SUBMIT_DICTIONARY,
  payload: dictionary
});

export const deleteDictionaryEntry = (dictionaryCode, entryCode) => ({
  type: DELETE_DICTIONARY_ENTRY,
  payload: {
    dictionaryCode,
    entryCode
  }
});
