import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import {
  ModalContainer,
  ModalBody,
  ModalHeader,
  ModalHeaderTitle,
  ButtonsRow
} from "../../common/Modal/styles";
import { ButtonIconClose, ButtonSave } from "../../common/Buttons";
import { Input, ErrorMessage } from "../../common/styles";
import { ButtonRedOutline } from "../../common/Buttons/styles";
import RowBlock from "../../common/RowBlock";
import FileUploader from "../../DocumentsEditor/DocumentsForm/FileUploader";

class TechSolutionUploadModal extends Component {
  handleCloseModal = () => {
    const { resetForm, closeModal } = this.props;
    resetForm();
    closeModal();
  };

  render() {
    const {
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors
    } = this.props;

    return (
      <ModalContainer>
        <ModalHeader>
          <ModalHeaderTitle>ДОБАВЛЕНИЕ ТЕХНИЧЕСКОГО РЕШЕНИЯ</ModalHeaderTitle>
          <ButtonIconClose onClick={this.handleCloseModal} />
        </ModalHeader>

        <ModalBody>
          <RowBlock text="ДОКУМЕНТ" labelWidth="200px" alignSelfCenter>
            <FileUploader
              selectedFile={values.file}
              onChange={file => {
                setFieldValue("file", file);
              }}
            />
          </RowBlock>
          <RowBlock text="ОПИСАНИЕ" labelWidth="200px" alignSelfCenter>
            <Input
              width="80%"
              name="comment"
              onChange={handleChange}
              maxLength={253}
            />
          </RowBlock>

          {errors && errors.file && <ErrorMessage>{errors.file}</ErrorMessage>}

          <ButtonsRow>
            <ButtonSave
              type="submit"
              text="Сохранить"
              onClick={handleSubmit}
              margin="0 25px 0 0"
            />
            <ButtonRedOutline onClick={this.handleCloseModal}>
              Отмена
            </ButtonRedOutline>
          </ButtonsRow>
        </ModalBody>
      </ModalContainer>
    );
  }
}

TechSolutionUploadModal.propTypes = {
  // onSubmitForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,

  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  resetForm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    file: null,
    comment: ""
  }),
  validate: values => {
    const errors = {};
    if (!values.file) {
      errors.file = "Пожалуйста, прикрепите файл";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    // documentType is hardcoded 41 for techSolutions dictionary entry
    const newDoc = {
      id: 0,
      comment: values.comment,
      file: values.file,
      documentType: { id: 41 }
    };
    formikBag.props.onSubmitForm(newDoc);
    formikBag.resetForm();
    formikBag.props.closeModal();
  }
})(TechSolutionUploadModal);
