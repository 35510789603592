import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { modalStateType } from "../../types";
import { modalStyles } from "../../utils/theme";
import { hideModal } from "../../store/modal/actions";
import { makeSelectModal } from "../../store/modal/selectors";
import {
  MODAL_TYPE_CONFIRMATION_DIALOG,
  MODAL_TYPE_NOTIFICATION_ALERT,
  MODAL_TYPE_DECLINE_RELEASE,
  MODAL_TYPE_VIEW_EXTERNAL_SYSTEM,
  MODAL_TYPE_TECH_SOLUTION_UPLOAD,
  MODAL_TYPE_DOCUMENT_UPLOAD,
  MODAL_TYPE_USERS_PERMISSION
} from "../../store/modal/constants";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import NotificationAlert from "../../components/common/NotificationAlert";
import ReleaseDeclineModal from "../../components/ProjectView/ReleaseDeclineModal";
import ExternalSystemViewModal from "../../components/ProjectView/ExternalSystemViewModal";
import TechSolutionUploadModal from "../../components/TechSolutions/TechSolutionUploadModal";
import DocumentUploadModal from "../../components/DocumentsEditor/DocumentUploadModal";
import UsersPermissionModal from "../../components/UsersPermission/UsersPermissionModal";

Modal.setAppElement("#root");

const MODAL_COMPONENTS = {
  [MODAL_TYPE_CONFIRMATION_DIALOG]: ConfirmationDialog,
  [MODAL_TYPE_DECLINE_RELEASE]: ReleaseDeclineModal,
  [MODAL_TYPE_VIEW_EXTERNAL_SYSTEM]: ExternalSystemViewModal,
  [MODAL_TYPE_TECH_SOLUTION_UPLOAD]: TechSolutionUploadModal,
  [MODAL_TYPE_DOCUMENT_UPLOAD]: DocumentUploadModal,
  [MODAL_TYPE_NOTIFICATION_ALERT]: NotificationAlert,
  [MODAL_TYPE_USERS_PERMISSION]: UsersPermissionModal
};

const ModalContainer = ({ modal, closeModal }) => {
  if (!modal.modalType) return null;

  const ModalComponent = MODAL_COMPONENTS[modal.modalType];

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel={modal.props.contentLabel}
      shouldCloseOnOverlayClick={false}
    >
      <ModalComponent closeModal={closeModal} {...modal.props} />
    </Modal>
  );
};

const mapStateToProps = state => ({
  modal: makeSelectModal(state)
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal())
});

ModalContainer.propTypes = {
  modal: modalStateType.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);
