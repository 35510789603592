import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { projectType, StageType } from "../../../types";
import Pagination from "../../common/Pagination";
import { IconNext, IconPrevious } from "../../common/Icon";
import { RELEASE_STATUS_TO_TEXT_MAPPING , GL_HOST, SONAR_URL } from "../../../utils/constants";
import { getDateTimeFormatted } from "../../../utils/helpers";
import ReleaseSubComponent from "./ReleaseSubComponent";
import ReleaseOptionsDropdown from "./ReleaseOptionsDropdown";
import PipelineStageView from "../../PipelineStageView";
import { TextRegular , LinkSpan } from "../../common/styles";


function ReleasesTable(props) {
  const {
    isAdmin,
    isUser,
    project,
    onEditRelease,
    onDeleteRelease,
    onChangeReleaseStatus,
    onDeclineRelease,
    onDownloadDocument,
    onViewSignedDocument,
    onViewExternalSystem,
    userInPermissions = {},
  } = props;

  return (
    <ReactTable
      PaginationComponent={Pagination}
      showPagination={project.releases.length > 0}
      defaultPageSize={5}
      minRows={0}
      data={project.releases}
      defaultSorted={[
        {
          id: "buildDate",
          desc: true,
        },
      ]}
      columns={[
        {
          Header: "",
          expander: true,
          Expander: ({ isExpanded }) => (
            <div>{isExpanded ? <IconPrevious /> : <IconNext />}</div>
          ),
        },
        {
          Header: "РЕЛИЗ",
          accessor: "name",
        },
        {
          Header: "СТАТУС",
          id: "status",
          style: { background: "#f4f4f4" },
          accessor: (d) => RELEASE_STATUS_TO_TEXT_MAPPING[d.status] || "-",
        },
        {
          Header: "КОММЕНТАРИЙ",
          id: "comment",
          accessor: "comment",
        },
        {
          Header: "ДАТА",
          id: "buildDate",
          style: { background: "#f4f4f4" },
          accessor: "buildDate",
          Cell: (cell) => (
            <div>{getDateTimeFormatted(cell.original.buildDate)}</div> 
          )
        },
        {
          Header: "СБОРКА",
          minWidth: 120,
          style: { height: "36px" },
          Cell: (row) => (
            <>
              <PipelineStageView
                project={project}
                pipeline={row.original.pipelineDTO}
                stageType={StageType.BUILD}
              />
            </>
          ),
        },
        {
          Header: "ТЕСТИРОВАНИЕ",
          id: "platform",
          style: {
            overflow: "visible",
            textAlign: "left",
            background: "#f4f4f4",
          },

          Cell: (row) => (
            <>
              <PipelineStageView
                project={project}
                pipeline={row.original.pipelineDTO}
                stageType={StageType.TEST_AUTOMATIC}
              />
            </>
          ),
        },
        {
          Header: "ИСПРАВЛЕННЫЕ ЗАМЕЧАНИЯ",
          id: "developmentEnvironment",
          accessor: "-",
          style: { height: "36px" },
          Cell: (row) => {
            const t = row.original.tasks.map((v) => v.taskNumber).join(", ");

            return (
              <>
                <TextRegular>{t}</TextRegular>
              </>
            );
          },
        },
        {
          Header: "АНАЛИЗ",
          id: "analiz",
          accessor: "-",
          style: { background: "#f4f4f4", height: "36px" },
          Cell: (row) => {
            const release = row.original;
            let analysisPassed = null;
            let repoSonar = null;
            release.tags.forEach((tag) => {
              analysisPassed = get(tag, "analysisResult.passed", -1);
              repoSonar = get(tag, "repository.sonar", "");
            });
            return (
              <>
                {analysisPassed !== -1
                  ? (SONAR_URL && (
                      <LinkSpan
                        onClick={() => {
                          window.open(`${SONAR_URL}${repoSonar}`, "_blank");
                        }}
                      >
                        {analysisPassed ? "passed" : "failed"}
                      </LinkSpan>
                    )) ||
                    (analysisPassed ? "passed" : "failed")
                  : "Нет данных"}
              </>
            );
          },
        },
        {
          Header: "АРТЕФАКТЫ",
          id: "artefact",
          accessor: "-",
          style: { background: "#f4f4f4", height: "36px" },
          Cell: (row) => {
            const release = row.original;
            let buildSuccess = null;
            let artifactsPath = null;
            const gitlabGroupName = get(project, "gitlabGroup.name", "");
            const projectGitlabPath = `${GL_HOST}/${gitlabGroupName}/`;

            release.tags.forEach((tag) => {
              buildSuccess = get(tag, "buildResult.success", -1);
              const repoName = get(tag, "repository.name", "");
              const formattedRepoName = repoName.split(" ").join("-");
              const pipelineId = get(tag, "buildResult.gitlabId", "");
              const repoPath = projectGitlabPath + formattedRepoName;
              const pipelinePath = `${repoPath}/pipelines/${pipelineId!=1 ?pipelineId:''}`;
              artifactsPath = buildSuccess === -1  ? "" : pipelinePath;
            });
            return (
              <>
                {artifactsPath ? (
                  <LinkSpan
                    onClick={() => {
                      window.open(artifactsPath, "_blank");
                    }}
                  >
                    Артефакты сборки
                  </LinkSpan>
                ) : (
                  "Нет данных"
                )}
              </>
            );
          },
        },
        {
          sortable: false,
          Header: "ДЕЙСТВИЯ",
          id: "options",
          maxWidth: 100,
          style: { overflow: "visible", textAlign: "center" },
          accessor: "id",
          Cell: (row) => {
            if (
              !isAdmin &&
              (row.original.status === "EXPLOITED" ||
                (isUser && row.original.status === "IN_PROGRESS"))
            )
              return null;

            return (
              <ReleaseOptionsDropdown
                userInPermissions={userInPermissions}
                release={row.original}
                projectId={project.id}
                gitlabGroupId={get(project, "gitlabGroup.gitLabId", "")}
                isAdmin={isAdmin}
                isUser={isUser}
                onEditRelease={onEditRelease}
                onChangeReleaseStatus={onChangeReleaseStatus}
                onDeleteRelease={onDeleteRelease}
                onDeclineRelease={onDeclineRelease}
              />
            );
          },
        },
      ]}
      SubComponent={(row) => (
        <ReleaseSubComponent
          release={row.original}
          project={project}
          onDownloadDocument={onDownloadDocument}
          onViewSignedDocument={onViewSignedDocument}
          onViewExternalSystem={onViewExternalSystem}
        />
      )}
    />
  );
}

ReleasesTable.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
  project: projectType.isRequired,
  onEditRelease: PropTypes.func.isRequired,
  onDeleteRelease: PropTypes.func.isRequired,
  onChangeReleaseStatus: PropTypes.func.isRequired,
  onDeclineRelease: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onViewSignedDocument: PropTypes.func.isRequired,
  onViewExternalSystem: PropTypes.func.isRequired,

  /*eslint-disable*/
  isExpanded: PropTypes.bool,
  /* eslint-enable */
};

export default ReleasesTable;
