import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const Content = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 25px;
  display: flex;
  flex-direction: column;
  
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`;

export const DropdownMenuItem = styled.button`
  display: flex;
  padding: 8px 23px;
  align-items: center;

  border: none;
  background-color: transparent;
  cursor: pointer;
  
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: #394a58;
  
  .icon {
    margin-right: 18px;
  }
   
  &:hover {
    background: #eee;
  }
`;
