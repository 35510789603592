import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  getReleaseById: id => axios.get(`${API_HOST}/api/fpa/v1/releases/${id}`),
  submitRelease: (method, projectId, release) =>
    axios({
      method,
      url: `${API_HOST}/api/fpa/v1/releases/project/${projectId}`,
      data: release
    }),
  deleteRelease: id => axios.delete(`${API_HOST}/api/fpa/v1/releases/${id}`),
  changeReleaseStatus: (status, id, comment, params) =>
    axios({
      method: "POST",
      url: `${API_HOST}/api/fpa/v1/status/release/${id}/${status}`,
      data: comment,
      params
    }),
  submitExternalSystem: extSys =>
    axios({
      method: "POST",
      url: `${API_HOST}/api/fpa/v1/external-systems`,
      data: extSys
    })
};
