import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RowBlock from "../common/RowBlock";
import { Select, Input } from "../common/styles";
import { ButtonSave } from "../common/Buttons";
import { ButtonsRow } from "../common/Modal/styles";

export const AddTagForm = (props) => {
  const { listBranches, handleSubmit } = props;

  const [values, setValues] = useState({
    commitSHA: "",
    name: "",
  });

	useEffect(() => {
		const firstEl = listBranches[0]
		setValues({
			commitSHA: firstEl ? firstEl.commitSHA : "",
			name: "",
		});
	}, [listBranches])

  const onSubmit = () => {
    handleSubmit(values);
  };
	const changeField = (key, value) => {
		setValues({
      ...values,
      [key]: value,
    })
	}
  const changeTag = (value) => {
    const text = value.trim();
    setValues({
      ...values,
      name: text,
    })
  }
  return (
    <>
      <RowBlock text="Ветка:" labelWidth="200px" alignSelfCenter>
        <Select
          name="commitSHA"
          value={values.commitSHA}
          onChange={({target}) => changeField("commitSHA", target.value)}
          maxLength={253}
        >
          {listBranches.map((item) => (
            <option
              key={item.name}
              value={item.name}
              label={item.name}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      <RowBlock text="Тег:" labelWidth="200px" alignSelfCenter>
        <Input
          name="name"
          type="text"
          value={values.name}
          onChange={({target}) => changeTag(target.value)}
          maxLength={253}
        />
      </RowBlock>

      <ButtonsRow>
        <ButtonSave
          type="submit"
          text="Сохранить"
          margin="0 25px 0 0"
          onClick={onSubmit}
        />
      </ButtonsRow>
    </>
  );
};

AddTagForm.propTypes = {
  listBranches: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};
