import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  ${props => `
    width: ${props.size};
    height: ${props.size};
  `}
  ${props => (props.dropdown && `
    width: 12px;
    height: 12px;
    display: inline-block;
    top: 4px;
    left: 10px;
  `)}
`;
