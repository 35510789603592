import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background-color: #ffffff;
`;

export const FooterText = styled.span`
  font-family: Roboto-Regular;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #394a58;
  margin-left: 50px;
`;
export const FpaVersion = styled.div`
  font-family: Roboto-Regular;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #394a58;
  margin-right: 50px;
`;
export const VesionWrapper = styled.div`
  margin-left: auto;
`;