import {all, takeLatest, put} from "redux-saga/effects";
import {
  setLoader,
  removeLoader,
} from "../store/preloader/actions";
import {GLOBAL_LOADER} from "../store/preloader/constants";
import {
  DELETE_PERMISSIONS_ADMINISTRATION,
  FETCH_LIST_PERMISSIONS_ADMINISTRATION,
  FETCH_PERMISSIONS_PROJECT_BY_ID_ADMINISTRATION,
  FETCH_PERMISSIONS_USER_BY_ID_ADMINISTRATION,
  FETCH_PROJECTS_ADMINISTRATION,
  FETCH_USER_ADMINISTRATION,
  UPDATE_PERMISSIONS_ADMINISTRATION
} from "../store/administration/constants";
import {getProject, getProjects} from "./adminstrations/project";
import {getUser, getUsers} from "./adminstrations/users";

// =====ACTION======//
export const KEY_LOAD_PROJECT = 'KEY_LOAD_PROJECT';

export function* getListPermissions(action) {
  yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "ПОЛУЧЕНИЕ ПРОЕКТОВ"));
  yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}
export function* updatePermissions(action) {
  yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "ПОЛУЧЕНИЕ ПРОЕКТОВ"));
  yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}
export function* deletePermissions(action) {
  yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "ПОЛУЧЕНИЕ ПРОЕКТОВ"));
  yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}

// ===========FLOW======= //
function* getUsersAdministrationFlow() {
  yield takeLatest(FETCH_USER_ADMINISTRATION, getUsers);
}
function* getProjectsAdministrationFlow() {
  yield takeLatest(FETCH_PROJECTS_ADMINISTRATION, getProjects);
}
function* getListPermisionsAdministrationFlow() {
  yield takeLatest(FETCH_LIST_PERMISSIONS_ADMINISTRATION, getListPermissions);
}
function* updatePermisionsAdministrationFlow() {
  yield takeLatest(UPDATE_PERMISSIONS_ADMINISTRATION, updatePermissions);
}
function* deletePermisionsAdministrationFlow() {
  yield takeLatest(DELETE_PERMISSIONS_ADMINISTRATION, deletePermissions);
}
function* getProjectAdministrationFlow() {
  yield takeLatest(FETCH_PERMISSIONS_PROJECT_BY_ID_ADMINISTRATION, getProject);
}
function* getUserAdministrationFlow() {
  yield takeLatest(FETCH_PERMISSIONS_USER_BY_ID_ADMINISTRATION, getUser);
}

export default function* administration() {
  yield all([
    getProjectsAdministrationFlow(),
    getUsersAdministrationFlow(),
    getListPermisionsAdministrationFlow(),
    updatePermisionsAdministrationFlow(),
    deletePermisionsAdministrationFlow(),
    getProjectAdministrationFlow(),
    getUserAdministrationFlow(),
  ]);
}
