import { all } from "redux-saga/effects";
import auth from "./auth";
import project from "./project";
import gitlab from "./gitlab";
import dictionaries from "./dictionaries";
import releases from "./releases";
import documents from "./documents";
import notifications from "./notifications";
import events from "./eventsLog";
import pipeline from "./pipelines";
import mailing from "./mailing";
import administration from "./administration";

export default function* rootSaga() {
  yield all([
    auth(),
    project(),
    gitlab(),
    dictionaries(),
    releases(),
    documents(),
    notifications(),
    events(),
    pipeline(),
    mailing(),
    administration()
  ]);
}
