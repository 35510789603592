import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalBody,
} from "../common/Modal/styles";
import { ButtonIconClose } from "../common/Buttons";

import { modalStyles } from "../../utils/theme";
import { AddTagForm } from "./AddTagForm";
import { getBranches } from "../../api/gitlab";
import { createTag } from "../../store/gitlab/actions";

const AddTagModal = (props) => {
  const { isVisible, gitlabGroup, repository, handleCloseModal, createTag } = props;
  const [listBranches, setListBranches] = useState([]);

  const fetchBranches = async (repositoryId) => {
    try {
      const { data } = await getBranches(repositoryId);
      setListBranches(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBranches(repository.gitLabId);
  }, []);

  const handleSubmit = (params) => {
    createTag(gitlabGroup.gitLabId, repository.gitLabId, params);
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={handleCloseModal}
      style={modalStyles}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalHeaderTitle>ДОБАВЛЕНИЕ ТЕГА В GITLAB</ModalHeaderTitle>
          <ButtonIconClose onClick={handleCloseModal} />
        </ModalHeader>
        <ModalBody>
          <AddTagForm listBranches={listBranches} handleSubmit={handleSubmit} />
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};

AddTagModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  gitlabGroup: PropTypes.object.isRequired,
  repository: PropTypes.any.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
	createTag: PropTypes.func.isRequired,
};

const mapStateToProps = (dispatch) => ({});

const mapDispatchToProps = (dispatch) => ({
  createTag: (groupId, repositoryId, params) =>
    dispatch(createTag(groupId, repositoryId, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTagModal);
