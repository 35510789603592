import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  ${props => {
    if (!props.loaderClass) return "";
    if (props.loaderClass === "notificationsPopup") {
      return "margin: 15px 0 10px 25px;";
    }
    if (props.loaderClass === "release") {
      return "margin: 50px 0 15px 0;";
    }
    if (props.loaderClass === "dropdown") {
      return "display: inline-block";
    }
    return "";
  }}
`;

export const LoaderText = styled.span`
  padding-left: 15px;
  color: #244a97;
  font-family: Roboto-Bold;
  font-size: 14px;
  line-height: 20px;
`;
