import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeSelectEventsList } from "../../store/eventsLog/selectors";
import { makeSelectProjectsOptions } from "../../store/project/selectors";
import { fetchEvents } from "../../store/eventsLog/actions";
import { fetchProjectsOptions } from "../../store/project/actions";
import EventsLog from "../../components/EventsLog";
import { eventsResponseType, gitlabOptionsType } from "../../types";

class EventsLogPage extends Component {
  componentDidMount() {
    this.props.fetchEvents(0, 10);
    this.props.fetchProjectsOptions();
  }

  state = {
    page: 0,
    pageSize: 10,
    projectId: ""
  };

  handleChangeProject = event => {
    this.setState(
      {
        projectId: event.target.value
      },
      this.handleFetchEvents
    );
  };

  handleInputPage = page => {
    this.setState({
      page
    })

    setTimeout(()=>{
      this.handleFetchEvents()
    }, 0)
  }

  handlePageSize = size => {
    this.setState({
      page: 0,
      pageSize: size
    })

    setTimeout(()=>{
      this.handleFetchEvents()
    }, 0)
  }

  handleFetchEvents = () => {
    const { page, pageSize, projectId } = this.state;
    this.props.fetchEvents(page, pageSize, projectId);
  };

  render() {
    return (
      <EventsLog
        eventsList={this.props.eventsList}
        projectsOptions={this.props.projectsOptions}
        onChangeProject={this.handleChangeProject}
        handleInputPage={this.handleInputPage}
        handlePageSize={this.handlePageSize}
      />
    );
  }
}

const mapStateToProps = state => ({
  eventsList: makeSelectEventsList(state),
  projectsOptions: makeSelectProjectsOptions(state)
});

const mapDispatchToProps = dispatch => ({
  fetchEvents: (page, size, projectId) => dispatch(fetchEvents(page, size, projectId)),
  fetchProjectsOptions: () => dispatch(fetchProjectsOptions())
});

EventsLogPage.propTypes = {
  eventsList: eventsResponseType.isRequired,
  projectsOptions: gitlabOptionsType.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  fetchProjectsOptions: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsLogPage);
