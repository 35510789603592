export const isInProgressAvailable = (status, isAdmin, isUser, isReleaseChangeStatus=false) => {
  if (status === "IN_PROGRESS") return false;
  if (isAdmin) return true;
  return isReleaseChangeStatus && status !== "ACCEPTED";
};

export const isReadyAvailable = (status, isAdmin, isUser, isReleaseChangeStatus=false) => {
  if (status === "READY") return false;
  if (isAdmin) return true;
  return isReleaseChangeStatus && status === "IN_PROGRESS";
};

export const isAcceptAvailable = (status, isAdmin, isUser, isReleaseChangeStatus=false) => {
  if (status === "ACCEPTED") return false;
  if (isAdmin) return true;
  return isReleaseChangeStatus && status !== "IN_PROGRESS";
};

export const isDeclineAvailable = (status, isAdmin, isUser,isReleaseChangeStatus=false) => {
  if (status === "DECLINED") return false;
  if (isAdmin) return true;
  return isReleaseChangeStatus && status === "READY";
};

export const isExploitAvailable = (status, isAdmin, isUser,isReleaseChangeStatus=false) => {
  if (status === "EXPLOITED") return false;
  if (isAdmin) return true;

  return isReleaseChangeStatus && status === "ACCEPTED";
};
