import { fromJS } from "immutable";
import { SET_INFO_PAPELINE, SET_PAGES, SET_PROJECT, SET_PROJECTS, SET_PROJECTS_OPTIONS, SET_BACK_VERSION, SET_FRONT_VERSION, SET_ALL_PROJECTS, SET_SIMPLE_PROJECT } from "./constants";
import { SET_RELEASE } from "../releases/constants";
import { SET_GROUP_TAGS, SET_TAG } from "../gitlab/constants";
import { SET_REPORTS } from "../documents/constants";
import packageJson from '../../../package.json';
import {SET_DICTIONARY_IN_PROJECT} from "../dictionaries/constants";

const initialState = fromJS({
    projects: [],
    totalPages: 0,
    reports: [],
    project: {
        id: 0,
        name: "",
        fullName: "",
        description: "",
        incidentURL: "",
        referenceAsuz: {
            code: "",
            redirectUrl: ""
        },
        referenceSonarQube: [{
            code: "",
            redirectUrl: ""
        }],
        referenceTestLink: {
            code: "",
            redirectUrl: ""
        },
        referenceKubernetes: {
            code: "",
            redirectUrl: ""
        },
        referenceGrafana: {
            code: "",
            redirectUrl: ""
        },
        lastRelease: {},
        lastPipeline: {},
        lastAcceptedRelease: {},
        releases: [],
        pipelines: [],
        userPermissions: [],
        signReleaseInventory: true,

        reportTemplates: [],
        dictionaryEntry: null
    },
    release: {
        id: 0,
        name: "",
        buildDate: "",
        status: "",
        comment: "",
        additionalComponents: [],
        database: {},
        developmentEnvironments: [],
        externalSystems: [],
        platform: {},
        tasks: []
    },
    tags: [],
    tagsResults: {},
    projectsOptions: [],
    links: [],
    commits: [],
    frontVersionFPA: '',
    backVersionFPA: '',
    allProjects: [],
    simpleProjects: []
});

const project = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIMPLE_PROJECT: {
            return state.set("simpleProjects", fromJS(action.payload.projects));
        }
        case SET_PROJECTS: {
            return state.set("projects", fromJS(action.payload.projects));
        }
        case SET_ALL_PROJECTS: {
            return state.set("allProjects", fromJS(action.payload.projects));
        }
        case SET_PAGES: {
            return state.set("totalPages", fromJS(action.payload.pages));
        }
        case SET_PROJECT: {
            return state.set("project", fromJS(action.payload.project));
        }
        case SET_RELEASE: {
            return state.set("release", fromJS(action.payload.release));
        }
        case SET_PROJECTS_OPTIONS: {
            return state.set("projectsOptions", fromJS(action.payload.projectsOptions));
        }
        case SET_GROUP_TAGS: {
            return state.set("tags", fromJS(action.payload.tags));
        }
        case SET_REPORTS: {
            return state.set("reports", fromJS(action.payload.reports));
        }
        case SET_INFO_PAPELINE: {
            return state.set("links", action.payload.params);
        }
        case SET_FRONT_VERSION: {
            return state.set("frontVersionFPA", { version: packageJson.version });
        }
        case SET_BACK_VERSION: {
            return state.set("backVersionFPA", { version: action.payload.version });
        }
        case SET_TAG: {
            const { tag } = action.payload;
            return state.setIn(["tagsResults", tag.commitSHA], fromJS(tag));
        }

        case SET_DICTIONARY_IN_PROJECT : {
            return  state.setIn(["project", "dictionaryEntry"], action.payload);
        }


        default: {
            return state;
        }
    }
};

export default project;
