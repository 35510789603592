import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { FieldArray } from "formik";
import CreatableSelect from "react-select/creatable";
import LoadingWrapper from "../../common/LoadingWrapper";
import { FETCH_DICTIONARIES } from "../../../store/dictionaries/constants";
import { FETCH_GROUP_TAGS } from "../../../store/gitlab/constants";
import {
  releaseDictionariesType,
  groupTagsType,
  tagType,
} from "../../../types";
import {
  Input,
  Select,
  SelectMulti,
  TextArea,
  ErrorMessage,
  SectionHeaderBlock,
  SectionHeaderTitleLink,
} from "../../common/styles";
import SectionBlock from "../../common/SectionBlock";
import RowBlock from "../../common/RowBlock";
import ExternalSystemEditorContainer from "../../../containers/ExternalSystemEditor";
import DocumentsEditorContainer from "../../../containers/DocumentsEditor";
import TechSolutionsEditorContainer from "../../../containers/TechSolutionsEditor";
import ReleaseTagsEditor from "../../ReleaseTagsEditor";
import { handleAddToLocalArrayField } from "../utils";
import { MODAL_TYPE_CONFIRMATION_DIALOG } from "../../../store/modal/constants";
import FpgEditor from "../../FpgEditor";

const ReleaseForm = (props) => {
  const {
    values,
    handleChange,
    setFieldValue,
    releaseDictionaries: { platform, devEnv, components, database },
    groupTags,
    tagsResults,
    onChangeTag,
    errors,
    touched,
    onShowModal,
    loaders,
    gitlabGroup,
  } = props;

  const rowLabelWidth = "300px";
  const inputWidth = "80%";
  const selectWidth = "40%";
  const allTags = [];

  if (groupTags.length > 0) {
    groupTags.forEach((grTag) => {
      if (grTag.tags) {
        grTag.tags.forEach((repoTag, index) => {
          allTags.push({
            key: `tag_opt_${grTag.gitLabId}_${repoTag.gitLabId}`,
            value: repoTag.gitLabId,
            label: repoTag.label,
            name: repoTag.name,
          });
          if (index === 0 && values && !values.releaseName) {
            values.releaseName = repoTag.gitLabId;
          }
        });
      }
    });
  }

  const releaseNameOptions = useMemo(() => {
    if (!allTags) return;

    return allTags.map((item) => ({
      ...item,
      label: item.name,
    }));
  }, [allTags]);

  const defaultReleaseName = useMemo(() => {
    if (!releaseNameOptions) return;

    const obj = releaseNameOptions.find(
      (item) => item.value === values.releaseName
    );

    return obj;
  }, [values.releaseName, releaseNameOptions]);

  return (
    <div>
      <SectionBlock text="ОСНОВНАЯ ИНФОРМАЦИЯ">
        <LoadingWrapper loaders={loaders} loaderKey={FETCH_DICTIONARIES}>
          <LoadingWrapper loaders={loaders} loaderKey={FETCH_GROUP_TAGS}>
            <RowBlock
              text="НАИМЕНОВАНИЕ РЕЛИЗА"
              labelWidth={rowLabelWidth}
              alignSelfCenter
            >
              <div style={{ minWidth: "250px" }}>
                {allTags.length === 0 && (
                  <Input
                    name="releaseName"
                    type="text"
                    value={values.releaseName}
                    onChange={handleChange}
                    width={inputWidth}
                    maxLength={253}
                  />
                )}
                {allTags.length > 0 && (
                  <CreatableSelect
                    name="releaseName"
                    defaultValue={defaultReleaseName}
                    placeholder="Введите наименование"
                    formatCreateLabel={(inputValue) =>
                      `Создать "${inputValue}"`
                    }
                    isClearable
                    options={releaseNameOptions}
                    onChange={(select) => {
                      setFieldValue("releaseName", select ? select.value : "");
                    }}
                  />
                )}
              </div>
            </RowBlock>
            {errors.releaseName && touched.releaseName && (
              <ErrorMessage>{errors.releaseName}</ErrorMessage>
            )}
          </LoadingWrapper>
          <RowBlock
            text="ОСНОВНАЯ ПЛАТФОРМА"
            labelWidth={rowLabelWidth}
            alignSelfCenter
          >
            <Select
              name="platformId"
              value={values.platformId}
              onChange={handleChange}
              width={selectWidth}
            >
              <option value="" label="Не выбрано">
                Не выбрано
              </option>
              {platform.map((item) => (
                <option
                  key={`opt_pl_${item.id}`}
                  value={item.id}
                  label={item.name}
                >
                  {item.name}
                </option>
              ))}
            </Select>
          </RowBlock>
          <RowBlock
            text="СРЕДА РАЗРАБОТКИ"
            labelWidth={rowLabelWidth}
            alignSelfCenter
          >
            <SelectMulti
              multiple
              name="devEnvId"
              value={values.devEnvId}
              onChange={(event) => {
                setFieldValue(
                  "devEnvId",
                  [...event.target.options]
                    .filter((option) => option.selected)
                    .map((option) => option.value)
                );
              }}
            >
              {devEnv.map((item) => (
                <option
                  key={`opt_de_${item.id}`}
                  value={item.id}
                  label={item.name}
                >
                  {item.name}
                </option>
              ))}
            </SelectMulti>
          </RowBlock>
          <RowBlock
            text="ДОПОЛНИТЕЛЬНЫЕ КОМПОНЕНТЫ"
            labelWidth={rowLabelWidth}
            alignSelfCenter
          >
            <SelectMulti
              multiple
              name="componentsId"
              value={values.componentsId}
              onChange={(event) => {
                setFieldValue(
                  "componentsId",
                  [...event.target.options]
                    .filter((option) => option.selected)
                    .map((option) => option.value)
                );
              }}
            >
              {components.map((item) => (
                <option
                  key={`opt_co_${item.id}`}
                  value={item.id}
                  label={item.name}
                >
                  {item.name}
                </option>
              ))}
            </SelectMulti>
          </RowBlock>
          <RowBlock
            text="БАЗА ДАННЫХ"
            labelWidth={rowLabelWidth}
            alignSelfCenter
          >
            <Select
              name="databaseId"
              value={values.databaseId}
              onChange={handleChange}
              width={selectWidth}
            >
              <option value="" label="Не выбрано">
                Не выбрано
              </option>
              {database.map((item) => (
                <option
                  key={`opt_db_${item.id}`}
                  value={item.id}
                  label={item.name}
                >
                  {item.name}
                </option>
              ))}
            </Select>
          </RowBlock>
          <RowBlock
            text="КОММЕНТАРИЙ"
            labelWidth={rowLabelWidth}
            alignSelfCenter
          >
            <TextArea
              rows="3"
              name="comment"
              value={values.comment}
              onChange={handleChange}
              width={inputWidth}
              maxLength={253}
            />
          </RowBlock>
        </LoadingWrapper>
      </SectionBlock>
      <FieldArray
        name="externalSystems"
        render={(arrayHelpers) => (
          <ExternalSystemEditorContainer
            selectedExternalSystems={values.externalSystems}
            onSaveExternalSystem={(newExtSys) => {
              handleAddToLocalArrayField(
                newExtSys,
                values.externalSystems,
                arrayHelpers
              );
            }}
            onDeleteExternalSystem={(localId) => {
              onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
                contentLabel: "Delete external system confirmation",
                message:
                  "Вы уверены, что хотите удалить связь с внешней с ИУС?",
                onConfirm: () => {
                  const index = values.externalSystems.findIndex(
                    (sys) => sys.localId === localId
                  );
                  arrayHelpers.remove(index);
                },
              });
            }}
          />
        )}
      />
      <SectionHeaderBlock>
        <SectionHeaderTitleLink
          onClick={() => {
            window.open(`${values.projectGitlabPath}`, "_blank");
          }}
        >
          РЕПОЗИТОРИИ
        </SectionHeaderTitleLink>
      </SectionHeaderBlock>
      <LoadingWrapper
        loaders={loaders}
        loaderKey={FETCH_GROUP_TAGS}
        showPlaceholder={groupTags.length === 0}
        placeholderText="В СИСТЕМЕ ОТСУТСТВУЮТ РЕПОЗИТОРИИ"
      >
        <ReleaseTagsEditor
          gitlabGroup={gitlabGroup}
          groupTags={groupTags}
          tagsResults={tagsResults}
          onChangeTag={onChangeTag}
          values={values}
          setFieldValue={setFieldValue}
          loaders={loaders}
        />
      </LoadingWrapper>
      <FieldArray
        name="documents"
        render={(arrayHelpers) => (
          <DocumentsEditorContainer
            selectedDocuments={values.documents}
            onSaveDocument={(newDoc) => {
              handleAddToLocalArrayField(
                newDoc,
                values.documents,
                arrayHelpers
              );
            }}
            onDeleteDocument={(localId) => {
              onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
                contentLabel: "Delete document confirmation",
                message: "Вы уверены, что хотите удалить документ?",
                onConfirm: () => {
                  const index = values.documents.findIndex(
                    (doc) => doc.localId === localId
                  );
                  arrayHelpers.remove(index);
                },
              });
            }}
            releaseId={values.releaseId || -1}
            requiredDocuments={values.projectRequiredDocuments}
          />
        )}
      />
      <FpgEditor values={values} />
      <FieldArray
        name="technicalSolutions"
        render={(arrayHelpers) => (
          <TechSolutionsEditorContainer
            selectedTechSolutions={values.technicalSolutions}
            onSaveTechSolution={(newTechSolution) => {
              handleAddToLocalArrayField(
                newTechSolution,
                values.technicalSolutions,
                arrayHelpers
              );
            }}
            onDeleteTechSolution={(localId) => {
              onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
                contentLabel: "Delete technical solution confirmation",
                message:
                  "Вы уверены, что хотите удалить типовое техническое решение?",
                onConfirm: () => {
                  const index = values.technicalSolutions.findIndex(
                    (doc) => doc.localId === localId
                  );
                  arrayHelpers.remove(index);
                },
              });
            }}
          />
        )}
      />
    </div>
  );
};

ReleaseForm.propTypes = {
  releaseDictionaries: releaseDictionariesType.isRequired,
  groupTags: groupTagsType.isRequired,
  tagsResults: tagType.isRequired,
  onChangeTag: PropTypes.func.isRequired,
  gitlabGroup: PropTypes.object.isRequired,

  project: PropTypes.object.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  onShowModal: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
};

export default ReleaseForm;
