import React, {Component} from "react";
import PropTypes from "prop-types";

export class SelectorList extends Component {
    state = {isOpen: false, search : '', options: [], load: false }


    render() {
        const { fetchData, nowData, labelKey, setValue, disabled = false } = this.props
        const {isOpen, search, options, load } =this.state;

        const customSetState = (field, value) => {
            this.setState(state=>{
                return {
                    ...state,
                    [field]: value,
                }
            });
        }

        const setSearch = (val)=>{
            customSetState('search', val)
        }

        const searchData =  async (searchVal='')=> {
            customSetState('load', true);
            const res = await fetchData(searchVal);
            customSetState('options', res);
            customSetState('load', false);
        }

        const showList = async (val= true)=>{
            customSetState('isOpen',  val)
            await searchData('')
        }

        const selectElement = (el) =>{
            setValue(el)
            showList(false);
        }

        const getElOpts = options.map(el=>{
            return (<li onClick={()=>selectElement(el)} key={labelKey in el? el[labelKey]: el.label} >{labelKey in el? el[labelKey]: el.label}</li>)
        })

        const   handleKeyPress = async (event)=> {
            if (event.key === "Enter") {
                customSetState('isOpen',  true);
                await searchData(search)
            }
        }

        const getValueNow = () => {
            if(nowData && labelKey) {
                return labelKey in nowData ? nowData[labelKey] :''
            }
            return ''
        }

        return (
            <div className='selectorList'>
                {isOpen && <div className='closeList' onClick={()=>showList(false)}>закрыть</div>}

                { !isOpen && nowData &&
                    <input className='inputBlock'
                           disabled={disabled}
                           onClick={()=>showList(true)}
                           defaultValue={getValueNow()}
                /> }

                { (isOpen || !nowData )  &&
                    <input className='inputBlock'
                       placeholder='искать'
                       value={search}
                       onClick={()=>showList(true)}
                       onChange={e => setSearch(e.target.value)}
                       onKeyPress={handleKeyPress}
                /> }

                {isOpen && <div className='selectorList__list'>
                    {load && <div>Поиск данных</div> }
                   <ul>
                       {getElOpts}
                   </ul>
                    {options.length === 0? 'нет данных' : ''}
                </div> }
            </div>
        )
    }
}


SelectorList.propTypes = {
    fetchData: PropTypes.func.isRequired,
    labelKey: PropTypes.string.isRequired,
    setValue:  PropTypes.func,
    nowData: PropTypes.object,
    disabled: PropTypes.bool
};
