import { fromJS, merge } from "immutable";
import { SHOW_MODAL, HIDE_MODAL } from "./constants";

const initialState = fromJS({
  modalType: null,
  props: {}
});

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return merge(state, fromJS(action.payload))
    }
    case HIDE_MODAL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default modal;
