import React, { Component } from "react";
import Select from "react-select";
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { isInclusivelyAfterDay } from "react-dates/esm";
import PropTypes from "prop-types";
import Modal from "react-modal";

import {modalStyles} from "../../../utils/theme";
import {ButtonsRow, ModalBody, ModalContainer, ModalHeader, ModalHeaderTitle} from "../../common/Modal/styles";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {ButtonIconClose, ButtonSave} from "../../common/Buttons";
import RowBlock from "../../common/RowBlock";

import {ButtonRedOutline} from "../../common/Buttons/styles";

class ModalReport extends Component {
    state = {
        dateFrom: moment().subtract(1, 'months'),
        focused: false,
        dateTo: moment(),
        focused2: false,
        selected: null,
    };


    render() {

        const {
            id,
            reportTemplates,
            isOpen,
            closeModalReport,
            onDownloadGeneratedReport,
        } = this.props;

        return (
            <Modal isOpen={isOpen}
                onRequestClose={this.handleCloseModal}
                style={modalStyles}
                contentLabel="Upload Technical Solution"
            >
                <ModalContainer>
                    <ModalHeader>
                        <ModalHeaderTitle>СОЗДАНИЕ ОТЧЕТА</ModalHeaderTitle>
                        <ButtonIconClose onClick={closeModalReport} />
                    </ModalHeader>

                    <ModalBody>
                        <RowBlock text="ВЫБЕРИТЕ ШАБЛОН" labelWidth="200px" alignSelfCenter>
                            <div className="wrap_select">
                                <Select
                                  options={reportTemplates}
                                  getOptionValue={option => `${option.id}`}
                                  getOptionLabel={option => `${option.comment} (${option.fileOriginalName})` }
                                  onChange={option => { this.setState({selected: option }) }}
                                  placeholder="Выберите шаблон"
                                />
                            </div>
                        </RowBlock>
                        <RowBlock text="ПЕРИОД" labelWidth="200px" alignSelfCenter>
                            <div className="wrap_select dates">
                                <div style={{width: 150, lineHeight: 2.3}}> Дата от</div>
                                <SingleDatePicker
                                    date={this.state.dateFrom} // momentPropTypes.momentObj or null
                                    onDateChange={dateFrom => {
                                        if(dateFrom) {
                                            this.setState({ dateFrom })
                                        }
                                        else {
                                            this.setState({ dateFrom: moment().subtract(1, 'months') })
                                        }
                                    }} // PropTypes.func.isRequired
                                    focused={this.state.focused} // PropTypes.bool
                                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                    isOutsideRange={day => isInclusivelyAfterDay(day, moment())}
                                    displayFormat={() => this.state.dateFrom.format('DD.MM.YYYY')}
                                    id="1" // PropTypes.string.isRequired,
                                    small
                                    numberOfMonths={1}
                                    placeholder="Дата начала"
                                />
                                <div style={{width: 150, lineHeight: 2.3}}> Дата до</div>
                                <SingleDatePicker
                                    date={this.state.dateTo} // momentPropTypes.momentObj or null
                                    onDateChange={dateTo => {
                                        if(dateTo) {
                                            this.setState({ dateTo })
                                        }
                                        else {
                                            this.setState({ dateTo: moment() })
                                        }
                                    }} // PropTypes.func.isRequired
                                    focused={this.state.focused2} // PropTypes.bool
                                    onFocusChange={({ focused: focused2 }) => this.setState({ focused2 })} // PropTypes.func.isRequired
                                    id="ewwewe2" // PropTypes.string.isRequired,
                                    displayFormat={() => this.state.dateTo.format('DD.MM.YYYY')}
                                    isOutsideRange={day => isInclusivelyAfterDay(day, moment())}
                                    small
                                    numberOfMonths={1}
                                    placeholder="Дата окончания"
                                />


                            </div>
                        </RowBlock>


                        <ButtonsRow>
                            <ButtonSave
                                type="submit"
                                text="Создать"
                                margin="0 25px 0 0"
                                onClick={()=> {
                                    const todExt = this.state.selected.comment.match(/\((\..+?)\)$/);
                                    console.log(todExt)
                                    let ext = ".pdf"
                                    if (Array.isArray(todExt) && typeof(todExt[1]) !== undefined){
                                        ext = todExt[1]
                                    }
                                    onDownloadGeneratedReport(this.state.selected.id, id, this.state.dateFrom.format('YYYY-MM-DD'), this.state.dateTo.format('YYYY-MM-DD'), null, `report_${this.state.selected.fileOriginalName.replace(/\.[^/.]+$/, "")}_${this.state.dateFrom.format('DD.MM.YYYY')}_${this.state.dateTo.format('DD.MM.YYYY')}${ext}`)
                                }}
                                disabled={!this.state.selected}
                            />
                            <ButtonRedOutline onClick={closeModalReport}>
                                Отмена
                            </ButtonRedOutline>
                        </ButtonsRow>
                    </ModalBody>
                </ModalContainer>
            </Modal>
        );
    }
}

ModalReport.propTypes = {
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    reportTemplates: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModalReport: PropTypes.func.isRequired,
    onDownloadGeneratedReport: PropTypes.func.isRequired,

};


export default ModalReport;