import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import Api from "../api";
import { setLoader, removeLoader, setError, errorParser } from "../store/preloader/actions";
import { GLOBAL_LOADER, LOCAL_LOADER } from "../store/preloader/constants";
import {
  FETCH_DICTIONARIES,
  FETCH_DICTIONARIES_LIST,
  FETCH_DICTIONARY_ENTRIES,
  SUBMIT_DICTIONARY_ENTRY,
  DELETE_DICTIONARY_ENTRY,
  SUBMIT_DICTIONARY
} from "../store/dictionaries/constants";
import {
  setDictionaries,
  setDictionariesList,
  setDictionaryEntries,
  fetchDictionaryEntries
} from "../store/dictionaries/actions";
import { DICTIONARY_REMOTE_TO_REDUX_MAPPING } from "../utils/constants";

export function* fetchDictionariesListSaga() {
  yield put(
    setLoader(LOCAL_LOADER, FETCH_DICTIONARIES_LIST, "ЗАГРУЗКА СПРАВОЧНИКОВ")
  );

  try {
    const response = yield call(Api.dictionaries.getDictionaries);
    yield put(setDictionariesList(response.data));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА СПРАВОЧНИКОВ - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_DICTIONARIES_LIST));
}
export function* fetchDictionariesListFlow() {
  yield takeLatest(FETCH_DICTIONARIES_LIST, fetchDictionariesListSaga);
}

export function* fetchDictionaryEntriesSaga(action) {
  yield put(
    setLoader(
      LOCAL_LOADER,
      FETCH_DICTIONARY_ENTRIES,
      "ЗАГРУЗКА ЭЛЕМЕНТОВ СПРАВОЧНИКА"
    )
  );

  try {
    const { code } = action.payload;
    const response = yield call(Api.dictionaries.getDictionary, code);
    const codeSelect =  code in DICTIONARY_REMOTE_TO_REDUX_MAPPING?DICTIONARY_REMOTE_TO_REDUX_MAPPING[code]:code

    yield put(
      setDictionaryEntries(
        codeSelect,
        response.data
      )
    );
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА ЭЛЕМЕНТОВ СПРАВОЧНИКА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_DICTIONARY_ENTRIES));
}
export function* fetchDictionaryEntriesFlow() {
  yield takeLatest(FETCH_DICTIONARY_ENTRIES, fetchDictionaryEntriesSaga);
}

export function* submitDictionaryEntrySaga(action) {
  yield put(
    setLoader(
      GLOBAL_LOADER,
      SUBMIT_DICTIONARY_ENTRY,
      "СОХРАНЕНИЕ ЭЛЕМЕНТА СПРАВОЧНИКА"
    )
  );

  try {
    const { dictionaryCode, dictionaryEntry } = action.payload;
    const isNew = dictionaryEntry.id === 0;

    yield call(
      Api.dictionaries.submitDictionaryEntry,
      isNew ? "POST" : "PUT",
      dictionaryCode,
      dictionaryEntry
    );

    yield put(fetchDictionaryEntries(dictionaryCode));
  } catch (error) {
    yield put(setError(`СОХРАНЕНИЕ ЭЛЕМЕНТА СПРАВОЧНИКА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, SUBMIT_DICTIONARY_ENTRY));
}
export function* submitDictionaryEntryFlow() {
  yield takeLatest(SUBMIT_DICTIONARY_ENTRY, submitDictionaryEntrySaga);
}

export function* deleteDictionaryEntrySaga(action) {
  yield put(
    setLoader(
      GLOBAL_LOADER,
      DELETE_DICTIONARY_ENTRY,
      "УДАЛЕНИЕ ЭЛЕМЕНТА СПРАВОЧНИКА"
    )
  );

  try {
    const { dictionaryCode, entryCode } = action.payload;

    yield call(
      Api.dictionaries.deleteDictionaryEntry,
      dictionaryCode,
      entryCode
    );

    yield put(fetchDictionaryEntries(dictionaryCode));
  } catch (error) {
    yield put(setError(`УДАЛЕНИЕ ЭЛЕМЕНТА СПРАВОЧНИКА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, DELETE_DICTIONARY_ENTRY));
}
export function* deleteDictionaryEntryFlow() {
  yield takeLatest(DELETE_DICTIONARY_ENTRY, deleteDictionaryEntrySaga);
}

export function* fetchDictionarySaga(code) {
  try {
    const response = yield call(Api.dictionaries.getDictionary, code);

    const dictionaryEntries = response.data;

    return {
      code,
      dictionaryEntries
    };
  } catch (error) {
    return {
      code,
      dictionaryEntries: []
    };
  }
}

export function* fetchDictionariesSaga(action) {
  yield put(
    setLoader(LOCAL_LOADER, FETCH_DICTIONARIES, "ЗАГРУЗКА СПРАВОЧНИКОВ")
  );

  const dictionariesToFetch = action.payload.dictionaries;

  const fetchResult = yield all(
    dictionariesToFetch.map(dict => call(fetchDictionarySaga, dict))
  );

  const result = {};

  fetchResult.forEach(item => {
    result[DICTIONARY_REMOTE_TO_REDUX_MAPPING[item.code]] =
      item.dictionaryEntries;
  });

  yield put(setDictionaries(result));

  yield put(removeLoader(LOCAL_LOADER, FETCH_DICTIONARIES));
}

export function* fetchDictionariesFlow() {
  yield takeEvery(FETCH_DICTIONARIES, fetchDictionariesSaga);
}

export function* submitDictionarySaga(action) {
  yield put(
      setLoader(
          GLOBAL_LOADER,
          SUBMIT_DICTIONARY_ENTRY,
          "СОХРАНЕНИЕ ЭЛЕМЕНТА СПРАВОЧНИКА"
      )
  );

  try {
    yield call(
        Api.dictionaries.createDictionary,
        "POST",
        action.payload
    );
    yield call(fetchDictionariesListSaga);
  } catch (error) {
    yield put(setError(`СОХРАНЕНИЕ ЭЛЕМЕНТА СПРАВОЧНИКА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, SUBMIT_DICTIONARY_ENTRY));
}
export function* submitDictionaryFlow() {
  yield takeLatest(SUBMIT_DICTIONARY, submitDictionarySaga);
}








export default function* dictionaries() {
  yield all([
    submitDictionaryFlow(),
    fetchDictionariesFlow(),
    fetchDictionariesListFlow(),
    fetchDictionaryEntriesFlow(),
    deleteDictionaryEntryFlow(),
    submitDictionaryEntryFlow()
  ]);
}
