import React from 'react'
import PropTypes from "prop-types";
import { Button } from "../../common/Buttons/styles";
import { ExportContainer } from "./styles";


export const ExportBlock = ({projects}) => (
    <ExportContainer>
        <Button type="submit" onClick={() => projects()}> Скачать таблицу </Button>
    </ExportContainer>
)

ExportBlock.propTypes = {
    projects: PropTypes.func.isRequired,
  };
