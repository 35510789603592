import React from "react";
import PropTypes from "prop-types";
import {
  dictionaryEntriesType,
  documentsType,
  gitlabOptionsType
} from "../../types";
import {
  Flex,
  SectionHeaderBlock,
  SectionHeaderTitle,
  LinkSpan
} from "../common/styles";
import {
  ListSectionHeader,
  ListSectionHeaderTitle,
  ListRowWrapper
} from "../common/ListRow/styles";
import { ButtonAdd } from "../common/Buttons";
import DocumentsTable from "./DocumentsTable";
import { MODAL_TYPE_DOCUMENT_UPLOAD } from "../../store/modal/constants";

function DocumentsEditor(props) {
  const {
    documentTypeDictionary,
    selectedDocuments,
    requiredDocuments,
    onSaveDocument,
    onDeleteDocument,
    onDownloadDocument,
    onSignDocument,
    onDeleteDocumentSign,
    releaseId,
    onShowModal
  } = props;
  const necessaryDocs =  requiredDocuments.filter(requiredDoc => {
    const docIndex = selectedDocuments.findIndex(
      selectedDoc => { 
         if( selectedDoc && requiredDoc && selectedDoc.documentType &&  selectedDoc.documentType.id  && requiredDoc.id)  {
          return selectedDoc.documentType.id === requiredDoc.id
         }
         return false
         }
    );
    return docIndex === -1;
  });

  return (
    <Flex flexDirection="column">
      <SectionHeaderBlock editor>
        <SectionHeaderTitle>ДОКУМЕНТЫ</SectionHeaderTitle>
        <ButtonAdd
          text="Добавить"
          onClick={() => {
            onShowModal(MODAL_TYPE_DOCUMENT_UPLOAD, {
              contentLabel: "Upload Document",
              documentTypeDictionary,
              onSaveDocument
            });
          }}
        />
      </SectionHeaderBlock>
      {necessaryDocs.length !== 0 && (
        <div>
          <ListSectionHeader>
            <ListSectionHeaderTitle>
              Для отправления релиза необходимо добавить документы следующих
              типов:
            </ListSectionHeaderTitle>
          </ListSectionHeader>
          {necessaryDocs.map(item => (
            <ListRowWrapper key={`req_doc_${item.id}`} margin="5px 0">
              <LinkSpan onClick={() => {
                onShowModal(MODAL_TYPE_DOCUMENT_UPLOAD, {
                  contentLabel: "Upload Document",
                  documentTypeDictionary,
                  onSaveDocument,
                  initialDocumentTypeId: item.id
                });
              }}>{item.name}</LinkSpan>
            </ListRowWrapper>
          ))}
        </div>
      )}
      <DocumentsTable
        documents={selectedDocuments}
        onDeleteDocument={onDeleteDocument}
        onDownloadDocument={onDownloadDocument}
        onSignDocument={onSignDocument}
        onDeleteDocumentSign={onDeleteDocumentSign}
        releaseId={releaseId}
        placeholderText="ДОКУМЕНТЫ ПОКА НЕ ДОБАВЛЕНЫ"
      />
    </Flex>
  );
}

DocumentsEditor.propTypes = {
  selectedDocuments: documentsType.isRequired,
  requiredDocuments: gitlabOptionsType.isRequired,
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  onSaveDocument: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onSignDocument: PropTypes.func.isRequired,
  onDeleteDocumentSign: PropTypes.func.isRequired,
  releaseId: PropTypes.number.isRequired,
  onShowModal: PropTypes.func.isRequired
};

export default DocumentsEditor;
