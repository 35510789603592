export const {
  GL_HOST,
  REDIRECT_URI_AUTHORIZE,
  CLIENT_ID,
  CLIENT_SECRET,
  APPLICATION_HOST,
  API_HOST,
  SONAR_URL,
  NEXUS_URL_MAVEN,
  NEXUS_URL_NUGET,
  NEXUS_URL_NPM,
  NEXUS_URL_DOCKER
} = window.appConfig;

export const RELEASE_DICTIONARIES = [
  "development_environment",
  "platform",
  "component",
  "database"
];

export const EXTERNAL_SYSTEM_DICTIONARIES = [
  "sourcemode",
  "sourcelocation",
  "informationconsumer",
  "consumerlocation",
  "authentication",
  "transport",
  "interface",
  "transdata"
];

export const DICTIONARY_REMOTE_TO_REDUX_MAPPING = {
  development_environment: "developmentEnvironment",
  platform: "platform",
  component: "additionalComponents",
  database: "database",
  sourcemode: "sourceMode",
  sourcelocation: "sourceLocation",
  informationconsumer: "informationConsumer",
  consumerlocation: "consumerLocation",
  authentication: "authentication",
  transport: "transport",
  interface: "interfaces",
  documenttype: "documentType",
  transdata: "transData"
};

export const DICTIONARY_CODE_TO_NAME_MAPPING = {
  development_environment: "Среда разработки",
  platform: "Платформа",
  component: "Компоненты",
  database: "База данных",
  sourcemode: "Режим источника",
  sourcelocation: "Расположение источника",
  informationconsumer: "Потребитель информации",
  consumerlocation: "Расположение потребителя",
  authentication: "Аутентификация",
  transport: "Транспорт",
  interface: "Интерфейс",
  documenttype: "Тип документа",
  transdata: "Передаваемые данные"
};

export const RELEASE_STATUS_TO_TEXT_MAPPING = {
  IN_PROGRESS: "В работе у Подрядчика",
  READY: "Готов к приёмке в СО",
  ACCEPTED: "Принят в СО",
  DECLINED: "Отклонён в СО",
  EXPLOITED: "Промышленная эксплуатация"
};

export const EVENT_TO_TEXT_MAPPING = {
  CREATE_PROJECT: "Создание ИУС",
  UPDATE_PROJECT: "Редактирование ИУС",
  DELETE_PROJECT: "Удаление ИУС",
  UPLOAD_DOCUMENT: "Загрузка документа",
  SIGN_DOCUMENT: "Подписание документа",
  CREATE_RELEASE: "Создание релиза",
  UPDATE_RELEASE: "Редактирование релиза",
  DELETE_RELEASE: "Удаление релиза",
  READY_RELEASE: "Релиз готов",
  ACCEPTED_RELEASE: "Релиз принят",
  DECLINED_RELEASE: "Релиз отклонен",
  LOGIN_USER: "Вход пользователя",
  LOGOUT_USER: "Выход пользователя"
};


export const KEY_TEMP_DATA = {
  KEY_PAGE_SIZE_REGISTRY: 'KEY_PAGE_SIZE_REGISTRY',
  KEY_TEMP_RELEASE:"KEY_TEMP_RELEASE"
}

export const LOAD_DATA_REPORT = "LOAD_DATA_REPORT";