export const FETCH_PIPELINES = "FETCH_PIPELINES";
export const fetchPipelines = (id, pageNumber, pageSize) => ({
  type: FETCH_PIPELINES,
  payload: {
    id,
    pageNumber,
    pageSize
  }
});


export const SET_PIPELINES = "SET_PIPELINES";
export const setPipelines = pipelines => ({
    type: SET_PIPELINES,
    payload: {
        pipelines
    }
});

