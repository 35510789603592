import { all, call, takeLatest, put } from "redux-saga/effects";
import { push } from "connected-react-router/immutable";
import {
  FETCH_PROJECTS,
  FETCH_ALL_PROJECTS,
  FETCH_PROJECTS_OPTIONS,
  SEARCH_PROJECTS,
  FETCH_PROJECT_BY_ID,
  SUBMIT_PROJECT,
  DELETE_PROJECT,
  GET_INFO_PAPELINE,
  RESTORE_PROJECT, FETCH_PROJECTS_METRICK, SEARCH_PROJECTS_METRICK, SIMPLE_SEARCH_PROJECT
} from "../store/project/constants";
import {
  setProjects,
  setAllProjects,
  setProject,
  setPages,
  setInfoPaipline,
  setBackVersion,
  setFrontVersion,
  setProjectsOptions, setSimpleProjects
} from "../store/project/actions";
import { setPipelines } from "../store/pipeline/actions";
// import { submitRelease } from "../store/releases/actions";
import {
  setLoader,
  removeLoader,
  setError,
  setSpecificError,
  errorParser
} from "../store/preloader/actions";
import {
  GLOBAL_LOADER,
  LOCAL_LOADER,
  ERROR_PROJECT_DUPLICATE_NAME
} from "../store/preloader/constants";
import Api from "../api";
import { writeExcel } from '../utils/excelProjects'
import {chunkArr} from "../utils/helpers";


export function* fetchProjectsAndMetrickSaga(params) {
  yield put(setLoader(LOCAL_LOADER, FETCH_PROJECTS, "ЗАГРУЗКА НАЧАЛЬНОЙ ИНФОРМАЦИИ О ПРОЕКТЕ"));
  try {

    const response = yield call(Api.project.getProjectsOnly, params.payload);
    let projects = response.data.content?  response.data.content : []
    yield put(setProjects(projects));
    yield put(setPages(response.data.totalPages));
    yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS));
    yield put(setLoader(LOCAL_LOADER, FETCH_PROJECTS_METRICK, "ЗАГРУЗКА  ИНФОРМАЦИИ ИЗ МЕТРИКИ"));
    const ids = projects.map(el=>el.gitlabGroup.gitLabId);
    const responseMetrika = yield call(Api.project.getMetrickDataOnly, ids);
    const metrikaConveretMap = new Map();
     if(responseMetrika.data) {
       responseMetrika.data.forEach(el=>{
         metrikaConveretMap.set(el.gitlabGroup.gitLabId, el);
       })
       projects = projects.map(project=> {
         const find = metrikaConveretMap.get(project.gitlabGroup.gitLabId);
         const lastPipeline = find?find.lastPipeline : null;
         return {
           ...project,
           lastPipeline
         }
       })
       yield put(setProjects(projects));
     }
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА ПРОЕКТОВ - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS));
  yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS_METRICK));
}

/**
 * @deprecated
 * @param params
 * @returns {Generator<*, void, *>}
 */
export function* fetchProjectsSaga(params) {
  yield put(setLoader(LOCAL_LOADER, FETCH_PROJECTS, "ЗАГРУЗКА ПРОЕКТОВ"));
  try {
    const response = yield call(Api.project.getProjects, params.payload);
    yield put(setProjects(response.data.content));
    yield put(setPages(response.data.totalPages));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА ПРОЕКТОВ - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS));
}

/**
 * @deprecated
 */
export function* fetchProjectsFlow() {
  yield takeLatest(FETCH_PROJECTS, fetchProjectsSaga);
}

export function* fetchProjectsMetricFlow() {
  yield takeLatest(FETCH_PROJECTS_METRICK, fetchProjectsAndMetrickSaga);
}


export function* fetchAllProjectsSaga(params) {

  yield put(setLoader(LOCAL_LOADER, FETCH_ALL_PROJECTS, "ПОДГОТОВКА ФАЙЛА"));
  try {

    console.log(params.payload);
    const response = yield call(Api.project.getProjectsOnly, {...params.payload, pageSize: 1000});
    let projects = response.data.content?  response.data.content : [];
    const ids = projects.map(el=>el.gitlabGroup.gitLabId);
    const chunkIds =  chunkArr(ids, 10);
    let allMetrika = [];

    for (let i = 0; i < chunkIds.length; i++) {
        try{

          const responseMetrika = yield call(Api.project.getMetrickDataOnly, chunkIds[i]);
          allMetrika = [...allMetrika, ...responseMetrika.data];
        }catch(error){
          console.log(error);
        }
     }

    const metrikaConveretMap = new Map();

    if(allMetrika) {
      allMetrika.forEach(el => {
        metrikaConveretMap.set(el.gitlabGroup.gitLabId, el);
      })
      projects = projects.map(project => {
        const find = metrikaConveretMap.get(project.gitlabGroup.gitLabId);
        const lastPipeline = find ? find.lastPipeline : null;
        return {
          ...project,
          lastPipeline
        }
      })
    }
    // const response = yield call(Api.project.getAllProjects, params.payload);
    yield put(setAllProjects(projects));
    yield writeExcel(projects)
  } catch (error) {
    yield put(setError(`ОШИБКА ЗАГРУЗКИ ДАННЫХ ТАБЛИЦЫ - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(LOCAL_LOADER, FETCH_ALL_PROJECTS));
}

export function* fetchAllProjectsFlow() {
  yield takeLatest(FETCH_ALL_PROJECTS, fetchAllProjectsSaga);
}


export function* fetchProjectsOptionsSaga() {
  yield put(setLoader(LOCAL_LOADER, FETCH_PROJECTS_OPTIONS, "ЗАГРУЗКА СПИСКА ПРОЕКТОВ"));

  try {
    const response = yield call(Api.project.getProjectsOptions);
    yield put(setProjectsOptions(response.data));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА СПИСКА ПРОЕКТОВ - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS_OPTIONS));
}

function* fetchProjectsOptionsFlow() {
  yield takeLatest(FETCH_PROJECTS_OPTIONS, fetchProjectsOptionsSaga);
}

export function*  searchProjectsMetrickSaga  (action) {
  yield put(setLoader(LOCAL_LOADER, SEARCH_PROJECTS, "ПОИСК ПРОЕКТОВ"));
  try {
    const params = {
      q: action.payload.query,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      sortByField: action.payload.sortByField,
      sortByOrder: action.payload.sortByOrder,
      inArchive: action.payload.inArchive,
      department: action.payload.department
    };

    const response = yield call(Api.project.getProjectsOnly, params);
    let projects = response.data.content?  response.data.content : []
    yield put(setProjects(projects));
    yield put(setPages(response.data.totalPages));
    yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS));
    yield put(setLoader(LOCAL_LOADER, FETCH_PROJECTS_METRICK, "ЗАГРУЗКА  ИНФОРМАЦИИ ИЗ МЕТРИКИ"));
    const ids = projects.map(el=>el.gitlabGroup.gitLabId);
    const responseMetrika = yield call(Api.project.getMetrickDataOnly, ids);
    const metrikaConveretMap = new Map();
    if(responseMetrika.data) {
      responseMetrika.data.forEach(el=>{
        metrikaConveretMap.set(el.gitlabGroup.gitLabId, el);
      })
      projects = projects.map(project=> {
        const find = metrikaConveretMap.get(project.gitlabGroup.gitLabId);
        const lastPipeline = find?find.lastPipeline : null;
        return {
          ...project,
          lastPipeline
        }
      })
      yield put(setProjects(projects));
    }
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА ПРОЕКТОВ - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(LOCAL_LOADER, SEARCH_PROJECTS));
  yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECTS_METRICK));




}

/**
 * @deprecated
 * @returns {Generator<*, void, *>}
 */
export function* searchProjectsFlow() {
  yield takeLatest(SEARCH_PROJECTS, searchProjectsSaga);
}


/**
 * @deprecated
 */
export function* searchProjectsSaga(action) {
  yield put(setLoader(LOCAL_LOADER, SEARCH_PROJECTS, "ПОИСК ПРОЕКТОВ"));

  try {
    const params = {
      q: action.payload.query,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      sortByField: action.payload.sortByField,
      sortByOrder: action.payload.sortByOrder,
      inArchive: action.payload.inArchive
    };

    const response = yield call(Api.project.searchProjects, params);
    yield put(setProjects(response.data.content));
    yield put(setPages(response.data.totalPages));
  } catch (error) {
    yield put(setError(`ПОИСК ПРОЕКТОВ - ${errorParser(error) || error.message}`));
  }

  yield put(removeLoader(LOCAL_LOADER, SEARCH_PROJECTS));
}


export function* searchProjectsMetrickFlow() {
  yield takeLatest(SEARCH_PROJECTS_METRICK, searchProjectsMetrickSaga);
}

export function* fetchProjectByIdSaga(action) {
  yield put(setLoader(LOCAL_LOADER, FETCH_PROJECT_BY_ID, "ЗАГРУЗКА ПРОЕКТА"));

  try {
    const projectId = action.payload.id;
    const response = yield call(Api.project.getProjectById, projectId);
    yield put(setProject(response.data));
    yield put(setPipelines(response.data.pipelines.content));
    yield put(setPages(response.data.pipelines.count));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА ПРОЕКТА - ${errorParser(error) || error.message}`));
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_PROJECT_BY_ID));
}

function* fetchProjectByIdFlow() {
  yield takeLatest(FETCH_PROJECT_BY_ID, fetchProjectByIdSaga);
}

export function* submitProjectSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, SUBMIT_PROJECT, "СОХРАНЕНИЕ ПРОЕКТА"));

  try {
    const { projectId, form } = action.payload;
    const isNew = projectId === 0;

    const requiredDocuments = form.requiredDocuments.map(item => ({
      id: item
    }));

    const projectToSubmit = {
      id: projectId,
      name: form.name,
      fullName: form.fullName,
      description: form.description,
      incidentURL: form.incidentURL,
      referenceAsuz: {
        code: form.codeAsuz,
        name: form.nameAsuz,
        redirectUrl: "",
      },
      sonarQubeProjectCode: form.sonarQubeProjectCode,
      testStandUrl: form.testStandUrl,
      passportCYYCIT: form.passportCYYCIT,
      kubernetes: form.kubernetes,
      grafana: form.grafana,
      maven: form.maven,
      nuget: form.nuget,
      npm: form.npm,
      docker: form.docker,
      userPermissions: form.users,
      requiredDocuments,
      signReleaseInventory: form.signReleaseInventory,
      dictionaryEntry : form.dictionaryEntry,
      reportTemplates: form.reportTemplates
    };

    const response = yield call(
      Api.project.submitProject,
      isNew ? "POST" : "PUT",
      projectToSubmit
    );

    const responseProjectId = response.data.id;

    yield put(push(`/project/${responseProjectId}`));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put(setSpecificError(ERROR_PROJECT_DUPLICATE_NAME));
    } else {
      yield put(setError(`СОХРАНЕНИЕ ПРОЕКТА - ${errorParser(error) || error.message}`));
    }
  }

  yield put(removeLoader(GLOBAL_LOADER, SUBMIT_PROJECT));
}

export function* submitProjectFlow() {
  yield takeLatest(SUBMIT_PROJECT, submitProjectSaga);
}

export function* deleteProjectSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, DELETE_PROJECT, "УДАЛЕНИЕ ПРОЕКТА"));

  try {
    const deleteProjectId = action.payload.id;
    yield call(Api.project.deleteProject, deleteProjectId);
    yield put(push("/registry"));
  } catch (error) {
    yield put(setError(`УДАЛЕНИЕ ПРОЕКТА - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(GLOBAL_LOADER, DELETE_PROJECT));
}

export function* deleteProjectFlow() {
  yield takeLatest(DELETE_PROJECT, deleteProjectSaga);
}

export function* restoreProjectSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, RESTORE_PROJECT, "ВОСТАНОВЛЕНИЕ ПРОЕКТА"));

  try {
    const id = action.payload.params;
    yield call(Api.project.restoreProject, id);
    yield put(push("/registry"));
  } catch (error) {
    yield put(setError(`ВОСТАНОВЛНИЕ ПРОЕКТА - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(GLOBAL_LOADER, RESTORE_PROJECT));
}
export function* restoreProjectFlow() {
  yield takeLatest(RESTORE_PROJECT, restoreProjectSaga);
}

function* getInfoPipeline(action) {
  const repositoryId = `GET_INFO_PAPELINE ${  action.payload.params.repositoryId}`;
  yield put(setLoader(LOCAL_LOADER, repositoryId, "ПОЛУЧЕНИЕ СТАТУСА"));
  try {
    let { params } = action.payload;
    const response = yield call(Api.project.getInfoPipline, params);
    params = { ...params, links: response.data }
    window.open(response.data)

    yield put(setInfoPaipline(params));
  } catch (error) {
    yield put(setError(`ПОЛУЧЕНИЕ СТАТУСОВ - ${errorParser(error) || error.message}`));
  }

  yield put(removeLoader(LOCAL_LOADER, repositoryId));
}

function* getInfoPipelineFlow() {
  yield takeLatest(GET_INFO_PAPELINE, getInfoPipeline);
}

export function* setVersion() {
  const responseBack = yield call(Api.project.getBackVersion);
  yield put(setBackVersion(responseBack.data));
  yield put(setFrontVersion());
}


function* simpleSearchProjectByName(action) {
  yield put(setLoader(LOCAL_LOADER, SIMPLE_SEARCH_PROJECT, "ЗАГРУЗКА ПРОЕКТОВ"));
  try {
    const response = yield call(Api.project.getSimpleProjects, action.payload.searchName);
    yield put(setSimpleProjects(response.data));
  } catch (error) {
    yield put(setError(`ОШИБКА ЗАГРУЗКИ ПРОЕКТОВ - ${errorParser(error) || error.message}`));
  }
  yield put(removeLoader(LOCAL_LOADER, SEARCH_PROJECTS));
}

function* simpleSearchProjectsFlow(){
  yield takeLatest(SIMPLE_SEARCH_PROJECT, simpleSearchProjectByName);
}

export default function* project() {
  yield all([
    fetchProjectsFlow(),
    fetchAllProjectsFlow(),
    fetchProjectsOptionsFlow(),
    searchProjectsMetrickFlow(),
    searchProjectsFlow(),
    fetchProjectByIdFlow(),
    submitProjectFlow(),
    deleteProjectFlow(),
    getInfoPipelineFlow(),
    setVersion(),
    restoreProjectFlow(),
    fetchProjectsMetricFlow(),
    simpleSearchProjectsFlow()
  ]);
}
