import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  getDictionary: code =>
    axios.get(`${API_HOST}/api/fpa/v1/dictionaries/${code}/entries`),

  getDictionaries: () => axios.get(`${API_HOST}/api/fpa/v1/dictionaries`),

  deleteDictionaryEntry: (dictionaryCode, entryCode) =>
    axios.delete(
      `${API_HOST}/api/fpa/v1/dictionaries/${dictionaryCode}/entries/${entryCode}`
    ),

  submitDictionaryEntry: (method, dictionaryCode, dictionaryEntry) =>
    axios({
      method,
      url: `${API_HOST}/api/fpa/v1/dictionaries/${dictionaryCode}/entries`,
      data: dictionaryEntry
    }),

   createDictionary: (method, dictionary) =>
        axios({
            method,
            url: `${API_HOST}/api/fpa/v1/dictionaries`,
            data: dictionary
        })

};
