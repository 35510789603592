import styled from "styled-components";


export const BadgeGreen = styled.span`
  background-color: #4fb14f;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
`;

export const BadgeGreenLinked = styled.span`
  background-color: #4fb14f;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    background-color: #6bcc6b
  }
`;

export const BadgeRed = styled.span`
  background-color: #fb756f;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
`;

export const BadgeRedLinked = styled.span`
  background-color: #fb756f;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    background-color: #f19b97 
  }
`;

export const BadgeYellow = styled.span`
  background-color: #deac1c;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
`;

export const BadgeYellowLinked = styled.span`
  background-color: #deac1c;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    background-color: #ecc34c 
  }
`;

export const BadgeBlue = styled.span`
  background-color: #1caade;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
`;

export const BadgeBlueLinked = styled.span`
  background-color: #1caade;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    background-color: #4dc3ee 
  }
`;

export const BadgeBlack = styled.span`
  background-color: #585858;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
`;

export const BadgeBlackLinked = styled.span`
  background-color: #585858;
  color: rgb(255, 255, 255);
  display: inline-block;
  margin: 0px 3px
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
    background-color: #818181 
  }
`;