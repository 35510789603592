import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  PageSizeBlock,
  NavigationBlock,
  PaginationLabel,
  PaginationInput
} from "./styles";
import { Select } from "../styles";
import {
  ButtonIconNext,
  ButtonIconPrevious,
  ButtonIconJumpForward,
  ButtonIconJumpBack
} from "../Buttons";

const pageSizeOptions = [5, 10, 20, 25, 50];

export default class Pagination extends Component {
  changePage = selectedPage => {
    const { page, pages, onPageChange } = this.props;
    const activePage = page + 1;

    if (selectedPage === activePage || selectedPage < 1 || selectedPage > pages)
      return;

    onPageChange(selectedPage - 1);
  };

  render() {
    const { pages, page, pageSize, onPageSizeChange } = this.props;
    const activePage = page + 1;

    return (
      <Container>
        <PageSizeBlock>
          <PaginationLabel>Показывать</PaginationLabel>

          <Select
            name="pageSize"
            value={pageSize}
            onChange={e => {
              onPageSizeChange(parseInt(e.target.value, 10));
            }}
          >
            {pageSizeOptions.map(size => (
              <option key={`opt_ps_${size}`} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </PageSizeBlock>
        <NavigationBlock key={`input_refresh_${activePage}`}>
          <ButtonIconJumpBack
            onClick={() => {
              this.changePage(1);
            }}
            disabled={activePage === 1}
          />
          <ButtonIconPrevious
            onClick={() => {
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          />
          <PaginationLabel>Страница</PaginationLabel>
          <PaginationInput
            type="number"
            min="1"
            max={pages}
            defaultValue={activePage}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.changePage(e.target.value);
              }
            }}
          />
          <PaginationLabel>{`/ ${pages}`}</PaginationLabel>
          <ButtonIconNext
            onClick={() => {
              this.changePage(activePage + 1);
            }}
            disabled={activePage === pages}
          />
          <ButtonIconJumpForward
            onClick={() => {
              this.changePage(pages);
            }}
            disabled={activePage === pages}
          />
        </NavigationBlock>
      </Container>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired
};
