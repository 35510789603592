import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import Api from "../api";
import {
  setLoader,
  removeLoader,
  setError,
  errorParser,
} from "../store/preloader/actions";
import { LOCAL_LOADER } from "../store/preloader/constants";
import { setGroupTags, setTag, fetchTag , fetchGroupTags } from "../store/gitlab/actions";
import {
  FETCH_GROUP_TAGS,
  FETCH_TAG,
  CREATE_TAG,
} from "../store/gitlab/constants";


export function* fetchGroupTagsSaga(action) {
  yield put(
    setLoader(LOCAL_LOADER, FETCH_GROUP_TAGS, "ЗАГРУЗКА ТЕГОВ ГРУППЫ GITLAB")
  );

  try {
    console.log(action);
    const groupId = action.payload.id;

    const response = yield call(Api.gitlab.getGroupTags, groupId);

    const tags = response.data || [];

    yield put(setGroupTags(tags));

    // get initial tag data
    const reposWithTags = tags.filter(
      (repo) => repo.tags && repo.tags.length > 0
    );
    if (reposWithTags.length > 0) {
      yield all(
        reposWithTags.map((repo) =>
          put(fetchTag(groupId, repo.gitLabId, repo.tags[0].gitLabId))
        )
      );
    }
  } catch (error) {
    yield put(
      setError(
        `ЗАГРУЗКА ТЕГОВ ГРУППЫ GITLAB - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_GROUP_TAGS));
}
export function* fetchGroupTagsFlow() {
  yield takeLatest(FETCH_GROUP_TAGS, fetchGroupTagsSaga);
}

export function* fetchTagSaga(action) {
  yield put(setLoader(LOCAL_LOADER, FETCH_TAG, "ЗАГРУЗКА ТЕГА"));

  try {
    const { groupId, repositoryId, tagId } = action.payload;

    const response = yield call(
      Api.gitlab.getTag,
      groupId,
      repositoryId,
      tagId
    );

    const tag = response.data;

    yield put(setTag(tag));
  } catch (error) {
    yield put(
      setError(`ЗАГРУЗКА ТЕГА - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_TAG));
}
export function* fetchTagFlow() {
  yield takeEvery(FETCH_TAG, fetchTagSaga);
}

export function* createTagSaga(action) {
  try {
    const { groupId, repositoryId, params } = action.payload;

    yield call(Api.gitlab.createTag, groupId, repositoryId, params);

    yield put(fetchGroupTags(groupId));
  } catch (error) {
    yield put(
      setError(`ДОБАВЛЕНИЕ ТЕГА - ${errorParser(error) || error.message}`)
    );
    return 0;
  }

  yield put(removeLoader(LOCAL_LOADER, CREATE_TAG));
}
export function* createTagFlow() {
  yield takeLatest(CREATE_TAG, createTagSaga);
}

export default function* gitlab() {
  yield all([fetchGroupTagsFlow(), fetchTagFlow(), createTagFlow()]);
}
