import { createSelector } from "reselect";

const selectMailing = state => state.getIn(["mailing", "listMailing"]);
const makeSelectMailing = createSelector(
    selectMailing,
    substate => substate.toJS()
);

export {
  makeSelectMailing
};