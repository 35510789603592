import React from "react";
import PropTypes from "prop-types";
import { ListRowWrapper, ListRowLabel, ListRowText } from "./styles";

const ListRow = ({ labelText, text }) => (
  <ListRowWrapper>
    <ListRowLabel>{labelText}</ListRowLabel>
    <ListRowText>{text}</ListRowText>
  </ListRowWrapper>
);

ListRow.propTypes = {
  labelText: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default ListRow;
