import { fromJS } from "immutable";
import { SET_REPORTS, SET_TECH_SOLUTIONS } from "./constants";

const initialState = fromJS({
  techSolutions: [],
  reports: [],

});

const documents = (state = initialState, action) => {
  switch (action.type) {
    case SET_TECH_SOLUTIONS: {
      return state.set("techSolutions", fromJS(action.payload.techSolutions));
    }
    case SET_REPORTS: {
      return state.set("reports", fromJS(action.payload.reports));
    }
    default: {
      return state;
    }
  }
};

export default documents;
