import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import 'moment/locale/ru'
import moment from 'moment';
import MailingList from "../../components/MailingList";
import { fetchMailings, putMailing } from "../../store/mailing/actions";
import {makeSelectMailing} from "../../store/mailing/selectors";

import { fetchProjectsOptions, fetchProjectById } from "../../store/project/actions";
import {makeSelectProjectsOptions, makeSelectProject} from "../../store/project/selectors";
import { makeSelectReports } from "../../store/documents/selectors";
import {
  fetchReports,
} from "../../store/documents/actions";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise,eqeqeq, no-mixed-operators
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line no-bitwise, no-mixed-operators
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
moment.updateLocale('ru', {
      week: {
        dow: 1,
      },
    })
class MailingPage extends Component {
  state = {
    isOpen: false,
    focused: false,
    date: moment(),
    duration: [
      {title: "Неделя", value: "WEEK"},
      {title: "Две недели", value: "TWO_WEEK"},
      {title: "Месяц", value: "MONTH"},
      {title: "Квартал", value: "QUARTER"},
    ],
   
    userKey: null,
    projectToAdd: null,
    reportTemplateToAdd: null,
    durationToAdd: null,
    list: []
  }

  onActionEdit = listItem => {
    console.log(moment())
     this.props.fetchProjectById(listItem.project.id)
    console.log(listItem)
    const period = this.state.duration.find((item) => item.value === listItem.period)
    console.log(period)
    this.setState({
      date: moment(listItem.date),
      userKey: listItem.userKey,
      projectToAdd: listItem.project,
      
      reportTemplateToAdd: listItem.reportTemplate,
      durationToAdd: period,
      isOpen: true
    })


  }

  onChangeProject = project => {
    this.props.fetchProjectById(project.id)
    // console.log(this.props.project)
    this.setState({
     
      projectToAdd: project
    })
  }

  onChangeReport = report => {
    this.setState({
      reportTemplateToAdd: report
    })
  }

  onChangeDuration = duration => {
    this.setState({
      durationToAdd: duration
    })
  }


  onDeleteRow = userKey => {
    const filteredList = this.state.list.filter((item) => item.userKey !== userKey )
    this.setState({
      list: filteredList
    })
  }

  onDateChange = date => {
    if(date) {
        this.setState({ date })
    }
    else {
        this.setState({ date: moment().subtract(1, 'months') })
    }
  }

  onFocusChange = () => {
    // eslint-disable-next-line prefer-destructuring
    const focused = this.state.focused
    this.setState({ focused: !focused })
  }


  handleShowModal = () => {
    this.setState({
      userKey: null,
      isOpen: true,
      date: moment(),
      projectToAdd: null,
      reportTemplateToAdd: null,
      durationToAdd: null,
    })
  }

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    })
  };

  onSubmitModal = () => {
    let newList = this.state.list
    let newListItem = {}
    if (this.state.userKey) {
        newList = newList.filter((item) => item.userKey !== this.state.userKey )
      newListItem = {
          "id": null,
          "userKey":this.state.userKey,
          "project": this.state.projectToAdd,
          "reportTemplate": this.state.reportTemplateToAdd,
          "date": this.state.date.format("YYYY-MM-DD"),
          "period": this.state.durationToAdd.value
      }
    } else {
      newListItem = {
          "id": null,
          "userKey": uuidv4(),
          "project": this.state.projectToAdd,
          "reportTemplate": this.state.reportTemplateToAdd,
          "date": this.state.date.format("YYYY-MM-DD"),
          "period": this.state.durationToAdd.value
      }
    }


    // eslint-disable-next-line no-const-assign
    newList.push(newListItem)
    this.setState({
      isOpen: false,
      list: newList,
      projectToAdd: {},
      reportTemplateToAdd: {},
      durationToAdd: {},
    })

  }

  onSubmitMailing = () => {
  console.log(this.state, this.props)
    this.props.putMailing(this.state.list)
  }

  componentDidMount() {
    this.props.fetchReports();
    this.props.fetchMailings();
     this.props.fetchProjectsOptions();
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newList = this.props.listMailing.map(item => {
      // eslint-disable-next-line no-param-reassign
      item.userKey = uuidv4();
      return item
    })
    this.setState({
      list: newList
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.listMailing !== prevProps.listMailing) {
      console.log(this.props.projectsOptions)
      const newList = this.props.listMailing.map(item => {
      // eslint-disable-next-line no-param-reassign
      item.userKey = uuidv4();
      return item
    })
    this.setState({
      list: newList
    })
    }
  }

  render() {
    const { projectsOptions, reports } = this.props;

    return (
      <MailingList
        isOpen={this.state.isOpen}
        projectToAdd={this.state.projectToAdd}
        durationToAdd={this.state.durationToAdd}
        reportTemplateToAdd={this.state.reportTemplateToAdd}
        handleShowModal={this.handleShowModal}
        handleCloseModal={this.handleCloseModal}
        onChangeProject={this.onChangeProject}
        onChangeReport={this.onChangeReport}
        onChangeDuration={this.onChangeDuration}
        reports={reports}
        list={this.state.list}
        duration={this.state.duration}
        date={this.state.date}
        focused={this.state.focused}
        onFocusChange={this.onFocusChange}
        onDateChange={this.onDateChange}
        onDeleteRow={this.onDeleteRow}
        onSubmitModal={this.onSubmitModal}
        onSubmitMailing={this.onSubmitMailing}
        onActionEdit={this.onActionEdit}
        projectsOptions={projectsOptions}
      />
    );
  }
}

const mapStateToProps = state => ({
  listMailing: makeSelectMailing(state),
  projectsOptions: makeSelectProjectsOptions(state),
  project: makeSelectProject(state),
  reports: makeSelectReports(state)
});

const mapDispatchToProps = dispatch => ({
  fetchProjectById: id => dispatch(fetchProjectById(id)),
  putMailing: list => dispatch(putMailing(list)),
  fetchMailings: () => dispatch(fetchMailings()),
  fetchProjectsOptions: () => dispatch(fetchProjectsOptions()),
  fetchReports: () => dispatch(fetchReports()),
});

MailingPage.propTypes = {
  putMailing: PropTypes.func.isRequired,
  fetchMailings: PropTypes.func.isRequired,
  fetchProjectById: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  listMailing: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectsOptions: PropTypes.array.isRequired,
  fetchProjectsOptions: PropTypes.func.isRequired,

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailingPage);


