import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Content } from "./styles";
import { ButtonIconDots } from "../Buttons";

class Dropdown extends Component {
  state = {
    showMenu: false
  };

  showMenu = event => {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.closeMenu);
  }

  render() {
    return (
      <Container>
        <ButtonIconDots type="button" onClick={this.showMenu} />
        {
          this.state.showMenu && (
          <Content>
            {this.props.children}
          </Content>
        )
        }
      </Container>
    );
  }
}

Dropdown.propTypes = {
  children: PropTypes.arrayOf(Object).isRequired
};

export default Dropdown;
