import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { externalSystemDictionariesType } from "../../../types";
import { Input, Select, ErrorMessage } from "../../common/styles";
import { ButtonSave } from "../../common/Buttons";
import RowBlock from "../../common/RowBlock";
import { initializeFormValues, processFormValues } from "./utils";
import FileUploader from "../../DocumentsEditor/DocumentsForm/FileUploader";

const ExternalSystemForm = props => {
  const {
    externalSystemDictionaries: {
      sourceMode,
      sourceLocation,
      informationConsumer,
      consumerLocation,
      authentication,
      transport,
      interfaces,
      transData
    },

    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched
  } = props;
  const rowLabelWidth = "300px";
  const inputWidth = "80%";
  const selectWidth = "40%";

  return (
    <div>
      <RowBlock text="НАИМЕНОВАНИЕ" labelWidth={rowLabelWidth} alignSelfCenter>
        <Input
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          width={inputWidth}
          maxLength={253}
        />
      </RowBlock>
      {touched.name && errors.name && (
        <ErrorMessage>{errors.name}</ErrorMessage>
      )}
      <RowBlock
        text="РЕЖИМ ИСТОЧНИКА"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="sourceMode"
          value={values.sourceMode}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {sourceMode.map(item => (
            <option key={`opt_sm_${item.id}`} value={item.id} label={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.sourceMode && errors.sourceMode && (
        <ErrorMessage>{errors.sourceMode}</ErrorMessage>
      )}
      <RowBlock
        text="РАСПОЛОЖЕНИЕ ИСТОЧНИКА"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="sourceLocation"
          value={values.sourceLocation}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {sourceLocation.map(item => (
            <option key={`opt_sl_${item.id}`} value={item.id} label={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.sourceLocation && errors.sourceLocation && (
        <ErrorMessage>{errors.sourceLocation}</ErrorMessage>
      )}
      <RowBlock
        text="ПОТРЕБИТЕЛЬ ИНФОРМАЦИИ"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="informationConsumer"
          value={values.informationConsumer}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {informationConsumer.map(item => (
            <option key={`opt_ic_${item.id}`} value={item.id} label={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.informationConsumer && errors.informationConsumer && (
        <ErrorMessage>{errors.informationConsumer}</ErrorMessage>
      )}
      <RowBlock
        text="РАСПОЛОЖЕНИЕ ПОТРЕБИТЕЛЯ"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="consumerLocation"
          value={values.consumerLocation}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {consumerLocation.map(item => (
            <option
              key={`opt_consl_${item.id}`}
              value={item.id}
              label={item.name}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.consumerLocation && errors.consumerLocation && (
        <ErrorMessage>{errors.consumerLocation}</ErrorMessage>
      )}
      <RowBlock
        text="ВНУТРИ ДЦ / МЕЖДУ ДЦ"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="insideDC"
          value={values.insideDC}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value label="Внутри ДЦ">
            Внутри ДЦ
          </option>
          <option value={false} label="Между ДЦ">
            Между ДЦ
          </option>
        </Select>
      </RowBlock>
      <RowBlock
        text="АУТЕНТИФИКАЦИЯ"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="authentication"
          value={values.authentication}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {authentication.map(item => (
            <option
              key={`opt_auth_${item.id}`}
              value={item.id}
              label={item.name}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.authentication && errors.authentication && (
        <ErrorMessage>{errors.authentication}</ErrorMessage>
      )}
      <RowBlock text="ТРАНСПОРТ" labelWidth={rowLabelWidth} alignSelfCenter>
        <Select
          name="transport"
          value={values.transport}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {transport.map(item => (
            <option
              key={`opt_trans_${item.id}`}
              value={item.id}
              label={item.name}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.transport && errors.transport && (
        <ErrorMessage>{errors.transport}</ErrorMessage>
      )}
      <RowBlock
        text="ПЕРЕДАВАЕМЫЕ ДАННЫЕ"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Select
          name="transData"
          value={values.transData}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {transData.map(item => (
            <option
              key={`opt_trdata_${item.id}`}
              value={item.id}
              label={item.name}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.transData && errors.transData && (
        <ErrorMessage>{errors.transData}</ErrorMessage>
      )}
      <RowBlock text="ИНТЕРФЕЙС" labelWidth={rowLabelWidth} alignSelfCenter>
        <Select
          name="interfaces"
          value={values.interfaces}
          onChange={handleChange}
          width={selectWidth}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {interfaces.map(item => (
            <option key={`opt_if_${item.id}`} value={item.id} label={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      {touched.interfaces && errors.interfaces && (
        <ErrorMessage>{errors.interfaces}</ErrorMessage>
      )}
      <RowBlock
        text="ИДЕНТИФИКАТОР ИНТЕРФЕЙСА"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Input
          name="internalInterfaceId"
          type="text"
          value={values.internalInterfaceId}
          onChange={handleChange}
          width={inputWidth}
          maxLength={253}
        />
      </RowBlock>
      {touched.internalInterfaceId && errors.internalInterfaceId && (
        <ErrorMessage>{errors.internalInterfaceId}</ErrorMessage>
      )}
      <RowBlock
        text="ПЕРИОДИЧНОСТЬ (РАЗ В СУТКИ)"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Input
          name="periodicity"
          type="number"
          min="0"
          value={values.periodicity}
          onChange={handleChange}
          width={inputWidth}
        />
      </RowBlock>
      {touched.periodicity && errors.periodicity && (
        <ErrorMessage>{errors.periodicity}</ErrorMessage>
      )}
      <RowBlock
        text="СУММАРНЫЙ ОБЪЕМ ЗА СУТКИ"
        labelWidth={rowLabelWidth}
        alignSelfCenter
      >
        <Input
          name="totalAmountPerDay"
          type="number"
          min="0"
          value={values.totalAmountPerDay}
          onChange={handleChange}
          width={inputWidth}
        />
      </RowBlock>
      {touched.totalAmountPerDay && errors.totalAmountPerDay && (
        <ErrorMessage>{errors.totalAmountPerDay}</ErrorMessage>
      )}
      <RowBlock text="РЕГЛАМЕНТ" labelWidth={rowLabelWidth} alignSelfCenter>
        <FileUploader
          selectedFile={values.regulation}
          onChange={file => {
            setFieldValue("regulation", file);
          }}
        />
      </RowBlock>
      <ButtonSave
        text="Сохранить"
        type="submit"
        onClick={handleSubmit}
        margin="20px 0"
      />
    </div>
  );
};

ExternalSystemForm.propTypes = {
  // onSubmitForm: PropTypes.func.isRequired,
  externalSystemDictionaries: externalSystemDictionariesType.isRequired,

  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: initializeFormValues,
  validate: values => {
    const errors = {};
    if (!values.name) {
      errors.name = "Это поле обязательно для заполнения!";
    }
    if (!values.internalInterfaceId) {
      errors.internalInterfaceId = "Это поле обязательно для заполнения";
    }
    if (!values.periodicity) {
      errors.periodicity = "Это поле обязательно для заполнения";
    }
    if (!values.totalAmountPerDay) {
      errors.totalAmountPerDay = "Это поле обязательно для заполнения";
    }
    if (!values.sourceMode) {
      errors.sourceMode = "Пожалуйста, сделайте выбор";
    }
    if (!values.sourceLocation) {
      errors.sourceLocation = "Пожалуйста, сделайте выбор";
    }
    if (!values.informationConsumer) {
      errors.informationConsumer = "Пожалуйста, сделайте выбор";
    }
    if (!values.consumerLocation) {
      errors.consumerLocation = "Пожалуйста, сделайте выбор";
    }
    if (!values.transData) {
      errors.transData = "Пожалуйста, сделайте выбор";
    }
    if (!values.transport) {
      errors.transport = "Пожалуйста, сделайте выбор";
    }
    if (!values.authentication) {
      errors.authentication = "Пожалуйста, сделайте выбор";
    }
    if (!values.interfaces) {
      errors.interfaces = "Пожалуйста, сделайте выбор";
    }

    return errors;
  },
  handleSubmit: (values, formikBag) => {
    const newExtSys = processFormValues(
      values,
      formikBag.props.externalSystemDictionaries
    );
    formikBag.props.onSubmitForm(newExtSys);
  }
})(ExternalSystemForm);
