import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {projectType} from "../../../types";
import {BoldSpan, Input, PageHeaderBlock, PageHeaderTitle} from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";
import {
    fetchUserAdministration,
    resetFilterAdministration,
    setFilterAdministration
} from "../../../store/administration/actions";
import {makeSelectAllUsersAdministaration, makeSelectFilters} from "../../../store/administration/selectors";



class AdminUsersPage extends Component {
    state = {search: ''}

    componentDidMount() {
        console.log('AdminUsersPage')
        const {
            resetFilter,
            fetchUsersAdmin,
        } = this.props;
        resetFilter();
        fetchUsersAdmin();
    }

    render() {
        const {
            setFilter,
            filterNow,
            fetchUsersAdmin,
            allUsers
        } = this.props;

        const {search} = this.state

        const getData = () => {
            fetchUsersAdmin();
        }

        const setPage = (page) => {
            setFilter({...filterNow, page});
            getData()
        }
        const setSize = (size) => {
            console.log(filterNow)
            setFilter({...filterNow, page: 0, size});
            getData()
        }

        const handleChange = (val) => {
            this.setState({search: val})
        }
        const searchHandel = () => {
            const res = {...filterNow, page: 0, nameUser: search};
            setFilter(res);
            getData()
        }
        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Администрирование пользователей </ PageHeaderTitle>
                    <Input width="80%" name="comment" value={search} onChange={e => handleChange(e.target.value)}/>
                    <ButtonSimple text="Поиск" onClick={searchHandel}/>

                </PageHeaderBlock>

                {allUsers.length > 0 ? (
                    <ReactTable
                        manual
                        data={allUsers}
                        page={filterNow.page}
                        pages={filterNow.maxPage}
                        pageSize={filterNow.size}
                        onPageChange={setPage}
                        onPageSizeChange={setSize}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "id",
                                accessor: "id"
                            },
                            {
                                Header: "Полный UID в LDAP",
                                id: "distinguishedName",
                                accessor: "distinguishedName",

                            },
                            {
                                Header: " Полное имя",
                                accessor: "fullName"
                            },
                            {
                                Header: " UID в LDAP",
                                accessor: "username"
                            },
                            {
                                Header: 'Действие',
                                Cell: (row => <div>
                                    <Link to={`/admin/users/${row.original.id}`}> <ButtonSimple text="открыть"/></Link>
                                </div>)
                            }
                        ]}
                    />
                ) : (
                    <BoldSpan>Нет проектов</BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = state => ({
    filterNow: makeSelectFilters(state),
    allUsers: makeSelectAllUsersAdministaration(state)
});

const mapDispatchToProps = dispatch => ({
    resetFilter: () => dispatch(resetFilterAdministration()),
    fetchUsersAdmin: () => dispatch(fetchUserAdministration()),
    setFilter: ({
                    page,
                    size,
                    nameProject,
                    nameUser,
                    totalElements = 100,
                    maxPage = 10,
                }) => dispatch(setFilterAdministration(page, size, nameProject, nameUser, totalElements, maxPage)),

});

AdminUsersPage.propTypes = {
    filterNow: projectType.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    allUsers: PropTypes.array.isRequired,
    fetchUsersAdmin: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUsersPage);
