import { fromJS, merge } from "immutable";
import {
  SET_DICTIONARIES_LIST,
  SET_DICTIONARIES,
  SET_DICTIONARY_ENTRIES
} from "./constants";

const initialState = fromJS({
  dictionariesList: [],

  developmentEnvironment: [],
  platform: [],
  additionalComponents: [],
  database: [],
  documentType: [],
  sourceMode: [],
  sourceLocation: [],
  informationConsumer: [],
  consumerLocation: [],
  authentication: [],
  transport: [],
  interfaces: [],
  transData: []
});

const dictionaries = (state = initialState, action) => {
  switch (action.type) {
    case SET_DICTIONARIES: {
      return merge(state, fromJS(action.payload.dictionaries));
    }
    case SET_DICTIONARIES_LIST: {
      return state.set("dictionariesList", fromJS(action.payload.dictionaries));
    }
    case SET_DICTIONARY_ENTRIES: {
      const { code, dictionaryEntries } = action.payload;
      return state.set(code, fromJS(dictionaryEntries));
    }
    default: {
      return state;
    }
  }
};

export default dictionaries;
