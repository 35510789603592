import React from "react";
import PropTypes from "prop-types";
import { ModalContainer, ModalBody, ButtonsRow } from "../Modal/styles";
import { SectionHeaderTitle } from "../styles";
import { Button } from "../Buttons/styles";

function NotificationAlert(props) {
  return (
    <ModalContainer>
      <ModalBody>
        <SectionHeaderTitle>{props.message}</SectionHeaderTitle>
        <ButtonsRow>
          <Button
            type="submit"
            onClick={() => {
              props.onClose();
              props.closeModal();
            }}
            margin="0 25px 0 0"
          >
            OK
          </Button>
        </ButtonsRow>
      </ModalBody>
    </ModalContainer>
  );
}

NotificationAlert.propTypes = {
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

NotificationAlert.defaultProps = {
  onClose: () => {}
};

export default NotificationAlert;
