import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Prompt } from 'react-router'
import {
  reportsType,
  dictionaryEntriesType
} from "../../types";

import ProjectForm from "./ProjectForm";
import { PageHeaderBlock, PageHeaderTitle, ButtonsRow } from "../common/styles";
import { ButtonSave } from "../common/Buttons";
import { Button } from "../common/Buttons/styles";
import { initializeFormValues } from "./utils";

const ProjectEditor = ({
  isNew,
  reports,
  project,
  documentTypeDictionary,
  specificError,
  onClickCancel,
  onShowModal,
  values,
  handleChange,
  setFieldValue,
  handleSubmit,
  errors,
  touched,
  dirty,
  listAsuz,
  handleAsuzChange,
  loadOptions,
  onChangeAsuz,
  selectedAsuz,
  handelChangeDictionaryEntry
}) => {

  const pageReloadListener = (e) => {
    e.preventDefault();
    e.returnValue = '';
  }
  const [preventReload, setPreventReload] = useState(false);
  useEffect(() => {
    window.addEventListener('beforeunload', pageReloadListener)
    return () => {
      window.removeEventListener('beforeunload', pageReloadListener)
    }
  }, [preventReload]);
  useEffect(() => {
    if (dirty) {
      setPreventReload(true);
    } else {
      setPreventReload(false);
    }
    
  }, [dirty])

  const saveChange = () => {
    setPreventReload(false);
    window.removeEventListener('beforeunload', pageReloadListener);
    handleSubmit();
  }
  return (
    <div>
      <PageHeaderBlock editor>
        <PageHeaderTitle>Данные об ИУС</PageHeaderTitle>
        <ButtonSave
          type="submit"
          onClick={saveChange}
          text="Сохранить"
          margin="0 10px 0 10px" />
        <Button onClick={onClickCancel}>Отмена</Button>
      </PageHeaderBlock>

      <ProjectForm
        listAsuz={listAsuz}
        selectedAsuz={selectedAsuz}
        loadOptions={loadOptions}
        handleAsuzChange={handleAsuzChange}
        onChangeAsuz={onChangeAsuz}
        id={isNew ? null : project.id}
        isNew={isNew}
        reports={reports}
        documentTypeDictionary={documentTypeDictionary}
        specificError={specificError}
        onShowModal={onShowModal}
        values={values}
        errors={errors}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        touched={touched}
        handelChangeDictionaryEntry={handelChangeDictionaryEntry}
      />
      <ButtonsRow>
        <ButtonSave
          type="submit"
          onClick={saveChange}
          text="Сохранить"
          margin="0 10px 0 0"
        />
        <Button onClick={onClickCancel}>Отмена</Button>
      </ButtonsRow>
      <Prompt
        when={preventReload}
        message={(location) => {
          if (preventReload && location.pathname === '/logout') {
            return `На этой странице есть несохраненные изменения. Вы уверены что хотите выйти из системы?`
          }
          return `На этой странице есть несохраненные изменения. Вы уверены что хотите покинуть страницу?`
        }}
      />
    </div>
  );
};

ProjectEditor.propTypes = {
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  specificError: PropTypes.string.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  // onSubmitForm: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  reports: reportsType.isRequired,

  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onChangeAsuz: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  listAsuz: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedAsuz: PropTypes.object.isRequired,
  handleAsuzChange: PropTypes.func.isRequired,
  handelChangeDictionaryEntry: PropTypes.func.isRequired,
};

ProjectEditor.defaultProps = {
  // project: null,
  release: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  dirty: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  reportTemplates: [],
};

export default withFormik({
  displayName: ProjectEditor.apply,
  enableReinitialize: true,
  mapPropsToValues: initializeFormValues,
  validateOnBlur: true,
  validate: (values, { project }) => { // { projectForm, isNew }
    const errors = {};
    if (!values.name) {
      errors.name = "Введите, пожалуйста, наименование системы";
    }
    if (!values.fullName) {
      errors.fullName = "Введите, пожалуйста, полное наименование системы";
    }

    return errors;
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmitForm(values);
  }
})(ProjectEditor);
