import axios from "axios";
import {
  GL_HOST,
  CLIENT_ID,
  CLIENT_SECRET,
  APPLICATION_HOST,
  REDIRECT_URI_AUTHORIZE,
  API_HOST
} from "../utils/constants";

export default {
  adLogin : (username, password) =>{
     axios.defaults.headers.common.Authorization = "";
     return axios.post(`${API_HOST}/api/fpa/v1/auth/loginad`, {username, password})
  },
  getToken: authorizeCode =>
    axios({
      method: "POST",
      url: `${GL_HOST}/oauth/token`,
      data: {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        code: authorizeCode,
        grant_type: "authorization_code",
        redirect_uri: `${APPLICATION_HOST}${REDIRECT_URI_AUTHORIZE}`
      }
    }),
  getUser: () => axios.get(`${API_HOST}/api/fpa/v1/users/current`),
  logout: () => axios.get(`${API_HOST}/api/fpa/v1/users/logout`)
};
