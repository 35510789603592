import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import get from "lodash.get";
import { techSolutionsType } from "../../../types";
import { Input, Select } from "../../common/styles";
import { ButtonSave } from "../../common/Buttons";
import RowBlock from "../../common/RowBlock";
import { generateId } from "../../../utils/helpers";

function TechSolutionForm(props) {
  const {
    techSolutions,

    values,
    handleChange,
    handleSubmit
  } = props;

  return (
    <div>
      <RowBlock text="ТЕХНИЧЕСКОЕ РЕШЕНИЕ" labelWidth="200px" alignSelfCenter>
        <Select
          name="techSolutionId"
          value={values.techSolutionId}
          onChange={handleChange}
          width="100%"
        >
          {techSolutions.map(solution => (
            <option
              key={`opt_ts_${solution.id}`}
              value={solution.id}
              label={`${solution.fileOriginalName} (${solution.comment})`}
            >
              {`${solution.fileOriginalName} (${solution.comment})`}
            </option>
          ))}
        </Select>
      </RowBlock>
      <RowBlock text="КОММЕНТАРИЙ" labelWidth="200px" alignSelfCenter>
        <Input
          width="80%"
          name="comment"
          onChange={handleChange}
          maxLength={253}
        />
      </RowBlock>
      <ButtonSave
        type="submit"
        onClick={handleSubmit}
        text="Сохранить"
        margin="20px 0 0 0"
      />
    </div>
  );
}

TechSolutionForm.propTypes = {
  techSolutions: techSolutionsType.isRequired,
  // onSubmitForm: PropTypes.func.isRequired,

  values: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    techSolutionId: get(props.techSolutions, "[0].id", ""),
    comment: ""
  }),
  handleSubmit: (values, formikBag) => {
    const techSolutionId = parseInt(values.techSolutionId, 10);
    const document = formikBag.props.techSolutions.find(
      solution => solution.id === techSolutionId
    );

    const newTechSolution = {
      id: 0,
      localId: generateId(),
      comment: values.comment,
      document
    };
    formikBag.props.onSubmitForm(newTechSolution);
  }
})(TechSolutionForm);
