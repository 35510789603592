import {all, call, put, takeLatest} from "redux-saga/effects";
import {FETCH_MAILING, PUT_MAILING, setMailings} from "../store/mailing/actions";

import {removeLoader, setError, setLoader, errorParser } from "../store/preloader/actions";

import Api from "../api";
import {GLOBAL_LOADER} from "../store/preloader/constants";


function* fetchMailings(action) {
    yield put(setLoader(GLOBAL_LOADER, FETCH_MAILING, "ЗАГРУЗКА РАССЫЛОК"));
    try {
        const response = yield call(Api.mailing.fetchMailing, action.payload);
        yield put(setMailings(response.data));

    } catch (error) {
        yield put(setError(`ЗАГРУЗКА ПРОЕКТОВ - ${ errorParser(error) || error.message }`));
    }
    yield put(removeLoader(GLOBAL_LOADER, FETCH_MAILING));
}

function* fetchMailingsFlow() {
    yield takeLatest(FETCH_MAILING, fetchMailings);
}

function* putMailing(action) {
    yield put(setLoader(GLOBAL_LOADER, PUT_MAILING, "СОХРАНЕНИЕ РАССЫЛОК"));

    try {
        console.log("action", action)
        yield call(Api.mailing.putMailing, action.payload);
        yield call(fetchMailings, action)
    } catch (error) {
        yield put(setError(`СОХРАНЕНИЕ РАССЫЛОК - ${ errorParser(error) || error.message }`));
    }

    yield put(removeLoader(GLOBAL_LOADER, PUT_MAILING));
}

function* putMailingFlow() {
    yield takeLatest(PUT_MAILING, putMailing);
}


export default function* mailing() {
    yield all([
        fetchMailingsFlow(),
        putMailingFlow(),
    ]);
}