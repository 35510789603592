import { fromJS } from "immutable";
import { SET_EVENTS } from "./actions";

const initialState = fromJS({
  eventsList: {
    content: [],
    empty: true,
    totalElements: 0,
    totalPages: -1
  }
});

const events = (state = initialState, action) => {
  if (action.type === SET_EVENTS) {
    return state.set("eventsList", fromJS(action.payload.events));
  }
  return state;
};

export default events;
