import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import {
  PageHeaderBlock,
  PageHeaderTitle,
  LinkStyled,
  BoldSpan,
  TextRegular
} from "../common/styles";
import Pagination from "../common/Pagination";
import { eventsResponseType, gitlabOptionsType } from "../../types";
import { getDateTimeFormatted } from "../../utils/helpers";
import { EVENT_TO_TEXT_MAPPING } from "../../utils/constants";
import EventsLogFilter from "./EventsLogFilter";

const EventsLog = ({
  eventsList,
  projectsOptions,
  onChangeProject,
  handleInputPage,
  handlePageSize
}) => (
  <div>
    <PageHeaderBlock margin="10px 0">
      <PageHeaderTitle>ЖУРНАЛ СОБЫТИЙ</PageHeaderTitle>
    </PageHeaderBlock>
    <EventsLogFilter
      projectsOptions={projectsOptions}
      onChangeProject={onChangeProject}
    />
    {eventsList.totalElements > 0 ? (
      <ReactTable
        PaginationComponent={Pagination}
        showPagination={eventsList.totalElements > 10}
        defaultPageSize={10}
        minRows={0}
        manual
        pages={eventsList.totalPages}
        sortable={false}
        data={eventsList.content}
        onPageChange = {handleInputPage}
        onPageSizeChange = {handlePageSize}
        columns={[
          {
            Header: "ДАТА",
            maxWidth: 200,
            id: "creationDate",
            accessor: d => getDateTimeFormatted(d.creationDate)
          },
          {
            Header: "ПОЛЬЗОВАТЕЛЬ",
            maxWidth: 200,
            id: "creator",
            style:{ background: "#f4f4f4", height: "36px" },
            accessor: d => get(d, "creator.fullName", "-")
          },
          {
            Header: "НАИМЕНОВАНИЕ",
            id: "event",
            accessor: d => EVENT_TO_TEXT_MAPPING[d.event] || "-"
          },
          {
            Header: "ПРОЕКТ",
            accessor: "projectName",
            style:{ background: "#f4f4f4", height: "36px" },
            Cell: row => {
              if (row.original.project) {
                const projectId = get(row, "original.project.id", -1);
                if (projectId !== -1) {
                  return (
                    <LinkStyled to={`/project/${projectId}`}>
                      {row.value || "-"}
                    </LinkStyled>
                  );
                }
                return <TextRegular>{row.value || "-"}</TextRegular>;
              }
              return "-";
            }
          },
          {
            Header: "РЕЛИЗ",
            id: "releaseName",
            accessor: d => d.releaseName || "-",
            Cell: row => {
              if (row.original.release) {
                const projectId = get(row, "original.project.id", -1);
                if (projectId !== -1) {
                  return (
                    <LinkStyled to={`/project/${projectId}`}>
                      {row.value || "-"}
                    </LinkStyled>
                  );
                }
                return <TextRegular>{row.value || "-"}</TextRegular>;
              }
              return "-";
            }
          },
          {
            Header: "ДОКУМЕНТ",
            style:{ background: "#f4f4f4", height: "36px" },
            accessor: "documentName"
          }
        ]}
      />
    ) : (
      <BoldSpan>ЖУРНАЛ СОБЫТИЙ ПУСТ</BoldSpan>
    )}
  </div>
);

EventsLog.propTypes = {
  eventsList: eventsResponseType.isRequired,
  projectsOptions: gitlabOptionsType.isRequired,
  onChangeProject: PropTypes.func.isRequired,
  handleInputPage: PropTypes.func.isRequired,
  handlePageSize: PropTypes.func.isRequired,
};

export default EventsLog;
