import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {

    getProjects: (params) => axios.get(`${API_HOST}/api/fpa/v1/projects/pageable?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&sortByField=${params.sortByField}&sortByOrder=${params.sortByOrder}&inArchive=${params.inArchive}`),

    getAllProjects: (params) => axios.get(`${API_HOST}/api/fpa/v1/projects/pageable?pageNumber=0&pageSize=1000&sortByField=${params.sortByField}&sortByOrder=${params.sortByOrder}&inArchive=${params.inArchive}`),
    getProjectsOptions: () => axios.get(`${API_HOST}/api/fpa/v1/projects/options`),

    searchProjects: (params) =>
        axios({
            method: "GET",
            url: `${API_HOST}/api/fpa/v1/projects/fulltext/pageable`,
            params,
        }),

    getProjectById: (id) => axios.get(`${API_HOST}/api/fpa/v1/projects/${id}`),

    submitProject: (method, project) =>
        axios({
            method,
            url: `${API_HOST}/api/fpa/v1/projects`,
            data: project,
        }),

    getInfoPipline: (params) =>
        axios.get(
            `${API_HOST}/api/fpa/v1/gitlab/group/${params.groupId}/repository/${params.repositoryId}/jobs/${params.jobId}/links`
        ),

    getBackVersion: () =>
        axios.get(
            `${API_HOST}/api/fpa/v1/version`
        ),

    deleteProject: (id) => axios.delete(`${API_HOST}/api/fpa/v1/projects/${id}`),
    restoreProject: (id) => axios.post(`${API_HOST}/api/fpa/v1/projects/${id}/restore`),
    syncProjectFromGit: ()=>  axios.get(`${API_HOST}/api/fpa/v1/projects/user/sync`),

    getProjectsOnly: (paramsIn) => {
      const params = {
          pageNumber: paramsIn.pageNumber,
          pageSize: paramsIn.pageSize,
          sortByField: paramsIn.sortByField,
          sortByOrder: paramsIn.sortByOrder,
          inArchive: paramsIn.inArchive,
          q: paramsIn.q?paramsIn.q: null,
          department: paramsIn.department?paramsIn.department:null
      }

       return  axios.get(`${API_HOST}/api/fpa/v1/projects/pageable/v2`,  {params})
    },

    getMetrickDataOnly: (ids) => axios.post(`${API_HOST}/api/fpa/v1/projects/pipelines/last`, ids),
    getSimpleProjects:(projectName) => axios.get(`${API_HOST}/api/fpa/v1/projects/search/short?projectName=${projectName || ''}`),


};
