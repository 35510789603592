import React from "react";
import PropTypes from "prop-types";
import {releaseType} from "../../../../types";
import {IconMail, IconPencil, IconTrash} from "../../../common/Icon";
import Dropdown from "../../../common/Dropdown";
import {DropdownMenuItem} from "../../../common/Dropdown/styles";
import {
  isAcceptAvailable,
  isDeclineAvailable,
  isExploitAvailable,
  isInProgressAvailable,
  isReadyAvailable
} from "./utils";

function ReleaseOptionsDropdown(props) {
  const {
    release,
    projectId,
    gitlabGroupId,
    isAdmin,
    isUser,
    onEditRelease,
    onDeleteRelease,
    onChangeReleaseStatus,
    onDeclineRelease,
    userInPermissions={}
  } = props;
  const {actions=[]} = userInPermissions
  const isReleaseChangeStatus = actions.find(el=>el==="releaseChangeStatus")
  const isReleaseEdit= actions.find(el=>el==="releaseEdit")
  const isReleaseRemove= actions.find(el=>el==="releaseRemove")

  return (
    <Dropdown>
      {isInProgressAvailable(release.status, isAdmin, isUser, isReleaseChangeStatus) && (
        <DropdownMenuItem
          onClick={() => {
            onChangeReleaseStatus(release.id, "inprogress", "");
          }}
        >
          <IconMail />В работу
        </DropdownMenuItem>
      )}
      {isReadyAvailable(release.status, isAdmin, isUser, isReleaseChangeStatus) && (
        <DropdownMenuItem
          onClick={() => {
            onChangeReleaseStatus(release.id, "ready", "");
          }}
        >
          <IconMail />
          Отправить релиз
        </DropdownMenuItem>
      )}
      {isAcceptAvailable(release.status, isAdmin, isUser, isReleaseChangeStatus) && (
        <DropdownMenuItem
          onClick={() => {
            onChangeReleaseStatus(release.id, "accepted", "");
          }}
        >
          <IconMail />
          Принять релиз
        </DropdownMenuItem>
      )}
      {isDeclineAvailable(release.status, isAdmin, isUser, isReleaseChangeStatus) && (
        <DropdownMenuItem
          onClick={() => {
            onDeclineRelease(release.id);
          }}
        >
          <IconMail />
          Отклонить релиз
        </DropdownMenuItem>
      )}
      {isExploitAvailable(release.status, isAdmin, isUser, isReleaseChangeStatus) && (
          <DropdownMenuItem
            onClick={() => {
              onChangeReleaseStatus(release.id, "exploited", "");
            }}
          >
            <IconMail />
            В эксплуатацию
          </DropdownMenuItem>
      )}
      {(isAdmin || (isReleaseEdit && release.status === "IN_PROGRESS")) && (
        <DropdownMenuItem
          onClick={() => onEditRelease(projectId, gitlabGroupId, release.id)}
        >
          <IconPencil />
          Редактировать
        </DropdownMenuItem>
      )}
      {(isAdmin || (isReleaseRemove && release.status === "IN_PROGRESS")) && (
        <DropdownMenuItem onClick={() => onDeleteRelease(release.id)}>
          <IconTrash />
          Удалить
        </DropdownMenuItem>
      )}
    </Dropdown>
  );
}

ReleaseOptionsDropdown.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
  release: releaseType.isRequired,
  projectId: PropTypes.number.isRequired,
  gitlabGroupId: PropTypes.string.isRequired,
  onEditRelease: PropTypes.func.isRequired,
  onDeleteRelease: PropTypes.func.isRequired,
  onChangeReleaseStatus: PropTypes.func.isRequired,
  onDeclineRelease: PropTypes.func.isRequired
};

export default ReleaseOptionsDropdown;
