import styled from "styled-components";
import { TextRegular } from "../styles";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxLabel = styled(TextRegular)`
  margin-left: 10px;
`;