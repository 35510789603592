import {all, call, put, takeLatest} from "redux-saga/effects";
import {LOCAL_LOADER, GLOBAL_LOADER} from "../store/preloader/constants";
import {FETCH_PIPELINES, setPipelines} from "../store/pipeline/actions";
import {setPages} from "../store/project/actions";

import {removeLoader, setError, setLoader, errorParser } from "../store/preloader/actions";

import Api from "../api";

function* fetchPipelines(action) {
    yield put(setLoader(LOCAL_LOADER, FETCH_PIPELINES, "ЗАГРУЗКА СТАТУСОВ"));
    yield put(setLoader(GLOBAL_LOADER, FETCH_PIPELINES, "ЗАГРУЗКА ДАННЫХ"));
    
    try {
        const response = yield call(Api.pipelines.getPipelinesByProject, action.payload);
        yield put(setPipelines(response.data.content));
        yield put(setPages(response.data.count ));

    } catch (error) {
        yield put(setError(`ЗАГРУЗКА СТАТУСОВ - ${ errorParser(error) || error.message }`));
    }
    yield put(removeLoader(GLOBAL_LOADER, FETCH_PIPELINES));
    yield put(removeLoader(LOCAL_LOADER, FETCH_PIPELINES));
}

function* fetchPipelinesFlow() {
    yield takeLatest(FETCH_PIPELINES, fetchPipelines);
}

export default function* pipeline() {
    yield all([
        fetchPipelinesFlow(),
    ]);
}