import React, {Component} from "react";
import PropTypes from "prop-types";
import Api from "../../../api";

class AutoSearchProject extends Component {
    state = {
        search: '',
        results: [],
        loading: false,
        isOpen: false
    }

    onOpen(val = true) {
        this.setState({isOpen: val})
        if(val){
            this.onSearch().then();
        }
    }

    selectElement(project) {
        this.props.selectProject(project);
        this.onOpen(false);
        this.setSearch(project.name);
    }

    setSearch(value) {
        this.setState({search: value})
    }

    async onSearch() {
        this.setState({loading: true})
        try {
            const res = await Api.project.getSimpleProjects(
                this.state.search ? this.state.search : '');
            this.setState({results: res.data});
        } catch (error) {
            console.error(error);
        }
        this.setState({loading: false})
    }


    render() {
        const {results, loading, isOpen} = this.state
        const list = results && results.length > 0
            ? results.map(item => (<div className='project-list-item' onClick={() => this.selectElement(item)}
                                        key={item.id}>{item.name}</div>))
            : (<div className='project-list-item'>нет результатов</div>)

        return (
            <div className="auto-search-container"
                 onMouseLeave={() => this.onOpen(false)}
            >
                <div className="auto-search-inputBlock">
                    <input onClick={() => this.onOpen()}
                           type="text"
                           placeholder="Search"
                           className="auto-search-input"
                           value={this.state.search}
                           onKeyDown={(event) => event.key === 'Enter' && this.onSearch()}
                           onChange={(e) => this.setSearch(e.target.value)}
                    />
                    <button disabled={loading}
                            className="auto-search-btn"
                            type="submit"
                            onClick={() => this.onSearch()}>
                        поиск
                    </button>
                </div>
                {isOpen && <div className="auto-search-results">{list}</div>}
            </div>
        )
    }
}


AutoSearchProject.propTypes = {
    selectProject: PropTypes.func.isRequired,
};

export default AutoSearchProject;
