import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";
import { IconLogo, IconSpinnerLine } from "../Icon";

const Loader = ({ size, dropdown }) => (
  <Container size={size} dropdown={dropdown}>
    {!dropdown && <IconLogo /> }
    <IconSpinnerLine />
  </Container>
);

Loader.propTypes = {
  size: PropTypes.string,
  dropdown: PropTypes.bool
};

Loader.defaultProps = {
  size: "50px",
  dropdown: false
};

export default Loader;
