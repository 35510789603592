import {
  INITIAL_AUTH,
  GET_ACCESS_TOKEN,
  SET_USER,
  LOGOUT
} from "./constants";

export const initialAuth = accessToken => ({
  type: INITIAL_AUTH,
  payload: {
    accessToken
  }
});

export const getAccessToken = authorizeCode => ({
  type: GET_ACCESS_TOKEN,
  payload: {
    authorizeCode
  }
});

export const setUser = user => ({
  type: SET_USER,
  payload: {
    user
  }
});

export const logout = () => ({
  type: LOGOUT
});
