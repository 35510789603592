import { fromJS } from "immutable";
import { SET_MAILINGS } from "./actions";

const initialState = fromJS({
  listMailing: [],

});

const mailing = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAILINGS: {

      return state.set("listMailing", fromJS(action.payload.mailing));
    }
    default: {
      return state;
    }
  }
};

export default mailing;
