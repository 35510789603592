import { createSelector } from "reselect";

const selectTechSolutions = state => state.getIn(["documents", "techSolutions"]);
const makeSelectTechSolutions = createSelector(
  selectTechSolutions,
  substate => substate.toJS()
);

const selectReports = state => state.getIn(["documents", "reports"]);
const makeSelectReports = createSelector(
    selectReports,
    substate => substate.toJS()
);

export {
  makeSelectTechSolutions, makeSelectReports
};
