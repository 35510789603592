import styled from "styled-components";
import { LinkStyled } from "../common/styles";

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const PopupMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  right: 10px;
  top: 45px;
  background-color: #f9f9f9;
  min-width: 600px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const PopupMenuItem = styled.div`
  background-color: inherit;
  padding: 10px;
  font-family: Roboto-Regular;
  font-size: 14px;
  text-align: left;
  color: #394a58;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
`;

export const PopupMenuText = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: #999999;
  padding-right: 10px;
`;

export const PopupMenuLink = styled(LinkStyled)`
  width: fit-content;
  padding: 10px 15px;
`;

// export const NotificationCount = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: red;
//   font-family: Roboto-Regular;
//   font-size: 12px;
//   width: 20px;
//   height: 20px;
//   border-radius: 10px;
//   margin: -15px 3px;
//   position: absolute;
//   color: white;
// `;