import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import debounce from "lodash.debounce";
import moment from 'moment';
import {SingleDatePicker} from 'react-dates';
import { autosuggestTheme } from "../../../utils/theme";
import { searchGitlabUsers } from "../../../api/gitlab";
import { Flex, TextRegular, Select, SuccessMessage } from "../../common/styles";
import RowBlock from "../../common/RowBlock";
import { ModalBody, ButtonsRowUserAccess } from "../../common/Modal/styles";
import { Button, ButtonIcon } from "../../common/Buttons/styles";
import { IconPlus } from "../../common/Icon";
import Loader from "../../common/Loader";
import 'moment/locale/ru'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const getSuggestionValue = suggestion =>
  suggestion.fullName || suggestion.username;

const renderSuggestion = suggestion => (
  <span>{suggestion.fullName || suggestion.username}</span>
);
moment.updateLocale('ru', {
  week: {
    dow: 1,
  },
})
class UsersPermissionForm extends Component {
  state = {
    // value is autosuggest query
    value: "",
    suggestions: [],
    loading: false,
    noSuggestions: false,

    selectedUser: null,
    permission: "USER",
    // used to check for duplicate adding
    addedUsers: [],
    userAdded: false,
    focus: false,
    focused: false,
    date: null,


  };

  componentDidMount() {
    this.input.focus();
  }

  onChange = (_, { newValue }) => {
    this.setState({
      value: newValue,
      noSuggestions: false,
      loading: true,
      selectedUser: null,
      userAdded: false
    });
  };

  loadSuggestions = value => {
    searchGitlabUsers(value, this.state.permission)
      .then(response => {
        const suggestions = response.data;
        const noSuggestions = suggestions.length === 0;

        this.setState({
          suggestions,
          noSuggestions,
          loading: false
        });
      })
      .catch(error => {
        console.error("UsersPermissionForm, loadSuggestions", error);
      });
  };

  debouncedLoadSuggestions = debounce(this.loadSuggestions, 1000);

  onSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      loading: false
    });
  };

  handleSuggestionSelect = (event, { method, suggestion }) => {
    if (method === "enter") {
      event.preventDefault();
    }
    this.setState(
      {
        selectedUser: suggestion
      },
      () => {
        this.buttonAdd.focus();
      }
    );
  };

  handleChangePermission = event => {
    this.setState({
      permission: event.target.value
    });
  };

  

  onFocusChange = () => {
    // eslint-disable-next-line prefer-destructuring
    const focused = this.state.focused 
    this.setState({ focused: !focused })
  }

  handleAddUser = () => {
    const user = this.state.selectedUser;
    const addUser =
      this.state.addedUsers.findIndex(
        item => item.username === user.username
      ) === -1;
    if (addUser) {
      this.props.onAddUser({
        user,
        permission: this.state.permission,
        expiresAt: (this.state.date != null) ? this.state.date.format('YYYY-MM-DD') : null

      });
    }

    this.setState(prevState => {
      const addedUsersTemp = prevState.addedUsers;
      if (addUser) addedUsersTemp.push(user);
      this.input.focus();
      return {
        value: "",
        suggestions: [],
        noSuggestions: false,
        selectedUser: null,
        addedUsers: addedUsersTemp,
        userAdded: addUser
      };
    });
  };

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };


  onDateChange = date =>  {
    this.setState({ date  })
  }
 
  render() {
    const {
      value,
      suggestions,
      loading,
      noSuggestions,
      selectedUser,
      permission,
      userAdded,
      date,
      focused
    } = this.state;
    const inputProps = {
      placeholder: "Начните ввод для поиска...",
      value,
      onChange: this.onChange
    };
    const inputIsBlank = value.trim() === "";
    const onDateChange = this.onDateChange
    return (
      <ModalBody>
        <RowBlock text="ПОЛЬЗОВАТЕЛЬ" labelWidth="200px" alignSelfCenter>
          <Flex alignItems="center">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionSelected={this.handleSuggestionSelect}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              theme={autosuggestTheme}
              ref={this.storeInputReference}
            />
            {loading && <Loader size="40px" />}
            {noSuggestions && !inputIsBlank && (
              <TextRegular>{`По запросу "${value}" нет результатов`}</TextRegular>
            )}
          </Flex>
        </RowBlock>
        <RowBlock text="Срок доступа" labelWidth="200px" alignSelfCenter>

          <div className="mailing_date_wrapper">
            <SingleDatePicker
              small
              placeholder="Срок доступа"
              date={date}
              onDateChange={date => {onDateChange(date)}}
              focused={focused}
              onFocusChange={this.onFocusChange}
            />
          
          </div>
        </RowBlock>
        <RowBlock text="РОЛЬ" labelWidth="200px" alignSelfCenter>
          <Select value={permission} onChange={this.handleChangePermission}>
            <option value="USER">USER</option>
            <option value="EXTERNAL_USER">EXTERNAL_USER</option>
            <option value="GUEST">GUEST</option>
          </Select>
        </RowBlock>
        {userAdded && <SuccessMessage>Пользователь добавлен</SuccessMessage>}
        <ButtonsRowUserAccess>
          <ButtonIcon
            text="Добавить"
            onClick={this.handleAddUser}
            margin="0 25px 0 0"
            disabled={selectedUser === null}
            ref={element => {
              this.buttonAdd = element;
            }}
          >
            <IconPlus />
            Добавить
          </ButtonIcon>
          <Button onClick={this.props.closeModal}>Закрыть</Button>
        </ButtonsRowUserAccess>
      </ModalBody>
    );
  }
}

UsersPermissionForm.propTypes = {
  onAddUser: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default UsersPermissionForm;
