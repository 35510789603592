import React, {Component} from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Select from "react-select";
import moment from "moment";
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {ButtonsRow, ModalBody, ModalContainer, ModalHeader, ModalHeaderTitle} from "../common/Modal/styles";
import {ButtonIconClose, ButtonSave} from "../common/Buttons";
import {ButtonRedOutline} from "../common/Buttons/styles";
import {modalStyles} from "../../utils/theme";
import RowBlock from "../common/RowBlock";

moment.updateLocale('ru', {
      week: {
        dow: 1,
      },
    })

class MailingModal extends Component {
  state = {
    reports: []
  }


  componentWillReceiveProps ({reports}) {
    this.setState ({
      reports
    })
  }

  render() {
    const {
      isOpen,
      handleCloseModal,
      onChangeProject,
      onChangeReport,
      projectsOptions,
      duration,
      date,
      focused,
      onFocusChange,
      onDateChange,
      onSubmitModal,
      onChangeDuration,
      projectToAdd,
      reportTemplateToAdd,
      durationToAdd
    } = this.props;
    function parsePeriod (per, a) {
      switch (per) {
        case 'WEEK':
          return [a,'weeks'];
        case 'TWO_WEEK':
          return [2 * a, 'weeks'];
        case 'MONTH':
          return [a, 'months'];
        case 'QUARTER':
          return [3 * a, 'months'];
        default:
          break;
      }
    }
    const localdate = moment(date);
    const localdate2 = moment(date);
    const localdate3 = moment(date);
    return (
      <Modal
        isOpen={isOpen}
        style={modalStyles}
        contentLabel="Upload Technical Solution"
      >
        <ModalContainer>
          <ModalHeader>
            <ModalHeaderTitle>СОЗДАТЬ РАССЫЛКУ</ModalHeaderTitle>
            <ButtonIconClose onClick={handleCloseModal} />
          </ModalHeader>

          <ModalBody>
            <RowBlock text="ВЫБЕРИТЕ ПРОЕКТ (обязательно)" labelWidth="250px" alignSelfCenter>
              <div  style={{ flex:1 }}>
                <Select
                  value={projectToAdd}
                  options={projectsOptions}
                  getOptionValue={option => `${option.id}`}
                  getOptionLabel={option =>
                  `${option.gitLabId}` }
                  onChange={option => onChangeProject(option)}
                  placeholder="Выберите проект"
                />
              </div>

            </RowBlock>
            <RowBlock text="ВЫБЕРИТЕ ШАБЛОН (обязательно)" labelWidth="250px" alignSelfCenter>
              <div style={{ flex:1 }}>
                <Select
                  value={reportTemplateToAdd}
                  options={this.state.reports}
                  getOptionValue={option => `${option.id}`}
                  getOptionLabel={option => `${option.comment} (${option.fileOriginalName})` }
                  onChange={option => onChangeReport(option)}
                  placeholder="Выберите шаблон"
                />
              </div>

            </RowBlock>
            <RowBlock text="ВЫБЕРИТЕ ДАТУ (обязательно)" labelWidth="250px" alignSelfCenter>

              <div className="mailing_date_wrapper">
                <SingleDatePicker
                  small
                  placeholder="Выберите дату"
                  date={date}
                  focused={focused}
                  onFocusChange={onFocusChange}
                  // displayFormat={() => date.format('DD.MM.YYYY')}
                  onDateChange={date => {onDateChange(date)}}
                />
              </div>

            </RowBlock>
            <RowBlock text="ВЫБЕРИТЕ ДИАПАЗОН (обязательно)" labelWidth="250px" alignSelfCenter>
              <div style={{ flex:1 }}>
                <Select
                  value={durationToAdd}
                  options={duration}
                  getOptionValue={option => `${option.value}`}
                  getOptionLabel={option =>
                  `${option.title}` }
                  onChange={option => onChangeDuration(option)}
                  placeholder="Выберите диапазон"
                />
              </div>
            </RowBlock>

            {durationToAdd && durationToAdd.title && durationToAdd.value &&
            <p style={{fontFamily: 'RobotoBold', lineHeight: '30px'}}>

              Вы выбрали дату: {date.format('L')}<br/>
              Вы выбрали период: '{durationToAdd.title}'<br/>
              Вы будете получать рассылку за период '{durationToAdd.title}' начиная с '{date.format('L')}' каждые(й) '{durationToAdd.title}'<br/>
              за период с '{localdate.format('L')}' по '{localdate.add(parsePeriod(durationToAdd.value, 1)[0], parsePeriod(durationToAdd.value, 1)[1]).subtract(1, 'days').format('L')}'
                  ---- '{localdate.add(1, 'days').format('L')}'<br/>

              за период с '{localdate.format('L')}'
                  по '{localdate2.add(parsePeriod(durationToAdd.value, 2)[0], parsePeriod(durationToAdd.value, 2)[1]).subtract(1, 'days').format('L')}'
                  ---- '{localdate2.add(1, 'days').format('L')}'<br/>

              за период с '{localdate2.format('L')}'
                  по '{localdate3.add(parsePeriod(durationToAdd.value, 3)[0], parsePeriod(durationToAdd.value, 3)[1]).subtract(1, 'days').format('L')}'
                  ---- '{localdate3.add(1, 'days').format('L')}'<br/>
            </p>
            }

            <ButtonsRow>
              <ButtonSave
                disabled={!(durationToAdd && reportTemplateToAdd && projectToAdd)}
                type="submit"
                text="Сохранить"
                onClick={onSubmitModal}
                margin="0 25px 0 0"
              />
              <ButtonRedOutline onClick={handleCloseModal}>
                Отмена
              </ButtonRedOutline>
            </ButtonsRow>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

Modal.setAppElement("#root");

MailingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  onSubmitModal: PropTypes.func.isRequired,
  onChangeProject: PropTypes.func.isRequired,
  onChangeReport: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  reports: PropTypes.array.isRequired,

};

export default  MailingModal;
