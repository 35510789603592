import { createSelector } from "reselect";

const selectAuthUser = state => state.getIn(["auth", "user"]);

const makeSelectUser = createSelector(
  selectAuthUser,
  substate => substate.toJS()
);

const makeSelectAdmin = createSelector(
  makeSelectUser,
  user => user.admin
);

export { makeSelectUser, makeSelectAdmin };
