export const GLOBAL_LOADER = "globalLoaders";
export const LOCAL_LOADER = "localLoaders";

export const PRELOADER_SET_LOADER = "PRELOADER_SET_LOADER";
export const PRELOADER_REMOVE_LOADER = "PRELOADER_REMOVE_LOADER";
export const PRELOADER_SET_ERROR = "PRELOADER_SET_ERROR";
export const PRELOADER_CLEAR_ERROR = "PRELOADER_CLEAR_ERROR";
export const PRELOADER_SET_SPECIFIC_ERROR = "PRELOADER_SET_SPECIFIC_ERROR";
export const PRELOADER_CLEAR_SPECIFIC_ERROR = "PRELOADER_CLEAR_SPECIFIC_ERROR";

export const ERROR_PROJECT_DUPLICATE_NAME = "ERROR_PROJECT_DUPLICATE_NAME";