import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { push } from "connected-react-router/immutable";
import {projectsType, userType} from "../../types";
import Registry from "../../components/Registry";
import {
  fetchAllProjects,
  searchProjects, fetchProjectsMetricks, searchProjectsAndMetrika,
} from "../../store/project/actions";
import {
  makeSelectProjects,
  makeSelectTotalPages,
  makeSelectAllProjects,
} from "../../store/project/selectors";
import { makeSelectAdmin } from "../../store/auth/selectors";
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";
import { saveTempData , getTempData} from "../../utils/helpers";
import { KEY_TEMP_DATA } from "../../utils/constants";
import {adminstrationService} from "../../Services/administration.service";

class RegistryPage extends Component {
  state = {
    pageNumber: 0,
    pageSize: getTempData(KEY_TEMP_DATA.KEY_PAGE_SIZE_REGISTRY, 10),
    query: "",
    inArchive: false,
    querySearch: "",
    orderBy: "COMMIT_DATE", // NAME, RELEASE_DATE, COMMIT_DATE, LAST_PIPELINE   RELEASE_DATE
    direction: "DESC",
    departmentSelected: null
  };

  componentDidMount() {
    this.props.fetchProjects(
      this.state.pageNumber,
      this.state.pageSize,
      this.state.orderBy,
      this.state.direction,
      this.state.inArchive
    );
  }

  componentDidUpdate(prevProps) {
    // case when user "reloads" on this page for search refresh
    if (this.props.location.key !== prevProps.location.key) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ query: "" }, () => {
        this.handleSearch();
      });
    }
  }

  handleInputPage = (page) => {
    this.setState({
      pageNumber: page,
    });

    setTimeout(() => {
      this.getData();
    }, 0);
  };

  onSyncProjectInGigLab= async ()=>{
     const res =  window.confirm('вы уверенны');
     if(res) {
       await adminstrationService.syncProjectFromGit()
       this.handleSearch();
     }
  }


  handlePageSize = (size) => {
    saveTempData(KEY_TEMP_DATA.KEY_PAGE_SIZE_REGISTRY, size);
    this.setState({
      pageNumber: 0,
      pageSize: size,
    });

    setTimeout(() => {
      this.getData();
    }, 0);
  };

  handleSearch = () => {
    const query = this.state.query;
    this.setState({
      pageNumber: 0,
      querySearch: query,
    });
    setTimeout(() => {
      this.getData();
    }, 0);
  };

  onClear = () => {
    this.setState({
      pageNumber: 0,
      querySearch: "",
      query: "",
      inArchive: false,
      departmentSelected:null
    });
    setTimeout(() => {
      this.getData();
    }, 0);
  };

  getData = () => {

   console.log(this.state.departmentSelected);

    if (this.state.querySearch === "") {
      this.props.fetchProjects(
        this.state.pageNumber,
        this.state.pageSize,
        this.state.orderBy,
        this.state.direction,
        this.state.inArchive,
        this.state.departmentSelected?this.state.departmentSelected.id:null
      );
    } else {
      this.props.searchProjects(
        this.state.querySearch,
        this.state.pageNumber,
        this.state.pageSize,
        this.state.orderBy,
        this.state.direction,
        this.state.inArchive,
        this.state.departmentSelected?this.state.departmentSelected.id:null
      );
    }
  };

  handleChangeQuery = (event) => {
    this.setState({
      query: event.target.value,
    });
  };

  handleSetInArchive = (event) => {
    this.setState({
      inArchive: event.currentTarget.checked,
    });
  };

  onSelectDepartment(department) {
    this.setState({
      departmentSelected: department,
    });
  }


  handleSetSort = (sortData) => {
    const { id, desc } = sortData[0];

    this.setState({
      orderBy: id,
      direction: desc ? "DESC" : "ASC",
      pageNumber: 0,
    });
    setTimeout(() => {
      this.getData();
    }, 0);
  };

  handleProjectsForExport = () => {
    this.props.fetchAllProjects(
      this.state.orderBy,
      this.state.direction,
      this.state.inArchive
    );
  };

  render() {
    const sorted = {
      id: this.state.orderBy,
      desc: this.state.direction === "DESC",
    };
    return (
      <Registry
        onSyncProjectInGigLab={this.onSyncProjectInGigLab}
        handleInputPage={this.handleInputPage}
        handlePageSize={this.handlePageSize}
        onCreateProject={this.props.pushToNewProjectEditor}
        onChangeQuery={this.handleChangeQuery}
        onChangeInArchive={this.handleSetInArchive}
        onSearchProjects={this.handleSearch}
        handleSetSort={this.handleSetSort}
        onClear={this.onClear}
        projects={this.props.projects}
        allProjects={this.handleProjectsForExport}
        searchQuery={this.state.query}
        inArchive={this.state.inArchive}
        pageNumber={this.state.pageNumber}
        pageSize={this.state.pageSize}
        totalPages={this.props.totalPages}
        isAdmin={this.props.isAdmin}
        loaders={this.props.loaders}
        sorted={sorted}

        onSelectDepartment={(dep)=>this.onSelectDepartment(dep)}
        departmentSelected={this.state.departmentSelected}

      />
    );
  }
}

const mapStateToProps = (state) => ({
  projects: makeSelectProjects(state),
  allProjects: makeSelectAllProjects(state),
  totalPages: makeSelectTotalPages(state),

  loaders: makeSelectLocalLoaders(state),
  isAdmin: makeSelectAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: (pageNumber, pageSize, orderBy, direction, inArchive, department) =>
    dispatch(
        fetchProjectsMetricks(pageNumber, pageSize, orderBy, direction, inArchive, department)
    ),
  fetchAllProjects: (orderBy, direction, inArchive) =>
    dispatch(fetchAllProjects(orderBy, direction, inArchive)),
  searchProjects: (
    query,
    pageNumber,
    pageSize,
    orderBy,
    direction,
    inArchive,
    department
  ) =>
    dispatch(
        searchProjectsAndMetrika(query, pageNumber, pageSize, orderBy, direction, inArchive,department)
    ),

  pushToNewProjectEditor: () => dispatch(push("/project/editor/new")),
});

RegistryPage.propTypes = {
  projects: projectsType.isRequired,
  allProjects: projectsType.isRequired,

  totalPages: PropTypes.number.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchAllProjects: PropTypes.func.isRequired,
  searchProjects: PropTypes.func.isRequired,

  pushToNewProjectEditor: PropTypes.func.isRequired,
  user: userType.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistryPage);
