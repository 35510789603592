import React, { Component } from "react";
import PropTypes from "prop-types";
import { techSolutionsType } from "../../types";
import { Flex, SectionHeaderBlock, SectionHeaderTitle } from "../common/styles";
import { ButtonAdd } from "../common/Buttons";
import TechSolutionsTable from "./TechSolutionsTable";
import TechSolutionModal from "./TechSolutionModal";

class TechSolutionsEditor extends Component {
  state = {
    modalIsOpen: false
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  handleClickSave = form => {
    this.props.onSaveTechSolution(form);
    this.closeModal();
  };

  render() {
    const {
      techSolutions,
      selectedTechSolutions,
      onDeleteTechSolution,
      onDownloadDocument
    } = this.props;

    return (
      <Flex flexDirection="column">
        <SectionHeaderBlock editor>
          <SectionHeaderTitle>ТИПОВЫЕ ТЕХНИЧЕСКИЕ РЕШЕНИЯ</SectionHeaderTitle>
          {techSolutions.length > 0 && (
            <ButtonAdd text="Добавить" onClick={this.openModal} />
          )}
        </SectionHeaderBlock>
        <TechSolutionModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          techSolutions={techSolutions}
          onSaveTechSolution={this.handleClickSave}
        />
        <TechSolutionsTable
          techSolutions={selectedTechSolutions}
          onDownloadDocument={onDownloadDocument}
          isEditor
          onDeleteTechSolution={onDeleteTechSolution}
        />
      </Flex>
    );
  }
}

TechSolutionsEditor.propTypes = {
  techSolutions: techSolutionsType.isRequired,
  selectedTechSolutions: techSolutionsType.isRequired,
  onSaveTechSolution: PropTypes.func.isRequired,
  onDeleteTechSolution: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired
};

export default TechSolutionsEditor;
