export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const MODAL_TYPE_CONFIRMATION_DIALOG = "MODAL_TYPE_CONFIRMATION_DIALOG";
export const MODAL_TYPE_NOTIFICATION_ALERT = "MODAL_TYPE_NOTIFICATION_ALERT";
export const MODAL_TYPE_DECLINE_RELEASE = "MODAL_TYPE_DECLINE_RELEASE";
export const MODAL_TYPE_VIEW_EXTERNAL_SYSTEM =
  "MODAL_TYPE_VIEW_EXTERNAL_SYSTEM";
export const MODAL_TYPE_TECH_SOLUTION_UPLOAD =
  "MODAL_TYPE_TECH_SOLUTION_UPLOAD";
export const MODAL_TYPE_DOCUMENT_UPLOAD = "MODAL_TYPE_DOCUMENT_UPLOAD";
export const MODAL_TYPE_USERS_PERMISSION = "MODAL_TYPE_USERS_PERMISSION";
