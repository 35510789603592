export const FETCH_EVENTS = "FETCH_EVENTS";
export const fetchEvents = (page, size, projectId = "") => ({
  type: FETCH_EVENTS,
  payload: {
    page,
    size,
    projectId
  }
});

export const SET_EVENTS = "SET_EVENTS";
export const setEvents = events => ({
  type: SET_EVENTS,
  payload: {
    events
  }
});
