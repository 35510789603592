import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px 0 10px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 30px 20px;
`;

export const ModalHeaderTitle = styled.span`
  font-family: Roboto-Bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #394a58;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsRow = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;

export const ButtonsRowUserAccess = styled.div`
  display: flex;
  padding-left: 224px;
`;