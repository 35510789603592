import { createSelector } from "reselect";

const selectProjects = (state) => state.getIn(["project", "projects"]);
const selectAllProjects = (state) => state.getIn(["project", "allProjects"]);
const makeSelectAllProjects = createSelector(selectAllProjects, (substate) =>
  substate.toJS()
);
const makeSelectProjects = createSelector(selectProjects, (substate) =>
  substate.toJS()
);

const selectProject = (state) => state.getIn(["project", "project"]);
const makeSelectProject = createSelector(selectProject, (substate) =>
  substate.toJS()
);

const selectProjectsOptions = (state) =>
  state.getIn(["project", "projectsOptions"]);
const makeSelectProjectsOptions = createSelector(
  selectProjectsOptions,
  (substate) => substate.toJS()
);

const selectSimpleProjects = (state) => state.getIn(["project", "simpleProjects"]);
const makeSimpleSelectProjects = createSelector(
    selectSimpleProjects,
    (substate) => substate.toJS()
);

const selectTotalPages = (state) => state.getIn(["project"]);

const makeSelectTotalPages = createSelector(selectTotalPages, (substate) => substate.get("totalPages"));

const selectRelease = (state) => state.getIn(["project", "release"]);
const makeSelectRelease = createSelector(selectRelease, (substate) =>
  substate.toJS()
);


const selectTags = (state) => state.getIn(["project", "tags"]);
const makeSelectTags = createSelector(selectTags, (substate) =>
  substate.toJS()
);
const links = (state) => state.getIn(["project", "links"]);

const frontVersionFPA = (state) => state.getIn(["project", "frontVersionFPA"]);

const backVersionFPA = (state) => state.getIn(["project", "backVersionFPA"]);

const selectTagsResults = (state) => state.getIn(["project", "tagsResults"]);
const makeSelectTagsResults = createSelector(selectTagsResults, (substate) =>
  substate.toJS()
);

export {
  makeSelectProjects,
  makeSelectTotalPages,
  makeSelectProject,
  makeSelectProjectsOptions,
  makeSelectRelease,
  makeSelectTags,
  makeSelectTagsResults,
  links,
  frontVersionFPA,
  backVersionFPA,
  makeSelectAllProjects,
  makeSimpleSelectProjects
};
