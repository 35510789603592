import { createSelector } from "reselect";

const selectPipelines = state => state.getIn(["pipeline", "pipelines"]);
const makeSelectPipelines = createSelector(
    selectPipelines,
    substate => substate.toJS()
);

export {
  makeSelectPipelines,
};