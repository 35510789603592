import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router/immutable";
import axios from "axios";
import PropTypes from "prop-types";
import { dictionaryEntriesType, projectType, reportsType } from "../../types";
import { submitProject, fetchProjectById } from "../../store/project/actions";
import { showModal } from "../../store/modal/actions";
import {fetchDictionaries, setChangeDictionaryEntry} from "../../store/dictionaries/actions";
import { fetchReports} from "../../store/documents/actions";
import { clearSpecificError } from "../../store/preloader/actions";
import { makeSelectProject } from "../../store/project/selectors";
import { makeSelectReports } from "../../store/documents/selectors";
import { makeSelectDocumentTypeDictionary } from "../../store/dictionaries/selectors";
import { makeSelectSpecificError } from "../../store/preloader/selectors";
import ProjectEditor from "../../components/ProjectEditor";
import { API_HOST } from "../../utils/constants";

class ProjectEditorPage extends Component {
  state = {
    inputValue: "13",
    listAsuz: [],
    selectedAsuz: null
  }

  componentDidMount() {
    this.props.fetchReports();
    const {
      match: {
        params: { projectId }
      },
      isNew,
      project,
      onFetchProject,
      fetchDocumentTypeDictionary
    } = this.props;

    fetchDocumentTypeDictionary();

    // fallback for page reload...
    if (project.id === 0 && !isNew) {
      onFetchProject(projectId);
    }
  }

  componentWillUnmount() {
    this.props.clearSpecificError();
  }

  loadOptions = (inputValue, callback) => {
     if (this.state.inputValue.length < 2) return false;
     setTimeout(() => {
       axios({
            method: "GET",
            url: `${API_HOST}/api/fpa/v1/tasks/projects/search?search=${inputValue}`
        })
        .then((res) => {
          this.setState({
            listAsuz: res.data.content
          })
        })
           .then(()=> {
             callback(this.state.listAsuz)
           })

    }, 1000);

  };

  handleSubmitProject = form => {
    const { onSubmitProject, isNew, project } = this.props;
    if (this.state.selectedAsuz) {
      form.codeAsuz = this.state.selectedAsuz.projectId
      form.nameAsuz = this.state.selectedAsuz.name;
    }

    onSubmitProject(isNew ? 0 : project.id, form);
  };

  handleAsuzChange = inputValue => {
    this.setState({ inputValue });
  }

  onChangeAsuz = option => {
    this.setState({ selectedAsuz: option });
  }

  handelChangeDictionaryEntryLocal = value => {
    this.props.handelChangeDictionaryEntry(value);
  }

  handleCancelClick = () => {
    const { pushTo, isNew, project } = this.props;
    if (isNew) {
      pushTo("/registry");
    } else {
      pushTo(`/project/${project.id}`);
    }
  };

  render() {
    const {
      isNew,
      project,
      documentTypeDictionary,
      specificError,
      onShowModal,
      reports,
    } = this.props;

    return (
        <ProjectEditor
          isNew={this.props.isNew}
          reports={reports}
          listAsuz={this.state.listAsuz}
          loadOptions={this.loadOptions}
          handleAsuzChange={this.handleAsuzChange}
          onChangeAsuz={this.onChangeAsuz}
          project={isNew ? null : project}
          documentTypeDictionary={documentTypeDictionary}
          specificError={specificError}
          onSubmitForm={this.handleSubmitProject}
          selectedAsuz={this.state.selectedAsuz}
          onClickCancel={this.handleCancelClick}
          onShowModal={onShowModal}
          handelChangeDictionaryEntry={this.handelChangeDictionaryEntryLocal}
      />
    );
  }
}

const mapStateToProps = state => ({
  reports: makeSelectReports(state),
  project: makeSelectProject(state),
  documentTypeDictionary: makeSelectDocumentTypeDictionary(state),
  specificError: makeSelectSpecificError(state)
});

const mapDispatchToProps = dispatch => ({
  handelChangeDictionaryEntry: value => dispatch(setChangeDictionaryEntry(value)),
  fetchDocumentTypeDictionary: () => dispatch(fetchDictionaries(["documenttype"])),
  fetchReports: () => dispatch(fetchReports()),
  onSubmitProject: (projectId, form) => dispatch(submitProject(projectId, form)),
  onFetchProject: id => dispatch(fetchProjectById(id)),
  clearSpecificError: () => dispatch(clearSpecificError()),
  pushTo: location => dispatch(push(location)),
  onShowModal: (modalType, props) => dispatch(showModal(modalType, props))
});

ProjectEditorPage.propTypes = {
  project: projectType.isRequired,
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  fetchDocumentTypeDictionary: PropTypes.func.isRequired,
  handelChangeDictionaryEntry: PropTypes.func.isRequired,
  specificError: PropTypes.string.isRequired,
  onSubmitProject: PropTypes.func.isRequired,
  onFetchProject: PropTypes.func.isRequired,
  clearSpecificError: PropTypes.func.isRequired,
  pushTo: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,

  isNew: PropTypes.bool,
  match: PropTypes.shape().isRequired,

  reports: reportsType.isRequired,
  fetchReports: PropTypes.func.isRequired,
};

ProjectEditorPage.defaultProps = {
  isNew: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEditorPage);
