export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const UPLOAD_REP_DOCUMENT = "UPLOAD_REP_DOCUMENT";
export const DELETE_REP_DOCUMENT = "DELETE_REP_DOCUMENT";
export const DOWNLOAD_REPORT_EXAMPLE = "DOWNLOAD_REPORT_EXAMPLE";
export const DOWNLOAD_GENERATED_REPORT = "DOWNLOAD_GENERATED_REPORT";

export const FETCH_TECH_SOLUTIONS = "FETCH_TECH_SOLUTIONS";
export const SET_TECH_SOLUTIONS = "SET_TECH_SOLUTIONS";

export const FETCH_REPORTS = "FETCH_REPORTS";
export const SET_REPORTS = "SET_REPORTS";

export const DELETE_REPORT_FROM_PROJECT = "DELETE_REPORT_FROM_PROJECT";
export const SET_REPORT_FROM_PROJECT = "SET_REPORT_FROM_PROJECT";
export const ADD_REPORT_FROM_PROJECT = "ADD_REPORT_FROM_PROJECT";
export const DOWNLOAD_DOCUMENTS_ZIP = "DOWNLOAD_DOCUMENTS_ZIP"

export const SIGN_DOCUMENT = "SIGN_DOCUMENT";
export const DELETE_DOCUMENT_SIGN = "DELETE_DOCUMENT_SIGN";