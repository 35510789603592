import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { techSolutionsType } from "../../types";
import {
  fetchTechSolutions,
  downloadDocument
} from "../../store/documents/actions";
import { makeSelectTechSolutions } from "../../store/documents/selectors";
import TechSolutionsEditor from "../../components/TechSolutionsEditor";

class TechSolutionsEditorContainer extends Component {
  componentDidMount() {
    this.props.fetchTechSolutions();
  }

  render() {
    const {
      techSolutions,
      selectedTechSolutions,
      onSaveTechSolution,
      onDeleteTechSolution,
      downloadDocument: onDownloadDocument
    } = this.props;

    return (
      <TechSolutionsEditor
        techSolutions={techSolutions}
        selectedTechSolutions={selectedTechSolutions}
        onSaveTechSolution={onSaveTechSolution}
        onDeleteTechSolution={onDeleteTechSolution}
        onDownloadDocument={onDownloadDocument}
      />
    );
  }
}

const mapStateToProps = state => ({
  techSolutions: makeSelectTechSolutions(state)
});

const mapDispatchToProps = dispatch => ({
  fetchTechSolutions: () => dispatch(fetchTechSolutions()),
  downloadDocument: id => dispatch(downloadDocument(id))
});

TechSolutionsEditorContainer.propTypes = {
  techSolutions: techSolutionsType.isRequired,
  fetchTechSolutions: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,

  selectedTechSolutions: techSolutionsType.isRequired,
  onSaveTechSolution: PropTypes.func.isRequired,
  onDeleteTechSolution: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TechSolutionsEditorContainer);
