import React, {Component} from "react";
import {connect} from "react-redux";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {BoldSpan, LinkStyled, PageHeaderBlock, PageHeaderTitle} from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";

import Api from "../../../api";
import {removeLoader, setError, setLoader} from "../../../store/preloader/actions";
import {GLOBAL_LOADER} from "../../../store/preloader/constants";
import {LOAD_DATA_REPORT} from "../../../utils/constants";

class AdminReportsPage extends Component {
    state = {
        reports: [],
    }
    async loadReports() {
       try {
           this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
           const res = await Api.reports.getReports();
           const convertData =  res && res.data ? res.data : [];
           this.setState({
               reports: convertData
           });
       }  catch (e){
           console.log(e);
       } finally {
           this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
       }
    }
    async componentDidMount() {
        await this.loadReports()
    }


    render() {
        const {  reports } = this.state;

        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Отчеты</PageHeaderTitle>
                </PageHeaderBlock>

                {reports.length > 0 ? (
                    <ReactTable
                        defaultPageSize={10}
                        minRows={1}
                        data={reports}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "Отчет",
                                Cell: row => (
                                    <div>
                                        <LinkStyled to={`/admin/reports/${row.original.reportName}`}>
                                            {row.original.displayName}
                                        </LinkStyled>
                                    </div>)
                            }
                        ]}
                    />
                ) : (
                    <BoldSpan>Нет отчетов </BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    setError: error=> dispatch(setError(error)),
    setLoader: (loaderScope, loaderKey, message) => dispatch(setLoader(loaderScope, loaderKey, message)),
    removeLoader: (loaderScope, loaderKey) => dispatch(removeLoader(loaderScope, loaderKey))
});

AdminReportsPage.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminReportsPage);
