import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {projectType} from "../../../types";
import {BoldSpan, Input, PageHeaderBlock, PageHeaderTitle} from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";
import {
    fetchProjectsAdministration,
    resetFilterAdministration,
    setFilterAdministration
} from "../../../store/administration/actions";
import {makeSelectAllProjectsAdministaration, makeSelectFilters} from "../../../store/administration/selectors";


class AdminProjectsPage extends Component {
    state = {
        nameProject: ''
    }

    componentDidMount() {
        const {

            fetchProjectAdmin,
            resetFilter,
        } = this.props;


        resetFilter();
        fetchProjectAdmin();
    }

    render() {
        const {
            setFilter,
            filterNow,
            fetchProjectAdmin,
            allProject
        } = this.props;

        const {nameProject} = this.state;

        const getData = () => {
            fetchProjectAdmin();
        }

        const setPage = (page) => {
            setFilter({...filterNow, page});
            getData()
        }
        const setSize = (size) => {
            setFilter({...filterNow, page: 0, size});
            getData()
        }

        const handleChange = (val) => {
            this.setState({nameProject: val})
        }
        const searchHandel = () => {
            const res = {...filterNow, page: 0, nameProject};
            setFilter(res);
            getData()
        }
        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Администрирование проектов</PageHeaderTitle>
                    <Input width="80%" name="comment" value={nameProject} onChange={e => handleChange(e.target.value)}/>
                    <ButtonSimple text="Поиск" onClick={searchHandel}/>

                </PageHeaderBlock>
                {allProject.length > 0 ? (
                    <ReactTable
                        manual
                        data={allProject}
                        page={filterNow.page}
                        pages={filterNow.maxPage}
                        pageSize={filterNow.size}
                        onPageChange={setPage}
                        onPageSizeChange={setSize}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "id",
                                accessor: "id"
                            },

                            {
                                Header: "Полное имя проекта",
                                id: "fullName",
                                accessor: "fullName"
                            },
                            {
                                Header: "Описание проект",
                                accessor: "description"
                            },
                            {
                                Header: "Короткое название",
                                accessor: "name"
                            },

                            {
                                Header: 'Действие',
                                Cell: (row => <div>
                                    <Link to={`/admin/projects/${row.original.id}`}>
                                        <ButtonSimple text="открыть"/></Link>
                                </div>)
                            }

                        ]}
                    />
                ) : (
                    <BoldSpan>Нет проектов</BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = state => ({
    filterNow: makeSelectFilters(state),
    allProject: makeSelectAllProjectsAdministaration(state)
});

const mapDispatchToProps = dispatch => ({
    resetFilter : ()=>dispatch(resetFilterAdministration()),
    fetchProjectAdmin: () => dispatch(fetchProjectsAdministration()),
    setFilter: ({
                    page,
                    size,
                    nameProject,
                    nameUser,
                    totalElements ,
                    maxPage,
                }) => dispatch(setFilterAdministration(page, size, nameProject, nameUser, totalElements, maxPage)),

});

AdminProjectsPage.propTypes = {
    filterNow: projectType.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    allProject: PropTypes.array.isRequired,
    fetchProjectAdmin: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminProjectsPage);
