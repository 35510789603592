import React from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import { externalSystemType } from "../../../../types";
import ListRow from "../../../common/ListRow";
import {
  ListContainer,
  ListSectionHeader,
  ListSectionHeaderTitle,
  ListRowWrapper,
  ListRowLabel,
  ListRowText
} from "../../../common/ListRow/styles";
import { LinkSpan } from "../../../common/styles";

function ExternalSystemSubComponent(props) {
  const { externalSystem, onDownloadDocument } = props;
  // needed to decide if regulation displays as text or link
  const isNewExternalSystem = externalSystem.id === 0;
  const hasRegulation = externalSystem.regulation != null;

  return (
    <ListContainer>
      <ListSectionHeader>
        <ListSectionHeaderTitle>Общая информация</ListSectionHeaderTitle>
      </ListSectionHeader>
      <ListRow
        labelText="Наименование"
        text={get(externalSystem, "name", "-")}
      />
      <ListRow
        labelText="Режим источника"
        text={get(externalSystem, "sourceMode.name", "-")}
      />
      <ListRow
        labelText="Расположение источника"
        text={get(externalSystem, "sourceLocation.name", "-")}
      />
      <ListRow
        labelText="Потребитель информации"
        text={get(externalSystem, "informationConsumer.name", "-")}
      />
      <ListRow
        labelText="Расположение потребителя"
        text={get(externalSystem, "consumerLocation.name", "-")}
      />
      <ListRow
        labelText="Внутри ДЦ / Между ДЦ"
        text={get(externalSystem, "insideDC", false) ? "Внутри ДЦ" : "Между ДЦ"}
      />
      <ListRow
        labelText="Аутентификация"
        text={get(externalSystem, "authentication.name", "-")}
      />
      <ListRow
        labelText="Транспорт"
        text={get(externalSystem, "transport.name", "-")}
      />
      <ListRow
        labelText="Интерфейс"
        text={get(externalSystem, "interfaces.name", "-")}
      />
      <ListRow
        labelText="Идентификатор интерфейса"
        text={get(externalSystem, "internalInterfaceId", "-")}
      />
      <ListRow
        labelText="Передаваемые данные"
        text={get(externalSystem, "transData.name", "-")}
      />
      <ListRow
        labelText="Периодичность"
        text={get(externalSystem, "periodicity", "-")}
      />
      <ListRow
        labelText="Суммарный объем за сутки"
        text={get(externalSystem, "totalAmountPerDay", "-")}
      />
      <ListRowWrapper>
        <ListRowLabel>Регламент</ListRowLabel>
        {hasRegulation && !isNewExternalSystem && (
          <LinkSpan
            onClick={() => {
              onDownloadDocument(externalSystem.regulation.id);
            }}
          >
            {externalSystem.regulation.fileOriginalName || "-"}
          </LinkSpan>
        )}
        {hasRegulation && isNewExternalSystem && (
          <ListRowText>
            {get(externalSystem, "regulation.file.name", "-")}
          </ListRowText>
        )}
        {!hasRegulation && <ListRowText>Не добавлен</ListRowText>}
      </ListRowWrapper>
    </ListContainer>
  );
}

ExternalSystemSubComponent.propTypes = {
  externalSystem: externalSystemType.isRequired,
  onDownloadDocument: PropTypes.func.isRequired
};

export default ExternalSystemSubComponent;
