import React, {Component} from "react";
import PropTypes from "prop-types";
import {userType} from "../../../types";
import {
    Container,
    AppTitle,
    UserBlock,
    UserInfo,
    UserLabel,
    AppLogo,
    AppInfoBlock,
    AppLogoWrapper,
    LogoutBlock,
    MenuBlock
} from "./styles";
import {IconUser, IconSettings} from "../../common/Icon";
import {ButtonWhiteOutline, ButtonIconOnly, ButtonLink} from "../../common/Buttons/styles";
import NotificationsPopupContainer from "../../../containers/NotificationsPopup";


class HeaderContent extends Component {
   state = {
       isOpenMenu: false,

   }



    render() {
        const {
            user,
            onClickHeaderTitle,
            onClickLogout,
            onClickDictionaries,
            onClickEventLog,
            onClickSettings,
            onClickAllProjects,
            onClickUsers,
            onClickReport
        } = this.props;

        const {isOpenMenu} = this.state;

        const onOpenMenu = ()=>{
            this.setState({ isOpenMenu: !isOpenMenu });
        }


        return (
            <Container>
                <AppInfoBlock>
                    <AppLogoWrapper>
                        <AppLogo onClick={onClickHeaderTitle}/>
                    </AppLogoWrapper>
                    <AppTitle>ФОНД ПРОГРАММ И АЛГОРИТМОВ</AppTitle>
                </AppInfoBlock>
                <MenuBlock/>
                <UserBlock>
                    {user.admin && (
                        <div className="wpapperMenu" ref={this.state.wrapperRef}>
                        <ButtonWhiteOutline margin='0 20px' onClick={onOpenMenu}>Дополнительно</ButtonWhiteOutline>

                            { isOpenMenu && <div className='subMenuBlock' onClick={onOpenMenu}>
                                <ButtonLink onClick={onClickUsers}
                                                   >Пользователи</ButtonLink>
                                <ButtonLink onClick={onClickAllProjects}
                                                   >Проекты</ButtonLink>
                                <ButtonLink onClick={onClickDictionaries}
                                                   >Справочники</ButtonLink>
                                <ButtonLink onClick={onClickEventLog}>Аудит</ButtonLink>
                                <ButtonLink onClick={onClickReport}>Отчеты</ButtonLink>
                            </div>}

                        </div>)}

                    {
                            user.id !== "" &&
                            <NotificationsPopupContainer/>
                        }

                        {
                            user.id !== "" && <ButtonIconOnly onClick={onClickSettings}>
                                <IconSettings/>
                            </ButtonIconOnly>
                        }

                        {
                            user.id !== "" && <IconUser/>
                        }
                            <UserInfo>
                                <UserLabel>{user.fullName}</UserLabel>
                                {user.admin && <UserLabel>Администратор</UserLabel>}
                            </UserInfo>
                            <LogoutBlock>
                                {user.id !== "" && <ButtonWhiteOutline onClick={onClickLogout}>Выйти</ButtonWhiteOutline>}
                            </LogoutBlock>
                        </UserBlock>
            </Container>)
    }
}


HeaderContent.propTypes = {
    user: userType.isRequired,
    onClickHeaderTitle: PropTypes.func.isRequired,
    onClickLogout: PropTypes.func.isRequired,
    onClickDictionaries: PropTypes.func.isRequired,
    onClickEventLog: PropTypes.func.isRequired,
    onClickSettings: PropTypes.func.isRequired,
    onClickUsers: PropTypes.func.isRequired,
    onClickAllProjects: PropTypes.func.isRequired,
    onClickReport: PropTypes.func.isRequired,
};

export default HeaderContent;
