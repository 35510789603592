import React, { Component } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import get from "lodash.get";
import { notificationsType } from "../../types";
import LoadingWrapper from "../common/LoadingWrapper";
import { FETCH_NEW_NOTIFICATIONS } from "../../store/notifications/actions";
import {
  Container,
  PopupMenu,
  PopupMenuItem,
  PopupMenuLink,
  PopupMenuText
  // NotificationCount
} from "./styles";
import { LinkStyled, TextRegular } from "../common/styles";
import { ButtonIconMail } from "../common/Buttons";
import { getDateTimeShortFormatted } from "../../utils/helpers";

class NotificationsPopup extends Component {
  state = {
    showPopup: false
  };

  showPopup = event => {
    event.preventDefault();

    this.props.onFetchNewNotifications();

    this.setState({ showPopup: true }, () => {
      document.addEventListener("click", this.closePopupMenu);
    });
  };

  closePopupMenu = () => {
    this.setState({ showPopup: false }, () => {
      document.removeEventListener("click", this.closePopupMenu);
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopupMenu);
  }

  render() {
    const { notificationsCount, newNotifications, loaders } = this.props;

    return (
      <Container>
        <ButtonIconMail
          notifications={notificationsCount > 0 ? "true" : "false"}
          onClick={event => {
            if (!this.state.showPopup) {
              this.showPopup(event);
            }
          }}
        />
        {/* {notificationsCount > 0 && (
          <NotificationCount>{notificationsCount}</NotificationCount>
        )} */}
        {this.state.showPopup ? (
          <PopupMenu>
            <LoadingWrapper
              loaders={loaders}
              loaderKey={FETCH_NEW_NOTIFICATIONS}
              loaderClass="notificationsPopup"
              loaderNoText
            >
              {newNotifications.length > 0 ? (
                newNotifications.map(notification => {
                  const projectId = get(notification, "project.id", -1);
                  return (
                    <PopupMenuItem key={notification.id}>
                      <PopupMenuText>
                        {getDateTimeShortFormatted(notification.creationDate)}
                      </PopupMenuText>
                      <PopupMenuText>
                        {notification.fromWhom.username || "-"}
                      </PopupMenuText>
                      {projectId !== -1 ? (
                        <LinkStyled to={`/project/${projectId}`}>
                          {notification.message || "-"}
                        </LinkStyled>
                      ) : (
                        <TextRegular>{notification.message || "-"}</TextRegular>
                      )}
                    </PopupMenuItem>
                  );
                })
              ) : (
                <PopupMenuItem>У Вас нет новых уведомлений</PopupMenuItem>
              )}
            </LoadingWrapper>

            <PopupMenuLink to="/notifications">ВСЕ УВЕДОМЛЕНИЯ</PopupMenuLink>
          </PopupMenu>
        ) : null}
      </Container>
    );
  }
}

NotificationsPopup.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  newNotifications: notificationsType.isRequired,
  onFetchNewNotifications: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired
};

export default NotificationsPopup;
