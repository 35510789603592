import styled from "styled-components";

export const ListContainer = styled.div`
  padding: 0 20px 15px 20px;
`;

export const ListSectionHeader = styled.div`
  padding: 15px 0 5px 0;
`;

export const ListSectionHeaderTitle = styled.span`
  font-family: Roboto-Bold;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #394a58;
`;

export const ListRowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 0;
  ${props => props.margin && `margin: ${props.margin}`}
`;

export const ListRowLabel = styled.label`
  flex: 0 0 25%;
  font-family: Roboto-Bold;
  font-size: 13px;
  line-height: 1.54;
  text-align: left;
  color: #999999;
`;

export const ListRowText = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #394a58;
  word-break: break-word;
`;