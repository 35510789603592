export const FETCH_DICTIONARIES = "FETCH_DICTIONARIES";
export const SET_DICTIONARIES = "SET_DICTIONARIES";

export const FETCH_DICTIONARIES_LIST = "FETCH_DICTIONARIES_LIST";
export const SET_DICTIONARIES_LIST = "SET_DICTIONARIES_LIST";

export const FETCH_DICTIONARY_ENTRIES = "FETCH_DICTIONARY_ENTRIES";
export const SET_DICTIONARY_ENTRIES = "SET_DICTIONARY_ENTRIES";

export const SUBMIT_DICTIONARY_ENTRY = "SUBMIT_DICTIONARY_ENTRY";
export const DELETE_DICTIONARY_ENTRY = "DELETE_DICTIONARY_ENTRY";

export const SUBMIT_DICTIONARY = 'SUBMIT_DICTIONARY';
export const SET_DICTIONARY_IN_PROJECT = 'SET_DICTIONARY_IN_PROJECT';
