import React, { Component } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import ReactTable from "react-table";
import LoadingWrapper from "../common/LoadingWrapper";
import { FETCH_DICTIONARY_ENTRIES } from "../../store/dictionaries/constants";
import { dictionaryEntriesType } from "../../types/index";
import { PageHeaderBlock, PageHeaderTitle } from "../common/styles";
import Pagination from "../common/Pagination";
import { IconNext, IconPrevious } from "../common/Icon";
import { ButtonAdd } from "../common/Buttons";
import DictionaryEntryCreateModal from "./DictionaryEntryCreateModal";
import DictionaryEntrySubComponent from "./DictionaryEntrySubComponent";
import { DICTIONARY_CODE_TO_NAME_MAPPING } from "../../utils/constants";

class DictionaryEditor extends Component {
  state = {
    isCreateModalOpen: false
  };

  openCreateEntryModal = () => {
    this.setState({
      isCreateModalOpen: true
    });
  };

  closeCreateEntryModal = () => {
    this.setState({
      isCreateModalOpen: false
    });
  };

  render() {
    const {
      dictionaryEntries,
      dictionaryCode,
      onSubmitDictionaryEntry,
      onDeleteDictionaryEntry,
      loaders
    } = this.props;

    return (
      <div>
        <PageHeaderBlock margin="10px 0 30px 0">
          <PageHeaderTitle>
            {DICTIONARY_CODE_TO_NAME_MAPPING[dictionaryCode] ||
              "Элементы справочника"}
          </PageHeaderTitle>
          <ButtonAdd text="Добавить" onClick={this.openCreateEntryModal} />
        </PageHeaderBlock>
        <DictionaryEntryCreateModal
          dictionaryCode={dictionaryCode}
          onSubmitDictionaryEntry={onSubmitDictionaryEntry}
          isOpen={this.state.isCreateModalOpen}
          closeModal={this.closeCreateEntryModal}
        />

        <LoadingWrapper
          loaders={loaders}
          loaderKey={FETCH_DICTIONARY_ENTRIES}
          showPlaceholder={dictionaryEntries.length === 0}
          placeholderText="В СПРАВОЧНИКЕ НЕТ ЭЛЕМЕНТОВ"
        >
          <ReactTable
            PaginationComponent={Pagination}
            showPagination={dictionaryEntries.length > 10}
            defaultPageSize={10}
            minRows={0}
            data={dictionaryEntries}
            columns={[
              {
                Header: "",
                expander: true,
                Expander: ({ isExpanded }) => (
                  <div>{isExpanded ? <IconPrevious /> : <IconNext />}</div>
                )
              },
              {
                Header: "НАИМЕНОВАНИЕ",
                accessor: "name"
              }
            ]}
            SubComponent={row => (
              <DictionaryEntrySubComponent
                dictionaryEntry={row.original}
                dictionaryCode={dictionaryCode}
                onSubmitDictionaryEntry={onSubmitDictionaryEntry}
                onDeleteDictionaryEntry={onDeleteDictionaryEntry}
              />
            )}
          />
        </LoadingWrapper>
      </div>
    );
  }
}

DictionaryEditor.propTypes = {
  dictionaryEntries: dictionaryEntriesType.isRequired,
  dictionaryCode: PropTypes.string.isRequired,
  onSubmitDictionaryEntry: PropTypes.func.isRequired,
  onDeleteDictionaryEntry: PropTypes.func.isRequired,

  loaders: PropTypes.instanceOf(Map).isRequired,

  /*eslint-disable*/
  isExpanded: PropTypes.bool
  /* eslint-enable */
};

export default DictionaryEditor;
