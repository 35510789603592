import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { Map } from "immutable";
import { groupTagsType, tagType } from "../../types";
import Pagination from "../common/Pagination";
import {
  CheckboxSelectContainer,
  Select,
  TextRegular,
  LinkSpan,
} from "../common/styles";
import Checkbox from "../common/Checkbox";
import { SONAR_URL } from "../../utils/constants";
import { FETCH_TAG } from "../../store/gitlab/constants";
import Loader from "../common/Loader";
import { ButtonAdd } from "../common/Buttons";
import AddTagModal from "./AddTagModal";

function ReleaseTagsEditor(props) {
  const {
    groupTags,
    tagsResults,
    onChangeTag,
    values,
    setFieldValue,
    loaders,
    gitlabGroup,
  } = props;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectedRepository, setSelectedRepository] = useState({});

  const processedRepos = groupTags.map((repo) => {
    const processedRepo = repo;
    processedRepo.hasTags = !!repo.tags.length;
    processedRepo.isIncluded = values[`repo_included_${repo.gitLabId}`];
    const selectedTag = repo.tags.find(
      (repoTag) => repoTag.gitLabId === values[`tag_repo_${repo.gitLabId}`]
    );
    if (selectedTag) {
      const tagWithResult = tagsResults[selectedTag.commitSHA];
      processedRepo.selectedTag = tagWithResult;
      processedRepo.tagBuildSuccess = get(
        tagWithResult,
        "buildResult.success",
        -1
      );
    }
    processedRepo.formattedRepoName = repo.name.split(" ").join("-");
    return processedRepo;
  });

  useEffect(() => {
    for (const repository of groupTags) {
      setFieldValue(
        `tag_repo_${repository.gitLabId}`,
        (repository.selectedTag && repository.selectedTag.gitLabId) ||
        !!repository.tags.length ? repository.tags[0].gitLabId : ""
      );
    }
  }, [groupTags]);

  const handleShowAddTagModal = (repo) => {
    setSelectedRepository(repo);
    setIsVisibleModal(true);
  };
  const handleCloseAddTagModal = () => {
    setIsVisibleModal(false);
  };

  return (
    <>
      {isVisibleModal && (
        <AddTagModal
          gitlabGroup={gitlabGroup}
          repository={selectedRepository}
          isVisible={isVisibleModal}
          handleCloseModal={handleCloseAddTagModal}
        />
      )}
      <ReactTable
        PaginationComponent={Pagination}
        showPagination={processedRepos.length > 10}
        defaultPageSize={10}
        minRows={0}
        data={processedRepos}
        columns={[
          /* {
            Header: "",
            expander: true,
            Expander: ({ original: repo, isExpanded }) => {
              if (repo.isIncluded) {
                return <div>{isExpanded ? <IconPrevious /> : <IconNext />}</div>;
              }
              return null;
            }
          }, */
          {
            Header: "НАИМЕНОВАНИЕ",
            accessor: "name",
            maxWidth: 400,
            Cell: ({ original: repo }) => (
              <LinkSpan
                onClick={() => {
                  window.open(
                    `${values.projectGitlabPath}${repo.formattedRepoName}`,
                    "_blank"
                  );
                }}
              >
                {repo.name}
              </LinkSpan>
            ),
          },
          {
            sortable: false,
            Header: "ТЕГ",
            id: "tag",
            minWidth: 175,
            style: { background: "#f4f4f4", height: "36px" },
            Cell: ({ original: repo }) => {
              if (repo.hasTags) {
                return (
                  <CheckboxSelectContainer>
                    <Checkbox
                      id={`repo_${repo.gitLabId}`}
                      checked={repo.isIncluded}
                      onChange={(event) => {
                        setFieldValue(
                          `repo_included_${repo.gitLabId}`,
                          event.target.checked
                        );
                      }}
                    />
                    <Select
                      name={`tag_repo_${repo.gitLabId}`}
                      value={values[`tag_repo_${repo.gitLabId}`] || ""}
                      onChange={(event) => {
                        const selectedTagId = event.target.value;
                        onChangeTag(repo.gitLabId, selectedTagId);
                        setFieldValue(
                          `tag_repo_${repo.gitLabId}`,
                          selectedTagId
                        );
                      }}
                      disabled={!repo.isIncluded}
                    >
                      {repo.tags.map((repoTag) => (
                        <option
                          key={`tag_opt_${repo.gitLabId}_${repoTag.gitLabId}`}
                          value={repoTag.gitLabId}
                          label={repoTag.name}
                        >
                          {repoTag.name}
                        </option>
                      ))}
                    </Select>
                    <ButtonAdd
                      style={{ marginLeft: "auto" }}
                      text="Добавить тег"
                      onClick={() => handleShowAddTagModal(repo)}
                    />
                  </CheckboxSelectContainer>
                );
              }
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextRegular>В репозитории отсутствуют теги</TextRegular>
                  <ButtonAdd
                    style={{ marginLeft: "auto" }}
                    text="Добавить тег"
                    onClick={() => handleShowAddTagModal(repo)}
                  />
                </div>
              );
            },
          },
          {
            sortable: false,
            Header: "СБОРКА",
            id: "buildResult",
            Cell: ({ original: repo }) => {
              if (!repo.hasTags || !repo.isIncluded) {
                return null;
              }
              if (loaders.has(FETCH_TAG)) {
                return <Loader size="35px" />;
              }
              if (repo.tagBuildSuccess === -1) {
                return <TextRegular>Нет данных</TextRegular>;
              }
              return (
                <LinkSpan
                  onClick={() => {
                    window.open(
                      `${values.projectGitlabPath}${repo.formattedRepoName}/pipelines/${repo.selectedTag.buildResult.gitlabId}`,
                      "_blank"
                    );
                  }}
                >
                  {repo.tagBuildSuccess ? "passed" : "failed"}
                </LinkSpan>
              );
            },
          },
          {
            sortable: false,
            Header: "АНАЛИЗ",
            style: { background: "#f4f4f4", height: "36px" },
            id: "analysisResult",
            Cell: ({ original: repo }) => {
              if (!repo.hasTags || !repo.isIncluded || loaders.has(FETCH_TAG)) {
                return null;
              }
              const analysisPassed = get(
                repo,
                "selectedTag.analysisResult.passed",
                -1
              );
              if (analysisPassed === -1) {
                return <TextRegular>Нет данных</TextRegular>;
              }
              const sonarPath = repo.sonar;
              if (SONAR_URL) {
                return (
                  <LinkSpan
                    onClick={() => {
                      window.open(`${SONAR_URL}${sonarPath}`, "_blank");
                    }}
                  >
                    {analysisPassed ? "passed" : "failed"}
                  </LinkSpan>
                );
              }
              return (
                <TextRegular>
                  {analysisPassed ? "passed" : "failed"}
                </TextRegular>
              );
            },
          },
          {
            sortable: false,
            Header: "АРТЕФАКТЫ",
            id: "artifacts",
            Cell: ({ original: repo }) => {
              if (!repo.hasTags || !repo.isIncluded || loaders.has(FETCH_TAG)) {
                return null;
              }
              /* const artifactsStorage =
                values[`repo_artifacts_storage_${repo.gitLabId}`];
              const basePath = values.artifactsStorageBasePath[artifactsStorage];
              let additionalPath =
                values[`repo_nexus_additionalPath_${repo.gitLabId}`];
              if (artifactsStorage === ARTIFACTS_STORAGE.GITLAB) { */
              if (repo.tagBuildSuccess === -1)
                return <TextRegular>Нет данных</TextRegular>;
              const additionalPath = `${repo.formattedRepoName}/pipelines/${
                repo.selectedTag &&
                repo.selectedTag.buildResult &&
                repo.selectedTag.buildResult.gitlabId
                  ? repo.selectedTag.buildResult.gitlabId
                  : ""
              }`;
              // }
              // const artifactsPath = basePath + additionalPath;
              const artifactsPath = values.projectGitlabPath + additionalPath;

              if (artifactsPath) {
                return (
                  <LinkSpan
                    onClick={() => {
                      window.open(artifactsPath, "_blank");
                    }}
                  >
                    Артефакты сборки
                  </LinkSpan>
                );
              }
              return <TextRegular>Ссылка на артефакты не задана</TextRegular>;
            },
          },
        ]}
        /* freezeWhenExpanded
        SubComponent={({ original: repo }) => {
          if (repo.isIncluded) {
            return (
              <ReleaseTagSubComponent
                repo={repo}
                values={values}
                handleChange={handleChange}
              />
            );
          }
          return null;
        }} */
      />
    </>
  );
}

ReleaseTagsEditor.propTypes = {
  groupTags: groupTagsType.isRequired,
  tagsResults: tagType.isRequired,
  onChangeTag: PropTypes.func.isRequired,
  gitlabGroup: PropTypes.object.isRequired,
  values: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,

  /*eslint-disable*/
  isExpanded: PropTypes.bool,
  original: PropTypes.shape(),
  /* eslint-enable */
};

export default ReleaseTagsEditor;
