import { all, call, put, takeLatest } from "redux-saga/effects";
import Api from "../api";
import { setLoader, removeLoader, setError, errorParser } from "../store/preloader/actions";
import { LOCAL_LOADER } from "../store/preloader/constants";
import {
  FETCH_NOTIFICATIONS,
  FETCH_NEW_NOTIFICATIONS,
  FETCH_NEW_NOTIFICATIONS_COUNT,
  setNotifications,
  setNewNotifications,
  setNewNotificationsCount
} from "../store/notifications/actions";

export function* fetchNewNotificationsCountSaga() {
  try {

    // const response = yield call(Api.notifications.getNewNotificationsCount);
    const count = 0;

    yield put(setNewNotificationsCount(count));
  } catch (error) {
    console.error("fetchNotificationsCount", error);
  }
}
export function* fetchNewNotificationsCountFlow() {
  yield takeLatest(
    FETCH_NEW_NOTIFICATIONS_COUNT,
    fetchNewNotificationsCountSaga
  );
}

export function* fetchNewNotificationsSaga() {
  yield put(setLoader(LOCAL_LOADER, FETCH_NEW_NOTIFICATIONS, "ЗАГРУЗКА НОВЫХ УВЕДОМЛЕНИЙ"));

  try {
    const response = yield call(Api.notifications.getNewNotifications);

    const result = response.data;

    yield put(setNewNotifications(result));

    yield call(fetchNewNotificationsCountSaga);
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА НОВЫХ УВЕДОМЛЕНИЙ - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_NEW_NOTIFICATIONS));
}
export function* fetchNewNotificationsFlow() {
  yield takeLatest(FETCH_NEW_NOTIFICATIONS, fetchNewNotificationsSaga);
}

export function* fetchNotificationsSaga(action) {
  try {
    const { page, size } = action.payload;
    const params = {
      page,
      size
    };

    const response = yield call(Api.notifications.getNotifications, params);

    const result = response.data;

    yield put(setNotifications(result));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА УВЕДОМЛЕНИЙ - ${ errorParser(error) || error.message }`));
  }
}
export function* fetchNotificationsFlow() {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotificationsSaga);
}

export default function* notifications() {
  yield all([
    fetchNewNotificationsCountFlow(),
    fetchNewNotificationsFlow(),
    fetchNotificationsFlow()
  ]);
}
