import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { withFormik } from "formik";
import {
  ModalContainer,
  ModalBody,
  ModalHeader,
  ModalHeaderTitle,
  ButtonsRow
} from "../../common/Modal/styles";
import { ButtonIconClose, ButtonSave } from "../../common/Buttons";
import { Input, ErrorMessage } from "../../common/styles";
import { ButtonRedOutline } from "../../common/Buttons/styles";
import { modalStyles } from "../../../utils/theme";
import RowBlock from "../../common/RowBlock";
import FileUploader from "../../DocumentsEditor/DocumentsForm/FileUploader";

class ReportUploadModal extends Component {
  handleCloseModal = () => {
    const { resetForm, closeModal } = this.props;
    resetForm();
    closeModal();
  };

  render() {
    const {
      isOpen,
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleCloseModal}
        style={modalStyles}
        contentLabel="Upload Technical Solution"
        shouldCloseOnOverlayClick={false}
      >
        <ModalContainer>
          <ModalHeader>
            <ModalHeaderTitle>СОЗДАТЬ ОТЧЕТ</ModalHeaderTitle>
            <ButtonIconClose onClick={this.handleCloseModal} />
          </ModalHeader>

          <ModalBody>
            <RowBlock text="ДОКУМЕНТ" labelWidth="200px" alignSelfCenter>
              <FileUploader
                selectedFile={values.file}
                onChange={file => {
                  setFieldValue("file", file);
                }}
              />
            </RowBlock>
            <RowBlock text="НАЗВАНИЕ" labelWidth="200px" alignSelfCenter>
              <Input width="80%" name="comment" onChange={handleChange} />
            </RowBlock>

            {errors && errors.file && (
              <ErrorMessage>{errors.file}</ErrorMessage>
            )}

            <ButtonsRow>
              <ButtonSave
                type="submit"
                text="Сохранить"
                onClick={handleSubmit}
                margin="0 25px 0 0"
              />
              <ButtonRedOutline onClick={this.handleCloseModal}>
                Отмена
              </ButtonRedOutline>
            </ButtonsRow>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

Modal.setAppElement("#root");

ReportUploadModal.propTypes = {
  // onSubmitForm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,

  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  resetForm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    file: null,
    comment: ""
  }),
  validate: values => {
    const errors = {};
    if (!values.file) {
      errors.file = "Пожалуйста, прикрепите файл";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    // TODO: test if it works w/o
    if (values.file) {
      // documentType is hardcoded 41 for techSolutions dictionary entry
      const newDoc = {
        id: 0,
        comment: values.comment,
        file: values.file,
        documentType: { id: 48 }
      };
      formikBag.props.onSubmitForm(newDoc);
      formikBag.resetForm();
      formikBag.props.closeModal();
    }
  }
})(ReportUploadModal);
