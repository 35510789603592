import { createSelector } from "reselect";

const selectNewNotificationsCount = state =>
  state.getIn(["notifications", "count"]);
const makeSelectNewNotificationsCount = createSelector(
  selectNewNotificationsCount,
  count => count
);

const selectNewNotifications = state =>
  state.getIn(["notifications", "newNotifications"]);
const makeSelectNewNotifications = createSelector(
  selectNewNotifications,
  substate => substate.toJS()
);

const selectNotifications = state =>
  state.getIn(["notifications", "notifications"]);
const makeSelectNotifications = createSelector(
  selectNotifications,
  substate => substate.toJS()
);

export {
  makeSelectNewNotificationsCount,
  makeSelectNewNotifications,
  makeSelectNotifications
};
