import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { Container, LoaderText } from "./styles";
import Loader from "../Loader";
import { BoldSpan } from "../styles";

function LoadingWrapper(props) {
  const {
    children,
    loaders,
    loaderKey,
    loaderClass,
    loaderNoText,
    showPlaceholder,
    placeholderText
  } = props;

  if (loaders && loaders.has(loaderKey)){    
    
    return (
      <Container loaderClass={loaderClass}>
        <Loader dropdown={loaderClass === 'dropdown' && true}/>
        {!loaderNoText && <LoaderText>{loaders.get(loaderKey)}</LoaderText>}
      </Container>
  )}

  if (showPlaceholder) return <BoldSpan>{placeholderText}</BoldSpan>;

  return children;
}

LoadingWrapper.propTypes = {
  loaders: PropTypes.instanceOf(Map).isRequired,
  loaderKey: PropTypes.string.isRequired,
  loaderClass: PropTypes.string,
  loaderNoText: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  children: PropTypes.any
};

LoadingWrapper.defaultProps = {
  loaderClass: "",
  loaderNoText: false,
  showPlaceholder: false,
  placeholderText: ""
};

export default LoadingWrapper;
