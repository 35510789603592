import {
  FETCH_RELEASE_BY_ID,
  SET_RELEASE,
  SUBMIT_RELEASE,
  DELETE_RELEASE,
  CHANGE_RELEASE_STATUS
} from "./constants";

export const fetchReleaseById = id => ({
  type: FETCH_RELEASE_BY_ID,
  payload: {
    id
  }
});

export const setRelease = release => ({
  type: SET_RELEASE,
  payload: {
    release
  }
});

export const submitRelease = (releaseId, projectId, form) => ({
  type: SUBMIT_RELEASE,
  payload: {
    releaseId,
    projectId,
    form
  }
});

export const deleteRelease = (releaseId, projectId) => ({
  type: DELETE_RELEASE,
  payload: {
    releaseId,
    projectId
  }
});

export const EXPLOIT_RELEASE = "EXPLOIT_RELEASE";
export const exploitRelease = releaseId => ({
  type: EXPLOIT_RELEASE,
  payload: {
    releaseId
  }
});

export const changeReleaseStatus = (
  releaseId,
  status,
  comment,
  hasToSign,
  projectId
) => ({
  type: CHANGE_RELEASE_STATUS,
  payload: {
    releaseId,
    status,
    comment,
    hasToSign,
    projectId
  }
});