import {call, put, select} from "redux-saga/effects";
import {errorParser, removeLoader, setError, setLoader} from "../../store/preloader/actions";
import {GLOBAL_LOADER} from "../../store/preloader/constants";
import {makeSelectFilters} from "../../store/administration/selectors";
import {
    setFilterAdministration,
    setOpenUserAdministration,
    setUsersAdministration
} from "../../store/administration/actions";
import {KEY_LOAD_PROJECT} from "../administration";
import Api from "../../api";

export function getRandomArbitrary(min = 0, max = 100) {
    return Math.random() * (max - min) + min;
}
export function* getUsers(action) {
    yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "ПОЛУЧЕНИЕ ПОЛЬЗОВАТЕЛЕЙ"));
    try {
        const filter = yield select(makeSelectFilters);
        const { page, size, nameUser, nameProject } = filter;
        const response = yield call(Api.administration.getUsers, page, size, nameUser);

        const {numberOfElements, totalPages } = response.data;

        yield put(setFilterAdministration(
            page,
            size,
            nameProject,
            nameUser,
            numberOfElements,
            totalPages,
        ));

        yield put(setUsersAdministration(response.data.content));


    } catch (error) {
        yield put(setError(`ПОЛУЧЕНИЕ ПОЛЬЗОВАТЕЛЕЙ - ${errorParser(error) || error.message}`));
    }
    yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}


export function* getUser(action) {
    yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "ПОЛУЧЕНИЕ ПРОЕКТОВ"));
    try {
        const { id } = action.payload;
        const response = yield call(Api.administration.getUserPermissionsByUserId, id);
        yield put(setOpenUserAdministration(response.data));
    } catch (error) {
        yield put(setError(`ПОЛУЧЕНИЕ ПРОЕКТОВ - ${errorParser(error) || error.message}`));
    }
    yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}
