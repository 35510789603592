import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { techSolutionsType } from "../../../types";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalBody
} from "../../common/Modal/styles";
import { ButtonIconClose } from "../../common/Buttons";
import { modalStyles } from "../../../utils/theme";
import TechSolutionForm from "../TechSolutionForm";

function DocumentsModal(props) {
  const {
    techSolutions,
    onSaveTechSolution,

    modalIsOpen,
    closeModal
  } = props;

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Technical Solution Add Modal"
      shouldCloseOnOverlayClick={false}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalHeaderTitle>ДОБАВЛЕНИЕ ТИПОВОГО ТЕХНИЧЕСКОГО РЕШЕНИЯ</ModalHeaderTitle>
          <ButtonIconClose onClick={closeModal} />
        </ModalHeader>
        <ModalBody>
          <TechSolutionForm
            techSolutions={techSolutions}
            onSubmitForm={onSaveTechSolution}
          />
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
}

Modal.setAppElement("#root");

DocumentsModal.propTypes = {
  techSolutions: techSolutionsType.isRequired,
  onSaveTechSolution: PropTypes.func.isRequired,

  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default DocumentsModal;
