import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { withFormik } from "formik";
import {
  ModalContainer,
  ModalBody,
  ButtonsRow
} from "../../common/Modal/styles";
import { ErrorMessage, Input, SectionHeaderTitle } from "../../common/styles";
import { ButtonSave } from "../../common/Buttons";
import { ButtonRedOutline } from "../../common/Buttons/styles";
import { modalStyles } from "../../../utils/theme";

class DictionaryEntryCreateModal extends Component {
  handleCloseModal = () => {
    const { resetForm, closeModal } = this.props;
    resetForm();
    closeModal();
  };

  render() {
    const { isOpen, values, handleChange, handleSubmit, errors } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleCloseModal}
        style={modalStyles}
        contentLabel="Create Dictionary Entry"
      >
        <ModalContainer>
          <ModalBody>
            <SectionHeaderTitle>
              Наименование элемента справочника
            </SectionHeaderTitle>
            <Input
              name="entryName"
              value={values.entryName}
              onChange={handleChange}
              maxLength={253}
            />

            {errors && errors.entryName && (
              <ErrorMessage>{errors.entryName}</ErrorMessage>
            )}

            <ButtonsRow>
              <ButtonSave
                text="Сохранить"
                type="submit"
                onClick={handleSubmit}
                margin="0 25px 0 0"
              />
              <ButtonRedOutline onClick={this.handleCloseModal}>
                Отмена
              </ButtonRedOutline>
            </ButtonsRow>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

Modal.setAppElement("#root");

DictionaryEntryCreateModal.propTypes = {
  // dictionaryCode: PropTypes.string.isRequired,
  // onSubmitDictionaryEntry: PropTypes.func.isRequired,

  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,

  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  resetForm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    entryName: ""
  }),
  validate: values => {
    const errors = {};
    if (!values.entryName) {
      errors.entryName = "Пожалуйста, введите наименование";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    const {
      onSubmitDictionaryEntry,
      dictionaryCode,
      closeModal
    } = formikBag.props;
    onSubmitDictionaryEntry(dictionaryCode, {
      id: 0,
      name: values.entryName
    });
    formikBag.resetForm();
    closeModal();
  }
})(DictionaryEntryCreateModal);
