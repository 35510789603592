import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import RowBlock from "../common/RowBlock";
import {makeSelectDictionaryEntries} from "../../store/dictionaries/selectors";
import {makeSelectLocalLoaders} from "../../store/preloader/selectors";
import {fetchDictionaryEntries} from "../../store/dictionaries/actions";
import {dictionaryEntriesType} from "../../types";
import {MultiSelectCustom} from "../common/MultiSelectCustiom/MultiSelectCustom";

class SelectorDepartment extends Component {

    componentDidMount() {
        const {fetchDictionary, code} = this.props;
        fetchDictionary(code);
    }

    setChange = (val) => {
        const {
            dictionaryEntries,
        } = this.props;
        if (this.props.isMultiSelect) {
            if(!val) {
                this.props.handleChange([]);
                return;
            }
           const {values, fieldName} = this.props;
           let  selectValue = values && values[fieldName] && values[fieldName].length > 0? values[fieldName].map(item=>item.id) : [];
           const has = selectValue.includes(Number(val));
           if(has) {
               selectValue = selectValue.filter(item => Number(item) !== Number(val)  );

           } else {
               selectValue.push(Number(val));
           }

           const finds = dictionaryEntries.filter(item => selectValue.find(id => Number(id) === item.id));
           this.props.handleChange(finds || []);
        } else {
            const id = val;
            const find = dictionaryEntries.find(item => item.id == id);
            this.props.handleChange(find || null);
        }


    }

    selectElement(id){
        console.log(id);
    }


    render() {
        console.log(this.props);
        const {
            dictionaryEntries,
        } = this.props;


        const {
            rowLabelWidth,
            selectWidth,
            values,
            fieldName,
            onlySelector,
            isMultiSelect,
            styleIsSearch
        } = this.props;

        let selectValue = values && values[fieldName] && values[fieldName].id ? values[fieldName].id : null;
        if (isMultiSelect) {
            selectValue = values && values[fieldName] && values[fieldName].length > 0? values[fieldName].map(item=>item.id) : [];
        }

       if(isMultiSelect) return <MultiSelectCustom styleIsSearch={styleIsSearch} selected={selectValue} element={dictionaryEntries} onChange={this.setChange} />

        if (onlySelector) {
            return (
                <MultiSelectCustom styleIsSearch={styleIsSearch} isMultiSelect={isMultiSelect} width={selectWidth} selected={selectValue} element={dictionaryEntries} onChange={this.setChange} />
            )
        }
        return (
            <RowBlock
                text="Ответственный за ИК"
                labelWidth={rowLabelWidth}
                alignSelfCenter
            >
                <MultiSelectCustom
                    styleIsSearch={styleIsSearch}
                    width={selectWidth}
                    isMultiSelect={isMultiSelect}
                    selected={selectValue}
                    element={dictionaryEntries}
                    onChange={this.setChange} />
            </RowBlock>
        )
    }
}

SelectorDepartment.defaultProps = {
    rowLabelWidth: 0,
    selectWidth: 0,
    onlySelector: false,
    isMultiSelect: false,
    styleIsSearch: false
};
SelectorDepartment.propTypes = {
    rowLabelWidth: PropTypes.string,
    selectWidth: PropTypes.string,
    values: PropTypes.shape().isRequired,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    dictionaryEntries: dictionaryEntriesType.isRequired,
    fetchDictionary: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    onlySelector: PropTypes.bool,
    isMultiSelect: PropTypes.bool,
    styleIsSearch: PropTypes.bool,
};


const mapStateToProps = (state, props) => ({
    dictionaryEntries: makeSelectDictionaryEntries(
        state,
        props.code
    ),
    loaders: makeSelectLocalLoaders(state)
});

const mapDispatchToProps = dispatch => ({
    fetchDictionary: code => dispatch(fetchDictionaryEntries(code)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectorDepartment);
