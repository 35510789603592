import styled from "styled-components";

export const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 5px 15px;
  background-color: #eef2f5;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;