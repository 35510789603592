import { all, call, takeLatest, put } from "redux-saga/effects";
import { push } from "connected-react-router/immutable";
import get from "lodash.get";
import {
  CHANGE_RELEASE_STATUS,
  FETCH_RELEASE_BY_ID,
  SUBMIT_RELEASE,
  DELETE_RELEASE
} from "../store/releases/constants";
import { fetchProjectById } from "../store/project/actions";
import { showModal } from "../store/modal/actions";
import { MODAL_TYPE_NOTIFICATION_ALERT } from "../store/modal/constants";
import { setRelease } from "../store/releases/actions";
import { setLoader, removeLoader, setError, errorParser } from "../store/preloader/actions";
import { GLOBAL_LOADER, LOCAL_LOADER } from "../store/preloader/constants";
import { submitDocumentSaga } from "./documents";
import Api from "../api";

export function* fetchReleaseByIdSaga(action) {
  yield put(setLoader(LOCAL_LOADER, FETCH_RELEASE_BY_ID, "ЗАГРУЗКА РЕЛИЗА"));

  try {
    const releaseId = action.payload.id;

    const response = yield call(Api.releases.getReleaseById, releaseId);
    yield put(setRelease(response.data));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА РЕЛИЗА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_RELEASE_BY_ID));
}
export function* fetchReleaseByIdFlow() {
  yield takeLatest(FETCH_RELEASE_BY_ID, fetchReleaseByIdSaga);
}

function* submitExternalSystem(externalSystem) {
  try {
    const extSysToSubmit = externalSystem;
    const regulationId = get(extSysToSubmit, "regulation.id", -1);
    if (regulationId === 0) {
      const createdDocId = yield call(
        submitDocumentSaga,
        extSysToSubmit.regulation
      );
      if (createdDocId !== 0) {
        extSysToSubmit.regulation = { id: createdDocId };
      }
    }

    const response = yield call(
      Api.releases.submitExternalSystem,
      extSysToSubmit
    );

    return response.data.id;
  } catch (error) {
    return 0;
  }
}

export function* submitReleaseSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, SUBMIT_RELEASE, "СОХРАНЕНИЕ РЕЛИЗА"));

  try {
    const { releaseId, projectId, form } = action.payload;
    const isNew = releaseId === 0;

    const developmentEnvironments = form.devEnvId.map(item => ({
      id: item
    }));

    const additionalComponents = form.componentsId.map(item => ({
      id: item
    }));

    const tags = [];
    Object.keys(form).forEach(formKey => {
      if (formKey.startsWith("repo_included_") && form[formKey]) {
        // slice = 14 cos "repo_includes_" for repository gitLabId
        const repoId = formKey.slice(14);
        tags.push({
          gitlabId: form[`tag_repo_${repoId}`],
          repository: {
            gitLabId: repoId,
            repositoryType: form[`repo_artifacts_storage_${repoId}`],
            additionalPath: form[`repo_nexus_additionalPath_${repoId}`]
          }
        });
      }
    });

    const externalSystems = [];
    const extSystemsToCreate = [];
    form.externalSystems.forEach(extSys => {
      if (extSys.id !== 0) {
        externalSystems.push({ id: extSys.id });
      } else {
        extSystemsToCreate.push(extSys);
      }
    });

    const createdExtSystemsId = yield all(
      extSystemsToCreate.map(extSys => call(submitExternalSystem, extSys))
    );
    createdExtSystemsId.forEach(extSysId => {
      // catch error === 0
      if (extSysId !== 0) externalSystems.push({ id: extSysId });
    });

    const documents = [];
    const documentsToCreate = [];
    // form.documents will be undefined when from newProject
    if (form.documents) {
      form.documents.forEach(doc => {
        if (doc.id !== 0) {
          documents.push({ id: doc.id });
        } else {
          documentsToCreate.push(doc);
        }
      });

      const createdDocumentsId = yield all(
        documentsToCreate.map(doc => call(submitDocumentSaga, doc))
      );
      createdDocumentsId.forEach(docId => {
        // catch error === 0
        if (docId !== 0) documents.push({ id: docId });
      });
    }

    const releaseToSubmit = {
      id: releaseId,
      buildDate: Date.now(),
      name: form.releaseName,
      comment: form.comment,
      platform: { id: form.platformId },
      developmentEnvironments,
      database: { id: form.databaseId },
      additionalComponents,
      tags,
      tasks: form.tasks,
      externalSystems,
      documents,
      technicalSolutions: form.technicalSolutions
    };

    yield call(
      Api.releases.submitRelease,
      isNew ? "POST" : "PUT",
      projectId,
      releaseToSubmit
    );

    yield put(push(`/project/${projectId}`));
  } catch (error) {
    yield put(setError(`СОХРАНЕНИЕ РЕЛИЗА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, SUBMIT_RELEASE));
}
export function* submitReleaseFlow() {
  yield takeLatest(SUBMIT_RELEASE, submitReleaseSaga);
}

export function* deleteReleaseSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, DELETE_RELEASE, "УДАЛЕНИЕ РЕЛИЗА"));

  try {
    const { releaseId, projectId } = action.payload;

    yield call(Api.releases.deleteRelease, releaseId);

    yield put(fetchProjectById(projectId));
  } catch (error) {
    yield put(setError(`УДАЛЕНИЕ РЕЛИЗА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, DELETE_RELEASE));
}
export function* deleteReleaseFlow() {
  yield takeLatest(DELETE_RELEASE, deleteReleaseSaga);
}

export function* changeReleaseStatusSaga(action) {
  yield put(
    setLoader(GLOBAL_LOADER, CHANGE_RELEASE_STATUS, "ОБРАБОТКА РЕЛИЗА")
  );

  try {
    const { releaseId, status, comment, hasToSign, projectId } = action.payload;

    const currentLocation = window.location.href;
    const params = status === "ready" ? { redirect_url: currentLocation } : {};

    const response = yield call(
      Api.releases.changeReleaseStatus,
      status,
      releaseId,
      {
        name: comment
      },
      params
    );

    if (status === "ready" && hasToSign && response.data.url) {
      window.open(response.data.url, "_self");
    } else {
      yield put(fetchProjectById(projectId));
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put(
        showModal(MODAL_TYPE_NOTIFICATION_ALERT, {
          contentLabel: "No required documents error",
          message: get(
            error,
            "response.data.message",
            "Необходимо добавить обязательные документы"
          )
        })
      );
    } else {
      yield put(setError(`ОБРАБОТКА РЕЛИЗА - ${ errorParser(error) || error.message }`));
    }
  }

  yield put(removeLoader(GLOBAL_LOADER, CHANGE_RELEASE_STATUS));
}
export function* changeReleaseStatusFlow() {
  yield takeLatest(CHANGE_RELEASE_STATUS, changeReleaseStatusSaga);
}

export default function* releases() {
  yield all([
    fetchReleaseByIdFlow(),
    submitReleaseFlow(),
    deleteReleaseFlow(),
    changeReleaseStatusFlow()
  ]);
}
