import React from "react";
import PropTypes from "prop-types";
import { gitlabOptionsType } from "../../../types";
import {
  Select,
  FilterSectionBlock,
  FilterSearchBlock
} from "../../common/styles";
import RowBlock from "../../common/RowBlock";
import {MultiSelectCustom} from "../../common/MultiSelectCustiom/MultiSelectCustom";

const EventsLogFilter = ({ projectsOptions, onChangeProject, useNewLogic = false }) => (
  <FilterSectionBlock>
    <FilterSearchBlock>
      <RowBlock text="ПРОЕКТ" labelWidth="80px" alignSelfCenter>
        {useNewLogic  && projectsOptions  && projectsOptions.length > 0 &&
            <Select onChange={onChangeProject}>
          <option key="opt_po_all" value="" label="Все проекты">
            Все проекты
          </option>
          {projectsOptions.map(project => (
              <option
                  key={`opt_po_${project.id}`}
                  value={project.id}
                  label={project.name}
              >
                {project.name || "-"}
              </option>
          ))}
        </Select> }

        {!useNewLogic  && projectsOptions && projectsOptions.length > 0 && <Select onChange={onChangeProject}>
          <option key="opt_po_all" value="" label="Все проекты">
            Все проекты
          </option>
          {projectsOptions.map(project => (
            <option
              key={`opt_po_${project.id}`}
              value={project.id}
              label={project.gitLabId}
            >
              {project.gitLabId || "-"}
            </option>
          ))}
        </Select> }
      </RowBlock>
    </FilterSearchBlock>
  </FilterSectionBlock>
);

EventsLogFilter.propTypes = {
  projectsOptions: gitlabOptionsType.isRequired,
  onChangeProject: PropTypes.func.isRequired
};

export default EventsLogFilter;
