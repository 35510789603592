import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import AsyncSelect from 'react-select/async';
import { dictionaryEntriesType } from "../../../types";
import {
  SectionHeaderBlock,
  SectionHeaderTitle,
  Input,
  TextArea,
  ErrorMessage,
  Select
} from "../../common/styles";
import SectionBlock from "../../common/SectionBlock";
import RowBlock from "../../common/RowBlock";
import UsersPermission from "../../UsersPermission";
import { ERROR_PROJECT_DUPLICATE_NAME } from "../../../store/preloader/constants";
import Checkbox from "../../common/Checkbox";
import SelectorDepartment from "../SelectorDepartment";

const ProjectForm = props => {
  const {
      documentTypeDictionary,
      specificError,
      onShowModal,
      values,
      handleChange,
      setFieldValue,
      errors,
      touched,
      isNew,
      listAsuz,
      handleAsuzChange,
      loadOptions,
      onChangeAsuz,
      handelChangeDictionaryEntry
  } = props;
  const rowLabelWidth = "300px";
  const inputWidth = "80%";
  const selectWidth = "40%";
  const documentTypes = documentTypeDictionary.filter(type => !type.system);

  return (

    <div>
      <SectionBlock text="ОСНОВНАЯ ИНФОРМАЦИЯ">
        <RowBlock
          text="КРАТКОЕ НАИМЕНОВАНИЕ СИСТЕМЫ"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <Input
            name="name"
            type="text"
            value={values.name}
            onChange={handleChange}
            width={inputWidth}
            maxLength={500}
          />
        </RowBlock>
        {errors.name && touched.name && (
          <ErrorMessage>{errors.name}</ErrorMessage>
        )}
        {specificError === ERROR_PROJECT_DUPLICATE_NAME && (
          <ErrorMessage>
            Данная ИУС уже создана. Воспользуйтесь поиском
          </ErrorMessage>
        )}
        <RowBlock
          text="ПОЛНОЕ НАИМЕНОВАНИЕ СИСТЕМЫ"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <TextArea
            rows="2"
            name="fullName"
            value={values.fullName}
            onChange={handleChange}
            width={inputWidth}
            maxLength={1000}
          />
        </RowBlock>
        {errors.fullName && touched.fullName && (
          <ErrorMessage>{errors.fullName}</ErrorMessage>
        )}
        <RowBlock
          text="КРАТКОЕ ОПИСАНИЕ"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <TextArea
            rows="4"
            name="description"
            value={values.description}
            onChange={handleChange}
            width={inputWidth}
            maxLength={4000}
          />
        </RowBlock>
        <RowBlock
          text="КОД ПРОЕКТА АСУЗ"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
        <AsyncSelect
          className="w100 border"
          cacheOptions
          loadOptions={loadOptions}
          defaultValue={isNew ? null :{projectId:values.codeAsuz, name: values.nameAsuz}}
          defaultOptions={listAsuz}
          getOptionValue={option => `${option.projectId}`}
          getOptionLabel={option =>`${option.name}` }
          onInputChange={option => handleAsuzChange(option) }
          onChange={option => onChangeAsuz(option) }
          noOptionsMessage={() => "проекты не найдены"}
          placeholder='Поиск проекта ASUZ'
        />

        </RowBlock>
        <RowBlock
          text="КОДЫ ПРОЕКТОВ SONARQUBE"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <Input
            name="sonarQubeProjectCode"
            type="text"
            value={values.sonarQubeProjectCode}
            onChange={handleChange}
            width={inputWidth}
          />
        </RowBlock>
          <span
            style={{
              display: "block",
              height: "20px",
              fontFamily: 'Roboto-Regular',
              fontSize: '10px',
              textTransform: 'uppercase',
              color: '#999999',
              alignSelf: 'flex-end'
            }}
          >
          Чтобы указать несколько ссылок на SONARQUBE запишите их через запятую</span>
        <RowBlock
          text="ТЕСТОВЫЙ СТЕНД"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <Input
            name="testStandUrl"
            type="text"
            value={values.testStandUrl}
            onChange={handleChange}
            width={inputWidth}
          />
        </RowBlock>
        <RowBlock
          text="Паспорт СУУС ИТ"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <Input
            name="passportCYYCIT"
            type="text"
            value={values.passportCYYCIT}
            onChange={handleChange}
            width={inputWidth}
          />
        </RowBlock>
        <RowBlock
          text="Управление контейнерами (Kubernetes)"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <Input
            name="kubernetes"
            type="text"
            value={values.kubernetes}
            onChange={handleChange}
            width={inputWidth}
          />
        </RowBlock>
        <RowBlock
          text="Аналитика (Grafana)"
          labelWidth={rowLabelWidth}
          alignSelfCenter
        >
          <Input
            name="grafana"
            type="text"
            value={values.grafana}
            onChange={handleChange}
            width={inputWidth}
          />
        </RowBlock>
          <RowBlock
              text="МЕТОД ПОДТВЕРЖДЕНИЯ РЕЛИЗА ПОДРЯДЧИКОМ"
              labelWidth={rowLabelWidth}
              alignSelfCenter
          >
              <Select
                  name="signReleaseInventory"
                  value={values.signReleaseInventory}
                  onChange={handleChange}
                  width={selectWidth}
              >
                  <option value label="Подписание протокола в ЕСЭП">Подписание протокола в ЕСЭП</option>
                  <option selected value={false} label="Простое подтверждение">Простое подтверждение</option>
              </Select>
          </RowBlock>
          <RowBlock
              text="ТИПЫ ДОКУМЕНТОВ"
              labelWidth={rowLabelWidth}
              alignSelfCenter
          >
              <div>
                  {documentTypes.map(type => (
                      <Checkbox
                          key={`check_doc_type_${type.id}`}
                          id={`check_doc_type_${type.id}`}
                          checked={
                              values.requiredDocuments.findIndex(
                                  docTypeId => docTypeId === type.id
                              ) !== -1
                          }
                          onChange={event => {
                              let changedDocuments;
                              if (event.target.checked) {
                                  changedDocuments = [...values.requiredDocuments, type.id];
                              } else {
                                  const removedIndex = values.requiredDocuments.findIndex(
                                      docTypeId => docTypeId === type.id
                                  );
                                  changedDocuments = [
                                      ...values.requiredDocuments.slice(0, removedIndex),
                                      ...values.requiredDocuments.slice(removedIndex + 1)
                                  ];
                              }
                              setFieldValue("requiredDocuments", changedDocuments);
                          }}
                          label={type.name}
                      />
                  ))}
              </div>
          </RowBlock>
     <SelectorDepartment
         code={'departments'}
         rowLabelWidth={rowLabelWidth}
         selectWidth={selectWidth}
         fieldName={'dictionaryEntry'}
         nameProperty={'dictionaryEntry'}
         values={values}
         handleChange={handelChangeDictionaryEntry} />


      </SectionBlock>
      <SectionHeaderBlock>
        <SectionHeaderTitle>ДОСТУП</SectionHeaderTitle>
      </SectionHeaderBlock>
      <FieldArray
        name="users"
        render={arrayHelpers => (
          <UsersPermission
            selectedUsers={values.users}
            onAddUser={user => {
              const index = values.users.findIndex(
                item => item.user.username === user.user.username
              );
              if (index === -1) {
                arrayHelpers.push(user);
              }
            }}
            onDeleteUser={user => {
              const index = values.users.findIndex(
                item => item.user.username === user.user.username
              );
              arrayHelpers.remove(index);
            }}
            onShowModal={onShowModal}
          />
        )}
      />
    </div>
  );
};
ProjectForm.defaultProps = {
  id: 0
};
ProjectForm.propTypes = {
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  specificError: PropTypes.string.isRequired,
  onShowModal: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  id: PropTypes.number,
  isNew: PropTypes.bool.isRequired,
  listAsuz: PropTypes.array.isRequired,
  handleAsuzChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  onChangeAsuz: PropTypes.func.isRequired,
  handelChangeDictionaryEntry : PropTypes.func.isRequired,
};

export default ProjectForm;
