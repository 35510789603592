import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  submitDocument: (data) =>
    axios({
      method: "POST",
      url: `${API_HOST}/api/fpa/v1/documents`,
      data,
    }),
  uploadRepDocument: (id) =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/documents/${id}/download`,
      responseType: "blob",
    }),
  downloadDocument: (id) =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/documents/${id}/download`,
      responseType: "blob",
    }),
  downloadReportExample: (id) =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/reports/${id}/example`,
      responseType: "blob",
    }),
  fetchTechSolutions: (params) =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/documents`,
      params,
    }),
  fetchReports: (params) =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/documents`,
      params,
    }),
  deleteReportFromProject: (documentId, projectId) =>
    axios({
      method: "DELETE",
      url: `${API_HOST}/api/fpa/v1/documents/${documentId}/project/${projectId}`,
    }),
  setReportToProject: (documentId, projectId) =>
    axios({
      method: "POST",
      url: `${API_HOST}/api/fpa/v1/documents/${documentId}/project/${projectId}`,
    }),
  generateReport: (
    templateReportDocumentId,
    projectId,
    dateBegin,
    dateEnd,
    releaseId
  ) =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/reports/${templateReportDocumentId}/projects/${projectId}?dateBegin=${dateBegin}&dateEnd=${dateEnd}&releaseId=${releaseId}`,
      responseType: "blob",
    }),
  deleteDocument: (id) =>
    axios.delete(`${API_HOST}/api/fpa/v1/documents/${id}`),
  deleteRepDocument: (id) =>
    axios.delete(`${API_HOST}/api/fpa/v1/documents/${id}`),
  signDocument: (method, releaseId, documentId, params) =>
    axios({
      method,
      url: `${API_HOST}/api/fpa/v1/sign/release/${releaseId}/document/${documentId}`,
      params,
    }),
  downloadDocumentsZip: (projectId, documentIds) => {
    let queryParams = "";
    let isFirst = true;
    for (const id of documentIds) {
      queryParams += `${isFirst ? "?" : "&"}documentIds[]=${id}`;
      isFirst = false;
    }

    return axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/projects/${projectId}/downloadZip${queryParams}`,
      responseType: "blob",
    });
  },
};
