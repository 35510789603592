import React from "react";
import PropTypes from "prop-types";
import { Row, RowLabel } from "./styles";

const RowBlock = ({ children, text, rowWidth, labelWidth, ...props }) => (
  <Row rowWidth={rowWidth} >
    <RowLabel {...props} labelWidth={labelWidth}>{text}</RowLabel>
    {children}
  </Row>
);

RowBlock.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  rowWidth: PropTypes.string,
  labelWidth: PropTypes.string,
  alignSelfCenter: PropTypes.bool
};

RowBlock.defaultProps = {
  rowWidth: "",
  labelWidth: "0%",
  alignSelfCenter: false
};

export default RowBlock;
