import { call, all, put, takeLatest } from "redux-saga/effects";
import Api from "../api";
import {
  setLoader,
  removeLoader,
  setError,
  errorParser,
} from "../store/preloader/actions";
import { GLOBAL_LOADER, LOCAL_LOADER } from "../store/preloader/constants";
import {
  FETCH_TECH_SOLUTIONS,
  FETCH_REPORTS,
  UPLOAD_DOCUMENT,
  UPLOAD_REP_DOCUMENT,
  DELETE_REP_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  DELETE_DOCUMENT,
  SIGN_DOCUMENT,
  DELETE_DOCUMENT_SIGN,
  DOWNLOAD_REPORT_EXAMPLE,
  DOWNLOAD_GENERATED_REPORT,
  DELETE_REPORT_FROM_PROJECT,
  SET_REPORT_FROM_PROJECT,
  ADD_REPORT_FROM_PROJECT,
  DOWNLOAD_DOCUMENTS_ZIP,
} from "../store/documents/constants";

import { setReports , setTechSolutions } from "../store/documents/actions";

import { fetchReleaseById } from "../store/releases/actions";

import { fetchProjectById } from "../store/project/actions";
import loadFileHelper from "../utils/loadFileHelper";

export function* fetchTechSolutionsSaga() {
  yield put(
    setLoader(
      LOCAL_LOADER,
      FETCH_TECH_SOLUTIONS,
      "ЗАГРУЗКА ТЕХНИЧЕСКИХ РЕШЕНИЙ"
    )
  );

  try {
    // 41 is hardcoded for techSolutions documentTypeId
    const params = {
      documentType: 41,
    };

    const response = yield call(Api.documents.fetchTechSolutions, params);

    yield put(setTechSolutions(response.data));
  } catch (error) {
    yield put(
      setError(
        `ЗАГРУЗКА ТЕХНИЧЕСКИХ РЕШЕНИЙ - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_TECH_SOLUTIONS));
}
export function* fetchTechSolutionsFlow() {
  yield takeLatest(FETCH_TECH_SOLUTIONS, fetchTechSolutionsSaga);
}

function* fetchReports() {
  yield put(
    setLoader(LOCAL_LOADER, FETCH_REPORTS, "ЗАГРУЗКА ШАБЛОНОВ ОТЧЕТОВ")
  );

  try {
    // 41 is hardcoded for techSolutions documentTypeId
    const params = {
      documentType: 48,
    };

    const response = yield call(Api.documents.fetchReports, params);

    yield put(setReports(response.data));
  } catch (error) {
    yield put(
      setError(
        `ЗАГРУЗКА ШАБЛОНОВ ОТЧЕТОВ - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_REPORTS));
}
function* fetchReportsFlow() {
  yield takeLatest(FETCH_REPORTS, fetchReports);
}

export function* submitDocumentSaga(document) {
  try {
    const formData = new FormData();

    formData.append("file", document.file);
    formData.append("comment", document.comment);
    if (document.documentType) {
      formData.append("documentTypeId", document.documentType.id);
    }

    const response = yield call(Api.documents.submitDocument, formData);

    return response.data.id;
  } catch (error) {
    return 0;
  }
}

export function* uploadDocumentSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, UPLOAD_DOCUMENT, "ЗАГРУЗКА ДОКУМЕНТА"));

  try {
    const document = action.payload.form;

    yield call(submitDocumentSaga, document);

    // maybe this call should be more flexible
    yield call(fetchTechSolutionsSaga);
  } catch (error) {
    yield put(
      setError(`ЗАГРУЗКА ДОКУМЕНТА - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, UPLOAD_DOCUMENT));
}
export function* uploadDocumentFlow() {
  yield takeLatest(UPLOAD_DOCUMENT, uploadDocumentSaga);
}

function* uploadRepDocument(action) {
  yield put(
    setLoader(GLOBAL_LOADER, UPLOAD_REP_DOCUMENT, "ЗАГРУЗКА ШАБЛОНА ОТЧЕТА")
  );

  try {
    const document = action.payload.form;

    yield call(submitDocumentSaga, document);

    // maybe this call should be more flexible
    yield call(fetchReports);
  } catch (error) {
    yield put(
      setError(
        `ЗАГРУЗКА ШАБЛОНА ОТЧЕТА - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, UPLOAD_REP_DOCUMENT));
}
function* uploadRepDocumentFlow() {
  yield takeLatest(UPLOAD_REP_DOCUMENT, uploadRepDocument);
}

export function* downloadFileSaga(action) {
  yield put(
    setLoader(GLOBAL_LOADER, DOWNLOAD_DOCUMENT, "СКАЧИВАНИЕ ДОКУМЕНТА")
  );

  try {
    const { id } = action.payload;

    const response = yield call(Api.documents.downloadDocument, id);
    loadFileHelper(response);
  } catch (error) {
    yield put(
      setError(`СКАЧИВАНИЕ ДОКУМЕНТА - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DOWNLOAD_DOCUMENT));
}
export function* downloadFileFlow() {
  yield takeLatest(DOWNLOAD_DOCUMENT, downloadFileSaga);
}

function* downloadReportExample(action) {
  yield put(
    setLoader(GLOBAL_LOADER, DOWNLOAD_REPORT_EXAMPLE, "СОЗДАНИЕ ПРИМЕРА ОТЧЕТА")
  );

  try {
    const { id, title } = action.payload;

    const response = yield call(Api.documents.downloadReportExample, id);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);

    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    yield put(
      setError(
        `СОЗДАНИЕ ПРИМЕРА ОТЧЕТА - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DOWNLOAD_REPORT_EXAMPLE));
}
function* downloadReportExampleFlow() {
  yield takeLatest(DOWNLOAD_REPORT_EXAMPLE, downloadReportExample);
}

function* downloadGeneratedReport(action) {
  yield put(
    setLoader(GLOBAL_LOADER, DOWNLOAD_GENERATED_REPORT, "СОЗДАНИЕ ОТЧЕТА")
  );

  try {
    const {
      templateReportDocumentId,
      projectId,
      dateBegin,
      dateEnd,
      releaseId,
      title,
    } = action.payload;

    const response = yield call(
      Api.documents.generateReport,
      templateReportDocumentId,
      projectId,
      dateBegin,
      dateEnd,
      releaseId
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);

    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    yield put(
      setError(`СОЗДАНИЕ ОТЧЕТА - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DOWNLOAD_GENERATED_REPORT));
}
function* downloadGeneratedReportFlow() {
  yield takeLatest(DOWNLOAD_GENERATED_REPORT, downloadGeneratedReport);
}

export function* deleteDocumentSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, DELETE_DOCUMENT, "УДАЛЕНИЕ ДОКУМЕНТА"));

  try {
    const deleteId = action.payload.id;

    yield call(Api.documents.deleteDocument, deleteId);

    // maybe this call should be more flexible
    yield call(fetchTechSolutionsSaga);
  } catch (error) {
    yield put(
      setError(`УДАЛЕНИЕ ДОКУМЕНТА - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DELETE_DOCUMENT));
}
export function* deleteDocumentFlow() {
  yield takeLatest(DELETE_DOCUMENT, deleteDocumentSaga);
}

function* deleteRepDocument(action) {
  yield put(
    setLoader(GLOBAL_LOADER, DELETE_REP_DOCUMENT, "УДАЛЕНИЕ ШАБЛОНА ОТЧЕТА")
  );

  try {
    const deleteId = action.payload.id;

    yield call(Api.documents.deleteDocument, deleteId);

    // maybe this call should be more flexible
    yield call(fetchReports);
  } catch (error) {
    yield put(
      setError(`УДАЛЕНИЕ ДОКУМЕНТА - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DELETE_REP_DOCUMENT));
}
function* deleteRepDocumentFlow() {
  yield takeLatest(DELETE_REP_DOCUMENT, deleteRepDocument);
}

export function* signDocumentSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, SIGN_DOCUMENT, "ПОДГОТОВКА ПОДПИСИ"));

  try {
    const { isSign, releaseId, documentId } = action.payload;

    const currentLocation = window.location.href;
    const params = {
      redirect_url: currentLocation,
    };

    const response = yield call(
      Api.documents.signDocument,
      isSign ? "POST" : "GET",
      releaseId,
      documentId,
      params
    );

    window.open(response.data.url, isSign ? "_self" : "_blank");
  } catch (error) {
    yield put(
      setError(`ПОДГОТОВКА ПОДПИСИ - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, SIGN_DOCUMENT));
}
export function* signDocumentFlow() {
  yield takeLatest(SIGN_DOCUMENT, signDocumentSaga);
}

function* delReportFromProject(action) {
  yield put(
    setLoader(
      GLOBAL_LOADER,
      DELETE_REPORT_FROM_PROJECT,
      "УДАЛЕНИЕ ШАБЛОНА ОТЧЕТА"
    )
  );

  try {
    const { templateId, projectId } = action.payload;
    yield call(Api.documents.deleteReportFromProject, templateId, projectId);
    yield put(fetchProjectById(projectId));
  } catch (error) {
    yield put(
      setError(
        `УДАЛЕНИЕ ШАБЛОНА ОТЧЕТА - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DELETE_REPORT_FROM_PROJECT));
}
function* deleteReportFromProjectFlow() {
  yield takeLatest(DELETE_REPORT_FROM_PROJECT, delReportFromProject);
}

function* setReportFromProject(action) {
  yield put(
    setLoader(
      GLOBAL_LOADER,
      SET_REPORT_FROM_PROJECT,
      "ПРИКРЕПЛЕНИЕ ШАБЛОНА К ОТЧЕТУ"
    )
  );

  try {
    const { templateId, projectId } = action.payload;
    yield call(Api.documents.setReportToProject, templateId, projectId);
  } catch (error) {
    yield put(
      setError(
        `ПРИКРЕПЛЕНИЕ ШАБЛОНА К ОТЧЕТУ - ${errorParser(error) || error.message}`
      )
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, SET_REPORT_FROM_PROJECT));
}
function* setReportFromProjectFlow() {
  yield takeLatest(SET_REPORT_FROM_PROJECT, setReportFromProject);
}

function* addDocumentation(action) {
  yield put(
    setLoader(GLOBAL_LOADER, ADD_REPORT_FROM_PROJECT, "ЗАГРУЗКА ДОКУМЕНТАЦИИ")
  );

  try {
    const { templateId, data } = action.payload;

    const id = yield call(submitDocumentSaga, data);

    yield call(Api.documents.setReportToProject, id, templateId);

    yield put(fetchProjectById(templateId));
  } catch (error) {
    yield put(
      setError(
        `ПРИКРЕПЛЕНИЕ ШАБЛОНА К ОТЧЕТУ - ${errorParser(error) || error.message}`
      )
    );
    return 0;
  }

  yield put(removeLoader(GLOBAL_LOADER, ADD_REPORT_FROM_PROJECT));
}
function* addDocumentationFlow() {
  yield takeLatest(ADD_REPORT_FROM_PROJECT, addDocumentation);
}

export function* deleteDocumentSignSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, DELETE_DOCUMENT_SIGN, "УДАЛЕНИЕ ПОДПИСИ"));

  try {
    const { documentId, releaseId } = action.payload;

    yield call(Api.documents.signDocument, "DELETE", documentId, {});

    yield put(fetchReleaseById(releaseId));
  } catch (error) {
    yield put(
      setError(`УДАЛЕНИЕ ПОДПИСИ - ${errorParser(error) || error.message}`)
    );
  }

  yield put(removeLoader(GLOBAL_LOADER, DELETE_DOCUMENT_SIGN));
}
export function* deleteDocumentSignFlow() {
  yield takeLatest(DELETE_DOCUMENT_SIGN, deleteDocumentSignSaga);
}

function* downloadDocumentsZip(action) {
  yield put(
    setLoader(GLOBAL_LOADER, DOWNLOAD_DOCUMENTS_ZIP, "ЗАГРУЗКА ДОКУМЕНТАЦИИ")
  );

  try {
    const { projectId, documentIds, title } = action.payload;

    const response = yield call(
      Api.documents.downloadDocumentsZip,
      projectId,
      documentIds
    );

    const url = window.URL.createObjectURL(new Blob([response.data]), {type: "octet/stream"});
    const link = document.createElement("a");
    link.setAttribute('href', url);
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click'));

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    yield put(
      setError(`ЗАГРУЗКА ДОКУМЕНТАЦИИ - ${errorParser(error) || error.message}`)
    );
    return 0;
  }

  yield put(removeLoader(GLOBAL_LOADER, DOWNLOAD_DOCUMENTS_ZIP));
}
function* downloadDocumentsZipFlow() {
  yield takeLatest(DOWNLOAD_DOCUMENTS_ZIP, downloadDocumentsZip);
}

export default function* documents() {
  yield all([
    fetchTechSolutionsFlow(),
    fetchReportsFlow(),
    uploadDocumentFlow(),
    uploadRepDocumentFlow(),
    downloadFileFlow(),
    deleteDocumentFlow(),
    deleteRepDocumentFlow(),
    signDocumentFlow(),
    deleteDocumentSignFlow(),
    downloadReportExampleFlow(),
    downloadGeneratedReportFlow(),
    deleteReportFromProjectFlow(),
    setReportFromProjectFlow(),
    addDocumentationFlow(),
    downloadDocumentsZipFlow(),
  ]);
}
