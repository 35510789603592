import React from "react";
import PropTypes from "prop-types";
import { gitlabOptionsType } from "../../types";
import { Flex } from "../common/styles";
import { ButtonAdd } from "../common/Buttons"
import UsersTable from "./UsersTable";
import { MODAL_TYPE_USERS_PERMISSION } from "../../store/modal/constants";

const UsersPermission = props => {
  const { selectedUsers, onAddUser, onDeleteUser, onShowModal } = props;

  return (
    <Flex flexDirection="column">
      <ButtonAdd text="Добавить" onClick={() => {
        onShowModal(MODAL_TYPE_USERS_PERMISSION, {
          contentLabel: "Users Permission",
          onAddUser
        })
      }} />
      <UsersTable users={selectedUsers} onDeleteUser={onDeleteUser} />
    </Flex>
  );
};

UsersPermission.propTypes = {
  selectedUsers: gitlabOptionsType.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired
};

export default UsersPermission;
