import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { modalStyles } from "../../../utils/theme";
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
} from "../../common/Modal/styles";
import { ButtonIconClose } from "../../common/Buttons";
import AddDocumentationForm from "./AddDocumentationForm"

class AddDocumentationModal extends Component {
  handleSubmitForm = form => {
    this.props.handleSubmit(form)
  }

  render() {
    const { isVisible, handleClose } = this.props;

    return (
      <Modal isOpen={isVisible} style={modalStyles}>
        <ModalContainer>
          <ModalHeader>
            <ModalHeaderTitle>Добавление документации</ModalHeaderTitle>
            <ButtonIconClose onClick={handleClose} />
          </ModalHeader>
          <ModalBody>
          
            <AddDocumentationForm
              handleClose={handleClose}
              onSubmitForm={this.handleSubmitForm}
            />
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

AddDocumentationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddDocumentationModal;
