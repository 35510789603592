import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 8px 0px 8px 0px;
  ${props => props.rowWidth !== "" && `width: ${props.rowWidth}` }
`;

export const RowLabel = styled.label`
  flex: ${props => `0 0 ${props.labelWidth}`}
  ${props => props.alignSelfCenter && "align-self: center;"}

  font-family: Roboto-Bold;
  font-size: 13px;
  line-height: 1.54;
  text-align: left;
  color: #999999;
`;

export const RowText = styled.span`
  flex: 0 0 38%;
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #394a58;
  word-break: break-word;
`;