import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notificationsResponseType } from "../../types";
import Notifications from "../../components/Notifications";
import { makeSelectNotifications } from "../../store/notifications/selectors";
import { fetchNotifications } from "../../store/notifications/actions";

class NotificationsPage extends Component {
  componentDidMount() {
    // needed init call to check if there are any notifications
    this.props.onFetchNotifications(0, 10);
  }

  render() {
    const { notifications, onFetchNotifications } = this.props;

    return (
      <Notifications
        notifications={notifications}
        onFetchNotifications={onFetchNotifications}
      />
    );
  }
}

const mapStateToProps = state => ({
  notifications: makeSelectNotifications(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchNotifications: (page, size) => dispatch(fetchNotifications(page, size))
});

NotificationsPage.propTypes = {
  notifications: notificationsResponseType.isRequired,
  onFetchNotifications: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsPage);
