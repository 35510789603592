import { fromJS } from "immutable";
import {
  SET_NOTIFICATIONS,
  SET_NEW_NOTIFICATIONS,
  SET_NEW_NOTIFICATIONS_COUNT
} from "./actions";

const initialState = fromJS({
  count: 0,
  newNotifications: [],
  notifications: {
    content: [],
    empty: true,
    totalElements: 0,
    totalPages: -1
  }
});

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_NOTIFICATIONS_COUNT: {
      return state.set("count", action.payload.count);
    }
    case SET_NEW_NOTIFICATIONS: {
      return state.set(
        "newNotifications",
        fromJS(action.payload.notifications)
      );
    }
    case SET_NOTIFICATIONS: {
      return state.set("notifications", fromJS(action.payload.notifications));
    }
    default: {
      return state;
    }
  }
};

export default notifications;
