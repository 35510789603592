import {call, put, select} from "redux-saga/effects";
import {errorParser, removeLoader, setError, setLoader} from "../../store/preloader/actions";
import {GLOBAL_LOADER} from "../../store/preloader/constants";
import {KEY_LOAD_PROJECT} from "../administration";
import {
    setFilterAdministration,
    setOpenProjectAdministration,
    setProjectAdministration
} from "../../store/administration/actions";
import {makeSelectFilters} from "../../store/administration/selectors";
import Api from "../../api";

export function* getProject(action) {
    yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "Получения списока пользователей и их разрешений"));
    try {
        const { id } = action.payload;
        const response =
            yield call(Api.administration.getUserAndPermissionByProjectId, id);
        yield put(setOpenProjectAdministration(response.data));
    } catch (error) {
        yield put(setError(`Получения списока пользователей и их разрешений - ${errorParser(error) || error.message}`));
    }
    yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}

export function* getProjects() {
    yield put(setLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT, "ПОЛУЧЕНИЕ ПРОЕКТОВ"));
    try {
        const filter = yield select(makeSelectFilters);
        const { page, size, nameProject, nameUser  } = filter;
        const response = yield call(
            Api.administration.getProjectsByName,
            page,
            size,
            nameProject);
        const {numberOfElements, totalPages } = response.data;

        yield put(setFilterAdministration(
            page,
            size,
            nameProject,
            nameUser,
            numberOfElements,
            totalPages,
        ));
        yield put(setProjectAdministration(response.data.content));
    } catch (error) {
        yield put(setError(`ПОЛУЧЕНИЕ ПРОЕКТОВ - ${errorParser(error) || error.message}`));
    }

    yield put(removeLoader(GLOBAL_LOADER, KEY_LOAD_PROJECT));
}
