import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ApiContainer,
  ApiPageSizeBlock,
  ApiNavigationBlock,
  ApiPaginationLabel,
  ApiPaginationInput
} from "./styles";
import { Select } from "../styles";
import {
  ButtonIconNext,
  ButtonIconPrevious,
  ButtonIconJumpForward,
  ButtonIconJumpBack
} from "../Buttons";

const pageSizeOptions = [5, 10, 20, 25, 50];

export default class PaginationApi extends Component {
  changePage = selectedPage => {
    const { pageNumber, totalPages, handleInputPage } = this.props;
    const activePage = pageNumber + 1;
    if (selectedPage === activePage || selectedPage < 1 || selectedPage > totalPages)
      return;

    handleInputPage(selectedPage - 1);
  };

  render() {
    const { pageNumber, pageSize, handlePageSize, totalPages } = this.props;
    const activePage = pageNumber + 1;

    return (
      <ApiContainer>
        <ApiPageSizeBlock>
          <ApiPaginationLabel>Показывать</ApiPaginationLabel>
          <Select
            name="pageSize"
            value={pageSize}
            onChange={e => {
              handlePageSize(parseInt(e.target.value, 10));
            }}
          >
            {pageSizeOptions.map(size => (
              <option key={`opt_ps_${size}`} value={size} label={size} />
            ))}
          </Select>
        </ApiPageSizeBlock>
        <ApiNavigationBlock key={`input_refresh_${activePage}`}>
          <ButtonIconJumpBack
            onClick={() => {
              this.changePage(1);
            }}
            disabled={activePage === 1}
          />
          <ButtonIconPrevious
            onClick={() => {
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          />
          <ApiPaginationLabel>Страница</ApiPaginationLabel>
          <ApiPaginationInput
            type="number"
            defaultValue={activePage}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.changePage(e.target.value);
              }
            }}
          />
          <ApiPaginationLabel>{`/ ${totalPages}`}</ApiPaginationLabel>
          <ButtonIconNext
            onClick={() => {
              this.changePage(activePage + 1);
            }}
            disabled={activePage === totalPages}
          />
          <ButtonIconJumpForward
            onClick={() => {
              this.changePage(totalPages);
            }}
            disabled={activePage === totalPages}
          />
        </ApiNavigationBlock>
      </ApiContainer>
    );
  }
}

PaginationApi.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleInputPage: PropTypes.func.isRequired,
  handlePageSize: PropTypes.func.isRequired
};
