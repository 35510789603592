import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { techSolutionsType } from "../../../types";
import { BoldSpan, LinkSpan } from "../../common/styles";
import { ButtonIconClose } from "../../common/Buttons";
import Pagination from "../../common/Pagination";

const TechSolutionsTable = props => {
  const {
    techSolutions,
    onDownloadDocument,
    isEditor,
    onDeleteTechSolution
  } = props;

  return (
    <div>
      {techSolutions.length > 0 ? (
        <ReactTable
          PaginationComponent={Pagination}
          showPagination={techSolutions.length > 10}
          defaultPageSize={10}
          minRows={0}
          data={techSolutions}
          columns={[
            {
              Header: "ФАЙЛ",
              id: "solutionName",
              Cell: row => (
                <LinkSpan
                  onClick={() => {
                    onDownloadDocument(row.original.document.id);
                  }}
                >
                  {get(row, "original.document.fileOriginalName", "-")}
                </LinkSpan>
              )
            },
            {
              Header: "ОПИСАНИЕ",
              id: "solutionDescription",
              style:{ background: "#f4f4f4", height: "36px" },
              accessor: d => get(d, "document.comment", "-")
            },
            {
              Header: "КОММЕНТАРИЙ",
              id: "comment",
              accessor: d => get(d, "comment", "")
            },
            {
              sortable: false,
              Header: "",
              id: "delete",
              maxWidth: 60,
              accessor: "localId",
              Cell: row => {
                if (!isEditor) return null;
                return (
                  <ButtonIconClose
                    onClick={() => {
                      onDeleteTechSolution(row.value);
                    }}
                  />
                );
              }
            }
          ]}
        />
      ) : (
        <BoldSpan>У РЕЛИЗА НЕТ ТИПОВЫХ ТЕХНИЧЕСКИХ РЕШЕНИЙ</BoldSpan>
      )}
    </div>
  );
};

TechSolutionsTable.propTypes = {
  techSolutions: techSolutionsType.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,

  isEditor: PropTypes.bool,
  onDeleteTechSolution: PropTypes.func,

  /*eslint-disable*/
  isExpanded: PropTypes.bool
  /* eslint-enable */
};

TechSolutionsTable.defaultProps = {
  isEditor: false,
  onDeleteTechSolution: () => {}
};

export default TechSolutionsTable;
