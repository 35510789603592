import React from "react";
import PropTypes from "prop-types";
import { externalSystemType } from "../../../types";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalBody,
  ButtonsRow
} from "../../common/Modal/styles";
import { Button } from "../../common/Buttons/styles";
import { ButtonIconClose } from "../../common/Buttons";
import ExternalSystemSubComponent from "../../ExternalSystemEditor/ExternalSystemsTable/ExternalSystemSubComponent";

function ExternalSystemViewModal(props) {
  const { externalSystem, onDownloadDocument, closeModal } = props;

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalHeaderTitle>Связь с внешней системой</ModalHeaderTitle>
        <ButtonIconClose onClick={closeModal} />
      </ModalHeader>
      <ModalBody>
        <ExternalSystemSubComponent
          externalSystem={externalSystem}
          onDownloadDocument={onDownloadDocument}
        />
        <ButtonsRow>
          <Button onClick={closeModal}>Готово</Button>
        </ButtonsRow>
      </ModalBody>
    </ModalContainer>
  );
}

ExternalSystemViewModal.propTypes = {
  externalSystem: externalSystemType.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,

  closeModal: PropTypes.func.isRequired
};

export default ExternalSystemViewModal;
