import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { withFormik } from "formik";
import get from "lodash.get";
import LoadingWrapper from "../common/LoadingWrapper";
import { FETCH_RELEASE_BY_ID } from "../../store/releases/constants";
import {
  releaseType,
  releaseDictionariesType,
  groupTagsType,
  tagType,
  userType,
} from "../../types";
import ReleaseForm from "./ReleaseForm";
import { PageHeaderBlock, PageHeaderTitle, ButtonsRow } from "../common/styles";
import { ButtonSave } from "../common/Buttons";
import { initializeFormValues } from "./utils";
import { Button } from "../common/Buttons/styles";

import { saveTempData, deleteTempData } from "../../utils/helpers";
import { KEY_TEMP_DATA } from "../../utils/constants";

const ReleaseEditor = (props) => {
  const {
    release,
    releaseDictionaries,
    groupTags,
    tagsResults,
    onChangeTag,
    user,
    project,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,

    onShowModal,
    loaders,
    onClickCancel,
  } = props;

  useEffect(() => {
    //  подробнее смотри initializeFormValues
    saveTempData(KEY_TEMP_DATA.KEY_TEMP_RELEASE, values);
    return () => {
      deleteTempData(KEY_TEMP_DATA.KEY_TEMP_RELEASE);
    };
  }, [values]);

  const userInPermissions = user
    ? get(project, "userPermissions", []).find((userWithAccess) => userWithAccess.user.id === user.id)
    : undefined;

  const isAdmin = user ? user.admin : false;
  /*
  let hasAccess = isAdmin;
  let isUser = false;

  if (userInPermissions !== undefined) {
    isUser = userInPermissions.permission === "USER";
    hasAccess = true;
  }
  */
  const { actions = [] } = userInPermissions || {};
  const isReleaseAdd = actions.find((el) => el === "releaseAdd");
  const isReleaseEdit = actions.find((el) => el === "releaseEdit");
  const hasAccesToEdit = isAdmin || isReleaseAdd || isReleaseEdit;

  return (
    <div>
      <PageHeaderBlock editor>
        <PageHeaderTitle>{`Релиз - ${(release && release.name) ||
          "Добавление"}`}</PageHeaderTitle>
        {hasAccesToEdit && (
          <ButtonSave type="submit" onClick={handleSubmit} text="Сохранить" />
        )}
        <Button onClick={onClickCancel}>Отмена</Button>
      </PageHeaderBlock>
      <LoadingWrapper
        loaders={loaders}
        loaderKey={FETCH_RELEASE_BY_ID}
        loaderClass="release"
      >
        <ReleaseForm
          gitlabGroup={project.gitlabGroup}
          releaseDictionaries={releaseDictionaries}
          groupTags={groupTags}
          tagsResults={tagsResults}
          onChangeTag={onChangeTag}
          project={project}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          onShowModal={onShowModal}
          loaders={loaders}
        />
      </LoadingWrapper>
      <ButtonsRow>
        {hasAccesToEdit && (
          <ButtonSave
            type="submit"
            onClick={handleSubmit}
            text="Сохранить"
            margin="0 40px 0 0"
          />
        )}
        <Button onClick={onClickCancel}>Отмена</Button>
      </ButtonsRow>
    </div>
  );
};

ReleaseEditor.propTypes = {
  // onSubmitForm: PropTypes.func.isRequired,
  // isNew: PropTypes.bool.isRequired,
  release: releaseType,
  releaseDictionaries: releaseDictionariesType.isRequired,
  groupTags: groupTagsType.isRequired,
  tagsResults: tagType.isRequired,
  onChangeTag: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,

  project: PropTypes.object.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  user: userType.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
};

ReleaseEditor.defaultProps = {
  release: null,
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: initializeFormValues,
  validate: (values) => {
    const errors = {};

    if (!values.releaseName) {
      errors.releaseName = "Введите, пожалуйста, наименование релиза";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmitForm(values);
  },
})(ReleaseEditor);
