import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { PreloaderContainer, PreloaderContent, PreloaderItem } from "./styles";
import Loader from "../common/Loader";

const Preloader = ({ loaders }) => (
  <PreloaderContainer visible={!loaders.isEmpty()}>
    <Loader size="150px" />
    <PreloaderContent>
      {loaders.entrySeq().map(([key, value]) => (
        <PreloaderItem key={key}>{value}</PreloaderItem>
      ))}
    </PreloaderContent>
  </PreloaderContainer>
);

Preloader.propTypes = {
  loaders: PropTypes.instanceOf(Map).isRequired
};
export default Preloader;
