import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { makeSelectGlobalLoaders } from "../../store/preloader/selectors";
import Preloader from "../../components/Preloader";

const PreloaderContainer = props => <Preloader loaders={props.loaders} />;

const mapStateToProps = state => ({
  loaders: makeSelectGlobalLoaders(state)
});

PreloaderContainer.propTypes = {
  loaders: PropTypes.instanceOf(Map).isRequired
};

export default connect(mapStateToProps)(PreloaderContainer);
