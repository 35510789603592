import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalBody,
  ButtonsRow
} from "../../common/Modal/styles";
import { TextArea, SectionHeaderTitle } from "../../common/styles";
import { ButtonSave } from "../../common/Buttons";
import { ButtonRedOutline } from "../../common/Buttons/styles";

class ReleaseDeclineModal extends Component {
  state = {
    comment: ""
  };

  handleChangeComment = event => {
    const commentText = event.target.value;
    this.setState({
      comment: commentText
    });
  };

  handleConfirmDecline = () => {
    const { onChangeReleaseStatus, releaseId, projectId } = this.props;
    onChangeReleaseStatus(releaseId, "declined", this.state.comment, null, projectId);
    this.props.closeModal();
  };

  render() {
    return (
      <ModalContainer>
        <ModalBody>
          <SectionHeaderTitle>Добавьте комментарий</SectionHeaderTitle>
          <TextArea
            value={this.state.comment}
            onChange={this.handleChangeComment}
            rows={8}
          />
          <ButtonsRow>
            <ButtonSave
              text="Сохранить"
              onClick={this.handleConfirmDecline}
              margin="0 25px 0 0"
            />
            <ButtonRedOutline onClick={this.props.closeModal}>
              Отмена
            </ButtonRedOutline>
          </ButtonsRow>
        </ModalBody>
      </ModalContainer>
    );
  }
}

ReleaseDeclineModal.propTypes = {
  onChangeReleaseStatus: PropTypes.func.isRequired,
  releaseId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ReleaseDeclineModal;
