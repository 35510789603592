export const FETCH_MAILING = "FETCH_MAILING";
export const fetchMailings = () => ({
  type: FETCH_MAILING,
});

export const SET_MAILINGS = "SET_MAILINGS";
export const setMailings = mailing => ({
    type: SET_MAILINGS,
    payload: {
        mailing
    }
});

export const PUT_MAILING = "PUT_MAILING"
export const putMailing = list => ({
    type: PUT_MAILING,
    payload: list
});
