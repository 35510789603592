import { all, call, put, takeLatest } from "redux-saga/effects";
import Api from "../api";
import { removeLoader, setError, setLoader, errorParser } from "../store/preloader/actions";
import { LOCAL_LOADER } from "../store/preloader/constants";
import { FETCH_EVENTS, setEvents } from "../store/eventsLog/actions";

export function* fetchEventsSaga(action) {
  yield put(setLoader(LOCAL_LOADER, FETCH_EVENTS, "ЗАГРУЗКА ЖУРНАЛА СОБЫТИЙ"));

  try {
    const response = yield call(Api.eventsLog.getEvents, action.payload);
    yield put(setEvents(response.data));
  } catch (error) {
    yield put(setError(`ЗАГРУЗКА СОБЫТИЙ - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(LOCAL_LOADER, FETCH_EVENTS));
}
export function* fetchEventsFlow() {
  yield takeLatest(FETCH_EVENTS, fetchEventsSaga);
}

export default function* events() {
  yield all([fetchEventsFlow()]);
}
