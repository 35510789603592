import ReactTable from "react-table";
import React, {Component} from "react";
import PropTypes from "prop-types";

export class TableRoleCheck extends Component {
    render() {
        const {tableCheck} = this.props;
        const columns = [
            {
                Header: "Пользователь",
                accessor: "username"
            },
            {
                Header: "уровень роли",
                accessor: "accessLevel",
                minWidth: 350,
                resizable: true
            },
            {
                Header: "Источник",
                accessor: "from"
            },
        ]

        const comparator = (a, b)=> {
            const usernameA = a.username;
            const usernameB = b.username;
            if (usernameA< usernameB) {
                return -1;
            }
            if (usernameA> usernameB) {
                return 1;
            }
            return 0;
        }

        const sortBYUser = tableCheck && tableCheck.length > 0?tableCheck.sort(comparator) :[];
        const fpa = sortBYUser.filter(el=>el.from.includes('FPA'));
        const gitlab = sortBYUser.filter(el=>el.from.includes('Gitlab'))
        return (
            <div className='checkTables'>
                <div className='checkT'>
                    <h5>GITLAB</h5>
                    <ReactTable
                        showPagination={false}
                        data={gitlab}
                        minRows={0}
                        manual
                        columns={columns}
                    />
                </div>
                <div className='checkT'>
                    <h5>FPA</h5>
                    <ReactTable
                        showPagination={false}
                        data={fpa}
                        minRows={1}
                        manual
                        columns={columns}
                    />
                </div>
            </div>
        )
    }
}

TableRoleCheck.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tableCheck: PropTypes.array.isRequired
};
