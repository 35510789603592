import React from "react";
import Modal from "react-modal";
import { modalStyles } from "../../utils/theme";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
 ButtonsRow } from "../common/Modal/styles";
import { ButtonIconClose } from "../common/Buttons";
import { Button } from "../common/Buttons/styles";

const ConfirmationDeleteModal = (props) => {
  const { handleConfirm, handleClose } = props;

  return (
    <Modal isOpen style={modalStyles}>
      <ModalContainer>
        <ModalHeader>
          <ModalHeaderTitle>
            Вы уверены, что хотите удалить документацию?
          </ModalHeaderTitle>
          <ButtonIconClose onClick={handleClose} />
        </ModalHeader>

        <ButtonsRow>
          <Button type="submit" margin="0 25px 0 0" onClick={handleConfirm}>
            Да
          </Button>
          <Button type="submit" margin="0 25px 0 0" onClick={handleClose}>
            Отмена
          </Button>
        </ButtonsRow>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmationDeleteModal;
