import PropTypes from "prop-types";

const { shape, bool, number, string, arrayOf, oneOfType } = PropTypes;

export const modalStateType = shape({
  modalType: string,
  props: shape()
});

export const userType = shape({
  id: string,
  email: string,
  fullName: string,
  admin: bool
});

export const optionType = shape({
  id: number,
  code: string,
  name: string
});

export const optionsType = arrayOf(optionType);

export const notificationType = shape({
  id: number,
  creationDate: string,
  message: string,
  fromWhom: userType
});

export const notificationsType = arrayOf(notificationType);

export const notificationsResponseType = shape({
  content: notificationsType,
  totalElements: number,
  totalPages: number,
  empty: bool
});

export const eventType = shape({
  id: number,
  name: string,
  date: string,
  text: string,
  object: string,
  user: string
});

export const eventsType = arrayOf(eventType);

export const eventsResponseType = shape({
  content: eventsType,
  totalElements: number,
  totalPages: number,
  empty: bool
});

export const dictionaryEntriesType = arrayOf(optionType);

export const releaseDictionariesType = shape({
  platform: dictionaryEntriesType,
  database: dictionaryEntriesType,
  additionalComponents: dictionaryEntriesType,
  developmentEnvironment: dictionaryEntriesType
});

export const externalSystemDictionariesType = shape({
  sourceMode: dictionaryEntriesType,
  sourceLocation: dictionaryEntriesType,
  informationConsumer: dictionaryEntriesType,
  consumerLocation: dictionaryEntriesType,
  authenticationDict: dictionaryEntriesType,
  transport: dictionaryEntriesType,
  interfaceDict: dictionaryEntriesType,
  transData: dictionaryEntriesType,
  connectionType: dictionaryEntriesType
});

export const gitlabOptionType = shape({
  id: oneOfType([number, string]),
  name: string,
  gitLabId: string
});

export const gitlabOptionsType = arrayOf(gitlabOptionType);

export const userPermissionType = shape({
  permission: string,
  user: gitlabOptionType
});

export const documentType = shape({
  id: number,
  comment: string,
  fileOriginalName: string,
  documentType: gitlabOptionType
});

export const documentsType = arrayOf(documentType);

export const externalSystemType = shape({
  id: number,
  name: string,
  authentication: gitlabOptionType,
  connectionType: gitlabOptionType,
  consumerLocation: gitlabOptionType,
  informationConsumer: gitlabOptionType,
  insideDC: oneOfType([bool, string]),
  interfaces: gitlabOptionType,
  internalInterfaceId: string,
  periodicity: oneOfType([number, string]),
  regulation: documentType,
  sourceLocation: gitlabOptionType,
  sourceMode: gitlabOptionType,
  totalAmountPerDay: oneOfType([number, string]),
  transData: gitlabOptionType,
  transport: gitlabOptionType
});

export const externalSystemsType = arrayOf(externalSystemType);

export const taskType = shape({
  id: number,
  author: string,
  taskNumber: number,
  topic: string,
  taskId: string,
});

export const TasksType = arrayOf(taskType);

export const releaseTagType = shape({
  gitlabId: string,
  id: number,
  name: string,
  repository: gitlabOptionType
});

export const tagType = shape({
  gitLabId: string,
  commitSHA: string,
  buildResult: shape({
    gitlabId: string,
    success: bool
  }),
  analysisResult: shape({
    gitLabId: string,
    passed: bool
  })
});

export const groupTagsEntryType = shape({
  gitLabId: string,
  name: string,
  repositoryType: string,
  additionalPath: string,
  sonar: string,
  tags: arrayOf(tagType)
});

export const groupTagsType = arrayOf(groupTagsEntryType);

export const releaseTagsType = arrayOf(releaseTagType);

export const techSolutionType = shape({
  document: documentType,
  comment: string
});

export const techSolutionsType = arrayOf(techSolutionType);

export const reportType = shape({
  document: documentType,
  comment: string
});

export const reportsType = arrayOf(reportType);

export const releaseType = shape({
  id: number,
  name: string,
  buildDate: string,
  status: string,
  comment: string,
  tags: releaseTagsType,
  developmentEnvironments: arrayOf(gitlabOptionType),
  platform: gitlabOptionType,
  additionalComponents: arrayOf(gitlabOptionType),
  externalSystems: externalSystemsType,
  documents: documentsType,
  technicalSolutions: techSolutionsType,
  typeRequiredDocuments: gitlabOptionsType,
  reports: reportsType
});

export const projectType = shape({
  id: number,
  name: string,
  fullName: string,
  description: string,
  incidentURL: string,
  gitlabGroup: gitlabOptionType,
  lastRelease: releaseType,
  userPermissions: arrayOf(userPermissionType),
  requiredDocuments: gitlabOptionsType,
  signReleaseInventory: bool
});

export const projectsType = arrayOf(projectType);

export const sortType = shape({
    id: string,
    desc: bool
})


export const StageType = {
  BUILD: "BUILD",
  CODE_ANALYZE: "STATIC_ANALYZE",
  DEPLOY: "DEPLOY",
  TEST_AUTOMATIC: "TEST_AUTOMATIC",
  TEST_PERFORMANCE: "TEST_PERFORMANCE",
  TEST_MANUAL: "TEST_MANUAL",
}

export const StageStatusType = {
  UNKNOWN: "UNKNOWN",
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  SUCCESSFULLY: "SUCCESSFULLY",
  FAILURE: "FAILURE",
  CANCELED: "CANCELED",
}

export const StageCodeAnalyzeStatusType = {
  OK: "OK",
  WARN: "WARN",
  ERROR: "ERROR",
  NONE: "NONE"
}

export const PipelineJobType = shape({
  id: number,
  name: string,
  status: StageStatusType,
  hasArtifacts: bool
})

export const PipelineStageType = shape({
  jobs: arrayOf(PipelineJobType),
  status: StageStatusType
})

export const PipelineType = shape({
  repository: gitlabOptionType,
  id: string,
  dateTime: string,
  name: string,
  status: StageStatusType,

  stageBuildStatus: StageStatusType,
  stageBuild: PipelineStageType,

  stageCodeAnalyzeStatus: StageStatusType,
  stageCodeAnalyze: PipelineStageType,
  stageCodeAnalyzeProjectStatus: StageCodeAnalyzeStatusType,

  stageDeployStatus: StageStatusType,
  stageDeploy: PipelineStageType,

  stageAutomaticTestsStatus: StageStatusType,
  stageAutomaticTests: PipelineStageType,
  stageAutomaticTestsCoverageP: number,

  stagePerformanceTestsStatus: StageStatusType,
  stagePerformanceTests: PipelineStageType,

  stageManualTestsStatus: StageStatusType,
  stageManualTests: PipelineStageType,
  stageManualTestsAllTestCount: number,
  stageManualTestsSuccessfullyTestCount: number,
  stageManualTestFailTestCount: number,
})


export const departamentType = shape({
  id: number,
  code: string,
  name: string
});