export const generateId = () =>
  `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

export const normalizeDate = dateString => {
  if(!dateString) return  '';
  let slice = dateString.replace(/Z+$/g, '');
  slice = slice.replace(/\+\d+/g, '');
  if (!slice.endsWith("Z")) {
    slice += "Z";
  }

  return slice;
}

// dateSlice is for IE11...
export const getDateFormatted = dateString => {
  const dateSlice = normalizeDate(dateString);
  const jsDate = new Date(dateSlice);
  const day = `0${jsDate.getDate()}`.slice(-2);
  const month = `0${jsDate.getMonth() + 1}`.slice(-2);
  const year = jsDate.getFullYear();
  return `${day}.${month}.${year}`;
};

export const getDateTimeFormatted = dateString => {
  if (!dateString) return '';
  const dateSlice = normalizeDate(dateString);
  const jsDate = new Date(dateSlice);
  const day = `0${jsDate.getDate()}`.slice(-2);
  const month = `0${jsDate.getMonth() + 1}`.slice(-2);
  const year = jsDate.getFullYear();
  const time = jsDate.toTimeString().split(" ")[0];
  return `${day}.${month}.${year} ${time}`;
};

export const getDateTimeShortFormatted = dateString => {
  const dateSlice = normalizeDate(dateString);
  const jsDate = new Date(dateSlice);
  const day = `0${jsDate.getDate()}`.slice(-2);
  const month = `0${jsDate.getMonth() + 1}`.slice(-2);
  const time = jsDate
    .toTimeString()
    .split(" ")[0]
    .slice(0, 5);
  return `${day}.${month} ${time}`;
};


export const saveTempData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
export const getTempData = (key, def=null) =>{
   const res= localStorage.getItem(key)
   return res ? JSON.parse(res) : def;
}
export const deleteTempData=(key)=> {
  localStorage.removeItem(key)
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function chunkArr(arr, chunk = 10) {
  return arr.reduce((result, item, index) => {
    if (index % chunk === 0) {
      result.push(arr.slice(index, index + chunk));
    }
    return result;
  }, []);
}
