import React from "react";
import PropTypes from "prop-types";
import { Container, ContentWrapper } from "./styles";
import { SectionHeaderBlock, SectionHeaderTitle } from "../styles";

const SectionBlock = ({ children, text }) => (
  <Container>
    <SectionHeaderBlock>
      <SectionHeaderTitle>{text}</SectionHeaderTitle>
    </SectionHeaderBlock>
    <ContentWrapper>{children}</ContentWrapper>
  </Container>
);

SectionBlock.propTypes = {
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
  // children: PropTypes.oneOfType([
  //   PropTypes.element,
  //   PropTypes.arrayOf(PropTypes.element),
  // ]).isRequired
};

export default SectionBlock;
