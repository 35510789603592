import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { techSolutionsType } from "../../types";
import {
  fetchTechSolutions,
  uploadDocument,
  deleteDocument,
  downloadDocument
} from "../../store/documents/actions";
import { showModal } from "../../store/modal/actions";
import {
  MODAL_TYPE_CONFIRMATION_DIALOG,
  MODAL_TYPE_TECH_SOLUTION_UPLOAD
} from "../../store/modal/constants";
import { makeSelectTechSolutions } from "../../store/documents/selectors";
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";
import TechSolutions from "../../components/TechSolutions";

class TechSolutionsPage extends Component {
  componentDidMount() {
    this.props.fetchTechSolutions();
  }

  handleUploadTechSolution = () => {
    const { onShowModal, onUploadDocument } = this.props;

    onShowModal(MODAL_TYPE_TECH_SOLUTION_UPLOAD, {
      contentLabel: "Upload technical solution",
      onSubmitForm: onUploadDocument
    });
  };

  handleDeleteTechSolution = id => {
    const { onShowModal, onDeleteDocument } = this.props;

    onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
      contentLabel: "Delete technical solution confirmation",
      message: "Вы уверены, что хотите удалить типовое техническое решение?",
      onConfirm: () => {
        onDeleteDocument(id);
      }
    });
  };

  render() {
    const { techSolutions, onDownloadDocument, loaders } = this.props;

    return (
      <TechSolutions
        techSolutions={techSolutions}
        onUploadDocument={this.handleUploadTechSolution}
        onDeleteDocument={this.handleDeleteTechSolution}
        onDownloadDocument={onDownloadDocument}
        loaders={loaders}
      />
    );
  }
}

const mapStateToProps = state => ({
  techSolutions: makeSelectTechSolutions(state),
  loaders: makeSelectLocalLoaders(state)
});

const mapDispatchToProps = dispatch => ({
  fetchTechSolutions: () => dispatch(fetchTechSolutions()),
  onUploadDocument: form => dispatch(uploadDocument(form)),
  onDeleteDocument: id => dispatch(deleteDocument(id)),
  onDownloadDocument: id => dispatch(downloadDocument(id)),
  onShowModal: (modalType, props) => dispatch(showModal(modalType, props))
});

TechSolutionsPage.propTypes = {
  techSolutions: techSolutionsType.isRequired,

  fetchTechSolutions: PropTypes.func.isRequired,
  onUploadDocument: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,

  loaders: PropTypes.instanceOf(Map).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TechSolutionsPage);
