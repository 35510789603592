import React from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { releaseType, projectType } from "../../../../types";
import ListRow from "../../../common/ListRow";
import {
  ListContainer,
  ListSectionHeader,
  ListSectionHeaderTitle,
  ListRowWrapper
} from "../../../common/ListRow/styles";
import { LinkSpan, TextAlert } from "../../../common/styles";
import { GL_HOST, SONAR_URL } from "../../../../utils/constants";
import {
  TableStyled,
  THead,
  TH,
  TBody,
  TR,
  TD
} from "../../../common/Table/styles";
import Pagination from "../../../common/Pagination";

// author: "ФПА СО ЕЭС"
// id: null
// taskId: "serviceCall$342166703"
// taskNumber: 497538
// topic: "ФПА тест 11"

function ReleaseSubComponent({
  release,
  project,
  onDownloadDocument,
  onViewSignedDocument,
  onViewExternalSystem,
}) {
  const gitlabGroupName = get(project, "gitlabGroup.name", "");
  const projectGitlabPath = `${GL_HOST}/${gitlabGroupName}/`;
  const requiredDocuments = release.typeRequiredDocuments.map(
    type => type.name
  );
  let noSignProtocol = false;
  if (project.signReleaseInventory && release.status === "READY") {
    // 42 - hardcoded protocol documentType id
    const protocol = release.documents.find(
      doc => get(doc, "documentType.id", 0) === 42
    );
    if (protocol && !protocol.sign) {
      noSignProtocol = true;
    }
  }
  // #51569
  /* const artifactsStorageBasePath = {
    [ARTIFACTS_STORAGE.GITLAB]: projectGitlabPath,
    [ARTIFACTS_STORAGE.MAVEN]: (project && project.maven) || NEXUS_URL_MAVEN,
    [ARTIFACTS_STORAGE.NUGET]: (project && project.nuget) || NEXUS_URL_NUGET,
    [ARTIFACTS_STORAGE.NPM]: (project && project.npm) || NEXUS_URL_NPM,
    [ARTIFACTS_STORAGE.DOCKER]: (project && project.docker) || NEXUS_URL_DOCKER
  }; */

  return (
    <ListContainer>
      {noSignProtocol && (
          <TextAlert>
              Протокол проверки исходного кода релиза не подписан в ЕСЭП
          </TextAlert>
      )}

      {requiredDocuments.length > 0 && (
          <TextAlert>
              {`В релизе не хватает документов следующих типов:
        ${requiredDocuments.join(", ")}`}
          </TextAlert>
      )}

      <ListSectionHeader>
          <ListSectionHeaderTitle>ИСПРАВЛЕННЫЕ ЗАМЕЧАНИЯ ФГП</ListSectionHeaderTitle>
      </ListSectionHeader>
      {
        release.tasks && release.tasks.length > 0
        ? <ReactTable
                PaginationComponent={Pagination}
                showPagination={release.tasks.length > 10}
                defaultPageSize={10}
                minRows={0}
                data={release.tasks}
                sortable
                columns={[
                    {
                        Header: 'НОМЕР',
                        accessor: 'taskNumber',
                        resizable: false,
                        minWidth: 150,
                    },
                    {
                        Header: 'АВТОР',
                        accessor: 'author',
                        resizable: false,
                        minWidth: 120,
                    },
                    {
                        Header: 'ТЕМА',
                        accessor: 'topic',
                        resizable: false,
                        minWidth: 150,
                    },

                ]}
            />
        : <ListRow labelText="ЗАМЕЧАНИЯ ОТСУТСТВУЮТ" text="" />
      }





        <ListSectionHeader>
            <ListSectionHeaderTitle>ДОКУМЕНТЫ</ListSectionHeaderTitle>
        </ListSectionHeader>
        {get(release, "documents", []).length > 0 ? (
            <TableStyled width="100%">
                <THead>
                    <TR compact>
                        <TH>ТИП</TH>
                        <TH>ФАЙЛ</TH>
                        <TH>ПОДПИСЬ</TH>
                        <TH>КОММЕНТАРИЙ</TH>
                    </TR>
                </THead>
                <TBody>
                    {release.documents.map(doc => (
                        <TR key={doc.id} compact>
                            <TD>{get(doc, "documentType.name", "-")}</TD>
                            <TD>
                                <LinkSpan
                                    onClick={() => {
                                        onDownloadDocument(doc.id);
                                    }}
                                >
                                    {doc.fileOriginalName}
                                </LinkSpan>
                            </TD>
                            <TD>
                                {doc.signature ? (
                                    <LinkSpan
                                        onClick={() => {
                                            onViewSignedDocument(doc.id);
                                        }}
                                    >
                                        Подписан
                                    </LinkSpan>
                                ) : (
                                    "-"
                                )}
                            </TD>
                            <TD>{doc.comment || "-"}</TD>
                        </TR>
                    ))}
                </TBody>
            </TableStyled>
        ) : (
            <ListRow labelText="ДОКУМЕНТЫ ОТСУТСТВУЮТ" text="" />
        )}

      <ListSectionHeader>
        <ListSectionHeaderTitle>ОБЩАЯ ИНФОРМАЦИЯ</ListSectionHeaderTitle>
      </ListSectionHeader>
      <ListRow labelText="НАИМЕНОВАНИЕ" text={get(release, "name", "-")} />
      <ListRow
        labelText="СРЕДА"
        text={
          get(release, "developmentEnvironments", [])
            .map(item => item.name)
            .join(", ") || "-"
        }
      />
      <ListRow
        labelText="КОМПОНЕНТЫ"
        text={
          get(release, "additionalComponents", [])
            .map(item => item.name)
            .join(", ") || "-"
        }
      />
      <ListRow labelText="КОММЕНТАРИЙ" text={get(release, "comment", "-")} />
      <ListSectionHeader>
        <ListSectionHeaderTitle>СВЯЗИ С ВНЕШНИМИ ИУС</ListSectionHeaderTitle>
      </ListSectionHeader>
      {get(release, "externalSystems", []).length > 0 ? (
        release.externalSystems.map(sys => (
          <ListRowWrapper key={sys.id}>
            <LinkSpan
              onClick={() => {
                onViewExternalSystem(sys);
              }}
            >
              {sys.name || "-"}
            </LinkSpan>
          </ListRowWrapper>
        ))
      ) : (
        <ListRow labelText="СВЯЗИ ОТСУТСТВУЮТ" text="" />
      )}

      {/* закоментили так как непонятно верно или нет задача */}
      {/* <ListSectionHeader>
        <ListSectionHeaderTitle>
          <LinkSpan
            onClick={() => {
              window.open(`${projectGitlabPath}`, "_blank");
            }}
          >
            РЕПОЗИТОРИИ
          </LinkSpan>
        </ListSectionHeaderTitle>
      </ListSectionHeader> */}

      { false &&  get(release, "tags", []).length > 0 ? (
        <TableStyled width="100%">
          <THead>
            <TR compact>
              <TH>РЕПОЗИТОРИЙ</TH>
              <TH>ТЕГ</TH>
              <TH>СБОРКА</TH>
              <TH>АНАЛИЗ</TH>
              <TH>АРТЕФАКТЫ</TH>
            </TR>
          </THead>
          <TBody>
            {release.tags.map(tag => {
              const repoName = get(tag, "repository.name", "");
              const formattedRepoName = repoName.split(" ").join("-");
              const repoPath = projectGitlabPath + formattedRepoName;

              const buildSuccess = get(tag, "buildResult.success", -1);
              const pipelineId = get(tag, "buildResult.gitlabId", "");
              const pipelinePath = `${repoPath}/pipelines/${pipelineId}`;

              const analysisPassed = get(tag, "analysisResult.passed", -1);
              const repoSonar = get(tag, "repository.sonar", "");

              // #51569
              /* const artifactsStorage =
                get(tag, "repository.repositoryType") ||
                ARTIFACTS_STORAGE.GITLAB;
              const additionalPath =
                get(tag, "repository.additionalPath") || "";
              let artifactsPath = `${
                artifactsStorageBasePath[artifactsStorage]
              }${additionalPath}`;
              if (artifactsStorage === ARTIFACTS_STORAGE.GITLAB) {
                artifactsPath = buildSuccess === -1 ? "" : pipelinePath;
              } */
              const artifactsPath = buildSuccess === -1 ? "" : pipelinePath;

              return (
                <TR key={tag.id} compact>
                  <TD>
                    <LinkSpan
                      onClick={() => {
                        window.open(repoPath, "_blank");
                      }}
                    >
                      {get(tag, "repository.name", "-")}
                    </LinkSpan>
                  </TD>
                  <TD>
                    <LinkSpan
                      onClick={() => {
                        window.open(`${repoPath}/tags/${tag.name}`, "_blank");
                      }}
                    >
                      {tag.name || "-"}
                    </LinkSpan>
                  </TD>
                  <TD>
                    {buildSuccess !== -1 ? (
                      <LinkSpan
                        onClick={() => {
                          if (pipelineId) {
                            window.open(pipelinePath, "_blank");
                          }
                        }}
                      >
                        {buildSuccess ? "passed" : "failed"}
                      </LinkSpan>
                    ) : (
                      "Нет данных"
                    )}
                  </TD>
                  <TD>
                    {analysisPassed !== -1
                      ? (SONAR_URL && (
                          <LinkSpan
                            onClick={() => {
                              window.open(`${SONAR_URL}${repoSonar}`, "_blank");
                            }}
                          >
                            {analysisPassed ? "passed" : "failed"}
                          </LinkSpan>
                        )) ||
                        (analysisPassed ? "passed" : "failed")
                      : "Нет данных"}
                  </TD>
                  <TD>
                    {artifactsPath ? (
                      <LinkSpan
                        onClick={() => {
                          window.open(artifactsPath, "_blank");
                        }}
                      >
                        Артефакты сборки
                      </LinkSpan>
                    ) : (
                      "Нет данных"
                    )}
                  </TD>
                </TR>
              );
            })}
          </TBody>
        </TableStyled>
      ) : (
       false ? <ListRow labelText="ТЕГИ ОТСУТСТВУЮТ" text="" /> : <></>
      )}
       {/* пока скрыли часть пока не ясна полностью задача */}
      
      <ListSectionHeader>
        <ListSectionHeaderTitle>
          ТИПОВЫЕ ТЕХНИЧЕСКИЕ РЕШЕНИЯ
        </ListSectionHeaderTitle>
      </ListSectionHeader>
      {get(release, "technicalSolutions", []).length > 0 ? (
        <TableStyled width="100%">
          <THead>
            <TR compact>
              <TH>ДОКУМЕНТ</TH>
              <TH>ОПИСАНИЕ</TH>
              <TH>КОММЕНТАРИЙ</TH>
            </TR>
          </THead>
          <TBody>
            {release.technicalSolutions.map(solution => (
              <TR key={solution.id} compact>
                <TD>
                  <LinkSpan
                    onClick={() => {
                      onDownloadDocument(get(solution, "document.id", 0));
                    }}
                  >
                    {get(solution, "document.fileOriginalName", "-")}
                  </LinkSpan>
                </TD>
                <TD>{get(solution, "document.comment", "-")}</TD>
                <TD>{solution.comment || "-"}</TD>
              </TR>
            ))}
          </TBody>
        </TableStyled>
      ) : (
        <ListRow labelText="ТЕХНИЧЕСКИЕ РЕШЕНИЯ ОТСУТСТВУЮТ" text="" />
      )}
    </ListContainer>
  );
}

ReleaseSubComponent.propTypes = {
  release: releaseType.isRequired,
  project: projectType.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onViewSignedDocument: PropTypes.func.isRequired,
  onViewExternalSystem: PropTypes.func.isRequired
};

export default ReleaseSubComponent;
