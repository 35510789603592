import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { push } from "connected-react-router/immutable";
import { projectType, userType } from "../../types";
import ProjectView from "../../components/ProjectView";
import { fetchProjectById, deleteProject, restoreProject } from "../../store/project/actions";
import { showModal } from "../../store/modal/actions";
import {
  MODAL_TYPE_CONFIRMATION_DIALOG,
  MODAL_TYPE_DECLINE_RELEASE,
  MODAL_TYPE_VIEW_EXTERNAL_SYSTEM
} from "../../store/modal/constants";
import { fetchPipelines } from "../../store/pipeline/actions";
import {
  deleteRelease,
  changeReleaseStatus
} from "../../store/releases/actions";
import { downloadDocument, signDocument, downloadGeneratedReport } from "../../store/documents/actions";
import {makeSelectProject, makeSelectTotalPages} from "../../store/project/selectors";
import { makeSelectUser } from "../../store/auth/selectors";
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";
import { makeSelectPipelines } from "../../store/pipeline/selectors";


class ProjectViewPage extends Component {
  state = {
    pageNumber: 0,
    pageSize: 10,
    id: 0
  }

  componentDidMount() {
    const {
      fetchProject,
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({
      id
    })
    fetchProject(id);
  }

  componentDidUpdate(prevProps) {
    const prevProjectId = prevProps.match.params.id;
    const newProjectId = this.props.match.params.id;
    if (newProjectId !== prevProjectId) {
      this.props.fetchProject(newProjectId);
    }
  }

  handleInputPage = page => {
    this.setState({
      pageNumber: page
    })

    this.props.fetchPipelines(this.state.id, page, this.state.pageSize);
  }

  handlePageSize = size => {
    this.setState({
      pageNumber: 0,
      pageSize: size
    })

    this.props.fetchPipelines(this.state.id, 0, size);
  }

  handleDeleteProject = () => {
    const { onShowModal, onDeleteProject, project } = this.props;

    onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
      contentLabel: "Delete project confirmation",
      message: "Вы уверены, что хотите удалить проект?",
      onConfirm: () => {
        onDeleteProject(project.id);
      }
    });
  };

  
  handleRestoreProject = () => {
    const { onShowModal, onRestoreProject, project } = this.props;

    onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
      contentLabel: "Restore project confirmation",
      message: "Вы уверены, что хотите востановить проект?",
      onConfirm: () => {
        onRestoreProject(project.id);
      }
    });
  };

  handleDeleteRelease = releaseId => {
    const { onShowModal, onDeleteRelease, project } = this.props;

    onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
      contentLabel: "Delete release confirmation",
      message: "Вы уверены, что хотите удалить релиз?",
      onConfirm: () => {
        onDeleteRelease(releaseId, project.id);
      }
    });
  };

  handleDeclineRelease = releaseId => {
    const { onShowModal, onChangeReleaseStatus, project } = this.props;

    onShowModal(MODAL_TYPE_DECLINE_RELEASE, {
      contentLabel: "Decline release confirmation",
      onChangeReleaseStatus,
      releaseId,
      projectId: project.id
    });
  };

  handleViewExternalSystem = externalSystem => {
    const { onShowModal, onDownloadDocument } = this.props;

    onShowModal(MODAL_TYPE_VIEW_EXTERNAL_SYSTEM, {
      contentLabel: "External system view",
      externalSystem,
      onDownloadDocument
    });
  };

  handleChangeReleaseStatus = (releaseId, status, comment) => {
    const { project, onChangeReleaseStatus, onShowModal } = this.props;

    if (status === "ready" && !project.signReleaseInventory) {
      onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
        contentLabel: "Change release status confirmation",
        message: "Подтверждаете готовность релиза к отправке?",
        onConfirm: () => {
          onChangeReleaseStatus(
            releaseId,
            status,
            comment,
            project.signReleaseInventory,
            project.id
          );
        }
      });
    } else {
      onChangeReleaseStatus(
        releaseId,
        status,
        comment,
        project.signReleaseInventory,
        project.id
      );
    }
  };

  render() {
    const {
      project,
      onDownloadDocument,
      onViewSignedDocument,

      pushToReleaseEditor,
      pushToNewReleaseEditor,
      pushToProjectEditor,

      downloadGeneratedReport: onDownloadGeneratedReport,
      loaders,
      user,
      pipelines,
    } = this.props;

    return (
      <ProjectView
        handleInputPage={this.handleInputPage}
        handlePageSize={this.handlePageSize}

        pageNumber={this.state.pageNumber}
        pageSize={this.state.pageSize}
        totalPages={this.props.totalPages}
        onRestoreProject={this.handleRestoreProject}
        onDownloadGeneratedReport={onDownloadGeneratedReport}
        project={project}
        pipelines={pipelines}
        onEditProject={pushToProjectEditor}
        onDeleteProject={this.handleDeleteProject}
        onEditRelease={pushToReleaseEditor}
        onCreateRelease={pushToNewReleaseEditor}
        onDeleteRelease={this.handleDeleteRelease}
        onChangeReleaseStatus={this.handleChangeReleaseStatus}
        onDeclineRelease={this.handleDeclineRelease}
        onDownloadDocument={onDownloadDocument}
        onViewSignedDocument={onViewSignedDocument}
        onViewExternalSystem={this.handleViewExternalSystem}
        loaders={loaders}
        user={user}
      />
    );
  }
}

const mapStateToProps = state => ({
  pipelines: makeSelectPipelines(state),
  project: makeSelectProject(state),
  user: makeSelectUser(state),
  loaders: makeSelectLocalLoaders(state),
  totalPages: makeSelectTotalPages(state),
});

const mapDispatchToProps = dispatch => ({
  downloadGeneratedReport: (templateReportDocumentId, projectId, dateBegin, dateEnd, releaseId, title) => dispatch(downloadGeneratedReport(templateReportDocumentId, projectId, dateBegin, dateEnd, releaseId, title)),
  fetchPipelines: (id, pageNumber, pageSize) => dispatch(fetchPipelines(id, pageNumber, pageSize)),

  fetchProject: id => dispatch(fetchProjectById(id)),
  onDeleteProject: id => dispatch(deleteProject(id)),
  onDeleteRelease: (releaseId, projectId) => dispatch(deleteRelease(releaseId, projectId)),
  onChangeReleaseStatus: (releaseId, status, comment, hasToSign, projectId) => dispatch(changeReleaseStatus(releaseId, status, comment, hasToSign, projectId)),
  onDownloadDocument: id => dispatch(downloadDocument(id)),
  onViewSignedDocument: id => dispatch(signDocument(false, id)),
  onShowModal: (modalType, props) => dispatch(showModal(modalType, props)),

  onRestoreProject:  id => dispatch(restoreProject(id)),

  pushToProjectEditor: id => dispatch(push(`/project/editor/${id}`)),
  pushToReleaseEditor: (projectId, glGroup, releaseId) =>
    dispatch(
      push(`/project/${projectId}/${glGroup}/release/editor/${releaseId}`)
    ),
  pushToNewReleaseEditor: (projectId, glGroup) =>
    dispatch(push(`/project/${projectId}/${glGroup}/release/editor/new`))
});

ProjectViewPage.propTypes = {
  project: projectType.isRequired,

  // pipelines: projectType.isRequired,
  totalPages: PropTypes.number.isRequired,
  fetchPipelines: PropTypes.func.isRequired,
  downloadGeneratedReport: PropTypes.func.isRequired,

  fetchProject: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  onDeleteRelease: PropTypes.func.isRequired,
  onChangeReleaseStatus: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onViewSignedDocument: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,

  pushToProjectEditor: PropTypes.func.isRequired,
  pushToReleaseEditor: PropTypes.func.isRequired,
  pushToNewReleaseEditor: PropTypes.func.isRequired,

  match: PropTypes.shape().isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
  user: userType.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectViewPage);
