import React from "react";
import HeaderContainer from "../Header";
import PreloaderContainer from "../Preloader";
import Routes from "../../routes";
import Footer from "../../components/Footer";
import ModalContainer from "../Modal";
import { AppWrapper, ContentWrapper, Content } from "./styles";
import { GlobalStyle } from "../../utils/theme";

export default function App() {
  return (
    <AppWrapper>
      <GlobalStyle />
      <PreloaderContainer />
      <HeaderContainer />
      <ContentWrapper>
        <Content>
          <Routes />
        </Content>
      </ContentWrapper>
      <Footer />
      <ModalContainer />
    </AppWrapper>
  );
}
