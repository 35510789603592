import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  getEvents: params =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/audit/info`,
      params
    })
};
