import PropTypes from "prop-types";
import React, {Component} from "react";

export class MultiSelectCustom extends Component {
    state = {
        isOpen: false,
        searchText: ''
    }

    clear(){
       this.handleClick(null)
    }

    toggleOpen() {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            isOpen: !this.state.isOpen
        });


        if(!this.state.isOpen){
            this.setSearchText('');
        }
    }

    setSearchText(text) {
        this.setState({
            searchText: text
        });
    }

    isSelect(id) {
        if(!this.props.isMultiSelect) {
            return  this.props.selected ? Number(this.props.selected) === Number(id) : false;
        }

        return this.props.selected ? this.props.selected.includes(Number(id)) : false;
    }

    handleClick(id) {
        this.props.onChange(id);
        if(!this.props.isMultiSelect) {this.toggleOpen()}
    }

    closeList() {
        if (this.state.isOpen) this.toggleOpen();
    }

    getElementById(id) {

        const find = this.props.element.find(el => el.id === id);
        if (!find) return null;
        return find;
    }

    render() {
        let listShow = this.props.element;
        const width = this.props.width;
        const styleIsSearch = this.props.styleIsSearch


        const {searchText} = this.state;
        if (listShow && listShow.length > 0 && searchText) {
            listShow = listShow.filter(el => {
                return el.name.toLowerCase().includes(searchText.toLowerCase())
            })
        }

        const list = listShow.map(el => (
            <div key={el.id}
                 onClick={() => this.handleClick(el.id)}
                 className={`multiSelectCustom ${this.isSelect(el.id) ? 'isSelectElement' : ''}`}>
                {el.name}
            </div>))

        const selected =
            this.props.selected && this.props.selected.length > 0 ?
                `Выбрано элементов: ${this.props.selected.length}` :
                this.props.selected && !this.props.isMultiSelect ?  this.getElementById(this.props.selected).name:'Выбери'

       const hasAnySelected =   this.props.selected && !this.props.isMultiSelect

        return (
            <div className={ `multiSelectCustomWrapper ${styleIsSearch? 'searchSelector' : ''}`  }   onMouseLeave={() => this.closeList()} style={{width}}  >
                <div className='selectInput'>
                    {this.state.isOpen ?
                        <div className="inputSearchBlock">
                            <input type='text'
                                   value={this.state.searchText}
                                   onChange={(e) => this.setSearchText(e.target.value)}/>
                            <button onClick={() => this.toggleOpen()} type='button' className='closeButton'>X</button>
                        </div>
                        : <div onClick={() => this.toggleOpen()} className="resultDepartment"  >{selected}
                            {hasAnySelected && <button onClick={() => this.clear()} type='button' className='closeButton'>X</button>}
                        </div>}
                </div>
                {this.state.isOpen &&
                    <div className='multiSelectCustomList'>
                        {list}
                    </div>}
            </div>
        )
    }
}

MultiSelectCustom.defaultProps = {
    isMultiSelect: true,
    width: '100%',
    styleIsSearch:false
};
MultiSelectCustom.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    selected: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    element: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isMultiSelect: PropTypes.bool,
    width: PropTypes.string,
    styleIsSearch: PropTypes.bool,
}