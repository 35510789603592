import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex, BoldSpan } from "../../../common/styles";
import { ButtonAdd, ButtonIconClose } from "../../../common/Buttons";

class FileUploader extends Component {
  triggerInputFile = () => this.fileInput.click();

  handleFileClear = () => {
    this.props.onChange(null);
    this.fileInput.value = "";
  };

  render() {
    const { selectedFile, onChange } = this.props;

    return (
      <div>
        {selectedFile === null ? (
          <ButtonAdd text="Загрузить" onClick={this.triggerInputFile} />
        ) : (
          <Flex margin="10px 0">
            <BoldSpan>{`ФАЙЛ ДОБАВЛЕН - ${selectedFile.name ||
              selectedFile.fileOriginalName ||
              "-"}`}</BoldSpan>
            <ButtonIconClose onClick={this.handleFileClear} />
          </Flex>
        )}
        <input
          ref={fileInput => {
            this.fileInput = fileInput;
          }}
          name="file"
          type="file"
          onChange={event => {
            onChange(event.target.files[0]);
          }}
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

FileUploader.propTypes = {
  selectedFile: PropTypes.shape(),
  onChange: PropTypes.func.isRequired
};

FileUploader.defaultProps = {
  selectedFile: null
};

export default FileUploader;
