import get from "lodash.get";
import { generateId , getTempData } from "../../utils/helpers";
import { GL_HOST, KEY_TEMP_DATA } from "../../utils/constants";

function processGroupTags(groupTags, releaseTags, form) {
  groupTags.forEach(repo => {
    if ((repo.tags || []).length > 0) {
      const repoReleaseTag = releaseTags ? releaseTags.find(
        tag => get(tag, "repository.gitLabId", -1) === repo.gitLabId
      ) : undefined;
      /* eslint-disable no-param-reassign */
      // indicates if repo is included in release
      form[`repo_included_${repo.gitLabId}`] = repoReleaseTag !== undefined;
      // ideally there will be no empty value
      form[`tag_repo_${repo.gitLabId}`] =
        repoReleaseTag !== undefined
          ? repoReleaseTag.gitlabId
          : get(repo, "tags[0].gitLabId", "");

      // #51569
      /* form[`repo_artifacts_storage_${repo.gitLabId}`] =
        repo.repositoryType || ARTIFACTS_STORAGE.GITLAB;
      form[`repo_nexus_additionalPath_${repo.gitLabId}`] =
        repo.additionalPath || ""; */
      /* eslint-enable */
    }
  });
}

export function initializeFormValues({
  isNew,
  release,
  project,
  releaseDictionaries,
  groupTags
}) {
  const form = {};

  console.log('initializeFormValues',form,release,isNew)


  if (isNew) {

   

    // new release has to inherit properties from project's previous release
    form.releaseName = "";
    form.platformId = get(project, "lastRelease.platform.id", "");
    form.devEnvId = get(project, "lastRelease.developmentEnvironments", []).map(
      devEnv => devEnv.id
    );
    form.databaseId = get(project, "lastRelease.database.id", "");
    form.componentsId =get(
        project,
        "lastRelease.additionalComponents",
        []
    )? get(
      project,
      "lastRelease.additionalComponents",
      []
    ).map(comp => comp.id):[];
    form.comment = "";

    // initializing form fields only for repositories with tags
    // "tag_repo_" prefix is used later to extract repo.gitLabId
    const lastReleaseTags = get(project, "lastRelease.tags", []);
    if (groupTags) {
      processGroupTags(groupTags, lastReleaseTags, form);
    }

    // localId is used to handle local storage of added/deleted items that were not submitted yet
    const lastReleaseExternalSystems = get(
      project,
      "lastRelease.externalSystems",
      []
    );
    form.externalSystems = lastReleaseExternalSystems.map(system => {
      // eslint-disable-next-line no-param-reassign
      system.localId = generateId();
      return system;
    });

    const lastReleaseDocuments = get(project, "lastRelease.documents", []);
    form.documents = lastReleaseDocuments.map(doc => {
      // eslint-disable-next-line no-param-reassign
      doc.localId = generateId();
      return doc;
    });

    const lastReleaseTechnicalSolutions = get(
      project,
      "lastRelease.technicalSolutions",
      []
    );
    form.technicalSolutions = lastReleaseTechnicalSolutions.map(solution => {
      // eslint-disable-next-line no-param-reassign
      solution.localId = generateId();
      return solution;
    });

    form.tasks = [];
  } else {
    form.releaseName = release.name;
    form.platformId = get(release, "platform.id", "");
    form.devEnvId = get(release, "developmentEnvironments", []).map(
      devEnv => devEnv.id
    );
    form.databaseId = get(release, "database.id", "");
    form.componentsId = get(release, "additionalComponents", []).map(
      comp => comp.id
    );
    form.comment = release.comment;

    const releaseTags = get(release, "tags", []);
    if (groupTags) {
      processGroupTags(groupTags, releaseTags, form);
    }

    const releaseExternalSystems = get(release, "externalSystems", []);
    form.externalSystems = releaseExternalSystems.map(system => {
      // eslint-disable-next-line no-param-reassign
      system.localId = generateId();
      return system;
    });

    const releaseDocuments = get(release, "documents", []);
    form.documents = releaseDocuments.map(doc => {
      // eslint-disable-next-line no-param-reassign
      doc.localId = generateId();
      return doc;
    });

    const releaseTechnicalSolutions = get(release, "technicalSolutions", []);
    form.technicalSolutions = releaseTechnicalSolutions.map(solution => {
      // eslint-disable-next-line no-param-reassign
      solution.localId = generateId();
      return solution;
    });

    // needed to pass in onDeleteDocumentSign
    // forbidden to delete signature in release-new cos needs re-fetch after
    form.releaseId = release.id;

    form.tasks = release.tasks;
  }



  // used to navigate to project gitlab
  const gitlabGroupName = get(project, "gitlabGroup.name", "");
  form.projectGitlabPath = `${GL_HOST}/${gitlabGroupName}/`;
  form.projectRequiredDocuments = get(project, "requiredDocuments", []);

  // user to navigate in project tasks
  form.projectId = get(project, "id", null);

  // logic to handle different storage redirects
  // #51569
  /* form.artifactsStorageBasePath = {
    [ARTIFACTS_STORAGE.GITLAB]: form.projectGitlabPath || "",
    [ARTIFACTS_STORAGE.MAVEN]: (project && project.maven) || NEXUS_URL_MAVEN,
    [ARTIFACTS_STORAGE.NUGET]: (project && project.nuget) || NEXUS_URL_NUGET,
    [ARTIFACTS_STORAGE.NPM]: (project && project.npm) || NEXUS_URL_NPM,
    [ARTIFACTS_STORAGE.DOCKER]: (project && project.docker) || NEXUS_URL_DOCKER
  }; */

  // fallback for default values
  if (form.platformId === "")
    form.platformId = get(releaseDictionaries, "platform[0].id", "");
  if (form.databaseId === "")
    form.databaseId = get(releaseDictionaries, "database[0].id", "");


  //  пришлось так сделать, сори( 
  //  это пул временных данных в нем храним 
  //  временные данные так при запросе инфы о тегах все стирается 
  //  происходит инициализации и введенные данные теряются 
  //  исправить более грамотно не смог
  // eslint-disable-next-line no-underscore-dangle
  const _tempData = getTempData(KEY_TEMP_DATA.KEY_TEMP_RELEASE, false);
  // console.log("form", form, _tempData)
  if(isNew && _tempData )  {
    return _tempData
  }

  return form;
}

export function handleAddToLocalArrayField(newValue, fieldArray, arrayHelpers) {
  const valueToPush = newValue;
  const { localId } = valueToPush;

  const entryIndex = fieldArray.findIndex(entry => entry.localId === localId);
  if (entryIndex === -1) {
    arrayHelpers.push(valueToPush);
  } else {
    arrayHelpers.replace(entryIndex, valueToPush);
  }
}
