import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { BoldSpan, LinkSpan, TextRegular } from "../../common/styles";
import { gitlabOptionsType } from "../../../types";
import Pagination from "../../common/Pagination";
import Dropdown from "../../common/Dropdown";
import { DropdownMenuItem } from "../../common/Dropdown/styles";
import { IconPencil, IconTrash, IconClose } from "../../common/Icon";

const DocumentsTable = props => {
  const {
    documents,
    onDeleteDocument,
    onDownloadDocument,
    onSignDocument,
    onDeleteDocumentSign,
    releaseId,
    placeholderText
  } = props;

  return (
    <div>
      {documents.length > 0 ? (
        <ReactTable
          PaginationComponent={Pagination}
          showPagination={documents.length > 10}
          defaultPageSize={10}
          minRows={0}
          data={documents}
          columns={[
            {
              Header: "ТИП",
              id: "documentType",
              accessor: d => get(d, "documentType.name", "-")
            },
            {
              Header: "ФАЙЛ",
              id: "file",
              accessor: "id",
              style:{ background: "#f4f4f4", height: "36px" },
              Cell: row => {
                if (row.value !== 0) {
                  return (
                    <LinkSpan
                      onClick={() => {
                        onDownloadDocument(row.value);
                      }}
                    >
                      {row.original.fileOriginalName || "-"}
                    </LinkSpan>
                  );
                }
                return (
                  <TextRegular>
                    {row.original.fileOriginalName || "-"}
                  </TextRegular>
                );
              }
            },
            {
              Header: "ПОДПИСЬ",
              id: "signature",
              accessor: "id",
              Cell: row => {
                if (row.original.signature) {
                  return (
                    <LinkSpan
                      onClick={() => {
                        onSignDocument(false, row.value);
                      }}
                    >
                      Подписан
                    </LinkSpan>
                  );
                }
                return <TextRegular>-</TextRegular>;
              }
            },
            {
              Header: "КОММЕНТАРИЙ",
              id: "comment",
              style:{ background: "#f4f4f4", height: "36px" },
              accessor: d => get(d, "comment", "-")
            },
            {
              sortable: false,
              Header: "",
              id: "options",
              maxWidth: 60,
              style: { overflow: "visible", textAlign: "center" },
              accessor: "localId",
              Cell: row => (
                <Dropdown>
                  {row.original.id !== 0 &&
                    (row.original.signature ? (
                      releaseId !== -1 && (
                        <DropdownMenuItem
                          onClick={() => {
                            onDeleteDocumentSign(row.original.id, releaseId);
                          }}
                        >
                          <IconClose strokecolor="#1e96c8" />
                          Удалить подпись
                        </DropdownMenuItem>
                      )
                    ) : (
                      <DropdownMenuItem
                        onClick={() => {
                          onSignDocument(true, row.original.id, releaseId);
                        }}
                      >
                        <IconPencil />
                        Подписать
                      </DropdownMenuItem>
                    ))}
                  <DropdownMenuItem
                    onClick={() => {
                      onDeleteDocument(row.value);
                    }}
                  >
                    <IconTrash />
                    Удалить
                  </DropdownMenuItem>
                </Dropdown>
              )
            }
          ]}
        />
      ) : (
        <BoldSpan>{placeholderText}</BoldSpan>
      )}
    </div>
  );
};

DocumentsTable.propTypes = {
  documents: gitlabOptionsType.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onSignDocument: PropTypes.func.isRequired,
  onDeleteDocumentSign: PropTypes.func.isRequired,
  releaseId: PropTypes.number.isRequired,
  placeholderText: PropTypes.string.isRequired
};

export default DocumentsTable;
