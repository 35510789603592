import styled from "styled-components";

export const TableContainer = styled.div`
  margin-top: 20px;
`;

export const TableStyled = styled.table`
  width: ${props => props.width || "500px"};
  border-collapse: collapse;
  border: 1px solid #ededed;
`;

export const THead = styled.thead`
  background-color: #ffffff;
`;

export const TH = styled.th`
  padding: 0 0 0 25px;
  
  font-family: Roboto-Bold;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  text-align: left;
`;

export const TBody = styled.tbody``;

export const TR = styled.tr`
  height: 60px;
  background-color: #fcfcfd;
  border: 1px solid #ededed;
  
  text-align: left;
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 18px;
  color: #0b2846;
  
  ${props => props.compact && `
    height: 40px;
  `}
`;

export const TD = styled.td`
  padding: 0 0 0 25px;
`;