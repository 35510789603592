import React from "react";
import PropTypes, {number} from "prop-types";
import { Map } from "immutable";
import { ButtonAdd } from "../../common/Buttons";
import { Button } from "../../common/Buttons/styles";
import {
    Input,
    FilterSectionBlock,
    FilterSearchBlock
} from "../../common/styles";
import Checkbox from "../../common/Checkbox";
import RowBlock from "../../common/RowBlock";
import { SEARCH_PROJECTS } from "../../../store/project/constants";
import Loader from "../../common/Loader";
import SelectorDepartment from "../../ProjectEditor/SelectorDepartment";

function RegistryFilter(props) {
    const {
        isAdmin,
        searchQuery,
        onChangeQuery,
        inArchive,
        onChangeInArchive,
        onSearchProjects,
        onCreateProject,
        loaders,
        onClear,
        onSyncProjectInGigLab,

        departmentSelected,
        onSelectDepartment,

    } = props;

    return (
        <FilterSectionBlock>
            <FilterSearchBlock>
                <RowBlock
                    text="ПОИСК"
                    rowWidth="30%"
                    labelWidth="60px"
                    alignSelfCenter
                >
                    <Input
                        width="100%"
                        value={searchQuery}
                        onChange={onChangeQuery}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                onSearchProjects()
                            }
                        }}
                    />
                </RowBlock>
                <SelectorDepartment
                    styleIsSearch
                    code="departments"
                    onlySelector
                    isMultiSelect={false}
                    rowLabelWidth={'40%'}
                    selectWidth={'200px'}
                    fieldName="dictionaryEntry"
                    nameProperty="dictionaryEntry"
                    values={{dictionaryEntry:departmentSelected}}
                    handleChange={(val=>{
                        onSelectDepartment( val);
                    })}/>
                <div className="checkboxSearchArhive">
                    <Checkbox
                        key="inArchive"
                        id='inArchive'
                        checked={inArchive}
                        onChange={onChangeInArchive}
                        label='Архивные'
                    /></div>
                <Button margin="0 15px" onClick={onSearchProjects}>Искать</Button>
                <Button margin="0 15px" onClick={onClear}>Cброс</Button>
                <Button margin="0 15px" onClick={onSyncProjectInGigLab}>Синхронизация из гитлаб</Button>
                {loaders.has(SEARCH_PROJECTS) && <Loader size="40px"/>}
            </FilterSearchBlock>

            {isAdmin && (
                <ButtonAdd text="Добавить проект" onClick={onCreateProject}/>
            )}
        </FilterSectionBlock>
    );
}

RegistryFilter.propTypes = {
    inArchive: PropTypes.bool.isRequired,
    onChangeInArchive: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
    onSyncProjectInGigLab: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onSearchProjects: PropTypes.func.isRequired,
    onCreateProject: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    loaders: PropTypes.instanceOf(Map).isRequired,

    departmentSelected:number.isRequired,
    onSelectDepartment: PropTypes.func.isRequired,
};

export default RegistryFilter;
