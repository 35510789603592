import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
    downloadDocument: id =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/documents/${id}/download`,
      responseType: "blob"
    }),
    tasksToRelease: (id, tasks) =>
    axios({
      method: "POST",
      url: `${API_HOST}/api/fpa/v1/tasks/releases/${id}`,
        data: tasks
    }),
}
