import React from "react";
import PropTypes from "prop-types";
import { ErrorContainer, ErrorLabel } from "./styles";
import { ButtonIconClose } from "../../common/Buttons";

const TopLevelAlert = ({ error, onClickError }) => {
  if (error === "") return null;

  return (
    <ErrorContainer>
      <ErrorLabel>{error}</ErrorLabel>
      <ButtonIconClose strokecolor="white" onClick={onClickError} />
    </ErrorContainer>
  );
};

TopLevelAlert.propTypes = {
  error: PropTypes.string.isRequired,
  onClickError: PropTypes.func.isRequired
};

export default TopLevelAlert;
