import { fromJS } from "immutable";
import {
  PRELOADER_SET_LOADER,
  PRELOADER_REMOVE_LOADER,
  PRELOADER_SET_ERROR,
  PRELOADER_CLEAR_ERROR,
  PRELOADER_SET_SPECIFIC_ERROR,
  PRELOADER_CLEAR_SPECIFIC_ERROR
} from "./constants";

const initialState = fromJS({
  globalLoaders: {},
  localLoaders: {},
  error: "",
  specificError: ""
});

const preloader = (state = initialState, action) => {
  switch (action.type) {
    case PRELOADER_SET_LOADER: {
      const { loaderScope, loaderKey, message } = action.payload;
      return state.set(
        loaderScope,
        state.get(loaderScope).set(loaderKey, message)
      );
    }
    case PRELOADER_REMOVE_LOADER: {
      return state.set(
        action.payload.loaderScope,
        state.get(action.payload.loaderScope).delete(action.payload.loaderKey)
      );
    }
    case PRELOADER_SET_ERROR: {
      return state.set("error", action.payload.errorMessage);
    }
    case PRELOADER_CLEAR_ERROR: {
      return state.set("error", "");
    }
    case PRELOADER_SET_SPECIFIC_ERROR: {
      return state.set("specificError", action.payload.error);
    }
    case PRELOADER_CLEAR_SPECIFIC_ERROR: {
      return state.set("specificError", "");
    }
    default: {
      return state;
    }
  }
};

export default preloader;
