import { fromJS } from "immutable";
import { SET_PIPELINES } from "./actions";

const initialState = fromJS({
  pipelines: [],
});

const pipeline = (state = initialState, action) => {
  switch (action.type) {
    case SET_PIPELINES: {
      return state.set("pipelines", fromJS(action.payload.pipelines));
    }
    default: {
      return state;
    }
  }
};

export default pipeline;
