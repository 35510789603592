import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  getReports: () =>
    axios.get(`${API_HOST}/api/fpa/v1/reports/available`),

  getReportsByCode: (code, dataSend) =>
      axios.post(`${API_HOST}/api/fpa/v1/reports/${code}/data`, dataSend),

  downloadReportsByCode: (code, dataSend) =>
      axios({
        method: "POST",
        url: `${API_HOST}/api/fpa/v1/reports/${code}/download`,
        data: dataSend,
        responseType: "blob",
      })
};
