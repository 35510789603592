import React from "react";
import PropTypes from "prop-types";
import { ModalContainer, ModalBody, ButtonsRow } from "../Modal/styles";
import { SectionHeaderTitle } from "../styles";
import { Button } from "../Buttons/styles";

function ConfirmationDialog(props) {
  return (
    <ModalContainer>
      <ModalBody>
        <SectionHeaderTitle>{props.message}</SectionHeaderTitle>
        <ButtonsRow>
          <Button type="submit" onClick={() => {
            props.onConfirm();
            props.closeModal();
          }} margin="0 25px 0 0">
            Да
          </Button>
          <Button onClick={props.closeModal}>Отмена</Button>
        </ButtonsRow>
      </ModalBody>
    </ModalContainer>
  );
}

ConfirmationDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmationDialog;
