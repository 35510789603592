import React from "react";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle
} from "../../common/Modal/styles";
import UsersPermissionForm from "../UsersPermissionForm";

function UsersPermissionModal(props) {
  return (
    <ModalContainer>
      <ModalHeader>
        <ModalHeaderTitle>ДОБАВЛЕНИЕ ДОСТУПА</ModalHeaderTitle>
      </ModalHeader>
      <UsersPermissionForm
        onAddUser={props.onAddUser}
        closeModal={props.closeModal}
      />
    </ModalContainer>
  );
}

UsersPermissionModal.propTypes = {
  onAddUser: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default UsersPermissionModal;
