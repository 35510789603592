import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { projectsType, StageType, sortType } from "../../../types";
import PaginationApi from "../../common/PaginationApi";
import { getDateFormatted } from "../../../utils/helpers";
import PipelineStageView from "../../PipelineStageView";
import { LinkStyled } from "../../common/styles";

const RegistryTable = ({
  projects,
  totalPages,
  handleInputPage,
  handlePageSize,
  pageNumber,
  pageSize,
  handleSetSort,
  sorted,
  loaders
}) => (
  <ReactTable
    style={{borderCollapse: 'collapse'}}
    handleInputPage={handleInputPage}
    handlePageSize={handlePageSize}
    onSortedChange={handleSetSort}
    totalPages={totalPages}
    pageNumber={pageNumber}
    pageSize={pageSize}
    PaginationComponent={PaginationApi}
    showPagination={projects.length > 0}
    defaultPageSize={10}
    minRows={0}
    data={projects}
    defaultSortMethod={(a, b, d) => d ? -1 : 0}
    sorted={[sorted]}
    columns={[
      {
        Header: "№",
        id: "index",
        sortable: false,
        Cell: (row) => <span>{pageNumber * pageSize + row.index + 1}</span>,
        maxWidth: 30,
        
      },
      {
        Header: "",
        columns: [
          {
            Header: "КРАТКОЕ НАИМЕНОВАНИЕ",
            id: "NAME",
            accessor: "name",
            resizable: false,
            minWidth: 190,
            style:{ borderRight: "1px solid #555555" },
            Cell: (row) => (
              <LinkStyled to={`/project/${row.original.id}`}>
                {row.value}
              </LinkStyled>
            ),
          },
        ],
      },
      {
        Header: "ПРИНЯТЫЙ В СО РЕЛИЗ",
        columns: [
          {
            Header: "РЕЛИЗ",
            resizable: false,
            sortable: false,
            id: "lastAcceptedReleaseName",
            minWidth: 120,
            style:{ background: "#f4f4f4" },
            accessor: (d) => get(d, "lastAcceptedRelease.name", "-"),
          },
          // {
          //   Header: "ВЕРСИЯ",
          //   resizable: false,
          //   sortable: false,
          //   id: "lastAcceptedReleasePipelineName",
          //   minWidth: 70,
          //   accessor: (d) => {
          //     let res = ''
          //     if (d.lastRelease && d.lastRelease.tags) {
          //       const tempRes = []
          //       d.lastRelease.tags.forEach(item=>{
          //         tempRes.push(item.name)
          //       })
          //       res = tempRes.join(', ')
          //     }
          //     return  res || "-"
          //   }
          // },
          {
            Header: "ДАТА ПРИНЯТИЯ",
            resizable: false,
            id: "RELEASE_DATE",
            minWidth: 115,
            style:{ borderRight: "1px solid #555555", background: "#f4f4f4" },
            accessor: (d) =>
              (d.lastAcceptedRelease &&
                d.lastAcceptedRelease.lastStatusInfo &&
                d.lastAcceptedRelease.lastStatusInfo.creationDate &&
                getDateFormatted(
                  d.lastAcceptedRelease.lastStatusInfo.creationDate
                  
                )) || 
              "-",
          },
        ],
      },
      {
        Header: "ПОСЛЕДНЕЕ ОБНОВЛЕНИЕ ИК",
        columns: [
          {
            Header: "ВЕРСИЯ",
            resizable: false,
            sortable: false,
            id: "lastPipelineName",
            minWidth: 80,
            accessor: (d) => ((d.lastPipeline && d.lastPipeline.name) || "-"),
          },
          {
            Header: "ОБНОВЛЕНИЕ ИК",
            id: "COMMIT_DATE",
            resizable: false,
            minWidth: 115,
            style:{ background: "#f4f4f4" },
            accessor: (d) =>(d.projectDate &&
                      d.projectDate.commitDate  &&
                      getDateFormatted(d.projectDate.commitDate)) ||
                  "-"
          },
          {
            Header: "Запуск CI/CD",
            id: "PIPELINE_DATE",
            resizable: false,
            minWidth: 90,
            style:{ background: "#f4f4f4" },
            accessor: (d) =>
              (d.lastPipeline &&
                  d.lastPipeline.dateTime &&
                  d.lastPipeline.dateTime
                  !== '0001-01-01T00:00:00Z' &&
                  getDateFormatted(d.lastPipeline.dateTime)) ||
              "-",
          },
          {
            Header: "СБОРКА",
            minWidth: 100,
            sortable: false,
            Cell: (row) => (
              <>
                <PipelineStageView
                  project={row.original}
                  pipeline={row.original.lastPipeline}
                  stageType={StageType.BUILD}
                />
              </>
            ),
          },
          {
            Header: "ПРОВЕРКА КОДА",
            id: "lastPipelineCodeAnalyzeStatus",
            resizable: false,
            sortable: false,
            minWidth: 115,
            style:{ background: "#f4f4f4", minHeight: "36px" },
            Cell: (row) => (
              <>
                <PipelineStageView
                  project={row.original}
                  pipeline={row.original.lastPipeline}
                  stageType={StageType.CODE_ANALYZE}
                />
              </>
            ),
          },
          {
            Header: "ТЕСТИРОВАНИЕ",
            id: "lastPipelineAutomaticTest",
            sortable: false,
            resizable: false,
            minWidth: 110,
            style: { overflow: "visible", textAlign: "left"},
            Cell: (row) => (
              <>
                <PipelineStageView
                  project={row.original}
                  pipeline={row.original.lastPipeline}
                  stageType={StageType.TEST_AUTOMATIC}
                  loaders={loaders}
                />
              </>
            ),
          },
          {
            Header: "АВТОТЕСТЫ",
            id: "autoTestsResult",
            sortable: false,
            resizable: false,
            minWidth: 85,
            style:{ background: "#f4f4f4" },
            accessor: (d) =>
              (d.lastPipeline &&
                d.lastPipeline.autoTestsResult) ||
              "-",
          },
        ],
      },
    ]}
  />
);

RegistryTable.propTypes = {
  projects: projectsType.isRequired,
  handleInputPage: PropTypes.func.isRequired,
  handlePageSize: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleSetSort: PropTypes.func.isRequired,
  sorted: sortType.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
};

export default RegistryTable;
