import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import {BoldSpan, PageHeaderBlock, PageHeaderTitle, SubTitle} from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";
import {
    fetchUserByIdPermissionsAdministration
} from "../../../store/administration/actions";
import {
    makeSelectPermissionForUserAdministaration,
} from "../../../store/administration/selectors";
import {SelectorList} from "../../../components/common/SelectList/SelectorList";

import {adminstrationService} from "../../../Services/administration.service";
import Api from "../../../api";
import {Link} from "react-router-dom";
import {InfoBar} from "../../../components/InfoBar/InfoBar";

const NAME_FIELD = {
    projectSelect: 'projectSelect',
    permissionSelect: 'permissionSelect',
    dateSelect: 'dateSelect',
    userId: 'userId',
    modeIsAdd: 'modeIsAdd',
    modeIsEdit: 'modeIsEdit',
    editNow: 'editNow',
    nameUser: 'nameUser'
}

class AdminUserPage extends Component {
    state = {
        [NAME_FIELD.modeIsAdd]: false,
        allMembersInProject: [],
        [NAME_FIELD.userId]: 0,
        [NAME_FIELD.projectSelect]: null,
        [NAME_FIELD.permissionSelect]: null,
        [NAME_FIELD.dateSelect]: '',
        [NAME_FIELD.modeIsAdd]: false,
        [NAME_FIELD.editNow]: null,
        [NAME_FIELD.nameUser]:''
    }

    async loadDetailInfo(userId) {
        try {
            const res = await Api.administration.getShortUserById(userId);
            this.setState({
                [NAME_FIELD.nameUser]: res && res.data && res.data.fullName ?  res.data.fullName : ''
            });
        }  catch (e){
            console.log(e);
        }
    }
    async componentDidMount() {
        const {
            match: {
                params: {userId}
            },
            fetchUserByIdAdmin,
        } = this.props;
        this.setState((state) => ({...state,  [NAME_FIELD.userId]:userId}));
        fetchUserByIdAdmin(userId);
        await this.loadDetailInfo(userId);
    }

    render() {
        const { fetchUserByIdAdmin, permissionForUser } = this.props;
        const { userId } = this.state;
        const { fetchProject,
            fetchPermissions ,
            saveNewPermisionsUser,
            deletePermisions,
            parsePermissionForSelector,
            convertDateFromRow}  = adminstrationService;
        const customSetState = (field, value) => {
            this.setState(state=>({
                    ...state,
                    [field]: value,
                }));
        }
        const getValue = (field) => this.state[field]
        const modeIsAdd = getValue(NAME_FIELD.modeIsAdd);
        const modeIsEdit = getValue(NAME_FIELD.modeIsEdit);
        const updateData = () => {
            fetchUserByIdAdmin(userId);
        }
        const deletePermissonHandele = async (row) => {
            await deletePermisions(row);
            updateData();
        }
        const addNew = ()=>{
            customSetState(NAME_FIELD.permissionSelect, null);
            customSetState(NAME_FIELD.dateSelect, '');
            customSetState(NAME_FIELD.projectSelect, null )

            customSetState(NAME_FIELD.modeIsEdit, false);
            customSetState(NAME_FIELD.modeIsAdd, true);
        }
        const save = async ()=>{
            customSetState(NAME_FIELD.modeIsAdd, false);
            await  saveNewPermisionsUser(
                getValue(NAME_FIELD.projectSelect),
                getValue(NAME_FIELD.permissionSelect),
                getValue(NAME_FIELD.dateSelect),
                getValue(NAME_FIELD.userId),
                getValue(NAME_FIELD.modeIsEdit),
                getValue(NAME_FIELD.editNow)
            );
            customSetState(NAME_FIELD.editNow, false)
            updateData()
        }

        const setModeEdit = (row) => {
            customSetState(NAME_FIELD.modeIsEdit, true)
            customSetState(NAME_FIELD.modeIsAdd, true);
            customSetState(NAME_FIELD.permissionSelect,
                parsePermissionForSelector(row.permission))
            customSetState(NAME_FIELD.dateSelect,
                convertDateFromRow(row.expiresAt))
            customSetState(NAME_FIELD.editNow, row);
            customSetState(NAME_FIELD.projectSelect, row.projectSearchDTO);
        }

        const closeAddForm = ()=> {
            customSetState(NAME_FIELD.modeIsAdd, false);
        }

        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Администрирование пользователя <SubTitle>{getValue(NAME_FIELD.nameUser)}</SubTitle> </PageHeaderTitle>
                    <ButtonSimple text="Добавить" onClick={addNew}/>
                    <Link to={`/admin/users/`}><ButtonSimple text="назад"/></Link>
                </PageHeaderBlock>

                {modeIsAdd && <div className='blockAdd'>
                    <div className='blockAdd__title'>{getValue(NAME_FIELD.modeIsAdd) && !getValue(NAME_FIELD.modeIsEdit) ?'Создать новый доступ':'Редактировать'}</div>
                      <div className='element'>Проект
                        <SelectorList fetchData={fetchProject}
                                      labelKey="name"
                                      disabled={modeIsEdit}
                                      nowData={getValue(NAME_FIELD.projectSelect)}
                                      setValue={(val)=>customSetState(NAME_FIELD.projectSelect, val)}
                        />
                    </div>
                    <div className='element'><div className='elementHead'>Роль<InfoBar /></div>
                        <SelectorList
                            fetchData={fetchPermissions}
                            labelKey="label"
                            nowData={getValue(NAME_FIELD.permissionSelect)}
                            setValue={(val)=>customSetState(NAME_FIELD.permissionSelect, val)}
                        />
                    </div>
                    <div className='element'>Срок доступа
                        <div className='dateInputWrap'>
                            <input type='date'
                                   className='dataInput'
                                   value={getValue(NAME_FIELD.dateSelect)}
                                   onChange={(val)=>customSetState(NAME_FIELD.dateSelect, val.target.value)}
                            />
                        </div>
                    </div>
                    <div className='element'><br/>
                        <ButtonSimple text="Сохранить" onClick={save}/>
                    </div>
                    <div className='element'><br/>
                        <ButtonSimple text="Закрыть" onClick={closeAddForm}/>
                    </div>


                </div> }

                {permissionForUser.length > 0 ? (
                    <ReactTable
                        defaultPageSize={10}
                        data={permissionForUser}
                        minRows={1}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "Срок доступа",
                                accessor: "expiresAt"
                            },
                            {
                                Header: "Роль",
                                accessor: "permission"
                            },
                            {
                                Header: "Проект",
                                Cell: row => (<div>{row.original.projectSearchDTO.name}</div>)
                            },
                            {
                                Header: "Действия",
                                Cell: row => (<div>
                                    <ButtonSimple text="Удалить"
                                                  onClick={ () => deletePermissonHandele(row.original)} />
                                    <ButtonSimple text="Редактировать"
                                                  onClick={ () => setModeEdit(row.original)} />
                                </div>)
                            }
                        ]}
                    />
                ) : (
                    <BoldSpan>Нет данных </BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = state => ({
    permissionForUser: makeSelectPermissionForUserAdministaration(state)
});

const mapDispatchToProps = dispatch => ({
    fetchUserByIdAdmin: (id) => dispatch(fetchUserByIdPermissionsAdministration(id)),
});

AdminUserPage.propTypes = {
    fetchUserByIdAdmin: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    permissionForUser: PropTypes.array.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserPage);
