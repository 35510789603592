import { createGlobalStyle } from "styled-components";
import RobotoRegular from "../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../assets/fonts/Roboto-Bold.ttf";
import RobotoMedium from "../assets/fonts/Roboto-Medium.ttf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Roboto-Regular;
    src: url(${RobotoRegular});
  }
  @font-face {
    font-family: Roboto-Bold;
    src: url(${RobotoBold});
  }
  @font-face {
    font-family: Roboto-Medium;
    src: url(${RobotoMedium});
  }

  html, body, #root {
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
  }
`;

export const fpaTheme = {
  colors: {
    THEME_COLOR: "#69c3e8",
    DARK_COLOR: "#2598c6",
    GRAY_COLOR: "#A3AAB1"
  }
};

export const autosuggestTheme = {
  container: { position: "relative", marginRight: "10px" },
  input: {
    width: 300,
    height: 36,
    padding: "0 10px",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    border: "1px solid #999999",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  },
  inputFocused: { outline: "none" },
  inputOpen: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
  suggestionsContainer: { display: "none" },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 37,
    width: 320,
    border: "1px solid #999999",
    backgroundColor: "#fff",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2
  },
  suggestionsList: { margin: 0, padding: 0, listStyleType: "none" },
  suggestion: { cursor: "pointer", padding: "10px 10px" },
  suggestionHighlighted: { backgroundColor: "#ddd" }
};

export const modalStyles = {
  content: {
    position: "fixed",
    bottom: "auto",
    left: "50%",
    padding: "2rem",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "40rem",
    width: "80%",
    maxWidth: "1080px",
    minHeight: "10rem",
    maxHeight: "80%",
    overflow: "auto"
  }
};
