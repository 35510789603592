export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECTS_METRICK = "FETCH_PROJECTS_METRICK";
export const FETCH_ALL_PROJECTS = "FETCH_ALL_PROJECTS";
export const FETCH_PROJECTS_OPTIONS = "FETCH_PROJECTS_OPTIONS";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";
export const SEARCH_PROJECTS_METRICK = "SEARCH_PROJECTS_METRICK";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_ALL_PROJECTS = "SET_ALL_PROJECTS";
export const SET_PROJECTS_OPTIONS = "SET_PROJECTS_OPTIONS";
export const SET_PAGES = "SET_PAGES";
export const FETCH_PROJECT_BY_ID = "FETCH_PROJECT_BY_ID";
export const SET_PROJECT = "SET_PROJECT";
export const SUBMIT_PROJECT = "SUBMIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const SET_RELEASE = "SET_RELEASE";

export const GET_INFO_PAPELINE = "GET_INFO_PAPELINE";
export const SET_INFO_PAPELINE = "SET_INFO_PAPELINE";

export const SET_BACK_VERSION = "SET_BACK_VERSION";
export const SET_FRONT_VERSION = "SET_FRONT_VERSION";

export const RESTORE_PROJECT = "RESTORE_PROJECT";

export const SIMPLE_SEARCH_PROJECT = "SIMPLE_SEARCH_PROJECT";
export const SET_SIMPLE_PROJECT = "SET_SIMPLE_PROJECT";