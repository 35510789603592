import moment from "moment";
import Api from "../api";

 const fetchUser = async (search='')=>{
    try{
        const response=
            await Api.administration.getUsers(0,20, search);
        return  response.data.content
    } catch (e) {
        console.error(e)
    }
    return []
}

 const fetchProject = async (search='')=>{
    try{
        const response=
            await Api.administration.getProjectsByName(0,20,search);
        return  response.data.content
    } catch (e) {
        console.error(e)
    }
    return []
}


 const fetchPermissions = async ()=>{
    try{
        const res=   await Api.administration.getAllPermissions();
        const convert = res.data.map(el=>({
                value: el,
                label: el
            }))
        return  convert
    } catch (e) {
        console.error(e)
    }
    return []
}

const saveNewPermisions = async (user,
                                 permisions,
                                 data,
                                 projectId,
                                 isEdit =false,
                                 editRow=null ) => {

   let  sendParams =  {
       "expiresAt":  null, // "2023-12-31T23:59:59Z",
       "permission": null, // "USER",
       "projectSearchDTO": null,
       "userSearchDTO": null
   }

   if(isEdit) {
       sendParams = editRow;
       sendParams.expiresAt = data;
       sendParams.permission = permisions.value;
   }  else {
       sendParams.expiresAt = data;
       sendParams.permission = permisions.value;
       sendParams.userSearchDTO = user;
       sendParams.projectSearchDTO = {id: projectId}
   }
    try{
        const project = await Api.administration.getShortProjectById(projectId)
        sendParams.projectSearchDTO = project.data;
        await Api.administration.updateOrCreatePermissions([sendParams]);
    } catch (e) {
        console.error(e)
    }
}

const saveNewPermisionsUser = async (project, permisions, data, userId, isEdit =false , editRow=null ) => {
    let  sendParams =  {
        "expiresAt":  null, // "2023-12-31T23:59:59Z",
        "permission": null, // "USER",
        "projectSearchDTO": null,
        "userSearchDTO": null
    }

    if(isEdit) {
        sendParams = editRow;
        sendParams.expiresAt = data;
        sendParams.permission = permisions.value;
    }  else {
        sendParams.expiresAt = data;
        sendParams.permission = permisions.value;
        sendParams.userSearchDTO = {id: userId};
        sendParams.projectSearchDTO = project;
    }

    try{
        const user = await Api.administration.getShortUserById(userId)
        sendParams.userSearchDTO = user.data;
       await Api.administration.updateOrCreatePermissions([sendParams]);
    } catch (e) {
        console.error(e)
    }
}

const syncAllPermissions = async (projectId) =>{
    let result  =  {
        result: true,
        data: [],
        error: {}
    }
     try {
     const response = await Api.administration.syncPermissionsByProjectId(projectId);
     result.data = response && response.data? response.data : []
     } catch (error){
         result =  {
             result: false,
             data: [],
             error
         }
     }
    return result
}

const checkAllPermissions = async (projectId) =>{
    let result  =  {
        result: true,
        data: [],
        error: {}
    }
    try {
        const response =  await Api.administration.checkPermissionsByProjectId(projectId);
        result.data = response && response.data? response.data : []
    } catch (error){
        result =  {
            result: false,
            data: [],
            error
        }
    }
    return result
}


const deletePermisions = async (row) =>{
    try{
        await Api.administration.deletePermissions(row);
    } catch (e) {
        console.error(e)
    }
}

const syncProjectFromGit = async () =>{
    try{
        await Api.project.syncProjectFromGit();
    } catch (e) {
        console.error(e)
    }
}


const parsePermissionForSelector = (p)=> ({
        label: p,
        value: p
    })


const converPermisionForSave = (pl) => pl.value

const convertDateFromRow = (val)=> val? moment(val).format('YYYY-MM-DD'):''



export const adminstrationService = {
    fetchUser,
    fetchProject,
    fetchPermissions,
    saveNewPermisions,
    deletePermisions,
    parsePermissionForSelector,
    converPermisionForSave,
    convertDateFromRow,
    saveNewPermisionsUser,
    syncAllPermissions,
    checkAllPermissions,
    syncProjectFromGit
}
