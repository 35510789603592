import { all, call, takeLatest, put } from "redux-saga/effects";
import axios from "axios";
import { replace, push } from "connected-react-router/immutable";
import { setUser } from "../store/auth/actions";
import {
  GET_ACCESS_TOKEN,
  INITIAL_AUTH,
  LOGOUT
} from "../store/auth/constants";
import { setLoader, removeLoader, setError, errorParser } from "../store/preloader/actions";
import { GLOBAL_LOADER } from "../store/preloader/constants";
import Api from "../api";

export function* initialAuthSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, INITIAL_AUTH, "АВТОРИЗАЦИЯ"));
  try {
    const { accessToken } = action.payload;
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // console.error('demo tests')
    const response = yield call(Api.auth.getUser);
    // console.log(response)
    // const demo = {
    //   "admin": true,
    //   "distinguishedName": "string",
    //   "email": "string",
    //   "fullName": "string",
    //   "id": "string",
    //   "provider": "string",
    //   "username": "string"
    // }
    // yield put(setUser(demo));
    // debugger
    yield put(setUser(response.data));
    // debugger
    // yield put(replace("/"));
  } catch (error) {
    localStorage.removeItem("accessToken");
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("accessToken");
    } else {
      yield put(setError(`АВТОРИЗАЦИЯ - ${ errorParser(error) || error.message }`));
    }
    yield put(replace("/login"));
  }

  yield put(removeLoader(GLOBAL_LOADER, INITIAL_AUTH));
}
export function* initialAuthFlow() {
  yield takeLatest(INITIAL_AUTH, initialAuthSaga);
}

export function* getAccessTokenSaga(action) {
  yield put(setLoader(GLOBAL_LOADER, GET_ACCESS_TOKEN, "ПОЛУЧЕНИЕ ТОКЕНА"));

  try {
    const { authorizeCode } = action.payload;

    const response = yield call(Api.auth.getToken, authorizeCode);

    const accessToken = response.data.access_token;

    localStorage.setItem("accessToken", accessToken);

    yield put(replace("/"));
  } catch (error) {
    yield put(setError(`ПОЛУЧЕНИЕ ТОКЕНА - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, GET_ACCESS_TOKEN));
}
export function* getAccessTokenFlow() {
  yield takeLatest(GET_ACCESS_TOKEN, getAccessTokenSaga);
}

export function* logoutSaga() {
  yield put(setLoader(GLOBAL_LOADER, LOGOUT, "ВЫХОД"));
  try {
    yield put(push("/logout"));
  } catch (error) {
    yield put(setError(`ВЫХОД - ${ errorParser(error) || error.message }`));
  }

  yield put(removeLoader(GLOBAL_LOADER, LOGOUT));
}
export function* logoutFlow() {
  yield takeLatest(LOGOUT, logoutSaga);
}

export default function* auth() {
  yield all([initialAuthFlow(), getAccessTokenFlow(), logoutFlow()]);
}
