import React from "react";
import PropTypes from "prop-types";
import { IconCheckboxTrue, IconCheckboxFalse } from "../Icon";
import { CheckboxContainer, CheckboxLabel } from "./styles";

const Checkbox = ({ id, checked, onChange, label }) => (
  <CheckboxContainer>
    <label htmlFor={id}>
      <input
        type="checkbox"
        id={id}
        style={{ display: "none" }}
        checked={checked}
        onChange={onChange}
      />
      {checked ? <IconCheckboxTrue /> : <IconCheckboxFalse />}
    </label>
    {label && (
      <CheckboxLabel>{label}</CheckboxLabel>
    )}
  </CheckboxContainer>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
};

Checkbox.defaultProps = {
  label: ""
};

export default Checkbox;
