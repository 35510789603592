import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { reportsType } from "../../types";
import {
  fetchReports,
  uploadRepDocument,
  deleteRepDocument,
  downloadDocument,
  downloadReportExample
} from "../../store/documents/actions";
import { makeSelectReports } from "../../store/documents/selectors";
import Reports from "../../components/Reports";
import {MODAL_TYPE_CONFIRMATION_DIALOG} from "../../store/modal/constants";
import {showModal} from "../../store/modal/actions";

class ReportPage extends Component {
  componentDidMount() {
    this.props.fetchReports();
  }

  handleDelete = id => {
    const { onShowModal, deleteRepDocument } = this.props;

    onShowModal(MODAL_TYPE_CONFIRMATION_DIALOG, {
      contentLabel: "Delete report template confirmation",
      message: "Вы уверены, что хотите удалить шаблон отчета?",
      onConfirm: () => {
        deleteRepDocument(id);
      }
    });
  };

  render() {
    const {
      reports,
      uploadRepDocument: onUploadDocument,
      downloadDocument: onDownloadDocument,
      downloadReportExample: onDownloadReportExample,
    } = this.props;

    return (
      <Reports
        reports={reports}
        onUploadDocument={onUploadDocument}
        onDeleteDocument={this.handleDelete}
        onDownloadDocument={onDownloadDocument}
        onDownloadReportExample={onDownloadReportExample}
      />
    );
  }
}

const mapStateToProps = state => ({
  reports: makeSelectReports(state)
});

const mapDispatchToProps = dispatch => ({
  fetchReports: () => dispatch(fetchReports()),
  uploadRepDocument: form => dispatch(uploadRepDocument(form)),
  deleteRepDocument: id => dispatch(deleteRepDocument(id)),
  downloadReportExample: (id, title) => dispatch(downloadReportExample(id, title)),
  downloadDocument: (id, title) => dispatch(downloadDocument(id, title)),
  onShowModal: (modalType, props) => dispatch(showModal(modalType, props))
});

ReportPage.propTypes = {
  reports: reportsType.isRequired,
  fetchReports: PropTypes.func.isRequired,
  uploadRepDocument: PropTypes.func.isRequired,
  deleteRepDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  downloadReportExample: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPage);
