import React from "react";
import PropTypes from "prop-types";
import {
  ButtonIcon,
  ButtonIconOnly,
  ButtonIconGrey,
  ButtonIconRight
} from "./styles";
import {
  IconPlus,
  IconPencil,
  IconTrash,
  IconNext,
  IconPrevious,
  IconJumpForward,
  IconJumpBack,
  IconDots,
  IconSave,
  IconClose,
  IconOut,
  IconMail,
  IconSettings
} from "../Icon";

export const ButtonAdd = props => (
  <ButtonIcon {...props} hover>
    <IconPlus />
    {props.text}
  </ButtonIcon>
);

export const ButtonSimple = props => (
    <ButtonIcon {...props} hover>
        {props.text}
    </ButtonIcon>
);


ButtonAdd.propTypes = {
  text: PropTypes.string.isRequired
};

export const ButtonEdit = props => (
  <ButtonIcon {...props}>
    <IconPencil fill="#88a4bd" />
    {props.text}
  </ButtonIcon>
);

ButtonEdit.propTypes = {
  text: PropTypes.string.isRequired
};

export const ButtonDelete = props => (
  <ButtonIcon {...props}>
    <IconTrash fill="#88a4bd" />
    {props.text}
  </ButtonIcon>
);
export const ButtonIconEdit = props => (
  <ButtonIconOnly {...props}>
    <IconPencil fill="#88a4bd" />
    {props.text}
  </ButtonIconOnly>
);


ButtonDelete.propTypes = {
  text: PropTypes.string.isRequired
};

export const ButtonIconMail = ({ onClick, ...props }) => (
  <ButtonIconOnly onClick={onClick}>
    <IconMail {...props} />
  </ButtonIconOnly>
);

ButtonIconMail.propTypes = {
  onClick: PropTypes.func.isRequired
};

export const ButtonIconNext = props => (
  <ButtonIconOnly {...props}>
    <IconNext />
  </ButtonIconOnly>
);

export const ButtonIconPrevious = props => (
  <ButtonIconOnly {...props}>
    <IconPrevious />
  </ButtonIconOnly>
);

export const ButtonIconJumpForward = props => (
  <ButtonIconOnly {...props}>
    <IconJumpForward />
  </ButtonIconOnly>
);

export const ButtonIconJumpBack = props => (
  <ButtonIconOnly {...props}>
    <IconJumpBack />
  </ButtonIconOnly>
);

export const ButtonIconDots = props => (
  <ButtonIconOnly {...props}>
    <IconDots />
  </ButtonIconOnly>
);

export const ButtonIconClose = ({ onClick, ...props }) => (
  <ButtonIconOnly onClick={onClick}>
    <IconClose {...props} />
  </ButtonIconOnly>
);

ButtonIconClose.propTypes = {
  onClick: PropTypes.func.isRequired
};

export const ButtonIconTrash = props => (
  <ButtonIconOnly {...props}>
    <IconTrash fill="#88a4bd" />
  </ButtonIconOnly>
);

export const ButtonIconSettings = props => (
  <ButtonIconOnly {...props}>
    <IconSettings fill="#88a4bd" />
  </ButtonIconOnly>
);

export const ButtonSave = props => (
  <ButtonIconGrey {...props}>
    <IconSave />
    {props.text}
  </ButtonIconGrey>
);

ButtonSave.propTypes = {
  text: PropTypes.string.isRequired
};

export const ButtonOut = props => (
  <ButtonIconRight {...props}>
    {props.text}
    <IconOut />
  </ButtonIconRight>
);

ButtonOut.propTypes = {
  text: PropTypes.string.isRequired
};
