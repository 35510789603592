import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { notificationsType } from "../../types";
import {
  makeSelectNewNotificationsCount,
  makeSelectNewNotifications
} from "../../store/notifications/selectors";
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";
import {
  fetchNewNotificationsCount,
  fetchNewNotifications
} from "../../store/notifications/actions";
import NotificationsPopup from "../../components/NotificationsPopup";

class NotificationsPopupContainer extends Component {
  componentDidMount() {
    this.checkNewNotifications();
  }

  componentWillUnmount() {
    clearTimeout(this.checkNewNotifications);
  }

  checkNewNotifications = () => {
    this.props.onFetchNewNotificationsCount();

    setTimeout(this.checkNewNotifications, 60000);
  };

  render() {
    const {
      notificationsCount,
      newNotifications,
      onFetchNewNotifications,
      loaders
    } = this.props;

    return (
      <NotificationsPopup
        notificationsCount={notificationsCount}
        newNotifications={newNotifications}
        onFetchNewNotifications={onFetchNewNotifications}
        loaders={loaders}
      />
    );
  }
}

const mapStateToProps = state => ({
  notificationsCount: makeSelectNewNotificationsCount(state),
  newNotifications: makeSelectNewNotifications(state),
  loaders: makeSelectLocalLoaders(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchNewNotificationsCount: () => dispatch(fetchNewNotificationsCount()),
  onFetchNewNotifications: () => dispatch(fetchNewNotifications())
});

NotificationsPopupContainer.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  newNotifications: notificationsType.isRequired,
  onFetchNewNotificationsCount: PropTypes.func.isRequired,
  onFetchNewNotifications: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsPopupContainer);
