import styled, { keyframes } from "styled-components";
import { ReactComponent as icPlus } from "../../../assets/icons/ic_plus.svg";
import { ReactComponent as icSettings } from "../../../assets/icons/settings.svg";
import { ReactComponent as icTrash } from "../../../assets/icons/ic_trash.svg";
import { ReactComponent as icPencil } from "../../../assets/icons/ic_pencil.svg";
import { ReactComponent as icMail } from "../../../assets/icons/ic_mail.svg";
import { ReactComponent as icNext } from "../../../assets/icons/ic_next.svg";
import { ReactComponent as icPrevious } from "../../../assets/icons/ic_previous.svg";
import { ReactComponent as icJumpBack } from "../../../assets/icons/ic_jump_back.svg";
import { ReactComponent as icJumpForward } from "../../../assets/icons/ic_jump_forward.svg";
import { ReactComponent as icDots } from "../../../assets/icons/ic_dots.svg";
import { ReactComponent as icUser } from "../../../assets/icons/ic_user.svg";
import { ReactComponent as icSave } from "../../../assets/icons/ic_save.svg";
import { ReactComponent as icClose } from "../../../assets/icons/ic_close.svg";
import { ReactComponent as icOut } from "../../../assets/icons/ic_out.svg";
import { ReactComponent as icCheckboxTrue } from "../../../assets/icons/ic_checkbox_true.svg";
import { ReactComponent as icCheckboxFalse } from "../../../assets/icons/ic_checkbox_false.svg";
import { ReactComponent as icLogo } from "../../../assets/icons/ic_logo.svg";
import { ReactComponent as icSpinnerLine } from "../../../assets/icons/ic_spinner_line.svg";

export const IconPlus = styled(icPlus).attrs({
  className: "icon"
})`
  fill: none;
  stroke: #88a4bd;
  stroke-width: 2px;
  width: 14px;
  height: 18px;
`;

export const IconTrash = styled(icTrash).attrs({
  className: "icon"
})`
  fill: ${props => props.fill || "#1e96c8"}

  width: 16px;
  height: 18px;
`;


export const IconSettings = styled(icSettings).attrs({
  className: "icon"
})`
  fill: #fff;
  margin-right: 0px;
  width: 40px;
  height: 40px;
`;


export const IconMail = styled(icMail).attrs({
  className: "icon"
})`
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #1e96c8;
  }

  width: 18px;
  height: 14px;

  ${props =>
    props.notifications === "true" &&
    `
    .cls-2 {
      fill: #B22222;
    }
    
    width: 30px;
    height: 26px;
  `}
  ${props =>
    props.notifications === "false" &&
    `
    .cls-2 {
      fill: #999999;
    }
    
    width: 30px;
    height: 26px;
  `}
}`;

export const IconPencil = styled(icPencil).attrs({
  className: "icon"
})`
  fill: ${props => props.fill || "#1e96c8"}

  width: 18px;
  height: 18px;
`;

export const IconNext = styled(icNext).attrs({
  className: "icon"
})`
  fill: ${props => props.fill || "#88a4bd"}

  width: 7px;
  height: 14px;
`;

export const IconPrevious = styled(icPrevious).attrs({
  className: "icon"
})`
  fill: ${props => props.fill || "#88a4bd"}

  width: 7px;
  height: 14px;
`;

const styleIconJump = `
  .cls-1 {
    fill: #88a4bd;
  }
  .cls-2 {
    fill: none;
    stroke: #88a4bd;
  }

  width: 8px;
  height: 14px;
`;

export const IconJumpForward = styled(icJumpForward).attrs({
  className: "icon"
})`
  ${styleIconJump}
`;

export const IconJumpBack = styled(icJumpBack).attrs({
  className: "icon"
})`
  ${styleIconJump}
`;

export const IconDots = styled(icDots).attrs({
  className: "icon"
})`
  fill: #88a4bd;
  width: 15px;
  height: 3px;
`;

export const IconUser = styled(icUser).attrs({
  className: "icon"
})`
  .cls-1 {
    fill: none;
  }
  .cls-2,
  .cls-4 {
    fill: #fff;
  }
  .cls-3,
  .cls-4 {
    stroke: none;
  }

  width: 40px;
  height: 40px;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const IconSpinnerLine = styled(icSpinnerLine)`
  animation: ${spinAnimation} 2s linear infinite;
  fill: #244a97;
  position: absolute;
`;

export const IconLogo = styled(icLogo)`
  fill: #ae8f6e;
  position: absolute;
`;

export const IconSave = styled(icSave).attrs({
  className: "icon"
})`
  fill: #fff;

  width: 16px;
  height: 16px;
`;

export const IconClose = styled(icClose).attrs({
  className: "icon"
})`
  fill: none;
  stroke: ${props => props.strokecolor || `#88a4bd`};
  stroke-width: 2px;

  width: 15.6px;
  height: 15.6px;
`;

export const IconOut = styled(icOut).attrs({
  className: "icon"
})`
  .cls-1,
  .cls-2 {
    fill: none;
    stroke: #f5f5f5;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
  .cls-1 {
    stroke-linejoin: round;
  }

  width: 15px;
  height: 15px;
`;

export const IconCheckboxTrue = styled(icCheckboxTrue).attrs({
  className: "icon"
})`
  .cls-1 {
    fill: #1e96c8;
  }
  .cls-2 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const IconCheckboxFalse = styled(icCheckboxFalse).attrs({
  className: "icon"
})`
  .cls-1,
  .cls-3 {
    fill: none;
  }
  .cls-1 {
    stroke: #999;
    opacity: 0.25;
  }
  .cls-2 {
    stroke: none;
  }

  width: 24px;
  height: 24px;
  cursor: pointer;
`;
