import React, { Component } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import get from "lodash.get";
import ReactTable from "react-table";
import { connect } from "react-redux";

import checkboxHOC from "react-table/lib/hoc/selectTable";
import moment from "moment";
import LoadingWrapper from "../common/LoadingWrapper";
import { FETCH_PROJECT_BY_ID } from "../../store/project/constants";
import { projectType, StageType, userType } from "../../types";
import {
  BoldSpan,
  Flex,
  LinkSpan,
  PageHeaderBlock,
  PageHeaderTitle,
  SectionHeaderBlock,
  SectionHeaderTitle,
} from "../common/styles";

import {
  ButtonAdd,
  ButtonDelete,
  ButtonEdit,
  ButtonSave,
} from "../common/Buttons";
import { RowText } from "../common/RowBlock/styles";
import SectionBlock from "../common/SectionBlock";
import RowBlock from "../common/RowBlock";
import { GL_HOST } from "../../utils/constants";
import ReleasesTable from "./ReleasesTable";
import ExternalSystemsTable from "../ExternalSystemEditor/ExternalSystemsTable";
import TechSolutionsTable from "../TechSolutionsEditor/TechSolutionsTable";
import PaginationApi from "../common/PaginationApi";
import ModalReport from "./ModalReport";
import AddDocumentationModal from "./AddDocumentationModal";
import { getDateTimeFormatted } from "../../utils/helpers";
import PipelineStageView from "../PipelineStageView";

import {
  fetchReports,
  deleteReportFromProject,
  addDocumentation,
  downloadDocumentsZip,
  downloadDocument,
} from "../../store/documents/actions";
import { makeSelectReports } from "../../store/documents/selectors";
import InfoModal from "./InfoModal";
import ConfirmationDeleteModal from "./ConfirmationDeleteModal";

const CheckboxTable = checkboxHOC(ReactTable);

class ProjectView extends Component {
  state = {
    isDeclineReleaseOpen: false,
    declineReleaseId: 0,
    openModalReport: false,
    isExternalSystemViewOpen: false,
    externalSystem: {},
    selection: [],
    selectAll: false,
    isVisibleAddDocumentationModal: false,
    isVisibleInfoModal: false,
    selectedDocument: {},
    isVisibleConfirmationDeleteModal: false,
    currentRole: "",
  };

  componentDidMount() {
    this.props.fetchReports();
  }

  openModalReport = () => {
    this.setState({
      openModalReport: true,
    });
  };

  closeModalReport = () => {
    this.setState({
      openModalReport: false,
    });
  };

  openExternalSystemModal = (externalSystem) => {
    this.setState({
      isExternalSystemViewOpen: true,
      externalSystem,
    });
  };

  closeExternalSystemModal = () => {
    this.setState({
      isExternalSystemViewOpen: false,
      externalSystem: {},
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(row._id);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(row._id);
    }
    this.setState({ selection });
  };

  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => this.state.selection.includes(key);

  showAddDocumentationModal = () => {
    this.setState({
      isVisibleAddDocumentationModal: true,
    });
  };

  closeAddDocumentationModal = () => {
    this.setState({
      isVisibleAddDocumentationModal: false,
    });
  };

  handleDeleteProjects = () => {
    for (const documentId of this.state.selection) {
      this.props.deleteReportFromProject(documentId, this.props.project.id);
    }
  };

  handleShowConfirmationDeleteModal = () => {
    this.setState({
      isVisibleConfirmationDeleteModal: true,
    });
  };

  handleConfirmConfirmationDeleteModal = () => {
    this.setState({
      isVisibleConfirmationDeleteModal: false,
    });
    this.handleDeleteProjects();
  };

  handleCloseConfirmationDeleteModal = () => {
    this.setState({
      isVisibleConfirmationDeleteModal: false,
    });
  };

  handleAddDocumentation = (params) => {
    this.props.addDocumentation(this.props.project.id, params);
  };

  handleSaveDocumentation = () => {
    this.props.downloadDocumentsZip(
      this.props.project.id,
      this.state.selection,
      `FPA_${moment().format("DD.MM.YYYY_HH.mm")}.zip`
    );
  };

  handleSingleSaveDocumentation = (id) => {
    this.props.downloadDocument(
      id,
      `FPA_${moment().format("DD.MM.YYYY_HH.mm")}.zip`
    );
  };

  handleShowInfoModal = (cell) => {
    this.setState({
      isVisibleInfoModal: true,
    });
    this.setState({
      selectedDocument: cell.original,
    });
  };

  handleCloseInfoModal = () => {
    this.setState({
      isVisibleInfoModal: false,
    });
  };

  render() {
    const {
      onDownloadGeneratedReport,
      project,
      onEditProject,
      onDeleteProject,
      onEditRelease,
      onRestoreProject,
      onCreateRelease,
      onDeleteRelease,
      onChangeReleaseStatus,
      onDeclineRelease,
      onDownloadDocument,
      onViewSignedDocument,
      onViewExternalSystem,
      loaders,

      user,
      pipelines,
      totalPages,
      handleInputPage,
      handlePageSize,
      pageNumber,
      pageSize,
      reports,
    } = this.props;
    const isAdmin = user.admin;
    let hasAccess = isAdmin;
    // current user can be "USER" || "EXTERNAL_USER"
    // !isUser = isExternalUser
    let isUser = false;
    let currentRole = "";
    const userInPermissions = get(project, "userPermissions", []).find(
      (userWithAccess) => userWithAccess.user.id === user.id
    );
    if (userInPermissions !== undefined) {
      isUser = userInPermissions.permission === "USER";
      hasAccess = true;
      currentRole = userInPermissions.permission;
    }
    const { actions = [] } = userInPermissions || {};
    const canReleaseAdd = actions.find((el) => el === "releaseAdd");
    const canReportProject = actions.find((el) => el === "reportProject");
    const canEditProject = actions.find((el) => el === "editProject");
    const canRemoveProject = actions.find((el) => el === "removeProject");
    const gitlabGroupName = get(project, "gitlabGroup.name", "");
    const isDeleteProject = get(project, "isDeleted", false);

    const generalDocumentationData = [];
    for (const key in project.documents) {
      generalDocumentationData.push({
        _id: project.documents[key].id,
        // name: project.documents[key].documentType.name || "",
        name: project.documents[key].fileName,
        comment: project.documents[key].comment,
        date: project.documents[key].lastModified,
        check: false,
        creationDate: project.documents[key].audit.creationDate,
        author: project.documents[key].audit.creator.fullName,
      });
    }

    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    return (
      <LoadingWrapper
        loaders={loaders}
        loaderKey={FETCH_PROJECT_BY_ID}
        showPlaceholder={project.id === 0}
        placeholderText="ПРОЕКТ НЕ НАЙДЕН"
      >
        {hasAccess ? (
          <Flex flexDirection="column">
            <PageHeaderBlock>
              <div>
                <PageHeaderTitle>Данные об ИУС</PageHeaderTitle>
              </div>
              <Flex justifyContent="flex-end">
                {isDeleteProject && (
                  <ButtonAdd
                    text="Восстановить"
                    onClick={() => onRestoreProject(project.id)}
                  />
                )}
                {(isAdmin || canReportProject) && (
                  <ButtonAdd
                    text="Создать отчет"
                    onClick={this.openModalReport}
                  />
                )}
                {(isAdmin || canEditProject) && (
                  <ButtonEdit
                    text="Редактировать"
                    onClick={() => onEditProject(project.id)}
                  />
                )}
                {(isAdmin || canRemoveProject) && (
                  <ButtonDelete
                    text="Удалить"
                    onClick={() => onDeleteProject(project.id)}
                  />
                )}
              </Flex>
            </PageHeaderBlock>

            <ModalReport
              closeModalReport={this.closeModalReport}
              onDownloadGeneratedReport={onDownloadGeneratedReport}
              id={project.id}
              isOpen={this.state.openModalReport}
              reportTemplates={reports}
            />

            <AddDocumentationModal
              isVisible={this.state.isVisibleAddDocumentationModal}
              handleClose={this.closeAddDocumentationModal}
              handleSubmit={this.handleAddDocumentation}
            />

            <InfoModal
              isVisible={this.state.isVisibleInfoModal}
              values={this.state.selectedDocument}
              handleClose={this.handleCloseInfoModal}
            />

            {this.state.isVisibleConfirmationDeleteModal && (
              <ConfirmationDeleteModal
                handleConfirm={this.handleConfirmConfirmationDeleteModal}
                handleClose={this.handleCloseConfirmationDeleteModal}
              />
            )}

            <Flex justifyContent="flex-start">
              <SectionBlock text="ОБЩАЯ ИНФОРМАЦИЯ">
                <RowBlock labelWidth="200px" text="КРАТКОЕ НАИМЕНОВАНИЕ">
                  <RowText>{project && project.name ? project.name: ''}</RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="ПОЛНОЕ НАИМЕНОВАНИЕ">
                  <RowText>{project.fullName}</RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="ОТВЕТСТВЕННЫЙ ЗА ИК">
                  <RowText>{project && project.dictionaryEntry
                      ? project.dictionaryEntry.name : ''}</RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="КРАТКОЕ ОПИСАНИЕ">
                  <RowText>{project.description}</RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="ГРУППА В GITLAB">
                  <RowText>
                    <LinkSpan
                      onClick={() => {
                        window.open(`${GL_HOST}/${gitlabGroupName}`, "_blank");
                      }}
                    >
                      {gitlabGroupName}
                    </LinkSpan>
                  </RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="ПРОЕКТ В АСУЗ">
                  <RowText>
                    <LinkSpan
                      onClick={() => {
                        window.open(
                          `${project.referenceAsuz&& project.referenceAsuz.redirectUrl ? project.referenceAsuz.redirectUrl: ''}`,
                          "_blank"
                        );
                      }}
                    >
                      {project.referenceAsuz && project.referenceAsuz.name ? project.referenceAsuz.name: ''}
                    </LinkSpan>
                  </RowText>
                </RowBlock>
                {project.referenceSonarQube &&
                project.referenceSonarQube.length > 0 ? (
                  project.referenceSonarQube.map((elem) => (
                    <RowBlock labelWidth="200px" text="ПРОЕКТ в SONARQUBE">
                      <RowText>
                        <LinkSpan
                          onClick={() => {
                            window.open(`${elem.redirectUrl}`, "_blank");
                          }}
                        >
                          {elem.code ? elem.code : ""}
                        </LinkSpan>
                      </RowText>
                    </RowBlock>
                  ))
                ) : (
                  <></>
                )}
                <RowBlock labelWidth="200px" text="ПРОЕКТ В TESTLINK">
                  <RowText>
                    <LinkSpan
                      onClick={() => {
                        window.open(
                          `${project.referenceTestLink.redirectUrl}`,
                          "_blank"
                        );
                      }}
                    >
                      {project.referenceTestLink.code}
                    </LinkSpan>
                  </RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="ТЕСТОВЫЙ СТЕНД">
                  <RowText>
                    <LinkSpan
                      onClick={() => {
                        window.open(`${project.testStandUrl}`, "_blank");
                      }}
                    >
                      {project.testStandUrl}
                    </LinkSpan>
                  </RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="Паспорт СУУС ИТ">
                  <RowText>
                    <LinkSpan
                      onClick={() => {
                        window.open(`${project.passportCYYCIT}`, "_blank");
                      }}
                    >
                      {project.passportCYYCIT}
                    </LinkSpan>
                  </RowText>
                </RowBlock>
              </SectionBlock>

              <SectionBlock text="ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ">
                <RowBlock labelWidth="200px" text="ОСНОВНАЯ ПЛАТФОРМА">
                  <RowText>
                    {get(project, "lastRelease.platform.name", "-")}
                  </RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="СРЕДА РАЗРАБОТКИ">
                  <RowText>
                    { get(project, "lastRelease.developmentEnvironments", []) ? get(project, "lastRelease.developmentEnvironments", [])
                      .map((devEnv) => devEnv.name)
                      .join(", ") || "-": "-"}
                  </RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="ДОПОЛНИТЕЛЬНЫЕ КОМПОНЕНТЫ">
                  <RowText>
                    {get(project, "lastRelease.additionalComponents", []) ?
                        get(project, "lastRelease.additionalComponents", [])
                      .map((comp) => comp.name)
                      .join(", ") || "-" : "-"}
                  </RowText>
                </RowBlock>
                <RowBlock labelWidth="200px" text="БАЗА ДАННЫХ">
                  <RowText>
                    {get(project, "lastRelease.database.name", "-")}
                  </RowText>
                </RowBlock>
              </SectionBlock>

              <SectionBlock text="ОБЩАЯ ДОКУМЕНТАЦИЯ">
                <div>
                  <ButtonAdd
                    text="Добавить"
                    onClick={this.showAddDocumentationModal}
                    disabled={
                      !(isAdmin || currentRole === "EXTERNAL_USER")
                    }
                  />
                  <ButtonDelete
                    text="Удалить"
                    onClick={this.handleShowConfirmationDeleteModal}
                    disabled={
                      !((isAdmin || currentRole === "EXTERNAL_USER") &&
                      this.state.selection.length)
                    }
                  />
                  <ButtonSave
                    text="Скачать"
                    onClick={this.handleSaveDocumentation}
                    disabled={!(generalDocumentationData && generalDocumentationData.length > 0)}
                  />
                </div>
                <CheckboxTable
                  ref={(r) => (this.checkboxTable = r)}
                  minRows={0}
                  showPagination={false}
                  defaultPageSize={10}
                  data={generalDocumentationData}
                  columns={[
                    {
                      Header: "Название",
                      id: "name",
                      accessor: "name",
                      className: "table-hint",
                      Cell: (cell) => (
                        <LinkSpan
                          className="table-hint__container"
                          onClick={() =>
                            this.handleSingleSaveDocumentation(
                              cell.original._id
                            )
                          }
                        >
                          <span className="table-hint__label">
                            {cell.value.replace(/\.[^/.]+$/, "")}
                          </span>
                          <span className="table-hint__hint">
                            {cell.value.replace(/\.[^/.]+$/, "")}
                          </span>
                        </LinkSpan>
                      ),
                    },
                    {
                      Header: "Описание",
                      id: "comment",
                      accessor: "comment",
                      className: "table-hint",
                      Cell: (cell) => (
                        <div className="table-hint__container">
                          <span className="table-hint__label">
                            {cell.value}
                          </span>
                          <span className="table-hint__hint">{cell.value}</span>
                        </div>
                      ),
                    },
                    {
                      Header: "Дата",
                      id: "date",
                      accessor: "date",
                      Cell: (cell) => (
                        <div>
                          {(cell.value && getDateTimeFormatted(cell.value)) ||
                            "-"}
                        </div>
                      ),
                    },
                  ]}
                  {...checkboxProps}
                  noDataText="Нет документации"
                />
              </SectionBlock>
            </Flex>

            <SectionHeaderBlock>
              <SectionHeaderTitle>РЕЛИЗЫ</SectionHeaderTitle>
              {(isAdmin || canReleaseAdd) && (
                <ButtonAdd
                  text="Добавить"
                  onClick={() =>
                    onCreateRelease(
                      project.id,
                      get(project, "gitlabGroup.gitLabId", "")
                    )
                  }
                />
              )}
            </SectionHeaderBlock>

            {project.releases.length > 0 ? (
              <div>
                <ReleasesTable
                  isAdmin={isAdmin}
                  isUser={isUser}
                  project={project}
                  onEditRelease={onEditRelease}
                  onDeleteRelease={onDeleteRelease}
                  onChangeReleaseStatus={onChangeReleaseStatus}
                  onDeclineRelease={onDeclineRelease}
                  onDownloadDocument={onDownloadDocument}
                  onViewSignedDocument={onViewSignedDocument}
                  onViewExternalSystem={onViewExternalSystem}
                  userInPermissions={userInPermissions}
                />
                <SectionHeaderBlock>
                  <SectionHeaderTitle>
                    СВЕДЕНИЯ ОБ ИНФОРМАЦИОННОМ ВЗАИМОДЕЙСТВИИ С ВНЕШНИМИ ИУС
                  </SectionHeaderTitle>
                </SectionHeaderBlock>
                <ExternalSystemsTable
                  externalSystems={project.lastRelease.externalSystems || []}
                  onDownloadDocument={onDownloadDocument}
                />
                <SectionHeaderBlock>
                  <SectionHeaderTitle>
                    ТИПОВЫЕ ТЕХНИЧЕСКИЕ РЕШЕНИЯ
                  </SectionHeaderTitle>
                </SectionHeaderBlock>
                <TechSolutionsTable
                  techSolutions={project.lastRelease.technicalSolutions || []}
                  onDownloadDocument={onDownloadDocument}
                />
              </div>
            ) : (
              <BoldSpan>РЕЛИЗЫ ПОКА НЕ ДОБАВЛЕНЫ</BoldSpan>
            )}

            {pipelines.length > 0 ? (
              <div>
                <SectionHeaderBlock>
                  <SectionHeaderTitle>ХРАНИЛИЩЕ ИК</SectionHeaderTitle>
                </SectionHeaderBlock>
                <ReactTable
                  handleInputPage={handleInputPage}
                  handlePageSize={handlePageSize}
                  totalPages={totalPages}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  PaginationComponent={PaginationApi}
                  data={pipelines}
                  minRows={0}
                  columns={[
                    {
                      Header: "ДАТА",
                      id: "dateTime",
                      minWidth: 85,
                      accessor: "dateTime",
                      Cell: (cell) => (
                        <div>{getDateTimeFormatted(cell.original.dateTime)}</div>
                      )
                    },
                    {
                      Header: "ВЕРСИЯ",
                      id: "name",
                      minWidth: 75,
                      style: { background: "#f4f4f4", height: "36px" },
                      accessor: "name",
                    },
                    {
                      Header: "СБОРКА",
                      minWidth: 120,

                      Cell: (row) => (
                        <>
                          <PipelineStageView
                            project={project}
                            pipeline={row.original}
                            stageType={StageType.BUILD}
                          />
                        </>
                      ),
                    },
                    {
                      Header: "ПРОВЕРКА КОДА",
                      minWidth: 120,
                      style: { background: "#f4f4f4", height: "36px" },
                      Cell: (row) => (
                        <>
                          <PipelineStageView
                            project={project}
                            pipeline={row.original}
                            stageType={StageType.CODE_ANALYZE}
                          />
                        </>
                      ),
                    },
                    {
                      Header: "УСТАНОВКА",
                      minWidth: 90,

                      Cell: (row) => (
                        <>
                          <PipelineStageView
                            project={project}
                            pipeline={row.original}
                            stageType={StageType.DEPLOY}
                          />
                        </>
                      ),
                    },
                    {
                      Header: "АВТОМАТИЧЕСКОЕ ТЕСТИРОВАНИЕ",
                      id: "stageAutomaticTestsStatus",
                      minWidth: 240,
                      style: {
                        overflow: "visible",
                        textAlign: "left",
                        background: "#f4f4f4",
                        height: "36px",
                      },

                      Cell: (row) => (
                        <>
                          <PipelineStageView
                            project={project}
                            pipeline={row.original}
                            stageType={StageType.TEST_AUTOMATIC}
                          />
                        </>
                      ),
                    },
                    {
                      Header: "НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ",
                      minWidth: 210,
                      style: { overflow: "visible", textAlign: "left" },

                      Cell: (row) => (
                        <>
                          <PipelineStageView
                            project={project}
                            pipeline={row.original}
                            stageType={StageType.TEST_PERFORMANCE}
                          />
                        </>
                      ),
                    },
                    {
                      Header: "РУЧНОЕ ТЕСТИРОВАНИЕ",
                      minWidth: 170,
                      style: { background: "#f4f4f4", height: "36px" },
                      Cell: (row) => (
                        <>
                          <PipelineStageView
                            project={project}
                            pipeline={row.original}
                            stageType={StageType.TEST_MANUAL}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            ) : (
              <BoldSpan>НЕТ ИК</BoldSpan>
            )}
          </Flex>
        ) : (
          <PageHeaderBlock>
            <PageHeaderTitle>
              У Вас нету доступа к данному проекту
            </PageHeaderTitle>
          </PageHeaderBlock>
        )}
      </LoadingWrapper>
    );
  }
}

ProjectView.propTypes = {
  project: projectType.isRequired,
  onEditProject: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  onEditRelease: PropTypes.func.isRequired,
  onRestoreProject: PropTypes.func.isRequired,
  onCreateRelease: PropTypes.func.isRequired,
  onDeleteRelease: PropTypes.func.isRequired,
  onChangeReleaseStatus: PropTypes.func.isRequired,
  onDeclineRelease: PropTypes.func.isRequired,
  onDownloadGeneratedReport: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onViewSignedDocument: PropTypes.func.isRequired,
  onViewExternalSystem: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,

  user: userType.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleInputPage: PropTypes.func.isRequired,
  handlePageSize: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pipelines: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  reports: makeSelectReports(state),
});
const mapDispatchToProps = (dispatch) => ({
  fetchReports: () => dispatch(fetchReports()),
  deleteReportFromProject: (templateId, projectId) =>
    dispatch(deleteReportFromProject(templateId, projectId)),
  addDocumentation: (projectId, data) =>
    dispatch(addDocumentation(projectId, data)),
  downloadDocumentsZip: (projectId, documentIds, title) =>
    dispatch(downloadDocumentsZip(projectId, documentIds, title)),
  downloadDocument: (id, title) => dispatch(downloadDocument(id, title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
