import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { externalSystemsType } from "../../../types";
import { BoldSpan } from "../../common/styles";
import Pagination from "../../common/Pagination";
import Dropdown from "../../common/Dropdown";
import { DropdownMenuItem } from "../../common/Dropdown/styles";
import {
  IconNext,
  IconPrevious,
  IconPencil,
  IconTrash
} from "../../common/Icon";
import ExternalSystemSubComponent from "./ExternalSystemSubComponent";

const ExternalSystemsTable = props => {
  const {
    externalSystems,
    onDownloadDocument,
    isEditor,
    onEditExternalSystem,
    onDeleteExternalSystem
  } = props;

  return (
    <div>
      {externalSystems.length > 0 ? (
        <ReactTable
          PaginationComponent={Pagination}
          showPagination={externalSystems.length > 10}
          defaultPageSize={10}
          minRows={0}
          data={externalSystems}
          columns={[
            {
              Header: "",
              expander: true,
              Expander: ({ isExpanded }) => (
                <div>{isExpanded ? <IconPrevious /> : <IconNext />}</div>
              )
            },
            {
              Header: "НАИМЕНОВАНИЕ ИУС",
              accessor: "name"
            },
            {
              Header: "РЕЖИМ ИСТОЧНИКА",
              id: "sourceMode",
              accessor: d => get(d, "sourceMode.name", "")
            },
            {
              Header: "ПОТРЕБИТЕЛЬ ИНФОРМАЦИИ",
              id: "informationConsumer",
              accessor: d => get(d, "informationConsumer.name", "")
            },
            {
              sortable: false,
              Header: "",
              id: "options",
              maxWidth: 60,
              style: { overflow: "visible", textAlign: "center" },
              accessor: "localId",
              Cell: row => {
                if (!isEditor) return null;
                return (
                  <Dropdown>
                    <DropdownMenuItem
                      onClick={() => {
                        onEditExternalSystem(row.original);
                      }}
                    >
                      <IconPencil />
                      Редактировать
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => onDeleteExternalSystem(row.value)}
                    >
                      <IconTrash />
                      Удалить
                    </DropdownMenuItem>
                  </Dropdown>
                );
              }
            }
          ]}
          SubComponent={row => (
            <ExternalSystemSubComponent
              externalSystem={row.original}
              onDownloadDocument={onDownloadDocument}
            />
          )}
        />
      ) : (
        <BoldSpan>У РЕЛИЗА НЕТ СВЯЗЕЙ С ВНЕШНИМИ СИСТЕМАМИ</BoldSpan>
      )}
    </div>
  );
};

ExternalSystemsTable.propTypes = {
  externalSystems: externalSystemsType.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  isEditor: PropTypes.bool,
  onEditExternalSystem: PropTypes.func,
  onDeleteExternalSystem: PropTypes.func,

  /*eslint-disable*/
  isExpanded: PropTypes.bool
  /* eslint-enable */
};

ExternalSystemsTable.defaultProps = {
  isEditor: false,
  onEditExternalSystem: () => {},
  onDeleteExternalSystem: () => {}
};

export default ExternalSystemsTable;
