import React from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import {userType} from "../types";
import {makeSelectUser} from "../store/auth/selectors";
import {initialAuth} from "../store/auth/actions";
import RedirectComponent from "../containers/Auth/RedirectComponent";
import RegistryPage from "../containers/RegistryPage";
import ProjectViewPage from "../containers/ProjectViewPage";
import ProjectEditorPage from "../containers/ProjectEditorPage";
import ReleaseEditorPage from "../containers/ReleaseEditorPage";
import DictionariesPage from "../containers/DictionariesPage";
import DictionaryPage from "../containers/DictionaryPage";
import TechSolutionsPage from "../containers/TechSolutionsPage";
import ReportPage from "../containers/ReportPage";
import NotificationsPage from "../containers/NotificationsPage";
import EventsLogPage from "../containers/EventsLogPage";
import MailingPage from "../containers/MailingPage";
import AdminProjectsPage from "../containers/Admin/AdminProjectsPage";
import AdminUsersPage from "../containers/Admin/AdminUsersPage";
import AdminProjectPage from "../containers/Admin/AdminProjectPage";
import AdminUserPage from "../containers/Admin/AdminUserPage";
import LoginPage from "../containers/LoginPage";
import AdminReportsPage from "../containers/Admin/AdminReportsPage";
import AdminReportDetailPage from "../containers/Admin/AdminReportDetailPage";
import EmployeeCommitReportPage from "../containers/Admin/AdminReportDetailPage/EmployeeCommitReportPage";


function Routes(props) {
    if (props.user.id === "") {
        const localToken = localStorage.getItem("accessToken");
        if (localToken) {
            props.initialAuth(localToken);
            return null;
        }

        return (
            <Switch>
                <Route
                    render={_props => <LoginPage {..._props} />}
                 />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route
                exact
                path="/login"
                render={_props => <LoginPage {..._props} />}
             />
            <Route
                exact
                path="/registry"
                render={_props => <RegistryPage {..._props}  user={props.user} />}
            />
            <Route
                exact
                path="/eventsLog"
                render={_props => <EventsLogPage {..._props} />}
            />
            <Route
                exact
                path="/techSolutions"
                render={_props => <TechSolutionsPage {..._props} />}
            />
            <Route
                exact
                path="/dictionaries"
                render={_props => <DictionariesPage {..._props} />}
            />
            <Route
                path="/dictionaries/:code"
                render={_props => <DictionaryPage {..._props} />}
            />
            <Route
                exact
                path="/reports"
                render={_props => <ReportPage {..._props} />}
            />





            <Route
                path="/notifications"
                render={_props => <NotificationsPage {..._props} />}
            />
            <Route
                path="/mailing"
                render={_props => <MailingPage {..._props} />}
            />
            <Route
                exact
                path="/project/:projectId/:glGroup/release/editor/new"
                render={_props => <ReleaseEditorPage isNew {..._props} />}
            />
            <Route
                path="/project/:projectId/:glGroup/release/editor/:releaseId"
                render={_props => <ReleaseEditorPage {..._props} />}
            />
            <Route
                exact
                path="/project/editor/new"
                render={_props => <ProjectEditorPage isNew {..._props} />}
            />
            <Route
                path="/project/editor/:projectId"
                render={_props => <ProjectEditorPage {..._props} />}
            />
            <Route
                path="/project/:id"
                render={_props => <ProjectViewPage {..._props} user={props.user}/>}
            />

            <Route
                path="/admin/reports/EmployeeCommitReport"
                render={_props => <EmployeeCommitReportPage {..._props} />}
            />

            <Route
                path="/admin/reports/:reportCode"
                render={_props => <AdminReportDetailPage {..._props} />}
            />

            <Route path="/admin/reports"
                   render={_props => <AdminReportsPage {..._props} user={props.user} />}
            />




            <Route
                path="/admin/projects/:projectId"
                render={_props => <AdminProjectPage {..._props} user={props.user}/>}
            />
            <Route
                path="/admin/projects"
                render={_props => <AdminProjectsPage {..._props} user={props.user}/>}
            />
            <Route
                path="/admin/users/:userId"
                render={_props => <AdminUserPage {..._props} user={props.user}/>}
            />
            <Route
                path="/admin/users"
                render={_props => <AdminUsersPage {..._props} user={props.user}/>}
            />
            <Route
                path="/logout"
                render={_props => {
                    localStorage.removeItem("accessToken");
                    // Api.auth.logout();
                    axios.defaults.headers.common.Authorization = "";
                    return (
                        <RedirectComponent {..._props} loc="/login"/>
                    );
                }}
            />
            <Route render={_props => <Redirect to="/registry" {..._props} />}/>
        </Switch>
    );
}

const mapStateToProps = state => ({
    user: makeSelectUser(state)
});

const mapDispatchToProps = dispatch => ({
    initialAuth: accessToken => dispatch(initialAuth(accessToken))
});

Routes.propTypes = {
    user: userType.isRequired,
    initialAuth: PropTypes.func.isRequired
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Routes)
);
