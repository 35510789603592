import {createSelector} from "reselect";

const selectAllProjects = (state) => state.getIn(["administration", "projects"]);
const makeSelectAllProjectsAdministaration
    = createSelector(selectAllProjects, (substate) => substate.toJS());
const selectAllUser = (state) => state.getIn(["administration", "users"]);
const makeSelectAllUsersAdministaration = createSelector(selectAllUser, (substate) =>
    substate.toJS()
);
const selectFilters = (state) => state.getIn(["administration", "filter"]);
const makeSelectFilters =
    createSelector(selectFilters,
        (substate) => substate.toJS());

const selectPermissions = (state) => state.getIn(["administration", "listPermissions"]);
const makeSelectPermissions = createSelector(selectPermissions, (substate) =>
    substate.toJS()
);

const selectAllMembers = (state) => state.getIn(["administration", "membersInProject"]);
const makeSelectAllMembersAdministaration
    = createSelector(selectAllMembers,
    (substate) => substate.toJS());

const selectPermissionForUser= (state) => state.getIn(["administration", "permissionForUser"]);
const makeSelectPermissionForUserAdministaration
    = createSelector(selectPermissionForUser,
    (substate) => substate.toJS());

export {
    makeSelectPermissionForUserAdministaration,
    makeSelectAllProjectsAdministaration,
    makeSelectAllUsersAdministaration,
    makeSelectAllMembersAdministaration,
    makeSelectFilters,
    makeSelectPermissions
};
