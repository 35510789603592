import styled from "styled-components";

export const ErrorContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: #fb2921;
  justify-content: center;
  align-items: center;
`;

export const ErrorLabel = styled.label`
  font-family: Roboto-Bold;
  font-size: 14px;
  color: #ffffff;
`;