import React, {Component} from "react";
import {connect} from "react-redux";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {BoldSpan, PageHeaderBlock, PageHeaderTitle} from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";
import Api from "../../../api";
import {errorParser, removeLoader, setError, setLoader} from "../../../store/preloader/actions";
import SelectorDepartment from "../../../components/ProjectEditor/SelectorDepartment";
import loadFileHelper from "../../../utils/loadFileHelper";
import {GLOBAL_LOADER} from "../../../store/preloader/constants";
import {LOAD_DATA_REPORT} from "../../../utils/constants";
import EventsLogFilter from "../../../components/EventsLog/EventsLogFilter";
import {fetchProjectsOptions, simpleSearchProject} from "../../../store/project/actions";
import {makeSelectProjectsOptions, makeSimpleSelectProjects} from "../../../store/project/selectors";
import {makeSelectEventsList} from "../../../store/eventsLog/selectors";
import AutoSearchProject from "../../../components/common/AutoSearch/AutoSearchProject";

class EmployeeCommitReportPage extends Component {
    state = {
        reportData: [],
        nameReport: 'EmployeeCommitReport',
        dictionaryEntry: [],
        reports: [],
        humanNameReport: '',
        disabledDownload: true,
        projectId: null,
        gitlabId: null,
        project: null,
        startDate: "",
        endDate: "",
    }

    get reportCode() {
        return 'EmployeeCommitReport'
    }

    customSetState(field, value) {
        this.setState(state => ({
            ...state,
            [field]: value,
        }));
    }


    async componentDidMount() {
        this.getReportName()
    }

    async getReportName() {
        try {
            this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
            const res = await Api.reports.getReports();
            const convertData = res && res.data ? res.data : [];
            if (convertData && convertData.length > 0) {
                const find = convertData.find(item => item.reportName === this.state.nameReport);
                if (find) this.customSetState('humanNameReport', find.displayName);
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
        }
    }


    getSendData() {
        const {gitlabId, startDate, endDate} = this.state;

        const sendData = {
            "gitlabId": this.state.gitlabId,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate
        };

        if (!gitlabId) delete sendData.gitlabId;
        if (!startDate) delete sendData.startDate;
        if (!endDate) delete sendData.endDate;

        return sendData;
    }

    async showReport() {
        try {
            const sendData = this.getSendData();
            this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
            const res = await Api.reports.getReportsByCode(this.state.nameReport, sendData);
            this.customSetState('reportData', res.data);
            this.customSetState('disabledDownload', false);
        } catch (error) {
            console.log(error);
            setError(`Ошибка  - ${errorParser(error) || error.message}`);
        } finally {
            this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
        }
    }

    async loadReport() {

        try {
            const sendData = this.getSendData();
            this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
            const res = await Api.reports.downloadReportsByCode(this.state.nameReport, sendData);
            const contentDisposition = res.headers['content-disposition'];
            const filenameIndex = contentDisposition.indexOf('filename=');
            let filename = contentDisposition.substring(filenameIndex + 10).split(';')[0];
            filename = filename.slice(0, filename.length - 1);
            if (!filename) filename = res.headers["file-name"];
            if (!filename) filename = 'report.xlsx';
            if (filename[0] == '_' || filename[0] == '"') filename = filename.slice(1);
            if (filename[filename.length - 1] == '_' || filename[filename.length - 1] == '"') filename = filename.slice(0, filename.length - 1);

            loadFileHelper(res, filename);
        } catch (error) {
            console.log(error);
            setError(`Ошибка  - ${errorParser(error) || error.message}`);
        } finally {
            this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
        }
    }

    selectProject(project) {
        this.setState({projectId: project.id, gitlabId: project.gitlabId});
    }


    render() {
        const {reportData, disabledDownload, startDate, endDate} = this.state;


        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Отчет: {this.state.humanNameReport || this.state.nameReport}</PageHeaderTitle>
                    <Link to="/admin/reports"><ButtonSimple text="назад"/></Link>
                </PageHeaderBlock>

                <div className='controlBlock controlEmployerReport'>
                    <div className='element selectorProjects'>Поиск
                        <AutoSearchProject selectProject={(project) => this.selectProject(project)}/>
                    </div>

                    <div className='element'>С
                        <div className='dateInputWrap '>
                            <input type='date'
                                   className='dataInput'
                                   value={startDate}
                                   onChange={(val) => {
                                       this.customSetState('startDate', val.target.value);
                                   }}
                            />
                        </div>
                    </div>

                    <div className='element'>По
                        <div className='dateInputWrap'>
                            <input type='date'
                                   className='dataInput'
                                   value={endDate}
                                   onChange={(val) => {
                                       this.customSetState('endDate', val.target.value);
                                   }}
                            />
                        </div>
                    </div>


                    <ButtonSimple text="Показать" onClick={() => this.showReport()}/>
                    <ButtonSimple disabled={disabledDownload} text="Скачать" onClick={() => this.loadReport()}/>
                </div>
                {reportData.length > 0 ? (
                    <ReactTable
                        defaultPageSize={10}
                        minRows={1}
                        data={reportData}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "Проект",
                                accessor: "projectName"
                            },

                            {
                                Header: "ФИО",
                                accessor: "employeeName"
                            },
                            {
                                Header: "Количество коммитов",
                                accessor: "commitCount",
                            },

                            {
                                Header: "Дата последнего коммита",
                                accessor: "lastCommitDate",
                                Cell: row => (<div>{moment(row.original.lastCommitDate).format('DD.MM.YYYY')}</div>)
                            },
                        ]}
                    />
                ) : (
                    <BoldSpan>Нет данных</BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    setError: error => dispatch(setError(error)),
    setLoader: (loaderScope, loaderKey, message) => dispatch(setLoader(loaderScope, loaderKey, message)),
    removeLoader: (loaderScope, loaderKey) => dispatch(removeLoader(loaderScope, loaderKey)),
});

EmployeeCommitReportPage.propTypes = {
    setLoader: PropTypes.func.isRequired,
    removeLoader: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeeCommitReportPage);
