export const SET_PROJECTS_ADMINISTRATION = "SET_PROJECTS_ADMINISTRATION";
export const SET_USERS_ADMINISTRATION = "SET_USERS_ADMINISTRATION";
export const SET_FILTER_ADMINISTRATION = "SET_FILTER_ADMINISTRATION";
export const SET_LIST_PERMISSIONS_ADMINISTRATION = "SET_LIST_PERMISSIONS_ADMINISTRATION";

export const FETCH_LIST_PERMISSIONS_ADMINISTRATION = 'FETCH_ALL_PROJECTS_ADMINISTRATION';
export const FETCH_PROJECTS_ADMINISTRATION = 'FETCH_ALL_PROJECTS_ADMINISTRATION';
export const FETCH_USER_ADMINISTRATION = 'FETCH_USER_ADMINISTRATION';

export const UPDATE_PERMISSIONS_ADMINISTRATION = "UPDATE_PERMISSIONS_ADMINISTRATION";
export const DELETE_PERMISSIONS_ADMINISTRATION = "DELETE_PERMISSIONS_ADMINISTRATION";
export const FETCH_PERMISSIONS_PROJECT_BY_ID_ADMINISTRATION = "FETCH_PERMISSIONS_PROJECT_BY_ID_ADMINISTRATION";
export const FETCH_PERMISSIONS_USER_BY_ID_ADMINISTRATION = "FETCH_PERMISSIONS_USER_BY_ID_ADMINISTRATION";

export const SET_PROJECT_ADMINISTRATION = "SET_PROJECT_ADMINISTRATION";
export const SET_USER_ADMINISTRATION = "SET_USER_ADMINISTRATION";
