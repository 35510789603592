import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router/immutable";
import { userType } from "../../types";
import { clearError } from "../../store/preloader/actions";
import { logout } from "../../store/auth/actions";
import { makeSelectError } from "../../store/preloader/selectors";
import { makeSelectUser } from "../../store/auth/selectors";
import Header from "../../components/Header";




const HeaderContainer = props => (
  <Header
    user={props.user}
    error={props.error}
    onClickError={props.onClickError}
    onClickHeaderTitle={props.pushToRegistry}
    onClickDictionaries={props.pushToDictionaries}
    onClickSettings={props.pushToSettings}
    onClickLogout={props.handleLogout}
    onClickEventLog={props.pushToEventLog}
    onClickUsers={props.goToUsers}
    onClickAllProjects={props.goToAllProject}
    onClickReport={props.goToReport}
  />
);

const mapStateToProps = state => ({
  user: makeSelectUser(state),
  error: makeSelectError(state)
});

const mapDispatchToProps = dispatch => ({
  onClickError: () => dispatch(clearError()),
  pushToRegistry: () => dispatch(push("/registry")),
  pushToDictionaries: () => dispatch(push("/dictionaries")),
  pushToSettings: () => dispatch(push("/mailing")),

  handleLogout: () => dispatch(logout()),
  pushToEventLog: () => dispatch(push("/eventsLog")),

  goToAllProject: ()=>dispatch(push("/admin/projects")),
  goToUsers: ()=>dispatch(push("/admin/users")),
  goToReport: ()=>dispatch(push("/admin/reports")),
});

HeaderContainer.propTypes = {
  user: userType.isRequired,
  error: PropTypes.string.isRequired,
  onClickError: PropTypes.func.isRequired,
  pushToRegistry: PropTypes.func.isRequired,
  pushToDictionaries: PropTypes.func.isRequired,
  pushToSettings: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  pushToEventLog: PropTypes.func.isRequired,
  goToAllProject: PropTypes.func.isRequired,
  goToUsers: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
