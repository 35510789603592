import "@babel/polyfill/";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import {
  ConnectedRouter,
  routerMiddleware
} from "connected-react-router/immutable";
import { fromJS } from "immutable";
import "./assets/css/styles.css"
import createSagaMiddleware from "redux-saga";
import { ThemeProvider } from "styled-components";
import { fpaTheme } from "./utils/theme";
import "react-table/react-table.css";
import "./utils/react-table-overrides.css";
import App from "./containers/App";
// import registerServiceWorker from "./registerServiceWorker";
import rootReducer from "./store/rootReducer";
import rootSaga from "./sagas/rootSaga";

const history = createBrowserHistory();

const initialState = fromJS({});

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history));

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer(history),
  initialState,
  compose(
    middleware,
      //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
/* eslint-enable */

sagaMiddleware.run(rootSaga);

const MOUNT_NODE = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={fpaTheme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
// registerServiceWorker();
