import React from "react";
import { connect } from "react-redux";
import { FooterWrapper, FooterText, FpaVersion, VesionWrapper } from "./styles";
import { frontVersionFPA, backVersionFPA } from "../../store/project/selectors";


const Footer = (props) => (
  <FooterWrapper>
    <FooterText>© АО «СО ЕЭС», 2018—2023</FooterText>
      <VesionWrapper>
      {props.front &&
        <FpaVersion> Фронт сборка {props.front.version}</FpaVersion> 
      }
      {props.back &&
        <FpaVersion> Бэкенд сборка {props.back.version}</FpaVersion> 
      }
      </VesionWrapper>
  </FooterWrapper>
)

const mapStateToProps = state => ({
  front: frontVersionFPA(state),
  back: backVersionFPA(state),
});

export default connect(mapStateToProps)(Footer);
