import axios from "axios";
import { API_HOST } from "../utils/constants";

const BEFORE_URL = '/api/fpa/v1/';

export default {
    /**
     * Все разрешения
     * @returns {AxiosPromise<any>}
     */
    getAllPermissions: () =>
        axios.get(`${API_HOST}${BEFORE_URL}administration/permissions`),

    /**
     * Обновление или создание разрешений пользователя в проекте   post
     * @param sendData
     * @returns {AxiosPromise<any>}
     */
    updateOrCreatePermissions: (sendData) =>
        axios.post(`${API_HOST}${BEFORE_URL}administration/permissions`, sendData),



    /**
     * Удаление разрешения пользователя в проекте  delete
     * @param sendData
     * @returns {AxiosPromise}
     */
    deletePermissions: (sendData) =>
        axios.delete(`${API_HOST}${BEFORE_URL}administration/permissions/delete`, { data :sendData } ),
    /**
     * Поиск проектов по имени
     * @returns {AxiosPromise<any>}
     * @param pageNumber
     * @param pageSize
     * @param projectName
     */
    getProjectsByName: (pageNumber= 0, pageSize = 20, projectName = ''  ) =>{
        const params = {
            pageNumber,
            pageSize,
            projectName
        };
       return  axios.get(`${API_HOST}${BEFORE_URL}administration/projects`, {params})
    },
    /**
     * Участники и их права в проекте
     * @param projectId
     * @returns {AxiosPromise<any>}
     */
    getUserAndPermissionByProjectId: (projectId) =>axios.get(`${API_HOST}${BEFORE_URL}administration/projects/${projectId}/members`),
    /**
     * Поиск пользователей
     * @param pageNumber
     * @param pageSize
     * @param fullName
     * @returns {AxiosPromise<any>}
     */
    getUsers: (pageNumber= 0, pageSize = 20, fullName = ''  ) =>{
        const params = {
            pageNumber,
            pageSize,
            fullName
        };
        return  axios.get(`${API_HOST}${BEFORE_URL}administration/users`, {params})
    },
    /**
     * Разрешения пользователя
     * @param userId
     * @returns {AxiosPromise<any>}
     */
    getUserPermissionsByUserId:(userId) =>axios.get(`${API_HOST}${BEFORE_URL}administration/users/${userId}/permissions`),

    getShortProjectById: (id)=> axios.get(`${API_HOST}${BEFORE_URL}administration/project/${id}`),
    getShortUserById: (id)=> axios.get(`${API_HOST}${BEFORE_URL}administration/user/${id}`),
    syncPermissionsByProjectId: (id)=> axios.get(`${API_HOST}${BEFORE_URL}administration/projects/${id}/synchronize`),
    checkPermissionsByProjectId: (id)=> axios.get(`${API_HOST}${BEFORE_URL}administration/projects/${id}/check-sync`),

};
