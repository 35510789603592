import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import RowBlock from "../../common/RowBlock";
import { TextArea , ErrorMessage } from "../../common/styles";

import { ButtonSave } from "../../common/Buttons";
import { ButtonRedOutline } from "../../common/Buttons/styles";
import { ButtonsRow } from "../../common/Modal/styles";
import FileUploader from "../../DocumentsEditor/DocumentsForm/FileUploader";
import { generateId } from "../../../utils/helpers";


const AddDocumentationForm = (props) => {
  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    handleClose
  } = props;

  return (
    <>
      <RowBlock text="Описание" labelWidth="200px" alignSelfCenter>
        <TextArea
          rows="3"
          name="comment"
          value={values.comment}
          onChange={handleChange}
          width="100%"
          maxLength={1000}
        />
      </RowBlock>
      {touched.comment && errors.comment && (
        <ErrorMessage>{errors.comment}</ErrorMessage>
      )}

      <RowBlock text="Файл" labelWidth="300px" alignSelfCenter>
        <FileUploader
          selectedFile={values.file}
          onChange={(file) => {
            setFieldValue("file", file);
          }}
        />
      </RowBlock>
      <ButtonsRow>
        <ButtonSave
          type="submit"
          text="Сохранить"
          margin="0 25px 0 0"
          onClick={handleSubmit}
          // disabled={!this.state.selected}
        />
        <ButtonRedOutline onClick={handleClose}>Отмена</ButtonRedOutline>
      </ButtonsRow>
    </>
  );
};

AddDocumentationForm.propTypes = {
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape().isRequired,

  handleClose: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
      comment: "",
      file: null,
    }),
  validate: (values) => {
    const errors = {};
    if (!values.file) {
      errors.file = "Прикрепите, пожалуйста, документ!";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    if (values.file) {
      const newDoc = {
        id: 0,
        localId: generateId(),
        comment: values.comment,
        file: values.file,
        fileOriginalName: values.file.name || "-"
      };
      formikBag.props.onSubmitForm(newDoc);
      formikBag.props.handleClose();
    }
  },
})(AddDocumentationForm);
