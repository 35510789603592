import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  externalSystemDictionariesType,
  externalSystemsType
} from "../../types";
import { makeSelectExternalSystemDictionaries } from "../../store/dictionaries/selectors";
import { fetchDictionaries } from "../../store/dictionaries/actions";
import { EXTERNAL_SYSTEM_DICTIONARIES } from "../../utils/constants";
import ExternalSystemEditor from "../../components/ExternalSystemEditor";
import { downloadDocument } from "../../store/documents/actions";

class ExternalSystemEditorContainer extends Component {
  componentDidMount() {
    this.props.fetchExternalSystemDictionaries();
  }

  render() {
    const {
      externalSystemDictionaries,
      selectedExternalSystems,
      onSaveExternalSystem,
      onDeleteExternalSystem,
      downloadDocument: onDownloadDocument
    } = this.props;

    return (
      <ExternalSystemEditor
        externalSystemDictionaries={externalSystemDictionaries}
        selectedExternalSystems={selectedExternalSystems}
        onSaveExternalSystem={onSaveExternalSystem}
        onDeleteExternalSystem={onDeleteExternalSystem}
        onDownloadDocument={onDownloadDocument}
      />
    );
  }
}

const mapStateToProps = state => ({
  externalSystemDictionaries: makeSelectExternalSystemDictionaries(state)
});

const mapDispatchToProps = dispatch => ({
  fetchExternalSystemDictionaries: () =>
    dispatch(fetchDictionaries(EXTERNAL_SYSTEM_DICTIONARIES)),
  downloadDocument: id => dispatch(downloadDocument(id))
});

ExternalSystemEditorContainer.propTypes = {
  externalSystemDictionaries: externalSystemDictionariesType.isRequired,
  fetchExternalSystemDictionaries: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,

  selectedExternalSystems: externalSystemsType.isRequired,
  onSaveExternalSystem: PropTypes.func.isRequired,
  onDeleteExternalSystem: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalSystemEditorContainer);
