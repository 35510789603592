import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import {
  externalSystemDictionariesType,
  externalSystemType
} from "../../../types";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalBody
} from "../../common/Modal/styles";
import { ButtonIconClose } from "../../common/Buttons";
import { modalStyles } from "../../../utils/theme";
import ExternalSystemForm from "../ExternalSystemForm";

function ExternalSystemModal(props) {
  const {
    isNew,
    externalSystemToEdit,
    externalSystemDictionaries,
    onSaveExternalSystem,

    modalIsOpen,
    closeModal
  } = props;

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="externalSystemEditorModal"
      shouldCloseOnOverlayClick={false}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalHeaderTitle>ДОБАВЛЕНИЕ СВЯЗИ С ВНЕШНЕЙ ИУС</ModalHeaderTitle>
          <ButtonIconClose onClick={closeModal} />
        </ModalHeader>
        <ModalBody>
          <ExternalSystemForm
            isNew={isNew}
            externalSystemToEdit={externalSystemToEdit}
            externalSystemDictionaries={externalSystemDictionaries}
            onSubmitForm={onSaveExternalSystem}
          />
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
}

Modal.setAppElement("#root");

ExternalSystemModal.propTypes = {
  isNew: PropTypes.bool.isRequired,
  externalSystemToEdit: externalSystemType.isRequired,
  externalSystemDictionaries: externalSystemDictionariesType.isRequired,
  onSaveExternalSystem: PropTypes.func.isRequired,

  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ExternalSystemModal;
