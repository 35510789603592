import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  dictionaryEntriesType,
  documentsType,
  gitlabOptionsType
} from "../../types";
import {
  downloadDocument,
  signDocument,
  deleteDocumentSign
} from "../../store/documents/actions";
import { fetchDictionaries } from "../../store/dictionaries/actions";
import { showModal } from "../../store/modal/actions";
import { makeSelectDocumentTypeDictionary } from "../../store/dictionaries/selectors";
import DocumentsEditor from "../../components/DocumentsEditor";

class DocumentsEditorContainer extends Component {
  componentDidMount() {
    this.props.fetchDocumentTypeDictionary();
  }

  render() {
    const {
      documentTypeDictionary,
      selectedDocuments,
      requiredDocuments,
      onSaveDocument,
      onDeleteDocument,
      onDownloadDocument,
      onSignDocument,
      onDeleteDocumentSign,
      releaseId,
      onShowModal
    } = this.props;

    return (
      <DocumentsEditor
        documentTypeDictionary={documentTypeDictionary}
        selectedDocuments={selectedDocuments}
        requiredDocuments={requiredDocuments}
        onSaveDocument={onSaveDocument}
        onDeleteDocument={onDeleteDocument}
        onDownloadDocument={onDownloadDocument}
        onSignDocument={onSignDocument}
        onDeleteDocumentSign={onDeleteDocumentSign}
        releaseId={releaseId}
        onShowModal={onShowModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  documentTypeDictionary: makeSelectDocumentTypeDictionary(state)
});

const mapDispatchToProps = dispatch => ({
  fetchDocumentTypeDictionary: () =>
    dispatch(fetchDictionaries(["documenttype"])),
  onDownloadDocument: id => dispatch(downloadDocument(id)),
  onSignDocument: (isSign, documentId, releaseId) =>
    dispatch(signDocument(isSign, documentId, releaseId)),
  onDeleteDocumentSign: (documentId, releaseId) =>
    dispatch(deleteDocumentSign(documentId, releaseId)),
  onShowModal: (modalType, props) => dispatch(showModal(modalType, props))
});

DocumentsEditorContainer.propTypes = {
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  fetchDocumentTypeDictionary: PropTypes.func.isRequired,

  selectedDocuments: documentsType.isRequired,
  requiredDocuments: gitlabOptionsType,
  onSaveDocument: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onSignDocument: PropTypes.func.isRequired,
  onDeleteDocumentSign: PropTypes.func.isRequired,
  releaseId: PropTypes.number.isRequired,

  onShowModal: PropTypes.func.isRequired
};

DocumentsEditorContainer.defaultProps = {
  requiredDocuments: []
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsEditorContainer);
