import {
  PRELOADER_SET_LOADER,
  PRELOADER_REMOVE_LOADER,
  PRELOADER_SET_ERROR,
  PRELOADER_CLEAR_ERROR,
  PRELOADER_SET_SPECIFIC_ERROR,
  PRELOADER_CLEAR_SPECIFIC_ERROR
} from "./constants";

export const setLoader = (loaderScope, loaderKey, message) => ({
  type: PRELOADER_SET_LOADER,
  payload: {
    loaderScope,
    loaderKey,
    message
  }
});

export const removeLoader = (loaderScope, loaderKey) => ({
  type: PRELOADER_REMOVE_LOADER,
  payload: {
    loaderScope,
    loaderKey
  }
});

export const setError = errorMessage => ({
  type: PRELOADER_SET_ERROR,
  payload: {
    errorMessage
  }
});

export const clearError = () => ({
  type: PRELOADER_CLEAR_ERROR
});

export const setSpecificError = error => ({
  type: PRELOADER_SET_SPECIFIC_ERROR,
  payload: {
    error
  }
});

export const clearSpecificError = () => ({
  type: PRELOADER_CLEAR_SPECIFIC_ERROR
});

// error parser

export const errorParser = error => 'response' in error && `${error.message} :: verbose :: ${error.response.data && error.response.data.message}`
