import { createSelector } from "reselect";
import { DICTIONARY_REMOTE_TO_REDUX_MAPPING } from "../../utils/constants";

const selectDictionaries = state => state.get("dictionaries");

const getDictionaryEntriesByCode = (state, code) => code;

const makeSelectDictionaryEntries = createSelector(
  selectDictionaries,
  getDictionaryEntriesByCode,
  (dictionaries, code) => {
      const key = code in  DICTIONARY_REMOTE_TO_REDUX_MAPPING ?  DICTIONARY_REMOTE_TO_REDUX_MAPPING[code] : code;
      const res = dictionaries.get(key);
      return res? res.toJS() : [];
  }

);

const makeSelectDictionariesList = createSelector(
  selectDictionaries,
  substate => substate.get("dictionariesList").toJS()
);

const makeSelectReleaseDictionaries = createSelector(
  selectDictionaries,
  substate => ({
    platform: substate.get("platform").toJS(),
    devEnv: substate.get("developmentEnvironment").toJS(),
    components: substate.get("additionalComponents").toJS(),
    database: substate.get("database").toJS()
  })
);

const makeSelectExternalSystemDictionaries = createSelector(
  selectDictionaries,
  substate => ({
    sourceMode: substate.get("sourceMode").toJS(),
    sourceLocation: substate.get("sourceLocation").toJS(),
    informationConsumer: substate.get("informationConsumer").toJS(),
    consumerLocation: substate.get("consumerLocation").toJS(),
    authentication: substate.get("authentication").toJS(),
    transport: substate.get("transport").toJS(),
    interfaces: substate.get("interfaces").toJS(),
    transData: substate.get("transData").toJS()
  })
);

const selectDictionariesDocumentType = state =>
  state.getIn(["dictionaries", "documentType"]);

const makeSelectDocumentTypeDictionary = createSelector(
  selectDictionariesDocumentType,
  substate => substate.toJS()
);

export {
  makeSelectDictionaryEntries,
  makeSelectDictionariesList,
  makeSelectReleaseDictionaries,
  makeSelectDocumentTypeDictionary,
  makeSelectExternalSystemDictionaries
};
