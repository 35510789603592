import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  externalSystemsType,
  externalSystemDictionariesType
} from "../../types";
import { Flex, SectionHeaderBlock, SectionHeaderTitle } from "../common/styles";
import { ButtonAdd } from "../common/Buttons";
import ExternalSystemModal from "./ExternalSystemModal";
import ExternalSystemsTable from "./ExternalSystemsTable";

class ExternalSystemEditor extends Component {
  state = {
    modalIsOpen: false,
    isNew: true,
    externalSystemToEdit: {}
  };

  handleCreateExternalSystem = () => {
    this.setState({
      modalIsOpen: true,
      isNew: true,
      externalSystemToEdit: {}
    });
  };

  handleEditExternalSystem = extSys => {
    this.setState({
      modalIsOpen: true,
      isNew: false,
      externalSystemToEdit: extSys
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  handleClickSave = form => {
    this.props.onSaveExternalSystem(form);
    this.closeModal();
  };

  render() {
    const { modalIsOpen, isNew, externalSystemToEdit } = this.state;
    const {
      externalSystemDictionaries,
      selectedExternalSystems,
      onDeleteExternalSystem,
      onDownloadDocument
    } = this.props;

    return (
      <Flex flexDirection="column">
        <SectionHeaderBlock editor>
          <SectionHeaderTitle>СВЯЗИ С ВНЕШНИМИ ИУС</SectionHeaderTitle>
          <ButtonAdd
            text="Добавить"
            onClick={this.handleCreateExternalSystem}
          />
        </SectionHeaderBlock>
        <ExternalSystemModal
          modalIsOpen={modalIsOpen}
          isNew={isNew}
          externalSystemToEdit={externalSystemToEdit}
          closeModal={this.closeModal}
          externalSystemDictionaries={externalSystemDictionaries}
          onSaveExternalSystem={this.handleClickSave}
        />
        <ExternalSystemsTable
          externalSystems={selectedExternalSystems}
          onDownloadDocument={onDownloadDocument}
          isEditor
          onEditExternalSystem={this.handleEditExternalSystem}
          onDeleteExternalSystem={onDeleteExternalSystem}
        />
      </Flex>
    );
  }
}

ExternalSystemEditor.propTypes = {
  externalSystemDictionaries: externalSystemDictionariesType.isRequired,
  selectedExternalSystems: externalSystemsType.isRequired,
  onSaveExternalSystem: PropTypes.func.isRequired,
  onDeleteExternalSystem: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired
};

export default ExternalSystemEditor;
