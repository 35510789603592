import React from "react";
import PropTypes from "prop-types";
import { userType } from "../../types";

import TopLevelAlert from "./TopLevelAlert";
import { Container } from "./styles";
import HeaderContent from "./HeaderContent/index";


const Header = ({ error, onClickError, ...props }) => (
  <Container>
    <TopLevelAlert error={error} onClickError={onClickError} />
    <HeaderContent {...props} />
  </Container>
);

Header.propTypes = {
  user: userType.isRequired,
  error: PropTypes.string.isRequired,
  onClickError: PropTypes.func.isRequired,
  onClickHeaderTitle: PropTypes.func.isRequired,
  onClickDictionaries: PropTypes.func.isRequired,
  onClickSettings: PropTypes.func.isRequired,
  onClickLogout: PropTypes.func.isRequired,
  onClickEventLog: PropTypes.func.isRequired,
  onClickUsers: PropTypes.func.isRequired,
  onClickAllProjects: PropTypes.func.isRequired,
  onClickReport: PropTypes.func.isRequired,
};

export default Header;
