import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import auth from "./auth/reducers";
import project from "./project/reducers";
import preloader from "./preloader/reducers";
import dictionaries from "./dictionaries/reducers";
import documents from "./documents/reducers";
import notifications from "./notifications/reducers";
import events from "./eventsLog/reducers";
import modal from "./modal/reducers";
import pipeline from "./pipeline/reducers";
import mailing from "./mailing/reducers";
import administration from "./administration/reducers";

const rootReducer = history =>
    combineReducers({
      router: connectRouter(history),
      auth,
      project,
      preloader,
      dictionaries,
      documents,
      notifications,
      modal,
      events,
      pipeline,
      mailing,
      administration
    });

export default rootReducer;
