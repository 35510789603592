import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { push } from "connected-react-router/immutable";
import { dictionaryEntriesType } from "../../types";
import Dictionaries from "../../components/Dictionaries";
import {fetchDictionariesList, submitDictionary} from "../../store/dictionaries/actions";
import { makeSelectDictionariesList } from "../../store/dictionaries/selectors";
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";

class DictionariesPage extends Component {

  state = {
    isCreateModalOpen: false,
    dictionary: ""
  };

  openCreateModal = () => {
    this.setState({
      isCreateModalOpen: true
    });
  };

  closeCreateModal = () => {
    this.setState({
      isCreateModalOpen: false
    });
  };

  submitCreate = (val2) => {
   this.props.submitDictionaryEvent(val2);
  };

  componentDidMount() {
    this.props.getDictionaries();
  }




  render() {
    return (
      <Dictionaries
        dictionariesList={this.props.dictionariesList}
        onClickTechSolutions={this.props.pushToTechSolutions}
        onClickReports={this.props.pushToReports}
        loaders={this.props.loaders}


        isCreateModalOpen={this.state.isCreateModalOpen}
        dictionary={this.state.dictionary}


        closeCreateModal={this.closeCreateModal}
        onSubmitDictionary={this.submitCreate}
        openCreateModal={this.openCreateModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  dictionariesList: makeSelectDictionariesList(state),
  loaders: makeSelectLocalLoaders(state)
});

const mapDispatchToProps = dispatch => ({
  getDictionaries: () => dispatch(fetchDictionariesList()),
  pushToTechSolutions: () => dispatch(push("/techSolutions")),
  pushToReports: () => dispatch(push("/reports")),
  submitDictionaryEvent: (val) => dispatch(submitDictionary(val)),
});

DictionariesPage.propTypes = {
  dictionariesList: dictionaryEntriesType.isRequired,
  getDictionaries: PropTypes.func.isRequired,
  submitDictionaryEvent: PropTypes.func.isRequired,
  pushToReports: PropTypes.func.isRequired,

  pushToTechSolutions: PropTypes.func.isRequired,

  loaders: PropTypes.instanceOf(Map).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionariesPage);
