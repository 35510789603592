export default function loadFileHelper(response, fileNameIn=null) {
    const fileBlob = new Blob([response.data]);
    const fileName = fileNameIn || response.headers["file-name"] ;



    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(fileBlob, fileName);
    } else {
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}