import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  getNewNotificationsCount: () =>
    axios.get(`${API_HOST}/api/fpa/v1/notifications/unread/count`),
  getNewNotifications: () =>
    axios.get(`${API_HOST}/api/fpa/v1/notifications/unread`),
  getNotifications: params =>
    axios({
      method: "GET",
      url: `${API_HOST}/api/fpa/v1/notifications`,
      params
    })
};
