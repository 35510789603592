import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  getGroupTags: (id) =>
    axios.get(`${API_HOST}/api/fpa/v1/gitlab/group/${id}/tags`),
  getTag: (groupId, repositoryId, tagId) =>
    axios.get(
      `${API_HOST}/api/fpa/v1/gitlab/group/${groupId}/repository/${repositoryId}/tag/${tagId}`
    ),
  createTag: (groupId, repositoryId, params) =>
    axios({
      method: "POST",
      url: `${API_HOST}/api/fpa/v1/gitlab/group/${groupId}/repository/${repositoryId}/tags`,
      data: params,
    }),
};

// used not with saga - specifically in user autosuggest
export const searchGitlabUsers = (query, permission) =>
  axios({
    method: "GET",
    url: `${API_HOST}/api/fpa/v1/gitlab/users/${query}`,
    params: { permission },
  });

export const getBranches = (repositoryId) =>
  axios({
    method: "GET",
    url: `${API_HOST}/api/fpa/v1/gitlab/repository/${repositoryId}/branches`,
  });
