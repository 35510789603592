import get from "lodash.get";
import { generateId } from "../../../utils/helpers";

export function initializeFormValues({ isNew, externalSystemToEdit: extSys }) {
  if (isNew) {
    return {
      localId: null,
      name: "",
      sourceMode: "",
      sourceLocation: "",
      informationConsumer: "",
      consumerLocation: "",
      authentication: "",
      transport: "",
      transData: "",
      interfaces: "",
      internalInterfaceId: "",
      insideDC: true,
      periodicity: "",
      totalAmountPerDay: "",
      regulation: null
    };
  }
  return {
    localId: extSys.localId,
    name: extSys.name,
    sourceMode: get(extSys, "sourceMode.id", ""),
    sourceLocation: get(extSys, "sourceLocation.id", ""),
    informationConsumer: get(extSys, "informationConsumer.id", ""),
    consumerLocation: get(extSys, "consumerLocation.id", ""),
    authentication: get(extSys, "authentication.id"),
    transport: get(extSys, "transport.id", ""),
    transData: get(extSys, "transData.id", ""),
    interfaces: get(extSys, "interfaces.id", ""),
    internalInterfaceId: extSys.internalInterfaceId,
    insideDC: extSys.insideDC,
    periodicity: extSys.periodicity,
    totalAmountPerDay: extSys.totalAmountPerDay,
    regulation: extSys.regulation || null
  };
}

export function processFormValues(values, extSysDictionaries) {
  const form = {
    id: 0,
    localId: values.localId || generateId(),
    name: values.name,
    insideDC: values.insideDC,
    internalInterfaceId: values.internalInterfaceId,
    periodicity: values.periodicity,
    totalAmountPerDay: values.totalAmountPerDay
  };

  Object.keys(extSysDictionaries).forEach(dict => {
    const selectedValueId = parseInt(values[dict], 10);
    form[dict] = extSysDictionaries[dict].find(
      dictEntry => dictEntry.id === selectedValueId
    );
  });

  if (values.regulation) {
    form.regulation = {
      id: 0,
      file: values.regulation,
      // documentType is hardcoded for Regulation type...
      documentType: {
        id: "40"
      },
      comment: ""
    };
  }

  return form;
}
