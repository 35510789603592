import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router/immutable";
import PropTypes from "prop-types";
import { Map } from "immutable";
import {
  releaseType,
  projectType,
  releaseDictionariesType,
  groupTagsType,
  tagType,
  userType
} from "../../types";
import { fetchReleaseById, submitRelease } from "../../store/releases/actions";
import { fetchDictionaries } from "../../store/dictionaries/actions";
import { fetchGroupTags, fetchTag } from "../../store/gitlab/actions";
import { fetchProjectById } from "../../store/project/actions";
import { showModal } from "../../store/modal/actions";
import {
  makeSelectRelease,
  makeSelectProject,
  makeSelectTags,
  makeSelectTagsResults
} from "../../store/project/selectors";
import { makeSelectReleaseDictionaries } from "../../store/dictionaries/selectors";
import ReleaseEditor from "../../components/ReleaseEditor";
import { RELEASE_DICTIONARIES, KEY_TEMP_DATA } from "../../utils/constants";
import {deleteTempData} from "../../utils/helpers"
import { makeSelectLocalLoaders } from "../../store/preloader/selectors";
import { makeSelectUser } from "../../store/auth/selectors";


class ReleaseEditorPage extends Component {
  state = {

  }

  componentDidMount() {
    
    const {
      isNew,
      match: {
        params: { releaseId, glGroup, projectId }
      },
      fetchRelease,
      fetchReleaseDictionaries,
      fetchGroupTags: fetchTags,
      project,
      fetchProject
    } = this.props;

    //  подробнее смотри initializeFormValues
    deleteTempData(KEY_TEMP_DATA.KEY_TEMP_RELEASE) 


    if (!isNew) {
      fetchRelease(releaseId);
      fetchProject(projectId);
    }
    fetchReleaseDictionaries();
    fetchTags(glGroup);

    // fallback for page reload...
    if (project.id === 0) {
      fetchProject(projectId);
    }
  }

  componentWillUnmount() {
     //  подробнее смотри initializeFormValues
    deleteTempData(KEY_TEMP_DATA.KEY_TEMP_RELEASE)
  }

  handleSubmitRelease = form => {
    const {
      onSubmitRelease,
      isNew,
      match: {
        params: { releaseId, projectId }
      }
    } = this.props;

    onSubmitRelease(isNew ? 0 : releaseId, projectId, form);
  };

  handleTagChange = (repositoryId, tagId) => {
    this.props.fetchTag(this.props.match.params.glGroup, repositoryId, tagId);
  };

  handleCancelClick = () => {
    const { pushTo, project } = this.props;
    pushTo(`/project/${project.id}`);
  };

  render() {
    const {
      isNew,
      release,
      project,
      releaseDictionaries,
      groupTags,
      tagsResults,
      onShowModal,
      loaders,
      user
    } = this.props;

    return (
      <ReleaseEditor
        user={user}
        isNew={isNew}
        release={isNew ? null : release}
        project={project}
        onSubmitForm={this.handleSubmitRelease}
        releaseDictionaries={releaseDictionaries}
        groupTags={groupTags}
        tagsResults={tagsResults}
        onChangeTag={this.handleTagChange}
        onShowModal={onShowModal}
        onClickCancel={this.handleCancelClick}
        loaders={loaders}
      />
    );
  }
}

const mapStateToProps = state => ({
  release: makeSelectRelease(state),
  releaseDictionaries: makeSelectReleaseDictionaries(state),
  groupTags: makeSelectTags(state),
  tagsResults: makeSelectTagsResults(state),
  project: makeSelectProject(state),
  loaders: makeSelectLocalLoaders(state),
  user: makeSelectUser(state),
});

const mapDispatchToProps = dispatch => ({
  fetchProjectById: id => dispatch(fetchProjectById(id)),
  fetchRelease: id => dispatch(fetchReleaseById(id)),
  fetchReleaseDictionaries: () => dispatch(fetchDictionaries(RELEASE_DICTIONARIES)),
  fetchGroupTags: id => dispatch(fetchGroupTags(id)),
  fetchTag: (groupId, repositoryId, tagId) => dispatch(fetchTag(groupId, repositoryId, tagId)),
  onSubmitRelease: (releaseId, projectId, form) => dispatch(submitRelease(releaseId, projectId, form)),
  fetchProject: id => dispatch(fetchProjectById(id)),
  onShowModal: (modalType, props) => dispatch(showModal(modalType, props)),
  pushTo: location => dispatch(push(location))
});

ReleaseEditorPage.propTypes = {
  release: releaseType.isRequired,
  project: projectType.isRequired,
  releaseDictionaries: releaseDictionariesType.isRequired,
  groupTags: groupTagsType.isRequired,
  tagsResults: tagType.isRequired,

  fetchProjectById: PropTypes.func.isRequired,
  fetchRelease: PropTypes.func.isRequired,
  fetchReleaseDictionaries: PropTypes.func.isRequired,
  fetchGroupTags: PropTypes.func.isRequired,
  fetchTag: PropTypes.func.isRequired,
  onSubmitRelease: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  pushTo: PropTypes.func.isRequired,
  user: userType.isRequired,
  isNew: PropTypes.bool,
  match: PropTypes.shape().isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired
};

ReleaseEditorPage.defaultProps = {
  isNew: false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseEditorPage);
