import {StageCodeAnalyzeStatusType, StageStatusType, StageType} from "../../../types";

function PipelineStagePrint(project, pipeline, stageType){

    // Безопасно получить статус из объекта
    const getStatusSafe = (object) => {
        if (object === null){
            return StageStatusType.UNKNOWN
        }
        return (object.status === null 
            ? StageStatusType.UNKNOWN 
            : object.status);
    }

    // Получить статус
    const getEffectiveStageStatus = (pipeline, stage) => {
        const pipelineStatus = getStatusSafe(pipeline);

        if (pipelineStatus === StageStatusType.PENDING){
            return StageStatusType.PENDING
        }
        return (pipelineStatus === StageStatusType.RUNNING 
            ? StageStatusType.RUNNING 
            : getStatusSafe(stage));
    }

    // Получить описание шага по типу шага
    const getStageByType = (pipeline, stageType) => {
        if (pipeline === null) {
            return null;
        }
        switch (stageType) {
            case StageType.BUILD:
                return pipeline.stageBuild;
            case StageType.CODE_ANALYZE:
                return pipeline.stageCodeAnalyze;
            case StageType.DEPLOY:
                return pipeline.stageDeploy;
            case StageType.TEST_AUTOMATIC:
                return pipeline.stageAutomaticTests;
            case StageType.TEST_PERFORMANCE:
                return pipeline.stagePerformanceTests;
            case StageType.TEST_MANUAL:
                return pipeline.stageManualTests;
            default:
                return null;
        }
    }

    const renderStatusBadge = (status, fallback) => {
        if (status === StageStatusType.FAILURE) {
            return 'Неудачно ';
        } if (status === StageStatusType.PENDING) {
            return 'В ожидании ';
        } if (status === StageStatusType.RUNNING) {
            return 'Выполняется ';
        } if (status === StageStatusType.SUCCESSFULLY) {
            return 'Успешно ';
        } if (status === StageStatusType.CANCELED) {
            return 'Отменено ';
        } if (status === StageStatusType.UNKNOWN) {
            return 'Нет данных ';
        }
        if( fallback !== null ) {
            return fallback(status);
        }
        return null;
    }

    const renderStatusBadgeNoData = () => 'Нет данных'

    const renderStaticAnalyzeBadge = (pipeline) => {
        if(pipeline === null || pipeline.stageCodeAnalyzeProjectStatus === null) {
            return null;
        }
        const status = pipeline.stageCodeAnalyzeProjectStatus
        if (status === StageCodeAnalyzeStatusType.OK) {
            return 'ОК';
        } if (status === StageCodeAnalyzeStatusType.WARN) {
            return 'Внимание';
        } if (status === StageCodeAnalyzeStatusType.ERROR) {
            return 'Ошибка';
        }
        return null;
    }

    const renderTestAutomaticBadge = (pipeline) => {
        if(pipeline === null || pipeline.stageAutomaticTestsCoverageP === null) {
            return null;
        }
        return `${pipeline.stageAutomaticTestsCoverageP.toFixed()}% `
    }

    const renderTestManualBadge = (pipeline) => {
        if (pipeline === null ||
            (pipeline.stageManualTestsAllTestCount === null &&
                pipeline.stageManualTestsSuccessfullyTestCount === null &&
                pipeline.stageManualTestFailTestCount === null)) {
            return null;
        }
        const safeAllCount = pipeline.stageManualTestsAllTestCount || "?";
        const safeSuccess = pipeline.stageManualTestsSuccessfullyTestCount || "?";
        const safeFail = pipeline.stageManualTestFailTestCount || "?";
        return {safeAllCount}/{safeSuccess}/{safeFail};
    }

    const renderBuildStage = (project, pipeline, stage, status) => renderStatusBadge(status, renderStatusBadgeNoData())

    const renderCodeAnalyzeStage = (project, pipeline, stage, status) => (renderStatusBadge(status, null) + renderStaticAnalyzeBadge(pipeline))

    const renderDeployStage = (project, pipeline, stage, status) => renderStatusBadge(status, null, null)
 
    const renderTestAutomaticStage = (project, pipeline, stage, status) => (renderStatusBadge(status, null, null) + renderTestAutomaticBadge(pipeline))

    const renderTestPerformanceStage = (project, pipeline, stage, status) => renderStatusBadge(status, null, null)

    const renderTestManualStage = (project, pipeline, stage, status) => renderStatusBadge(status, null, null) + renderTestManualBadge(pipeline)

    // Получить описание шага и общий статус операции
    const stage = getStageByType(pipeline, stageType);
    if (stage === null) {
        return null;
    }
    const status = getEffectiveStageStatus(pipeline, stage);

    switch (stageType) {
        case StageType.BUILD:
            return renderBuildStage(project, pipeline, stage, status);
        case StageType.CODE_ANALYZE:
            return renderCodeAnalyzeStage(project, pipeline, stage, status);
        case StageType.DEPLOY:
            return renderDeployStage(project, pipeline, stage, status);
        case StageType.TEST_AUTOMATIC:
            return renderTestAutomaticStage(project, pipeline, stage, status);
        case StageType.TEST_PERFORMANCE:
            return renderTestPerformanceStage(project, pipeline, stage, status);
        case StageType.TEST_MANUAL:
            return renderTestManualStage(project, pipeline, stage, status);
        default:
            return null;
    }
}

export default PipelineStagePrint;
