import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import ReactTable from "react-table";
import LoadingWrapper from "../common/LoadingWrapper";
import { FETCH_TECH_SOLUTIONS } from "../../store/documents/constants";
import { techSolutionsType } from "../../types/index";
import { PageHeaderBlock, PageHeaderTitle, LinkSpan } from "../common/styles";
import Pagination from "../common/Pagination";
import { ButtonAdd, ButtonIconTrash } from "../common/Buttons";

function TechSolutions(props) {
  const {
    techSolutions,
    onUploadDocument,
    onDeleteDocument,
    onDownloadDocument,
    loaders
  } = props;

  return (
      <div>
        <PageHeaderBlock margin="10px 0 50px 0">
          <PageHeaderTitle>ТИПОВЫЕ ТЕХНИЧЕСКИЕ РЕШЕНИЯ</PageHeaderTitle>
          <ButtonAdd text="Добавить" onClick={onUploadDocument} />
        </PageHeaderBlock>
        <LoadingWrapper
            loaders={loaders}
            loaderKey={FETCH_TECH_SOLUTIONS}
            showPlaceholder={techSolutions.length === 0}
            placeholderText="ТИПОВЫЕ ТЕХНИЧЕСКИЕ РЕШЕНИЯ ОТСУТСТВУЮТ"
        >
          <ReactTable
              PaginationComponent={Pagination}
              showPagination={techSolutions.length > 10}
              defaultPageSize={10}
              minRows={0}
              data={techSolutions}
              columns={[
                {
                  Header: "ДОКУМЕНТ",
                  id: "fileOriginalName",
                  Cell: row => (
                      <LinkSpan
                          onClick={() => {
                            onDownloadDocument(row.original.id);
                          }}
                      >
                        {row.original.fileOriginalName}
                      </LinkSpan>
                  )
                },
                {
                  Header: "ОПИСАНИЕ",
                  accessor: "comment"
                },
                {
                  Header: "",
                  accessor: "id",
                  maxWidth: 50,
                  Cell: row => (
                      <ButtonIconTrash
                          onClick={() => {
                            onDeleteDocument(row.value);
                          }}
                      />
                  )
                }
              ]}
          />
        </LoadingWrapper>
      </div>
  );
}

TechSolutions.propTypes = {
  techSolutions: techSolutionsType.isRequired,

  onUploadDocument: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,

  loaders: PropTypes.instanceOf(Map).isRequired
};

export default TechSolutions;
