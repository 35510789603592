import React from "react";
import PropTypes from "prop-types";
import { dictionaryEntriesType } from "../../../types";
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
  ModalBody
} from "../../common/Modal/styles";
import { ButtonIconClose } from "../../common/Buttons";
import DocumentsForm from "../DocumentsForm";

function DocumentUploadModal(props) {
  const {
    documentTypeDictionary,
    onSaveDocument,
    initialDocumentTypeId,
    closeModal
  } = props;

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalHeaderTitle>ДОБАВЛЕНИЕ ДОКУМЕНТА</ModalHeaderTitle>
        <ButtonIconClose onClick={closeModal} />
      </ModalHeader>
      <ModalBody>
        <DocumentsForm
          documentTypeDictionary={documentTypeDictionary}
          onSubmitForm={onSaveDocument}
          initialDocumentTypeId={initialDocumentTypeId}
          closeModal={closeModal}
        />
      </ModalBody>
    </ModalContainer>
  );
}

DocumentUploadModal.propTypes = {
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  onSaveDocument: PropTypes.func.isRequired,
  initialDocumentTypeId: PropTypes.number,

  closeModal: PropTypes.func.isRequired
};

DocumentUploadModal.defaultProps = {
  initialDocumentTypeId: 0
};

export default DocumentUploadModal;
