import get from "lodash.get";

export function initializeFormValues({isNew, project}) {
    const form = {};

    form.name = get(project, "name", "");
    form.fullName = get(project, "fullName", "");
    form.description = get(project, "description", "");
    form.incidentURL = get(project, "incidentURL", "");
    form.codeAsuz = get(project, "referenceAsuz.code", "");
    form.nameAsuz = get(project, "referenceAsuz.name", "");
    form.sonarQubeProjectCode = get(project, "sonarQubeProjectCode", "");
    form.testStandUrl = get(project, "testStandUrl", "");
    form.passportCYYCIT = get(project, "passportCYYCIT", "");
    form.kubernetes = get(project, "kubernetes", "");
    form.grafana = get(project, "grafana", "");

    if(isNew) {
        // helper fields - not actually changed in form
        form.currentForm = "project-new";
    }
    else {
        // helper fields - not actually changed in form
        form.currentForm = "project-edit";
    }

    form.users = get(project, "userPermissions", []);
    form.reportTemplates = get(project, "reportTemplates", []);
    form.requiredDocuments = get(project, "requiredDocuments", []).map(item => item.id);
    form.signReleaseInventory = get(project, "signReleaseInventory", false);
    form.dictionaryEntry = get(project, "dictionaryEntry", null);
    return form;
}
