import styled from "styled-components";
import { Input } from "../styles";

export const ApiContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #ffffff;
`;

export const ApiPageSizeBlock = styled.div`
  margin-left: 34px;
`;

export const ApiPaginationLabel = styled.label`
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #394a58;
  margin-right: 12px;
`;

export const ApiNavigationBlock = styled.div`
  padding-right: 28px;
`;

export const ApiPaginationInput = styled(Input)`
  width: 70px;
  margin-right: 10px;
`;