export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const fetchNotifications = (page, size) => ({
  type: FETCH_NOTIFICATIONS,
  payload: {
    page,
    size
  }
});

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const setNotifications = notifications => ({
  type: SET_NOTIFICATIONS,
  payload: {
    notifications
  }
});

export const FETCH_NEW_NOTIFICATIONS_COUNT = "FETCH_NEW_NOTIFICATIONS_COUNT";
export const fetchNewNotificationsCount = () => ({
  type: FETCH_NEW_NOTIFICATIONS_COUNT
});

export const SET_NEW_NOTIFICATIONS_COUNT = "SET_NEW_NOTIFICATIONS_COUNT";
export const setNewNotificationsCount = count => ({
  type: SET_NEW_NOTIFICATIONS_COUNT,
  payload: {
    count
  }
});

export const FETCH_NEW_NOTIFICATIONS = "FETCH_NEW_NOTIFICATIONS";
export const fetchNewNotifications = () => ({
  type: FETCH_NEW_NOTIFICATIONS
});

export const SET_NEW_NOTIFICATIONS = "SET_NEW_NOTIFICATIONS";
export const setNewNotifications = notifications => ({
  type: SET_NEW_NOTIFICATIONS,
  payload: {
    notifications
  }
});
