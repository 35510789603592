import { SHOW_MODAL, HIDE_MODAL } from "./constants";

export const showModal = (modalType, props) => ({
  type: SHOW_MODAL,
  payload: {
    modalType,
    props
  }
});

export const hideModal = () => ({
  type: HIDE_MODAL
});