import { createSelector } from "reselect";

const selectModal = state => state.get("modal");
const makeSelectModal = createSelector(
  selectModal,
  substate => substate.toJS()
);

export {
  makeSelectModal
};
