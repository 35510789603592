import React, { Component } from "react";
import { BoldSpan } from "../../components/common/styles";

export class RedirectComponent extends Component {
  constructor(props) {
    super();
    this.state = { ...props };
  }

  componentDidMount() {
    window.location = this.state.loc;
  }

  render() {
    return <BoldSpan>REDIRECTING</BoldSpan>;
  }
}

export default RedirectComponent;
