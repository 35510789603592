import {
    DELETE_PROJECT,
    FETCH_PROJECT_BY_ID,
    FETCH_PROJECTS,
    FETCH_ALL_PROJECTS,
    FETCH_PROJECTS_OPTIONS,
    GET_INFO_PAPELINE,
    SEARCH_PROJECTS,
    SET_INFO_PAPELINE,
    SET_PAGES,
    SET_PROJECT,
    SET_PROJECTS,
    SET_ALL_PROJECTS,
    SET_PROJECTS_OPTIONS,
    SET_RELEASE,
    SUBMIT_PROJECT,
    SET_BACK_VERSION,
    SET_FRONT_VERSION,
    RESTORE_PROJECT, FETCH_PROJECTS_METRICK, SEARCH_PROJECTS_METRICK, SIMPLE_SEARCH_PROJECT, SET_SIMPLE_PROJECT
} from "./constants";

/**
 * @deprecated
 */
export const fetchProjects = (pageNumber, pageSize, sortByField, sortByOrder, inArchive) => ({
    type: FETCH_PROJECTS,
    payload: {
        pageNumber,
        pageSize,
        sortByField,
        sortByOrder,
        inArchive
    }
});

export const fetchProjectsMetricks = (pageNumber, pageSize, sortByField, sortByOrder, inArchive,department) => ({
    type: FETCH_PROJECTS_METRICK,
    payload: {
        pageNumber,
        pageSize,
        sortByField,
        sortByOrder,
        inArchive,
        department
    }
});

export const fetchAllProjects = (sortByField, sortByOrder, inArchive) => ({
    type: FETCH_ALL_PROJECTS,
    payload: {
        sortByField,
        sortByOrder,
        inArchive
    }
});

export const fetchProjectsOptions = () => ({
    type: FETCH_PROJECTS_OPTIONS,
});

/**
 * @deprecated
 * @param query
 * @param pageNumber
 * @param pageSize
 * @param sortByField
 * @param sortByOrder
 * @param inArchive
 * @returns {{payload: {sortByField, pageNumber, sortByOrder, query, pageSize, inArchive}, type: string}}
 */
export const searchProjects = (query, pageNumber, pageSize, sortByField, sortByOrder, inArchive) => ({
    type: SEARCH_PROJECTS,
    payload: {
        query,
        pageNumber,
        pageSize,
        sortByField,
        sortByOrder,
        inArchive
    }
});

export const searchProjectsAndMetrika = (query, pageNumber, pageSize, sortByField, sortByOrder, inArchive, department) => ({
    type: SEARCH_PROJECTS_METRICK,
    payload: {
        query,
        pageNumber,
        pageSize,
        sortByField,
        sortByOrder,
        inArchive,
        department
    }
});



export const setProjects = projects => ({
    type: SET_PROJECTS,
    payload: {
        projects
    }
});

export const setPages = pages => ({
    type: SET_PAGES,
    payload: {
        pages
    }
});

export const setAllProjects = projects => ({
    type: SET_ALL_PROJECTS,
    payload: {
        projects
    }
});

export const fetchProjectById = id => ({
    type: FETCH_PROJECT_BY_ID,
    payload: {
        id
    }
});

export const setProject = project => ({
    type: SET_PROJECT,
    payload: {
        project
    }
});

export const setProjectsOptions = projectsOptions => ({
    type: SET_PROJECTS_OPTIONS,
    payload: {
        projectsOptions
    }
});

export const submitProject = (projectId, form) => ({
    type: SUBMIT_PROJECT,
    payload: {
        projectId,
        form
    }
});

export const deleteProject = id => ({
    type: DELETE_PROJECT,
    payload: {
        id
    }
});

export const setRelease = release => ({
    type: SET_RELEASE,
    payload: {
        release
    }
});

export const getInfoPaipline = params => ({
    type: GET_INFO_PAPELINE,
    payload: {
        params
    }
});

export const setInfoPaipline = params => ({
    type: SET_INFO_PAPELINE,
    payload: {
        params
    }
});

export const setBackVersion = version => ({
    type: SET_BACK_VERSION,
    payload: {
        version
    }
});

export const setFrontVersion = () => ({
    type: SET_FRONT_VERSION
});


export const restoreProject = params => ({
    type: RESTORE_PROJECT,
    payload: {
        params
    }
})

export const simpleSearchProject = (searchName) => ({
    type: SIMPLE_SEARCH_PROJECT,
    payload: {
        searchName,
    }
})

export const setSimpleProjects = projects => ({
    type: SET_SIMPLE_PROJECT,
    payload: {
        projects
    }
});
