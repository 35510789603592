import styled from "styled-components";
import { Link } from "react-router-dom";

const IconSelectArrow = require("../../assets/icons/ic_select_arrow.svg");

export const ErrorMessage = styled.div`
    padding: 5px 20px;
    font-family: Roboto-Medium;
    background-color: #f44336;
    color: white;
    opacity: 0.9;
    margin: 10px 0;
    border-radius: 3px;
    font-size: 14px;
`;

export const SuccessMessage = styled.div`
    padding: 5px 20px;
    font-family: Roboto-Medium;
    background-color: #28a745;
    color: white;
    opacity: 0.9;
    margin: 10px 0;
    border-radius: 3px;
    font-size: 14px;
`;

export const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex}
  flex-direction: ${props => props.flexDirection}
  justify-content: ${props => props.justifyContent}
  align-items: ${props => props.alignItems}
  ${props => props.margin && `margin: ${props.margin}`}
`;

Flex.defaultProps = {
  flex: "1 1 auto",
  flexDirection: "row",
  justifyContent: "normal",
  alignItems: "normal"
};

export const LinkStyled = styled(Link)`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #1e96c8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkSpan = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
  color: #1e96c8;

  &:hover {
    text-decoration: underline;
  }
`;

export const TextRegular = styled.span`
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #394a58;
  word-break: break-word;
`;

export const TextAlert = styled.div`
  font-family: Roboto-Regular;
  font-size: 16px;
  color: red;
  word-break: break-word;
  padding: 10px 0;
`;

export const PageHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  ${props =>
    props.editor &&
    `justify-content: flex-start;`}
  ${props => props.margin && `margin: ${props.margin}`}
`;

export const PageHeaderTitle = styled.h1`
  margin: 0;
  font-family: Roboto-Bold;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  color: #394a58;
`;

export const SectionHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;

  .button-styled {
    margin-left: 10px;
  }

  ${props =>
    props.editor &&
    `justify-content: flex-start; .button-styled { margin-left: 60px; }`}
`;

export const SectionHeaderTitle = styled.h4`
  margin: 10px 0 16px;

  font-family: Roboto-Bold;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: left;
  color: #394a58;
`;

export const SectionHeaderTitleLink = styled(SectionHeaderTitle)`
  cursor: pointer;
  color: #1e96c8;

  &:hover {
    text-decoration: underline;
  }
`;

export const FilterSectionBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
`;

export const FilterSearchBlock = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const ButtonsRow = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

export const BoldSpan = styled.span`
  font-family: Roboto-Bold;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
`;

export const Input = styled.input`
  ${props => props.width && `width: ${props.width}`}
  
  &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  height: 30px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #999999;
  outline: none;
  padding: 0 10px;

  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.21;
  text-align: left;
  color: #394a58;
`;

export const TextArea = styled.textarea`
  ${props => props.width && `width: ${props.width}`}
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #999999;
  outline: none;
  padding: 10px;

  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.21;
  text-align: left;
  color: #394a58;
`;

export const Select = styled.select`
  ${props => props.width && `width: ${props.width};`}
  height: 30px;
  border-color: #999999;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 0 40px 0 10px;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${IconSelectArrow});
  background-size: 12px;
  background-position: calc(100% - 14px);
  background-repeat: no-repeat;

  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.21;
  text-align: left;
  color: #394a58;

  ::-ms-expand {
    display: none;
  }
`;

export const SelectMulti = styled.select`
  width: 40%;
  height: 100px;
  border-color: #999999;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  outline: none;

  overflow-y: auto;

  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 1.21;
  text-align: left;
  color: #394a58;
`;

export const CheckboxSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 30px;

  .icon {
    margin-right: 20px;
  }
`;


export const SubTitle = styled.span`
  margin: 0;
  font-family: Roboto-Bold;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #394a58;
`;
