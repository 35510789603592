import styled from "styled-components";

export const PreloaderContainer = styled.div`
  ${props => (props.visible ? `display: flex;` : `display: none`)};
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(218, 224, 229, 0.5);
  height: 100%;
  width: 100%;
  z-index: 10;
`;

export const PreloaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PreloaderItem = styled.span`
  font-family: Roboto-Bold;
  font-size: 20px;
  line-height: 20px;
  margin-top: 30px;
  color: #244a97;
`;
