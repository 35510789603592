import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import moment from "moment";
import { modalStyles } from "../../utils/theme";
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderTitle,
} from "../common/Modal/styles";
import { ButtonIconClose } from "../common/Buttons";

class InfoModal extends Component {
  render() {
    const { isVisible, values, handleClose } = this.props;

    return (
      <Modal isOpen={isVisible} style={modalStyles}>
        <ModalContainer>
          <ModalHeader>
            <ModalHeaderTitle />
            <ButtonIconClose onClick={handleClose} />
          </ModalHeader>
          <ModalBody>
            <div style={{ marginBottom: "5px" }}>
              <strong>Название файла:</strong> {values.name}
            </div>
            <div style={{ marginBottom: "5px" }}>
              <strong>Описание:</strong> {values.comment}
            </div>
            <div style={{ marginBottom: "5px" }}>
              <strong>Документ добавил:</strong> {values.author}
            </div>
            <div style={{ marginBottom: "5px" }}>
              <strong>Время добавления:</strong>{" "}
              {moment(values.creationDate).format("YYYY.MM.DD HH:mm")}
            </div>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

InfoModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  values: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InfoModal;
