import styled from "styled-components";

export const Button = styled.button.attrs({
  className: "button-styled"
})`
  ${props => props.margin && `margin: ${props.margin}`}
  width: fit-content;
  height: 30px;
  padding: 4px 23px;
  border: 2px solid #88a4bd;
  border-radius: 20px;
  background-color: transparent;
  color: #88a4bd;
  font-family: Roboto-Medium,serif;
  line-height: 17px;
  font-size: 14px;
  outline: none;
  margin: 0 5px;
  &:hover {
    background-color: #fb2921;
    border-color: #fb2921;
    color: white;
    ${props => props.hbgc && `background-color: ${props.hbgc}`}
    ${props => props.hbc && ` border-color: ${props.hbc}`}
    ${props => props.hc && `color: ${props.hc}`}
    
  }

  &:disabled {
    opacity: 0.4;
  }

  &:focus {
    border-color: #fb2921;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
`;

export const ButtonIcon = styled(Button)`
  display: inline-flex;
  padding: 4px 23px;
  align-items: center;
  
  .icon {
    padding-right: 8px;
  }
  font {
    vertical-align: middle!important;
  }
  &:hover {
    .icon {
      fill: white;
      ${props => props.hover && `stroke: #ffffff`}
    }
  }
`;

export const ButtonIconGrey = styled(ButtonIcon)`
  background-color: #8aa5be;
  // border: none;
  color: white;
  padding: 6px 23px;
`;

export const ButtonIconOnly = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  margin: 0 0px;
`;

export const ButtonWhiteOutline = styled(Button)`
  border-color: #fff;
  color: #fff;
  margin: 0 10px;

  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #394a58;
  }
`;

export const ButtonRedOutline = styled(Button)`
  border-color: #fb2921;
  color: #fb2921;

  &:hover {
    background-color: #fb2921;
    border-color: #fb2921;
    color: white;
  }
`;

export const ButtonIconRight = styled(Button)`
  padding: 4px 20px 0 24px;
  align-items: center;

  display: inline-flex;
  color: white;
  background-color: #fb2921;
  border-color: #fb2921;

  .icon {
    padding-left: 7px;
  }

  &:hover {
    .icon {
      fill: white;
      stroke: #ffffff;
    }
  }
`;


export const ButtonLink = styled(Button)`
  color: #fff;
  outline: none;
  border: none;
  border-bottom:  2px solid transparent;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    color: #fff;
    background: transparent ;
    border-bottom:  2px solid #fff;
  }
`;


