import {
    DELETE_PERMISSIONS_ADMINISTRATION,
    FETCH_LIST_PERMISSIONS_ADMINISTRATION,
    FETCH_PERMISSIONS_PROJECT_BY_ID_ADMINISTRATION,
    FETCH_PERMISSIONS_USER_BY_ID_ADMINISTRATION,
    FETCH_PROJECTS_ADMINISTRATION,
    FETCH_USER_ADMINISTRATION,
    SET_FILTER_ADMINISTRATION,
    SET_LIST_PERMISSIONS_ADMINISTRATION,
    SET_PROJECT_ADMINISTRATION,
    SET_PROJECTS_ADMINISTRATION,
    SET_USER_ADMINISTRATION,
    SET_USERS_ADMINISTRATION,
    UPDATE_PERMISSIONS_ADMINISTRATION
} from "./constants";

export const fetchProjectsAdministration = () => ({
    type: FETCH_PROJECTS_ADMINISTRATION
});
export const fetchUserAdministration = () => ({
    type: FETCH_USER_ADMINISTRATION
});
export const fetchListPermissionsAdministration = () => ({
    type: FETCH_LIST_PERMISSIONS_ADMINISTRATION
});
export const fetchUpdatePermissionsAdministration = () => ({
    type: UPDATE_PERMISSIONS_ADMINISTRATION
});
export const fetchDeletePermissionsAdministration = () => ({
    type: DELETE_PERMISSIONS_ADMINISTRATION
});

export const fetchProjectByIdPermissionsAdministration = (id) => ({
    type: FETCH_PERMISSIONS_PROJECT_BY_ID_ADMINISTRATION,
    payload: {
        id
    }
});
export const fetchUserByIdPermissionsAdministration = (id) => ({
    type: FETCH_PERMISSIONS_USER_BY_ID_ADMINISTRATION,
    payload: {
        id
    }
});


export const setFilterAdministration = (page = 0,
     size = 10,
     nameProject = '',
     nameUser = '',
     totalElements = 100,
     maxPage = 10,
    ) => ({
        type: SET_FILTER_ADMINISTRATION,
        payload: {
            page,
            size,
            nameProject,
            nameUser,
            totalElements,
            maxPage,
        }
    });

export const resetFilterAdministration = () => ({
    type: SET_FILTER_ADMINISTRATION,
    payload: {
        page: 0,
        size: 10,
        nameProject: '',
        nameUser: '',
        totalElements: 1,
        maxPage: 1,
    }
});


export const setProjectAdministration =
    (projects = []) => ({
        type: SET_PROJECTS_ADMINISTRATION,
        payload: {
            projects
        }
    });

export const setOpenProjectAdministration =
    (members = []) => ({
        type: SET_PROJECT_ADMINISTRATION,
        payload: {
            members
        }
    });
export const setOpenUserAdministration =
    (permissions = []) => ({
        type: SET_USER_ADMINISTRATION,
        payload: {
            permissions
        }
    });
export const setUsersAdministration =
    (users = []) => ({
        type: SET_USERS_ADMINISTRATION,
        payload: {
            users
        }
    });
export const setListPermissionAdministration =
    (users = []) => ({
        type: SET_LIST_PERMISSIONS_ADMINISTRATION,
        payload: {
            users
        }
    });
