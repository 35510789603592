import React from "react";
import PropTypes, {number} from "prop-types";
import { Map } from "immutable";
import LoadingWrapper from "../common/LoadingWrapper";
import {FETCH_PROJECTS, FETCH_ALL_PROJECTS, FETCH_PROJECTS_METRICK} from "../../store/project/constants";
import { projectsType, sortType } from "../../types";
import { Flex } from "../common/styles";
import RegistryFilter from "./RegistryFilter";
import RegistryTable from "./RegistryTable";
import { ExportBlock } from "./ExportBlock"

const Registry = props => (
  <Flex flexDirection="column">
    <RegistryFilter
      onCreateProject={props.onCreateProject}
      onChangeQuery={props.onChangeQuery}
      onChangeInArchive={props.onChangeInArchive}
      onSearchProjects={props.onSearchProjects}
      onClear={props.onClear}
      searchQuery={props.searchQuery}
      inArchive={props.inArchive}
      isAdmin={props.isAdmin}
      loaders={props.loaders}
      onSyncProjectInGigLab={props.onSyncProjectInGigLab}

      onSelectDepartment={props.onSelectDepartment}
      departmentSelected={props.departmentSelected}
    />
    <LoadingWrapper
        loaders={props.loaders}
        loaderKey={FETCH_PROJECTS_METRICK}
        showPlaceholder
        />

    <LoadingWrapper
      loaders={props.loaders}
      loaderKey={FETCH_PROJECTS}
      showPlaceholder={props.projects.length === 0}
      placeholderText="ПРОЕКТЫ НЕ НАЙДЕНЫ"
    >
      <RegistryTable
        pageNumber={props.pageNumber}
        pageSize={props.pageSize}
        totalPages={props.totalPages}
        projects={props.projects}
        handleSetSort={props.handleSetSort}
        handleInputPage={props.handleInputPage}
        handlePageSize={props.handlePageSize}
        sorted={props.sorted}
        loaders={props.loaders}
      />
      <LoadingWrapper
        loaders={props.loaders}
        loaderKey={FETCH_ALL_PROJECTS}
        showPlaceholder={props.projects.length === 0}
        placeholderText="НЕ УДАЛОСЬ ЗАГРУЗИТЬ ФАЙЛ"
      >
        <ExportBlock projects={props.allProjects} />
      </LoadingWrapper>
    </LoadingWrapper>
  </Flex>
);

Registry.propTypes = {
  projects: projectsType.isRequired,
  allProjects: PropTypes.func.isRequired,
  onCreateProject: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  inArchive: PropTypes.bool.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  onSearchProjects: PropTypes.func.isRequired,
  onChangeInArchive: PropTypes.func.isRequired,
  handleInputPage: PropTypes.func.isRequired,
  handlePageSize: PropTypes.func.isRequired,
  handleSetSort: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onSyncProjectInGigLab: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
  isAdmin: PropTypes.bool.isRequired,

  sorted: sortType.isRequired,
  onClear: PropTypes.func.isRequired,

    departmentSelected:number.isRequired,
    onSelectDepartment: PropTypes.func.isRequired,
};

export default Registry;
