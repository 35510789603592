import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { dictionaryEntriesType } from "../../../types";
import RowBlock from "../../common/RowBlock";
import FileUploader from "./FileUploader";
import { Input, Select, ErrorMessage } from "../../common/styles";
import { ButtonSave } from "../../common/Buttons";
import { generateId } from "../../../utils/helpers";

function DocumentsForm(props) {
  const {
    documentTypeDictionary,

    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched
  } = props;
  const documentTypes = documentTypeDictionary.filter(type => !type.system);

  return (
    <div>
      <RowBlock text="ТИП ДОКУМЕНТА" labelWidth="200px" alignSelfCenter>
        <Select
          name="documentType"
          value={values.documentType}
          onChange={handleChange}
        >
          <option value="" label="Не выбрано">
            Не выбрано
          </option>
          {documentTypes.map(item => (
            <option key={`opt_dt_${item.id}`} value={item.id} label={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
      </RowBlock>
      <RowBlock text="КОММЕНТАРИЙ" labelWidth="200px" alignSelfCenter>
        <Input
          width="80%"
          name="comment"
          onChange={handleChange}
          maxLength={253}
        />
      </RowBlock>
      {touched.file && errors.file && (
        <ErrorMessage>{errors.file}</ErrorMessage>
      )}
      <FileUploader
        selectedFile={values.file}
        onChange={file => {
          setFieldValue("file", file);
        }}
      />
      <ButtonSave
        type="submit"
        onClick={handleSubmit}
        text="Сохранить"
        margin="20px 0 0 0"
      />
    </div>
  );
}

DocumentsForm.propTypes = {
  documentTypeDictionary: dictionaryEntriesType.isRequired,
  // onSubmitForm: PropTypes.func.isRequired,

  values: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const initDocType = props.documentTypeDictionary.find(type => {
      if (props.initialDocumentTypeId !== 0) {
        return type.id === props.initialDocumentTypeId;
      }
      return !type.system;
    });
    return {
      comment: "",
      file: null,
      documentType: initDocType !== undefined ? initDocType.id : ""
    };
  },
  validate: values => {
    const errors = {};
    if (!values.file) {
      errors.file = "Прикрепите, пожалуйста, документ!";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    if (values.file) {
      const newDoc = {
        id: 0,
        localId: generateId(),
        comment: values.comment,
        file: values.file,
        fileOriginalName: values.file.name || "-"
      };
      if (values.documentType !== "") {
        const documentTypeId = parseInt(values.documentType, 10);
        newDoc.documentType = formikBag.props.documentTypeDictionary.find(
          entry => documentTypeId === entry.id
        );
      }
      formikBag.props.onSubmitForm(newDoc);
      formikBag.props.closeModal();
    }
  }
})(DocumentsForm);
