import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { reportsType } from "../../types/index";
import {
  PageHeaderBlock,
  PageHeaderTitle,
  BoldSpan,
  LinkSpan
} from "../common/styles";
import Pagination from "../common/Pagination";
import { ButtonAdd, ButtonIconTrash } from "../common/Buttons";
import ReportUploadModal from "./ReportUploadModal";

class Reports extends Component {
  state = {
    isUploadModalOpen: false
  };

  openUploadModal = () => {
    this.setState({
      isUploadModalOpen: true
    });
  };

  closeUploadModal = () => {
    this.setState({
      isUploadModalOpen: false
    });
  };

  render() {
    const {
      reports,
      onUploadDocument,
      onDeleteDocument,
      onDownloadDocument,
      onDownloadReportExample
    } = this.props;
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth()+1;
    const day = today.getDate();
    return (
      <div>
        <PageHeaderBlock margin="10px 0 30px 0">
          <PageHeaderTitle>ШАБЛОНЫ ОТЧЕТОВ</PageHeaderTitle>
          <ButtonAdd text="Добавить шаблон" onClick={this.openUploadModal} />
        </PageHeaderBlock>
        <ReportUploadModal
          isOpen={this.state.isUploadModalOpen}
          closeModal={this.closeUploadModal}
          onSubmitForm={onUploadDocument}
        />

        {reports.length > 0 ? (
          <ReactTable
            PaginationComponent={Pagination}
            showPagination={reports.length > 10}
            defaultPageSize={10}
            minRows={0}
            data={reports}
            columns={[
              {
                Header: "ДОКУМЕНТ",
                id: "fileOriginalName",
                Cell: row => (
                  <LinkSpan
                    onClick={() => {
                      onDownloadDocument(row.original.id, row.original.fileOriginalName);
                    }}
                  >
                    {row.original.fileOriginalName}
                  </LinkSpan>
                )
              },
                {
                Header: "СКАЧАТЬ ПРИМЕР",
                id: "fileOriginalName",
                style:{ background: "#f4f4f4", height: "36px" },
                Cell: row => (
                  <LinkSpan
                    onClick={() => {
                      onDownloadReportExample(row.original.id, `report_${row.original.fileOriginalName.replace(/\.[^/.]+$/, "")}_${day}.${month}.${year}.pdf`);
                    }}
                  >
                    Пример отчета
                  </LinkSpan>
                )
              },
              {
                Header: "НАЗВАНИЕ",
                accessor: "comment"
              },
              {
                Header: "",
                accessor: "id",
                maxWidth: 50,
                Cell: row => (
                  <ButtonIconTrash
                    onClick={() => {
                      onDeleteDocument(row.value);
                    }}
                  />
                )
              }
            ]}
          />
        ) : (
          <BoldSpan>ШАБЛОНЫ ОТЧЕТОВ ПОКА НЕ ДОБАВЛЕНЫ</BoldSpan>
        )}
      </div>
    );
  }
}

Reports.propTypes = {

  reports: reportsType.isRequired,
  onUploadDocument: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onDownloadDocument: PropTypes.func.isRequired,
  onDownloadReportExample: PropTypes.func.isRequired
};

export default Reports;
