import { createSelector } from "reselect";

const selectGlobalLoaders = state => state.getIn(["preloader", "globalLoaders"]);

const makeSelectGlobalLoaders = createSelector(
  selectGlobalLoaders,
  substate => substate
);

const selectLocalLoaders = state => state.getIn(["preloader", "localLoaders"]);

const makeSelectLocalLoaders = createSelector(
  selectLocalLoaders,
  substate => substate
);

const selectError = state => state.getIn(["preloader", "error"]);

const makeSelectError = createSelector(
  selectError,
  substate => substate
);

const selectSpecificError = state => state.getIn(["preloader", "specificError"]);

const makeSelectSpecificError = createSelector(
  selectSpecificError,
  substate => substate
);

export {
  makeSelectGlobalLoaders,
  makeSelectLocalLoaders,
  makeSelectError,
  makeSelectSpecificError
};
