import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { optionType } from "../../../types";
import { Flex, Input, ErrorMessage } from "../../common/styles";
import { ButtonDelete, ButtonSave } from "../../common/Buttons";
import RowBlock from "../../common/RowBlock";

function DictionaryEntrySubComponent(props) {
  const {
    dictionaryEntry,
    dictionaryCode,
    onDeleteDictionaryEntry,

    values,
    handleChange,
    handleSubmit,
    errors
  } = props;

  return (
    <div>
      <RowBlock text="НАИМЕНОВАНИЕ" labelWidth="20%" alignSelfCenter>
        <Input
          width="100%"
          value={values.entryName}
          name="entryName"
          onChange={handleChange}
          maxLength={253}
        />
      </RowBlock>
      {errors && errors.entryName && (
        <ErrorMessage>{errors.entryName}</ErrorMessage>
      )}
      <Flex justifyContent="space-between" margin="0 0 20px 0">
        <ButtonSave text="Сохранить" onClick={handleSubmit} type="submit" />
        {!dictionaryEntry.system && (
          <ButtonDelete
            text="Удалить"
            onClick={() =>
              onDeleteDictionaryEntry(dictionaryCode, dictionaryEntry.code)
            }
          />
        )}
      </Flex>
    </div>
  );
}

DictionaryEntrySubComponent.propTypes = {
  dictionaryEntry: optionType.isRequired,
  dictionaryCode: PropTypes.string.isRequired,
  // onSubmitDictionaryEntry: PropTypes.func.isRequired,
  onDeleteDictionaryEntry: PropTypes.func.isRequired,

  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    entryName: props.dictionaryEntry.name
  }),
  validate: values => {
    const errors = {};
    if (!values.entryName) {
      errors.entryName =
        "Пожалуйста, введите название для элемента справочника!";
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    const {
      dictionaryCode,
      dictionaryEntry,
      onSubmitDictionaryEntry
    } = formikBag.props;
    onSubmitDictionaryEntry(dictionaryCode, {
      id: dictionaryEntry.id,
      code: values.entryName,
      name: values.entryName
    });
  }
})(DictionaryEntrySubComponent);
