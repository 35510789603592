import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Dropdown from "../../common/Dropdown";
import LoadingWrapper from "../../common/LoadingWrapper";
import {DropdownMenuItem} from "../../common/Dropdown/styles";
import {getInfoPaipline} from "../../../store/project/actions";

class MenuGitExt extends Component {
    getInfo(project, pipeline, job, self) {
        const params = {
            groupId: project.gitlabGroup.id,
            repositoryId: pipeline.repository.gitLabId,
            jobId: job.id,
        };
        self.props.getInfoPipeline(params);
    }

    render() {
        const {project, pipeline, stage, loaders} = this.props;
        const showMenu = stage && stage.jobs ? stage.jobs.filter((job) => job.hasArtifacts) : false;
        if (pipeline.repository == null){
            return <></>;
        }

        const repositoryId = pipeline.repository.gitLabId
        if (showMenu && showMenu.length > 0) {
            const listJobs = stage.jobs
                .map((job, index) => (
                    <DropdownMenuItem
                        key={index}
                        onClick={() => this.getInfo(project, pipeline, job, this)}>
                        {job.name}
                    </DropdownMenuItem>
                ));
            return (            
            <LoadingWrapper
                loaders={loaders}
                loaderClass='dropdown'
                loaderNoText
                loaderKey={`GET_INFO_PAPELINE ${ repositoryId}`}
            >
                <Dropdown>{listJobs}</Dropdown>
            </LoadingWrapper>
            
            )
        } 
            return <></>;
        
    }
}

// export default MenuGitExt;
MenuGitExt.propTypes = {
    loaders: PropTypes.instanceOf(Map).isRequired,
}
const mapStateToProps = (state) => ({
    // project: makeSelectProject(state)
});

const mapDispatchToProps = (dispatch) => ({
    getInfoPipeline: (query) => dispatch(getInfoPaipline(query)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuGitExt);
