import auth from "./auth";
import gitlab from "./gitlab";
import project from "./project";
import dictionaries from "./dictionaries";
import releases from "./releases";
import documents from "./documents";
import notifications from "./notifications";
import pipelines from "./pipelines";
import tasks from "./tasks";
import mailing from "./mailing";
import eventsLog from "./eventsLog";
import administration from "./administration";
import reports from "./reports";

const Api = {
  auth,
  gitlab,
  project,
  dictionaries,
  releases,
  documents,
  notifications,
  pipelines,
  tasks,
  mailing,
  eventsLog,
  administration,
  reports
};

export default Api;
