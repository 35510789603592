import {
  FETCH_GROUP_TAGS,
  SET_GROUP_TAGS,
  FETCH_TAG,
  SET_TAG,
  CREATE_TAG,
} from "./constants";

export const fetchGroupTags = (id) => ({
  type: FETCH_GROUP_TAGS,
  payload: {
    id,
  },
});

export const setGroupTags = (tags) => ({
  type: SET_GROUP_TAGS,
  payload: {
    tags,
  },
});

export const fetchTag = (groupId, repositoryId, tagId) => ({
  type: FETCH_TAG,
  payload: {
    groupId,
    repositoryId,
    tagId,
  },
});

export const setTag = (tag) => ({
  type: SET_TAG,
  payload: {
    tag,
  },
});

export const createTag = (groupId, repositoryId, params) => ({
  type: CREATE_TAG,
  payload: {
    groupId,
    repositoryId,
    params,
  },
});
