import {fromJS} from "immutable";
import {
    SET_FILTER_ADMINISTRATION,
    SET_LIST_PERMISSIONS_ADMINISTRATION, SET_PROJECT_ADMINISTRATION,
    SET_PROJECTS_ADMINISTRATION, SET_USER_ADMINISTRATION,
    SET_USERS_ADMINISTRATION
} from "./constants";


const initialState = fromJS({
    projects: [],
    membersInProject: [],
    permissionForUser: [],
    listPermissions: [],
    users: [],
    filter: {
        totalElements: 100,
        maxPage: 10,
        page: 0,
        size: 10,
        nameProject: '',
        nameUser: ''
    }
});

const administration = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECTS_ADMINISTRATION: {
            return state.set("projects", fromJS(action.payload.projects))
        }
        case SET_USERS_ADMINISTRATION: {
            return state.set("users", fromJS(action.payload.users))
        }
        case SET_FILTER_ADMINISTRATION: {
            return state.set("filter", fromJS(action.payload))
        }
        case SET_LIST_PERMISSIONS_ADMINISTRATION: {
            return state.set("listPermissions", fromJS(action.payload.listPermissions))
        }
        case SET_PROJECT_ADMINISTRATION: {
            return state.set("membersInProject", fromJS(action.payload.members))
        }
        case SET_USER_ADMINISTRATION: {
            return state.set("permissionForUser", fromJS(action.payload.permissions))
        }
        default: {
            return state;
        }
    }
};

export default administration;
