import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import ReactTable from "react-table";
import LoadingWrapper from "../common/LoadingWrapper";
import { FETCH_DICTIONARIES_LIST } from "../../store/dictionaries/constants";
import { dictionaryEntriesType } from "../../types/index";
import { LinkStyled, PageHeaderBlock, PageHeaderTitle } from "../common/styles";
import { Button } from "../common/Buttons/styles";
import Pagination from "../common/Pagination";
import DictionaryCreateModal from "../Dictionary/DictionaryCreateModal";


const Dictionaries = ({
  dictionariesList,
  onSelectObject,
  onClickReports,
  onClickTechSolutions,
  loaders,

  isCreateModalOpen,
  closeCreateModal,
  onSubmitDictionary,
  dictionary,
  openCreateModal,
}) => (
  <div>
    <PageHeaderBlock margin="10px 0 30px 0">
      <PageHeaderTitle>СПРАВОЧНИКИ</PageHeaderTitle>
      <div>
        <Button onClick={openCreateModal}>СОЗДАТЬ</Button>
        <Button onClick={onClickTechSolutions}>ТЕХНИЧЕСКИЕ РЕШЕНИЯ</Button>
        <Button onClick={onClickReports}>ШАБЛОНЫ ОТЧЕТОВ</Button>
      </div>
    </PageHeaderBlock>

      <DictionaryCreateModal
          dictionaryCode={dictionary}
          onSubmitDictionaryEntry={onSubmitDictionary}
          isOpen={isCreateModalOpen}
          closeModal={closeCreateModal}
      />

    <LoadingWrapper
      loaders={loaders}
      loaderKey={FETCH_DICTIONARIES_LIST}
      showPlaceholder={dictionariesList.length === 0}
      placeholderText="СПРАВОЧНИКИ ОТСУТСТВУЮТ"
    >
      <ReactTable
        PaginationComponent={Pagination}
        showPagination={dictionariesList.length > 10}
        defaultPageSize={10}
        minRows={0}
        data={dictionariesList}
        columns={[
          {
            Header: "НАИМЕНОВАНИЕ",
            accessor: "name",
            Cell: row => (
              <LinkStyled to={`/dictionaries/${row.original.code}`}>
                {row.value}
              </LinkStyled>
            )
          }
        ]}
      />
    </LoadingWrapper>
  </div>
);

Dictionaries.propTypes = {
  dictionariesList: dictionaryEntriesType.isRequired,
  onSelectObject: PropTypes.func.isRequired,
  onClickReports: PropTypes.func.isRequired,
  onClickTechSolutions: PropTypes.func.isRequired,
  loaders: PropTypes.instanceOf(Map).isRequired,
  isCreateModalOpen: PropTypes.bool.isRequired,
  closeCreateModal : PropTypes.func.isRequired,
    onSubmitDictionary : PropTypes.func.isRequired,
    dictionary:PropTypes.string.isRequired,
  openCreateModal: PropTypes.func.isRequired,
};

export default Dictionaries;
