import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  TableStyled,
  THead,
  TH,
  TBody,
  TD,
  TR
} from "../../common/Table/styles";
import { BoldSpan } from "../../common/styles";
import { ButtonIconClose } from "../../common/Buttons";
import { gitlabOptionsType } from "../../../types";
import { generateId } from "../../../utils/helpers";

const UsersTable = props => {
  const { users, onDeleteUser } = props;

  return (
    <TableContainer>
      {users.length > 0 ? (
        <TableStyled>
          <THead>
            <TR>
              <TH>ПОЛЬЗОВАТЕЛЬ</TH>
              <TH>РОЛЬ</TH>
              <TH>Срок доступа</TH>
              <TH />
            </TR>
          </THead>
          <TBody>
            {users.map(item => (
              <TR key={`users_item_${generateId()}`}>
                <TD>{item.user.fullName || item.user.username}</TD>
                <TD>
                  {item.permission === "USER"
                    ? "Пользователь"
                    : item.permission === "EXTERNAL_USER" ? "Внешний пользователь" : "Гость"}
                </TD>
                <TD width='200px'>
                  {item.expiresAt ? item.expiresAt:"-"}
                  </TD>
                <TD >
                  <ButtonIconClose
                    onClick={() => {
                      onDeleteUser(item);
                    }}
                  />
                </TD>
              </TR>
            ))}
          </TBody>
        </TableStyled>
      ) : (
        <BoldSpan>ПОЛЬЗОВАТЕЛИ НЕ ДОБАВЛЕНЫ</BoldSpan>
      )}
    </TableContainer>
  );
};

UsersTable.propTypes = {
  users: gitlabOptionsType.isRequired,
  onDeleteUser: PropTypes.func.isRequired
};

export default UsersTable;
