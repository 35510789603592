import React, {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {BoldSpan, Flex, SectionHeaderBlock, SectionHeaderTitle} from "../common/styles";
import {ButtonAdd, ButtonIconTrash} from "../common/Buttons";

import {API_HOST} from "../../utils/constants";
import {makeSelectProject} from "../../store/project/selectors";
import Pagination from "../common/Pagination";

class FpgEditor extends Component {
    state = {
        modalIsOpen: false,
        isNew: true,
        inputValue: "",
        selectedTask: null,
        error: {
            visible: false,
            text: "Данная задача уже выбрана"
        }
    };

    // eslint-disable-next-line consistent-return
    loadOptions = (inputValue, callback) => {
        if (this.state.inputValue.length < 2) return false;
        setTimeout(() => {
            axios({
                method: "GET",
                url: `${API_HOST}/api/fpa/v1/tasks/projects/asuz/${this.props.project.referenceAsuz.code}/search?search=${inputValue}`
            })
                .then((res) => {
                    this.setState({
                        listTasks: res.data.content
                    })
                })
                .then(() => {
                    callback(this.state.listTasks)
                })
        }, 1000);

    };

    handleTaskChange = inputValue => {
        this.setState({inputValue});
    }

    onChangeTask = option => {
        this.setState({selectedTask: option});
    }

    componentDidMount() {
        this.setState({
            selectedTasksToAdd: this.props.selectedTasks,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTasks !== prevProps.selectedTasks) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selectedTasksToAdd: this.props.selectedTasks,
            })
        }
    }

    render() {
        const {values} = this.props;

        return (
            <Flex flexDirection="column">
                <SectionHeaderBlock editor>
                    <SectionHeaderTitle>ИСПРАВЛЕННЫЕ ЗАМЕЧАНИЯ ФГП</SectionHeaderTitle>
                </SectionHeaderBlock>

                <div className="async_wrapper">
                  <AsyncSelect
                      className="w100 border"
                      cacheOptions
                      loadOptions={this.loadOptions}
                      getOptionValue={option => `${option.taskNumber}`}
                      getOptionLabel={option =>`Номер: '${option.taskNumber}', Автор: '${option.author}', Тема: '${option.topic}'` }
                      onInputChange={option => this.handleTaskChange(option) }
                      onChange={option => this.onChangeTask(option) }
                      noOptionsMessage={() => "Задачи не найдены"}
                      placeholder='Поиск по номеру'
                      value={this.state.selectedTask}
                  />

                  <ButtonAdd text="Добавить"
                             onClick={() => {
                               const idx = this.props.values.tasks.findIndex((el) => el.taskId === this.state.selectedTask.taskId)

                               if (idx !== -1) {
                                 this.setState({
                                   error: {
                                     visible: true,
                                     text: "Данная задача уже выбрана"
                                   }
                                 })
                               } else {
                                 this.props.values.tasks.push(this.state.selectedTask);
                                 this.setState({
                                   selectedTask: null,
                                   error: {
                                     visible: false
                                   }
                                 })
                               }

                             }}
                             disabled={!this.state.selectedTask}
                  />
                </div>

                <div>
                    {values.tasks && values.tasks.length > 0 ? (
                        <ReactTable
                            PaginationComponent={Pagination}
                            showPagination={values.tasks.length > 10}
                            defaultPageSize={10}
                            minRows={0}
                            data={values.tasks}
                            columns={[
                                {
                                    Header: 'НОМЕР',
                                    accessor: 'taskNumber',
                                    resizable: false,
                                    minWidth: 150,
                                },
                                {
                                    Header: 'АВТОР',
                                    accessor: 'author',
                                    resizable: false,
                                    minWidth: 120,
                                },
                                {
                                    Header: 'ТЕМА',
                                    accessor: 'topic',
                                    resizable: false,
                                    minWidth: 150,
                                },
                                {
                                    Header: "",
                                    accessor: "id",
                                    maxWidth: 50,
                                    Cell: row => (
                                        <ButtonIconTrash
                                            onClick={() => {
                                              this.props.values.tasks.splice(row.index, 1)
                                              this.setState({
                                                error: {
                                                  visible: false
                                                }
                                              })
                                            }}
                                        />
                                    )
                                }

                            ]}
                        />
                    ) : (
                        <BoldSpan>ИСПРАВЛЕННЫЕ ЗАМЕЧАНИЯ ФГП ОТСУТСТВУЮТ</BoldSpan>
                    )}
                </div>

            </Flex>
        );
    }
}


FpgEditor.propTypes = {
    values: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
    project: makeSelectProject(state)
});

export default connect(mapStateToProps)(FpgEditor);
