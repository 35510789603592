import React, {Component} from "react";

export class InfoBar extends Component {
    state = {isOpen: false}
   
    toggle(val=false){
        this.setState({isOpen:val});
    }

    render() {
        const {isOpen} = this.state;

        return (
            <div className='infoBar'>
                <button type='button' className='btnOpen' onClick={()=>this.toggle(!isOpen)} >?</button>
                {isOpen &&  <div className='list'>
                    <table className='minimalistBlack'>
                        <thead><tr><th>GITLAB</th><th>CODE</th><th>FPA</th></tr></thead>
                        <tbody>
                          <tr><td>MAINTAINER_ACCESS_LEVEL</td><td>40</td><td>USER</td></tr>
                          <tr><td>DEVELOPER_ACCESS_LEVEL</td><td>30</td><td>EXTERNAL_USER</td></tr>
                          <tr><td>GUEST_ACCESS_LEVEL</td><td>10</td><td>GUEST</td></tr>
                        </tbody>
                   </table>
                </div> }
            </div>
        )
    }
}
