import axios from "axios";
import { API_HOST } from "../utils/constants";

export default {
  putMailing: params =>
      axios({
          method: "PUT",
          url: `${API_HOST}/api/fpa/v1/periodicalReports`,
          data: params,
      }),

  fetchMailing: () =>
    axios({
        method: "GET",
        url: `${API_HOST}/api/fpa/v1/periodicalReports`,
    }),


};
