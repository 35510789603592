import {
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SIGN,
  DELETE_REP_DOCUMENT,
  DELETE_REPORT_FROM_PROJECT,
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_GENERATED_REPORT,
  DOWNLOAD_REPORT_EXAMPLE,
  FETCH_REPORTS,
  FETCH_TECH_SOLUTIONS,
  SET_REPORT_FROM_PROJECT,
  ADD_REPORT_FROM_PROJECT,
  SET_REPORTS,
  SET_TECH_SOLUTIONS,
  SIGN_DOCUMENT,
  UPLOAD_DOCUMENT,
  UPLOAD_REP_DOCUMENT,
  DOWNLOAD_DOCUMENTS_ZIP,
} from "./constants";

export const uploadDocument = (form) => ({
  type: UPLOAD_DOCUMENT,
  payload: {
    form,
  },
});

export const uploadRepDocument = (form) => ({
  type: UPLOAD_REP_DOCUMENT,
  payload: {
    form,
  },
});

export const downloadDocument = (id, title) => ({
  type: DOWNLOAD_DOCUMENT,
  payload: {
    id,
    title,
  },
});

export const downloadReportExample = (id, title) => ({
  type: DOWNLOAD_REPORT_EXAMPLE,
  payload: {
    id,
    title,
  },
});

export const downloadGeneratedReport = (
  templateReportDocumentId,
  projectId,
  dateBegin,
  dateEnd,
  releaseId,
  title
) => ({
  type: DOWNLOAD_GENERATED_REPORT,
  payload: {
    templateReportDocumentId,
    projectId,
    dateBegin,
    dateEnd,
    releaseId,
    title,
  },
});

export const deleteDocument = (id) => ({
  type: DELETE_DOCUMENT,
  payload: {
    id,
  },
});

export const deleteRepDocument = (id) => ({
  type: DELETE_REP_DOCUMENT,
  payload: {
    id,
  },
});

export const fetchTechSolutions = () => ({
  type: FETCH_TECH_SOLUTIONS,
});

export const setTechSolutions = (techSolutions) => ({
  type: SET_TECH_SOLUTIONS,
  payload: {
    techSolutions,
  },
});

export const fetchReports = () => ({
  type: FETCH_REPORTS,
});

export const setReports = (reports) => ({
  type: SET_REPORTS,
  payload: {
    reports,
  },
});

export const deleteReportFromProject = (templateId, projectId) => ({
  type: DELETE_REPORT_FROM_PROJECT,
  payload: {
    templateId,
    projectId,
  },
});

export const setReportToProject = (templateId, projectId) => ({
  type: SET_REPORT_FROM_PROJECT,
  payload: {
    templateId,
    projectId,
  },
});

export const addDocumentation = (templateId, data) => ({
  type: ADD_REPORT_FROM_PROJECT,
  payload: {
    templateId,
    data,
  },
});

export const signDocument = (isSign, documentId, releaseId) => ({
  type: SIGN_DOCUMENT,
  payload: {
    isSign,
    documentId,
    releaseId,
  },
});

export const deleteDocumentSign = (documentId, releaseId) => ({
  type: DELETE_DOCUMENT_SIGN,
  payload: {
    documentId,
    releaseId,
  },
});

export const downloadDocumentsZip = (projectId, documentIds, title) => ({
  type: DOWNLOAD_DOCUMENTS_ZIP,
  payload: {
    projectId,
    documentIds,
    title,
  },
});
