import styled from "styled-components";

const AppLogoIcon = require("../../../assets/icons/ic_app_logo.svg");

export const Container = styled.div`
  display: flex;
  height: 60px;
  background-color: #244a97;
  justify-content: space-between;
  align-items: center;
`;

export const AppInfoBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const AppLogoWrapper = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
`;

export const AppLogo = styled.div`
  height: 54px;
  width: 62px;
  background-image: url(${AppLogoIcon});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const AppTitle = styled.div`
  width: 205px;
  height: 55px;
  font-family: Roboto-Bold;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 1px;
  text-align: left;
  color: #ffffff;
  margin-left: 30px;
`;

export const UserBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 0px;
`;

export const UserLabel = styled.label`
  height: 26px;
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  
  padding: 2.5px 0;
`;

export const MenuBlock = styled.div`
  display: flex;
  align-items: center;
  
  .button-styled {
    font-family: Roboto-Regular;
    margin: 0 5px;
  }
`;

export const LogoutBlock = styled.div`
  display: flex;
  align-items: center;
  
  .button-styled {
    font-family: Roboto-Regular;
    padding: 0 34px;
  }
`;
