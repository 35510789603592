import * as ExcelJS from 'exceljs/dist/exceljs';
import { getDateFormatted } from "./helpers";
import  PipelineStagePrint from "../components/PipelineStageView/PipelineStageForPrint";
import { StageType } from "../types";


export const writeExcel = (projects) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'FPA';
    workbook.lastModifiedBy = 'Paul';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet("Projects");
    worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
    ];

    worksheet.autoFilter = {
        from: 'A1',
        to: 'M1'
    };
    																									
    worksheet.columns = [
        { header: '№', key: 'id', width: 10 },
        { header: 'ИУС', key: 'name', width: 25 },
        { header: 'Версия релиза', key: 'lastAcceptedReleaseName', width: 15, style: { alignment: { wrapText: true } } },
        { header: 'Дата принятия релиза', key: 'RELEASE_DATE', width: 20, style: { alignment: { wrapText: true } } },
        { header: 'Версия ИК', key: 'lastPipelineName', width: 20, style: { alignment: { wrapText: true } } },
        { header: 'Обновление ИК', key: 'UPDATE_SC', width: 20, style: { alignment: { wrapText: true } } },
        { header: 'Запуск CI/CD', key: 'COMMIT_DATE', width: 20, style: { numFmt: "0000" } },
        { header: 'Сборка', key: 'BUILD', width: 15 },
        { header: 'Проверка кода', key: 'CODE_ANALYZE', width: 25 },
        { header: 'Установка', key: 'DEPLOY', width: 25 },
        { header: 'Тестирование', key: 'TEST_AUTOMATIC', width: 25 },
    ];

    const firstRow = worksheet.getRow(1);
        firstRow.font = { name: 'New Times Roman', family: 4, size: 10, bold: true, color: { argb: '80EF1C1C' } };
        firstRow.alignment = { vertical: 'middle', horizontal: 'center' };
        firstRow.height = 20;
    
    const currentList = [];
    for (let row = 0; row < projects.length; row += 1) {
        currentList.push({
            id: row + 1,
            name: projects[row].name, 
            lastAcceptedReleaseName: (projects[row].lastAcceptedRelease && projects[row].lastAcceptedRelease.name )|| '-', 
            RELEASE_DATE: (projects[row].lastAcceptedRelease &&
                projects[row].lastAcceptedRelease.lastStatusInfo &&
                projects[row].lastAcceptedRelease.lastStatusInfo.creationDate &&
                getDateFormatted(
                    projects[row].lastAcceptedRelease.lastStatusInfo.creationDate
                )) || "-", 
            lastPipelineName: ((projects[row].lastPipeline && projects[row].lastPipeline.name )|| "-"),	
             COMMIT_DATE: (projects[row].lastPipeline &&
                projects[row].lastPipeline.dateUpdateCode &&
                projects[row].lastPipeline.dateUpdateCode !== '0001-01-01T00:00:00Z' &&
                getDateFormatted(projects[row].lastPipeline.dateUpdateCode)) ||
              "-",
             UPDATE_SC: (projects[row].projectDate &&
                projects[row].projectDate.commitDate &&
                getDateFormatted(projects[row].projectDate.commitDate)) ||
              "-", 
            BUILD: PipelineStagePrint(projects[row], projects[row].lastPipeline, StageType.BUILD),
            CODE_ANALYZE: PipelineStagePrint(projects[row], projects[row].lastPipeline, StageType.CODE_ANALYZE),
            DEPLOY: PipelineStagePrint(projects[row], projects[row].lastPipeline, StageType.DEPLOY),
            TEST_AUTOMATIC: PipelineStagePrint(projects[row], projects[row].lastPipeline, StageType.TEST_AUTOMATIC)
        })
      } 
    worksheet.addRows(currentList);

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        // const fileName = new Date();
        anchor.download = `${getDateFormatted(Date().toString())}_FPA_download.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
}
