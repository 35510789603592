function renderPeriod(period) {
  if (period.period === "WEEK") {
    return "Неделя"
  } if (period.period === "TWO_WEEK") {
    return "Две недели"
  } if (period.period === "MONTH") {
    return "Месяц"
  } if (period.period === "QUARTER") {
    return "Квартал"
  }
    return "-"

}
function MailingPeriod(props) {
  return (
    renderPeriod(props)
  )
}

export default MailingPeriod;