import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import get from "lodash.get";
import { notificationsResponseType } from "../../types";
import {
  BoldSpan,
  PageHeaderBlock,
  PageHeaderTitle,
  LinkStyled,
  TextRegular
} from "../common/styles";
import Pagination from "../common/Pagination";
import { getDateTimeFormatted } from "../../utils/helpers";

function Notifications(props) {
  const { notifications, onFetchNotifications } = props;

  return (
    <div>
      <PageHeaderBlock margin="10px 0 50px 0">
        <PageHeaderTitle>УВЕДОМЛЕНИЯ</PageHeaderTitle>
      </PageHeaderBlock>
      {notifications.totalElements > 0 ? (
        <ReactTable
          PaginationComponent={Pagination}
          showPagination={notifications.totalElements > 10}
          defaultPageSize={10}
          minRows={0}
          manual
          sortable={false}
          pages={notifications.totalPages}
          data={notifications.content}
          onFetchData={state => {
            const { page, pageSize } = state;
            onFetchNotifications(page, pageSize);
          }}
          columns={[
            {
              Header: "ДАТА",
              maxWidth: 200,
              id: "creationDate",
              accessor: d => getDateTimeFormatted(d.creationDate)
            },
            {
              Header: "ОТ КОГО",
              maxWidth: 200,
              id: "fromWhom",
              accessor: d => get(d, "fromWhom.fullName", "-")
            },
            {
              Header: "СООБЩЕНИЕ",
              id: "message",
              Cell: row => {
                const projectId = get(row, "original.project.id", -1);
                if (projectId !== -1) {
                  return (
                    <LinkStyled to={`project/${projectId}`}>
                      {row.original.message || "-"}
                    </LinkStyled>
                  );
                }
                return <TextRegular>{row.original.message || "-"}</TextRegular>;
              }
            }
          ]}
        />
      ) : (
        <BoldSpan>УВЕДОМЛЕНИЯ ОТСУТСТВУЮТ</BoldSpan>
      )}
    </div>
  );
}

Notifications.propTypes = {
  notifications: notificationsResponseType.isRequired,
  onFetchNotifications: PropTypes.func.isRequired
};

export default Notifications;
