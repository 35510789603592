import React, {Component} from "react";
import {connect} from "react-redux";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {BoldSpan, PageHeaderBlock, PageHeaderTitle } from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";
import Api from "../../../api";
import {errorParser, removeLoader, setError, setLoader} from "../../../store/preloader/actions";
import SelectorDepartment from "../../../components/ProjectEditor/SelectorDepartment";
import loadFileHelper from "../../../utils/loadFileHelper";
import {GLOBAL_LOADER} from "../../../store/preloader/constants";
import {LOAD_DATA_REPORT} from "../../../utils/constants";

class AdminReportDetailPage extends Component {
    state = {
        reportData: [],
        nameReport: '',
        dictionaryEntry: [],
        fromDate: null,
        dateTo: null,
        reports: [],
        humanNameReport: '',
        disabledDownload: true,
    }

     customSetState (field, value)  {
        this.setState(state=>({
            ...state,
            [field]: value,
        }));
    }

    checkDate() {
        const {fromDate, dateTo} = this.state;
        if(!fromDate || !dateTo) {
            return true;
        }
        const from = moment(fromDate);
        const to = moment(dateTo);
        const diff = to.diff(from, 'day');
        return diff >= 0;
    }

    async getReportName(){
        try {
            this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
            const res = await Api.reports.getReports();
            const convertData =  res && res.data ? res.data : [];
            if( convertData && convertData.length > 0) {
                const find = convertData.find(item => item.reportName === this.state.nameReport );
               if(find) this.customSetState('humanNameReport', find.displayName);
            }
        }  catch (e){
            console.log(e);
        } finally {
            this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
        }
    }


   async componentDidMount() {
        const {
            match: {
                params: {reportCode}
            },
        } = this.props;
       this.customSetState('nameReport', reportCode);
       await this.getReportName();
    }

   getSendData(){
       const sendData = {
           "departments": this.state.dictionaryEntry.map(item => Number(item.id)),
           "dateFrom": this.state.fromDate,
           "dateTo": this.state.dateTo
       };

       if(!sendData.dateTo) delete sendData.dateTo;
       if(!sendData.dateFrom) delete sendData.dateFrom;

       return sendData;
   }

   async showReport() {
        try {
            const sendData = this.getSendData();
            this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
            const res = await Api.reports.getReportsByCode(this.state.nameReport, sendData);
            this.customSetState('reportData', res.data);
            this.customSetState('disabledDownload', false);
        }  catch (error){
            console.log(error);
            setError(`Ошибка  - ${ errorParser(error) || error.message }`);
        } finally {
            this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
        }
    }

    async loadReport() {

        try {
            const sendData = this.getSendData();
            this.props.setLoader(GLOBAL_LOADER, LOAD_DATA_REPORT, "ЗАГРУЗКА ДАННЫХ");
            const res = await Api.reports.downloadReportsByCode(this.state.nameReport, sendData);
            const contentDisposition = res.headers['content-disposition'];
            const filenameIndex = contentDisposition.indexOf('filename=');
            let filename = contentDisposition.substring(filenameIndex + 10).split(';')[0];
            filename = filename.slice(0, filename.length - 1);
            if(!filename) filename = res.headers["file-name"];
            if(!filename) filename = 'report.xlsx';
            if(filename[0] == '_' || filename[0] == '"') filename = filename.slice(1);
            if(filename[filename.length - 1] == '_' || filename[filename.length - 1] == '"') filename = filename.slice(0, filename.length - 1);

            loadFileHelper(res,filename);
        }  catch (error){
            console.log(error);
            setError(`Ошибка  - ${ errorParser(error) || error.message }`);
        } finally {
            this.props.removeLoader(GLOBAL_LOADER, LOAD_DATA_REPORT);
        }
    }

    render() {
        const { reportData, dictionaryEntry, disabledDownload } = this.state;
        const rowLabelWidth = "300px";
        const selectWidth = "40%";
        const convertData  = {
            dictionaryEntry
        }
        const curentDate = new Date().toISOString().split("T")[0];


        const hasAllSelected = dictionaryEntry &&  dictionaryEntry.length > 0 && this.checkDate();

        const getCheckLink = (project)=> project && project.gitlabUrl?  project.gitlabUrl  : ''

        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Отчет: { this.state.humanNameReport || this.state.nameReport}</PageHeaderTitle>
                    <Link to="/admin/reports"><ButtonSimple text="назад"/></Link>
                </PageHeaderBlock>

                <div className='controlBlock'>
                    <div className='element'>от
                        <div className='dateInputWrap'>
                            <input type='date'
                                   max={curentDate}
                                   className='dataInput'
                                   value={this.state.fromDate}
                                   onChange={(val) =>{
                                       this.customSetState('fromDate', val.target.value);
                                       this.customSetState('disabledDownload', true);
                                   }}
                            />
                        </div>
                    </div>

                    <div className='element'>до
                        <div className='dateInputWrap'>
                            <input type='date'
                                   max={curentDate}
                                   className='dataInput'
                                   value={this.state.dateTo}
                                   onChange={(val) =>{
                                       this.customSetState('dateTo', val.target.value);
                                       this.customSetState('disabledDownload', true);
                                   }}
                            />
                        </div>
                    </div>
                    <div className='element'>Ответственный за ИК
                        <div className='selectWrap'>
                    <SelectorDepartment
                        code="departments"
                        onlySelector
                        rowLabelWidth={rowLabelWidth}
                        selectWidth={selectWidth}
                        fieldName="dictionaryEntry"
                        nameProperty="dictionaryEntry"
                        values={convertData}
                        isMultiSelect
                        handleChange={(val=>{
                            this.customSetState('dictionaryEntry', val);
                            this.customSetState('disabledDownload', true);
                        })}/>
                        </div>
                    </div>
                    <ButtonSimple disabled={!hasAllSelected} style={{marginTop: "20px"}} text="Показать" onClick={()=>this.showReport()}/>
                    <ButtonSimple disabled={disabledDownload} style={{marginTop: "20px"}} text="Скачать" onClick={()=>this.loadReport()}/>
                </div>

                {reportData.length > 0 ? (
                    <ReactTable
                        defaultPageSize={10}
                        minRows={1}
                        data={reportData}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "Дата последнего обновления",
                                accessor: "dateUpdateCode",
                                Cell: row => (<div>{row.original.dateUpdateCode || 'Обновлений кода не найдено'}</div>)
                            },
                            {
                                Header: "Ответственный за ИК",
                                accessor: "department",
                                Cell: row => (<div>{row.original.department.name}</div>)
                            },

                            {
                                Header: "Название проекта",
                                Cell: row => (<div>{row.original.project.name}</div>)
                            },

                            {
                                Header: "Ссылка на проект GITLAB",
                                Cell: row => (<a href={getCheckLink(row.original.project)} target="_blank" rel="noreferrer" >{getCheckLink(row.original.project)}</a>)
                            },
                        ]}
                    />
                ) : (
                    <BoldSpan>Нет данных</BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
    setError: error=> dispatch(setError(error)),
    setLoader: (loaderScope, loaderKey, message) => dispatch(setLoader(loaderScope, loaderKey, message)),
    removeLoader: (loaderScope, loaderKey) => dispatch(removeLoader(loaderScope, loaderKey))
});

AdminReportDetailPage.propTypes = {
    setLoader: PropTypes.func.isRequired,
    removeLoader: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminReportDetailPage);
