import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import get from "lodash.get";
import { BoldSpan, PageHeaderBlock, PageHeaderTitle } from "../common/styles";
import {ButtonIconTrash, ButtonIconEdit, ButtonAdd, ButtonSave} from "../common/Buttons";
import MailingModal from "../MailingModal";
import MailingPeriod from "./MailingPeriod";

function MailingList(props) {
  const { isOpen, list, projectToAdd, reportTemplateToAdd, reports, projectsOptions, duration, focused, date, onFocusChange, onDateChange, onDeleteRow, handleShowModal, onSubmitModal, handleCloseModal, onChangeProject, onChangeReport, onChangeDuration, onSubmitMailing, durationToAdd, onActionEdit } = props;

  return (
    <div>
      <PageHeaderBlock margin="10px 0 30px 0">
          <PageHeaderTitle>МОИ РАССЫЛКИ</PageHeaderTitle>
          <div style={{marginLeft: "auto"}}>
            <ButtonAdd text="Добавить"  onClick={handleShowModal}/>
            <ButtonSave type="submit"  text="Сохранить" onClick={onSubmitMailing}/>
          </div>

        </PageHeaderBlock>
      <MailingModal
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          onChangeProject={onChangeProject}
          onChangeReport={onChangeReport}
          onChangeDuration={onChangeDuration}
          reports={reports}
          duration={duration}
          projectsOptions={projectsOptions}
          focused={focused}
          date={date}
          onFocusChange={onFocusChange}
          onDateChange={onDateChange}
          projectToAdd={projectToAdd}
          reportTemplateToAdd={reportTemplateToAdd}
          durationToAdd={durationToAdd}
          onSubmitModal={onSubmitModal}
        />

      {list.length > 0 ? (
        <ReactTable
          minRows={0}
          showPagination={false}
          data={props.list}
          columns={[
            {
              Header: "ПРОЕКТ",
              id: "name",
              accessor: d => get(d, "project.gitLabId", "-")
            },
            {
              Header: "ШАБЛОН ОТЧЕТА",
              id: "template",
              Cell: row => (
                  <>
                    {row.original.reportTemplate ? `${row.original.reportTemplate.comment} (${row.original.reportTemplate.fileOriginalName})` : "-"}
                  </>
                )
            },
            {
              Header: "ДАТА ФОРМИРОВАНИЯ",
              id: "date",
              accessor: d => get(d, `date`, "-")
            },
            {
              Header: "ДИАПАЗОН",
              id: "period",
              Cell: row => (
                  <>
                  <MailingPeriod
                    period={row.original.period}
                  />
                  </>

                )
            },
            {
                Header: "ДЕЙСТВИЯ",
                accessor: "id",
                maxWidth: 100,
                Cell: row => (
                  <>
                  <ButtonIconEdit
                  onClick={() => {
                      onActionEdit(row.original);
                    }}

                  />
                  <ButtonIconTrash
                    onClick={() => {
                      onDeleteRow(row.original.userKey);
                    }}
                  />
                  </>

                )
              }
          ]}
        />
      ) : (
        <BoldSpan>РАССЫЛКИ ОТСУТСТВУЮТ</BoldSpan>
      )}
    </div>
  );
}
MailingList.defaultProps = {
  projectToAdd: {},
  reportTemplateToAdd: {},
  reports: [],
};

MailingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  projectToAdd: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  reportTemplateToAdd: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  reports: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired,
  onActionEdit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default MailingList;
