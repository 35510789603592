/* eslint-disable class-methods-use-this */
import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    BadgeBlack,
    BadgeBlackLinked,
    BadgeBlue,
    BadgeBlueLinked,
    BadgeGreen,
    BadgeGreenLinked,
    BadgeRed,
    BadgeRedLinked,
    BadgeYellow,
    BadgeYellowLinked
} from "./styles";
import {StageCodeAnalyzeStatusType, StageStatusType, StageType} from "../../types";
import MenuGitExt from "./MenuGitExt";
import {GL_HOST} from "../../utils/constants";


class PipelineStageView extends Component {

    // Безопасно получить статус из объекта
    getStatusSafe(object) {
        return object === null
            ? StageStatusType.UNKNOWN
            : object.status === null
                ? StageStatusType.UNKNOWN
                : object.status
    }

    // Получить статус
    getEffectiveStageStatus(pipeline, stage) {
        const pipelineStatus = this.getStatusSafe(pipeline);
        return pipelineStatus === StageStatusType.PENDING
            ? StageStatusType.PENDING
            : pipelineStatus === StageStatusType.RUNNING
                ? StageStatusType.RUNNING
                : this.getStatusSafe(stage)
    }

    // Получить описание шага по типу шага
    getStageByType(pipeline, stageType) {
        if (pipeline === null) {
            return null;
        }
        switch (stageType) {
            case StageType.BUILD:
                return pipeline.stageBuild;
            case StageType.CODE_ANALYZE:
                return pipeline.stageCodeAnalyze;
            case StageType.DEPLOY:
                return pipeline.stageDeploy;
            case StageType.TEST_AUTOMATIC:
                return pipeline.stageAutomaticTests;
            case StageType.TEST_PERFORMANCE:
                return pipeline.stagePerformanceTests;
            case StageType.TEST_MANUAL:
                return pipeline.stageManualTests;
            default:
                return null;
        }
    }

    renderStatusBadge(status, link, fallback) {
        if(link === null) {
            if (status === StageStatusType.FAILURE) {
                return <BadgeRed>Неудачно</BadgeRed>;
            } if (status === StageStatusType.PENDING) {
                return <BadgeYellow>В ожидании</BadgeYellow>;
            } if (status === StageStatusType.RUNNING) {
                return <BadgeBlue>Выполняется</BadgeBlue>;
            } if (status === StageStatusType.SUCCESSFULLY) {
                return <BadgeGreen>Успешно</BadgeGreen>;
            } if (status === StageStatusType.CANCELED) {
                return <BadgeBlack>Отменено</BadgeBlack>;
            }
        }
        else {
            if (status === StageStatusType.FAILURE) {
                return <BadgeRedLinked onClick={() => { window.open(link, "_blank" );}}>Неудачно</BadgeRedLinked>;
            } if (status === StageStatusType.PENDING) {
                return <BadgeYellowLinked onClick={() => { window.open(link, "_blank");}}>В ожидании</BadgeYellowLinked>;
            } if (status === StageStatusType.RUNNING) {
                return <BadgeBlueLinked onClick={() => { window.open(link, "_blank");}}>Выполняется</BadgeBlueLinked>;
            } if (status === StageStatusType.SUCCESSFULLY) {
                return <BadgeGreenLinked onClick={() => { window.open(link, "_blank");}}>Успешно</BadgeGreenLinked>;
            } if (status === StageStatusType.CANCELED) {
                return <BadgeBlackLinked onClick={() => { window.open(link, "_blank");}}>Отменено</BadgeBlackLinked>;
            }
        }

        if( fallback !== null ) {
            return fallback(status, link);
        }

        return <></>;
    }

    renderStatusBadgeNoData(status, link) {
        if(link !== null) {
            return <BadgeBlackLinked onClick={() => { window.open(link, "_blank");}}>Нет данных</BadgeBlackLinked>
        }

        return <BadgeBlack>Нет данных</BadgeBlack>
    }

    renderStaticAnalyzeBadge(pipeline) {
        if(pipeline === null || pipeline.stageCodeAnalyzeProjectStatus === null) {
            return <></>;
        }

        const status = pipeline.stageCodeAnalyzeProjectStatus
        if (status === StageCodeAnalyzeStatusType.OK) {
            return <BadgeGreen>ОК</BadgeGreen>;
        } if (status === StageCodeAnalyzeStatusType.WARN) {
            return <BadgeYellow>Внимание</BadgeYellow>;
        } if (status === StageCodeAnalyzeStatusType.ERROR) {
            return <BadgeRed>Ошибка</BadgeRed>;
        }

        return <></>;
    }

    renderTestAutomaticBadge(pipeline) {
        if(pipeline === null || pipeline.stageAutomaticTestsCoverageP === null) {
            return <></>
        }

        return <BadgeBlue>{pipeline.stageAutomaticTestsCoverageP.toFixed()}%</BadgeBlue>
    }

    // eslint-disable-next-line class-methods-use-this
    renderTestManualBadge(pipeline) {
        if (pipeline === null ||
            (pipeline.stageManualTestsAllTestCount === null &&
                pipeline.stageManualTestsSuccessfullyTestCount === null &&
                pipeline.stageManualTestFailTestCount === null)) {
            return <></>
        }

        const safeAllCount = pipeline.stageManualTestsAllTestCount || "?";
        const safeSuccess = pipeline.stageManualTestsSuccessfullyTestCount || "?";
        const safeFail = pipeline.stageManualTestFailTestCount || "?";
        return <BadgeBlue>{safeAllCount}/{safeSuccess}/{safeFail}</BadgeBlue>
    }

    renderBuildStage(project, pipeline, stage, status) {
        let link = null
        if (project.gitlabGroup && project.gitlabGroup.name && pipeline.repository && pipeline.repository.name && pipeline.id) {
            link = `${GL_HOST}/${project.gitlabGroup.name}/${pipeline.repository.name}/pipelines/${pipeline.id}`
        }

        return <>
            {this.renderStatusBadge(status, link, this.renderStatusBadgeNoData)}
        </>
    }

    renderCodeAnalyzeStage(project, pipeline, stage, status) {
        const link = project && project.referenceSonarQube && project.referenceSonarQube[0]
            ? project.referenceSonarQube[0].redirectUrl
            : null;

        return <>
            {this.renderStatusBadge(status, link, null)}
            {this.renderStaticAnalyzeBadge(pipeline)}
        </>;
    }

    renderDeployStage(project, pipeline, stage, status) {
        return <>
            {this.renderStatusBadge(status, null, null)}
        </>;
    }

    renderTestAutomaticStage(project, pipeline, stage, status) {
        return <>
            {this.renderStatusBadge(status, null, null)}
            {this.renderTestAutomaticBadge(pipeline)}
            <MenuGitExt
                loaders={this.props.loaders}
                project={project}
                pipeline={pipeline}
                stage={stage}/>
        </>
    }

    renderTestPerformanceStage(project, pipeline, stage, status) {
        return <>
            {this.renderStatusBadge(status, null, null)}
            <MenuGitExt
                project={project}
                pipeline={pipeline}
                stage={stage}/>
        </>
    }

    renderTestManualStage(project, pipeline, stage, status) {
        return <>
            {this.renderStatusBadge(status, null, null)}
            {this.renderTestManualBadge(pipeline)}
        </>;
    }


    render() {
        const {project, pipeline, stageType} = this.props;
        // Получить описание шага и общий статус операции
        const stage = this.getStageByType(pipeline, stageType);
        if (stage === null) {
            return <></>
        }
        const status = this.getEffectiveStageStatus(pipeline, stage);

        switch (stageType) {
            case StageType.BUILD:
                return this.renderBuildStage(project, pipeline, stage, status);
            case StageType.CODE_ANALYZE:
                return this.renderCodeAnalyzeStage(project, pipeline, stage, status);
            case StageType.DEPLOY:
                return this.renderDeployStage(project, pipeline, stage, status);
            case StageType.TEST_AUTOMATIC:
                return this.renderTestAutomaticStage(project, pipeline, stage, status);
            case StageType.TEST_PERFORMANCE:
                return this.renderTestPerformanceStage(project, pipeline, stage, status);
            case StageType.TEST_MANUAL:
                return this.renderTestManualStage(project, pipeline, stage, status);
            default:
                return null;
        }
    }

}
PipelineStageView.propTypes = {
    loaders: PropTypes.instanceOf(Map).isRequired,
}

export default PipelineStageView;
