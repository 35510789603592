import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import {BoldSpan, PageHeaderBlock, PageHeaderTitle, SubTitle} from "../../../components/common/styles";
import {ButtonSimple} from "../../../components/common/Buttons";
import Pagination from "../../../components/common/Pagination";
import {fetchProjectByIdPermissionsAdministration} from "../../../store/administration/actions";
import {makeSelectAllMembersAdministaration,} from "../../../store/administration/selectors";
import {SelectorList} from "../../../components/common/SelectList/SelectorList";
import {adminstrationService} from "../../../Services/administration.service";
import Api from "../../../api";
import {errorParser, setError} from "../../../store/preloader/actions";
import {Link} from "react-router-dom";
import {InfoBar} from "../../../components/InfoBar/InfoBar";
import {TableRoleCheck} from "../../../components/TableRoleCheck/TableRoleCheck";

const NAME_FIELD = {
    userSelect: 'userSelect',
    permissionSelect: 'permissionSelect',
    dateSelect: 'dateSelect',
    projectId: 'projectId',
    modeIsAdd: 'modeIsAdd',
    modeIsEdit: 'modeIsEdit',
    editNow: 'editNow',
    nameProject: 'nameProject',
    checkTable: 'checkTable'
}

class AdminProjectPage extends Component {
    state = {
        [NAME_FIELD.modeIsAdd]: false,
        allMembersInProject: [],
        [NAME_FIELD.projectId]: 0,
        [NAME_FIELD.userSelect]: null,
        [NAME_FIELD.permissionSelect]: null,
        [NAME_FIELD.dateSelect]: '',
        [NAME_FIELD.modeIsAdd]: false,
        [NAME_FIELD.editNow]: null,
        [NAME_FIELD.checkTable]: []
    }
    async loadDetailInfo(projectId) {
       try {
           const res = await Api.administration.getShortProjectById(projectId);
           this.setState({
               [NAME_FIELD.nameProject]: res && res.data && res.data.name ?  res.data.name : ''
           });
       }  catch (e){
           console.log(e);
       }
    }
    async componentDidMount() {
        const {
            match: {
                params: {projectId}
            },
            fetchProjectByIdAdmin,
        } = this.props;
        this.setState((state) => ({...state,  [NAME_FIELD.projectId]:projectId}));
        fetchProjectByIdAdmin(projectId);
        await this.loadDetailInfo(projectId)
    }

    render() {
        const { fetchProjectByIdAdmin, allMembers, setError } = this.props;
        const { projectId} = this.state;
        const { fetchUser,
            fetchPermissions ,
            saveNewPermisions,
            deletePermisions,
            parsePermissionForSelector,
            convertDateFromRow,
            checkAllPermissions,
            syncAllPermissions}  = adminstrationService;
        const customSetState = (field, value) => {
            this.setState(state=>({
                    ...state,
                    [field]: value,
                }));
       }
        const getValue = (field) => this.state[field]
        const modeIsAdd = getValue(NAME_FIELD.modeIsAdd);
        const modeIsEdit = getValue(NAME_FIELD.modeIsEdit);
        const updateData = () => {
            fetchProjectByIdAdmin(projectId);
        }
        const deletePermissonHandele = async (row) => {
            await deletePermisions(row);
            updateData();
        }
        const addNew = ()=>{

            customSetState(NAME_FIELD.permissionSelect, null);
            customSetState(NAME_FIELD.dateSelect, '');
            customSetState(NAME_FIELD.userSelect, null );
            customSetState(NAME_FIELD.modeIsEdit, false);
            customSetState(NAME_FIELD.modeIsAdd, true);
        }
        const save = async ()=>{
            customSetState(NAME_FIELD.modeIsAdd, false);
            await  saveNewPermisions(
                getValue(NAME_FIELD.userSelect),
                getValue(NAME_FIELD.permissionSelect),
                getValue(NAME_FIELD.dateSelect),
                getValue(NAME_FIELD.projectId),
                getValue(NAME_FIELD.modeIsEdit),
                getValue(NAME_FIELD.editNow)
            );
            customSetState(NAME_FIELD.editNow, false)
            updateData()
        }

        const setModeEdit = (row) => {
            customSetState(NAME_FIELD.userSelect, row.userSearchDTO )
            customSetState(NAME_FIELD.modeIsEdit, true)
            customSetState(NAME_FIELD.modeIsAdd, true);
            customSetState(NAME_FIELD.permissionSelect, parsePermissionForSelector(row.permission));
            customSetState(NAME_FIELD.dateSelect, convertDateFromRow(row.expiresAt));
            customSetState(NAME_FIELD.editNow, row);
        }

        const commonSync = async (check = false) =>{
            if (check || window.confirm("Вы уверены?")) {
                const res =  check? await checkAllPermissions(getValue(NAME_FIELD.projectId)) :
                await syncAllPermissions(getValue(NAME_FIELD.projectId));
                if(!res.result) {
                    const error = res.error;
                    setError(`Ошибка  - ${ errorParser(error) || error.message }`);
                } else {
                    customSetState(NAME_FIELD.checkTable, res.data);
                }
            }
        }

        const checkAllPerm = async () => {
          await  commonSync(true)
        }

        const syncAllPerm = async () => {
           await commonSync(false)
        }

        const closeRules= ()=>{
            customSetState(NAME_FIELD.checkTable, []);
        }

        const closeAddForm = ()=> {
            customSetState(NAME_FIELD.modeIsAdd, false);
        }

       const  tableCheck = getValue(NAME_FIELD.checkTable);
        const linkProject = `/project/${getValue(NAME_FIELD.projectId)}`;
        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Администрирование проекта <SubTitle><Link to={linkProject}>{getValue(NAME_FIELD.nameProject)}</Link></SubTitle></PageHeaderTitle>
                    <ButtonSimple text="Добавить пользователя" onClick={addNew}/>
                    <ButtonSimple text="Состояние ролей" onClick={checkAllPerm}/>
                    <ButtonSimple text="Обновить роли из Gitlab" onClick={syncAllPerm}  hbc='#f17a04'  hbgc='#f17a04' hc='#fff' />
                    <Link to="/admin/projects/"><ButtonSimple text="назад"/></Link>
                </PageHeaderBlock>

                {modeIsAdd && <div className='blockAdd'>
                    <div className='blockAdd__title'>
                        {getValue(NAME_FIELD.modeIsAdd) && !getValue(NAME_FIELD.modeIsEdit) ?'Создать новый доступ':'Редактировать'}</div>

                    <div className='element'>Пользователь
                       <SelectorList fetchData={fetchUser}
                                     disabled={getValue(NAME_FIELD.modeIsEdit)}
                                  labelKey="fullName"
                                  nowData={getValue(NAME_FIELD.userSelect)}
                                  setValue={(val)=>customSetState(NAME_FIELD.userSelect, val)}
                        />
                    </div>
                    <div className='element'> <div className='elementHead'>Роль<InfoBar /></div>
                        <SelectorList
                            fetchData={fetchPermissions}
                            labelKey="label"
                            nowData={getValue(NAME_FIELD.permissionSelect)}
                            setValue={(val)=>customSetState(NAME_FIELD.permissionSelect, val)}
                        />
                    </div>
                    <div className='element'>Срок доступа
                        <div className='dateInputWrap'>
                            <input type='date'
                                   className='dataInput'
                                   value={getValue(NAME_FIELD.dateSelect)}
                                   onChange={(val)=>customSetState(NAME_FIELD.dateSelect, val.target.value)}
                            />
                        </div>
                    </div>
                    <div className='element'><br/>
                        <ButtonSimple text="Сохранить" onClick={save}/>
                    </div>
                    <div className='element'><br/>
                        <ButtonSimple text="Закрыть" onClick={closeAddForm}/>
                    </div>
                </div> }

                {tableCheck.length>0?
                    <div className='confirmRuleBlock'><ButtonSimple text="закрыть" onClick={closeRules}/>
                     <TableRoleCheck tableCheck={tableCheck}   />
                </div>

                    :<></>}

                {allMembers.length > 0 ? (
                    <ReactTable
                        defaultPageSize={10}
                        minRows={1}
                        data={allMembers}
                        PaginationComponent={Pagination}
                        columns={[
                            {
                                Header: "Срок доступа",
                                accessor: "expiresAt"
                            },
                            {
                                Header: "Роль",
                                accessor: "permission"
                            },
                            {
                                Header: "Пользователь",
                                Cell: row => (<div>{row.original.userSearchDTO.fullName}</div>)
                            },
                            {
                                Header: "Действия",
                                Cell: row => (<div>
                                    <ButtonSimple text="Удалить"
                                                  onClick={ () => deletePermissonHandele(row.original)} />
                                    <ButtonSimple text="Редактировать"
                                                  onClick={ () => setModeEdit(row.original)} />
                                </div>)
                            }
                        ]}
                    />
                ) : (
                    <BoldSpan>Нет пользователей в проекте </BoldSpan>
                )}
            </div>);
    }
}

const mapStateToProps = state => ({
    allMembers: makeSelectAllMembersAdministaration(state)
});

const mapDispatchToProps = dispatch => ({
    fetchProjectByIdAdmin: (id) => dispatch(fetchProjectByIdPermissionsAdministration(id)),
    setError: error=> dispatch(setError(error))
});

AdminProjectPage.propTypes = {
    fetchProjectByIdAdmin: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    allMembers: PropTypes.array.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminProjectPage);
